var profile = angular.copy(window.myAppActiveProfile);

var locale = profile.lang === 'en' ? 'en-gb' : profile.lang;

moment.locale(locale);
moment.tz.setDefault('Europe/Copenhagen');

var app = angular.module('ecom', [
    'ngRoute',
    'ngCookies',
    'chieffancypants.loadingBar',
    'pascalprecht.translate',
    'ngFileUpload',
    'monospaced.elastic',
    'colorpicker.module',
    'textAngular',
    'ui.bootstrap',
    'ui.sortable',
    'ui.select',
    'ngSanitize',
    'chart.js',
    'mw-datepicker-range',
    'ui.bootstrap.datetimepicker',
    'ecom.services',
    'ecom.controllers'
]);

app.filter("asDate", function () {
    return function (input) {
        return new Date(input);
    }
});

app.filter("unique", function() {
    // we will return a function which will take in a collection
    // and a keyname
    return function(collection, keyname) {
        // we define our output and keys array;
        var output = [],
            keys = [];

        // we utilize angular's foreach function
        // this takes in our original collection and an iterator function
        angular.forEach(collection, function(item) {
            // we check to see whether our object exists
            var key = item[keyname];
            // if it's not already part of our keys array
            if (keys.indexOf(key) === -1) {
                // add it to our keys array
                keys.push(key);
                // push this item to our final output array
                output.push(item);
            }
        });
        // return our array which should be devoid of
        // any duplicates
        return output;
    };
});

app.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {
    $locationProvider.hashPrefix('');
    $routeProvider.when('/dashboard', {
        templateUrl: function() {
            return 'partials/dashboard-' + window.myAppActiveProfile.type + '.html';
        },
        controller: 'dashCtrl'
    });
    $routeProvider.when('/orders', {templateUrl: 'partials/order-list.html', controller: 'orderCtrl', reloadOnSearch: false});
    $routeProvider.when('/orders/:orderId', {
        templateUrl: 'partials/order-details.html',
        controller: 'orderDetailCtrl'
    });
    $routeProvider.when('/customers', {templateUrl: 'partials/customer-list.html', controller: 'customerCtrl', reloadOnSearch: false});
    $routeProvider.when('/customers/import', {
        templateUrl: 'partials/customer-import.html',
        controller: 'customerImportCtrl'
    });
    $routeProvider.when('/customers/handout', {
        templateUrl: 'partials/customer-handout.html',
        controller: 'customerHandoutCtrl',
        reloadOnSearch: false
    });
    $routeProvider.when('/customers/groups', {
        templateUrl: 'partials/customer-group.html',
        controller: 'customerGroupCtrl'
    });
    $routeProvider.when('/customers/pending', {
        templateUrl: 'partials/pending-customers.html',
        controller: 'pendingCustomersCtrl'
    });
    $routeProvider.when('/customers/:customerId', {
        templateUrl: 'partials/customer-details.html',
        controller: 'customerDetailCtrl'
    });
    $routeProvider.when('/products', {templateUrl: 'partials/product-list.html', controller: 'productCtrl', reloadOnSearch: false});
    $routeProvider.when('/products/archive', {
        templateUrl: 'partials/product-archive.html',
        controller: 'productArchiveCtrl'
    });
    $routeProvider.when('/products/import', {
        templateUrl: 'partials/product-import.html',
        controller: 'productImportCtrl'
    });
    $routeProvider.when('/products/:productId', {
        templateUrl: 'partials/product-details.html',
        controller: 'productDetailCtrl'
    });
    $routeProvider.when('/categories', {templateUrl: 'partials/category-list.html', controller: 'categoryCtrl', reloadOnSearch: false});
    $routeProvider.when('/categories/archive', {
        templateUrl: 'partials/category-archive.html',
        controller: 'categoryArchiveCtrl'
    });
    $routeProvider.when('/categories/:categoryId', {
        templateUrl: 'partials/category-details.html',
        controller: 'categoryDetailCtrl'
    });
    $routeProvider.when('/shops', {
        templateUrl: 'partials/shop-list.html',
        controller: 'shopCtrl',
        reloadOnSearch: false
    });
    $routeProvider.when('/shops/archive', {
        templateUrl: 'partials/shop-archive.html',
        controller: 'shopArchiveCtrl',
        reloadOnSearch: false
    });
    $routeProvider.when('/shops/:shopId', {
        templateUrl: 'partials/shop-details.html',
        controller: 'shopDetailCtrl'
    });
    $routeProvider.when('/pages', {templateUrl: 'partials/page-list.html', controller: 'pageCtrl', reloadOnSearch: false});
    $routeProvider.when('/pages/archive', {
        templateUrl: 'partials/page-archive.html',
        controller: 'pageArchiveCtrl'
    });
    $routeProvider.when('/pages/:pageId', {templateUrl: 'partials/page-details.html', controller: 'pageDetailCtrl'});
    $routeProvider.when('/reports', {templateUrl: 'partials/reports.html', controller: 'reportCtrl', reloadOnSearch: false});
    $routeProvider.when('/themes', {templateUrl: 'partials/themes.html', controller: 'themeCtrl'});
    $routeProvider.when('/settings', {templateUrl: 'partials/settings.html', controller: 'settingCtrl'});
    $routeProvider.when('/account', {templateUrl: 'partials/account.html', controller: 'accountCtrl'});
    $routeProvider.when('/account/users', {templateUrl: 'partials/account-users.html', controller: 'accountUsersCtrl'});
    $routeProvider.when('/inventory', {templateUrl: 'partials/inventory.html', controller: 'inventoryCtrl'});
    $routeProvider.when('/emails', {templateUrl: 'partials/emails.html', controller: 'emailCtrl'});
    $routeProvider.when('/files', {templateUrl: 'partials/files.html', controller: 'filesCtrl'});
    $routeProvider.when('/vendors', {templateUrl: 'partials/vendor-list.html', controller: 'vendorCtrl'});
    $routeProvider.when('/vendors/:vendorId', {templateUrl: 'partials/vendor-details.html', controller: 'vendorDetailCtrl'});

    $routeProvider.when('/giftuniverse/product-assortments', {
        templateUrl: 'partials/product-assortments/product-assortments.html',
        controller: 'productAssortmentsCtrl',
        reloadOnSearch: false
    });
    $routeProvider.when('/product-assortments/:productAssortmentId', {
        templateUrl: 'partials/product-assortments/product-assortment.html',
        controller: 'productAssortmentCtrl'
    });

    $routeProvider.when('/product-assortments/:productAssortmentId/products', {
        templateUrl: 'partials/product-assortments/add-products-to-assortment.html',
        controller: 'addProductsToAssortmentCtrl',
        reloadOnSearch: false
    });
    //GIFTUNIVERSE SHOPS
    $routeProvider.when('/giftuniverse/shops', {
        templateUrl: 'partials/giftuniverse/shop-list.html',
        controller: 'shopsCtrl',
        reloadOnSearch: false
    });
    //GIFTUNIVERSE USERS
    $routeProvider.when('/giftuniverse/users', {
        templateUrl: 'partials/giftuniverse/user-list.html',
        controller: 'usersCtrl',
        reloadOnSearch: false
    });

    $routeProvider.when('/giftuniverse/users/:userId', {
        templateUrl: 'partials/giftuniverse/user-details.html',
        controller: 'userDetailsCtrl',
    });

    $routeProvider.when('/giftuniverse/settings', {
        templateUrl: 'partials/giftuniverse/settings.html',
        controller: 'settingsCtrl',
        reloadOnSearch: false
    });

    $routeProvider.when('/offers', {
        templateUrl: 'partials/offers.html', controller: 'offersCtrl', reloadOnSearch: false
    });
    $routeProvider.when('/allocation', {
        templateUrl: 'partials/allocation.html', controller: 'allocationCtrl', reloadOnSearch: false
    });
    $routeProvider.when('/offers/shop/:shopId', {
        templateUrl: 'partials/offers-shop.html', controller: 'offersShopCtrl'
    });
    $routeProvider.when('/offers/shop/:shopId/products', {
        templateUrl: 'partials/offers-shop-products.html', controller: 'offersShopProductCtrl', reloadOnSearch: false
    });
    $routeProvider.when('/offers/product/:productId', {
        templateUrl: 'partials/offers-product.html', controller: 'offersProductCtrl'
    });
    $routeProvider.when('/offers/shop/:shopId/products/:productId', {
        templateUrl: 'partials/offers-product.html', controller: 'offersProductCtrl'
    });

    $routeProvider.when('/discounts', {templateUrl: 'partials/discounts.html', controller: 'discountCtrl', reloadOnSearch: false});
    $routeProvider.when('/discounts/import', {
        templateUrl: 'partials/discount-import.html',
        controller: 'discountImportCtrl'
    });
    $routeProvider.when('/slideshows', {templateUrl: 'partials/slideshows.html', controller: 'slideshowCtrl'});
    $routeProvider.when('/slideshows/:slideshowId', {templateUrl: 'partials/slideshow-details.html', controller: 'slideshowDetailCtrl'});
    $routeProvider.when('/translations', {
        templateUrl: 'partials/translation-list.html',
        controller: 'translationCtrl',
        reloadOnSearch: false
    });
    $routeProvider.when('/nav-variants', {
        templateUrl: 'partials/nav-variant-list.html',
        controller: 'navVariantCtrl',
        reloadOnSearch: false
    });
    $routeProvider.when('/tags', {templateUrl: 'partials/tag-list.html', controller: 'tagCtrl', reloadOnSearch: false});
    $routeProvider.otherwise({redirectTo: '/dashboard'});
}])
    .constant('activeProfile', profile)
    .config(["cfpLoadingBarProvider", function (cfpLoadingBarProvider) {
        cfpLoadingBarProvider.includeSpinner = false;
    }])
    .config(["$translateProvider", "activeProfile", function($translateProvider, activeProfile) {
        $translateProvider.useStaticFilesLoader({
            prefix: './lang/',
            suffix: '.json'
        });
        $translateProvider.preferredLanguage(activeProfile.lang);
        $translateProvider.useSanitizeValueStrategy('escaped');
    }]);

angular.module('ecom.controllers', ['kcd.directives', 'moment-module']);

var ctrl = angular.module('ecom.controllers');

app.constant('copyToClipboard', function (str) {
    var el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    var selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }
});

app.directive('focusMe', ["$timeout", function($timeout) {
    return {
        scope: { trigger: '@focusMe' },
        link: function(scope, element) {
            scope.$watch('trigger', function(value) {
                if(value === "true") {
                    $timeout(function() {
                        element[0].focus();
                    }, 200);
                }
            });
        }
    };
}]);

app.directive('restrict', ["activeProfile", function(activeProfile){
    return {
        restrict: 'A',
        priority: 100000,
        scope: false,
        compile:  function(element, attr){
            var accessDenied = true;
            var attributes = attr.access.split(" ");
            for(var i in attributes){
                if(activeProfile.type == attributes[i]){
                    accessDenied = false;
                }
            }
            if(accessDenied){
                element.children().remove();
                element.remove();
            }
        }
    }
}]);

angular.module("angular-bootstrap-select", [])
    .directive("selectpicker",
    [
        "$timeout",
        function($timeout) {
            return {
                restrict: "A",
                require: ["?ngModel", "?collectionName"],
                compile: function(tElement, tAttrs, transclude) {
                    //console.log("init bootstrap-select");
                    tElement.selectpicker();

                    if (angular.isUndefined(tAttrs.ngModel)) {
                        throw new Error("Please add ng-model attribute!");
                    } else if (angular.isUndefined(tAttrs.collectionName)) {
                        throw new Error("Please add data-collection-name attribute!");
                    }

                    return function(scope, element, attrs, ngModel) {
                        if (angular.isUndefined(ngModel)){
                            return;
                        }

                        scope.$watch(attrs.ngModel, function(newVal, oldVal) {
                            if (newVal !== oldVal) {
                                $timeout(function() {
                                    //console.log("value selected");
                                    element.selectpicker("val", element.val());
                                });
                            }
                        });

                        scope.$watch(attrs.collectionName, function(newVal, oldVal) {
                            $timeout(function() {
                                //console.log("select collection updated");
                                element.selectpicker("refresh");
                            });
                        });

                        ngModel.$render = function() {
                            element.selectpicker("val", ngModel.$viewValue || "");
                        };

                        ngModel.$viewValue = element.val();
                    };
                }
            }
        }
    ]
);

angular.module('kcd.directives', [])
    .directive('kcdRecompile', ['$parse', function($parse) {
    'use strict';
    return {
        transclude: true,
        link: function link(scope, $el, attrs, ctrls, transclude) {
            var previousElements;

            compile();

            function compile() {
                transclude(scope, function(clone, clonedScope) {
                    // transclude creates a clone containing all children elements;
                    // as we assign the current scope as first parameter, the clonedScope is the same
                    previousElements = clone;
                    $el.append(clone);
                });
            }

            function recompile() {
                if (previousElements) {
                    previousElements.remove();
                    previousElements = null;
                    $el.empty();
                }

                compile();
            }

            scope.$watch(attrs.kcdRecompile, function(_new, _old) {
                var useBoolean = attrs.hasOwnProperty('useBoolean');
                if ((useBoolean && (!_new || _new === 'false')) || (!useBoolean && (!_new || _new === _old))) {
                    return;
                }
                // reset kcdRecompile to false if we're using a boolean
                if (useBoolean) {
                    $parse(attrs.kcdRecompile).assign(scope, false);
                }
                recompile();
            }, typeof $parse(attrs.kcdRecompile)(scope) === 'object');
        }
    };
}]);

angular.module('moment-module', []).factory('moment', ["$window", function ($window) {
    var moment = $window.moment

    moment.tz.setDefault("Europe/Copenhagen");

    return moment;
}]);

app.directive("formatDateTimezone", function () {
    return {
        restrict: "A",
        require: "ngModel",
        link: function (parentScope, element, attrs, ngModelCtrl) {
            var scope = parentScope.$new(true); //Simulated isolation.
            scope.dateFormat = attrs.dateFormat; //one-way binding @

            ngModelCtrl.$parsers.push(function (datepickerValue) {
                // if (!scope.dateFormat) {
                //     scope.dateFormat = 'L LT';
                // }

                return moment(datepickerValue).tz('Europe/Copenhagen').format();
            });
        },
    };
});

app.config(["$provide", function($provide){
    $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function(taRegisterTool, taOptions){
        // $delegate is the taOptions we are decorating
        // register the tool with textAngular
        taRegisterTool('iframeTag', {
            iconclass: "fa fa-external-link",
            tooltiptext: 'Insert iframe tag',
            action: function() {
                var text = '[IFRAME][/IFRAME]';
                function insertTextAtCursor() {
                    var sel, range;
                    if (window.getSelection) {
                        sel = window.getSelection();
                        if (sel.getRangeAt && sel.rangeCount) {
                            text = '[IFRAME]' + window.getSelection().toString() + '[/IFRAME]';
                            range = sel.getRangeAt(0);
                            range.deleteContents();
                            range.insertNode(document.createTextNode(text));
                        }
                    } else if (document.selection && document.selection.createRange) {
                        text = '[IFRAME]' + document.selection.createRange().text + '[/IFRAME]';
                        document.selection.createRange().text = text;
                    }
                }
                function moveCaret(charCount) {
                    var sel, range;
                    if (window.getSelection) {
                        sel = window.getSelection();
                        if (sel.rangeCount > 0) {
                            var textNode = sel.focusNode;
                            sel.collapse(textNode.nextSibling, charCount);
                        }
                    } else if ((sel = window.document.selection)) {
                        if (sel.type != "Control") {
                            range = sel.createRange();
                            range.move("character", charCount);
                            range.select();
                        }
                    }
                }
                insertTextAtCursor();

                return moveCaret(text.length - 9);
            }
        });
        // add the button to the default toolbar definition
        taOptions.toolbar[1].push('iframeTag');

        return taOptions;
    }]);
}]);

app.filter('dateToISO', function() {
    return function(input) {
        input = new Date(input).toISOString();

        return input;
    };
});

app.filter('bytes', function() {
    return function(bytes, precision) {
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
        if (typeof precision === 'undefined') precision = 1;
        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
            number = Math.floor(Math.log(bytes) / Math.log(1024));

        return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +  ' ' + units[number];
    }
});

app.filter('secondsToDateTime', function() {
    return function(seconds) {
        var d = new Date(0,0,0,0,0,0,0);
        d.setSeconds(seconds);

        return d;
    };
});

app.filter('dateTimeToHuman', ["$translate", "$filter", function($translate, $filter) {
    return function(dateTime) {
        var d = new Date(dateTime);
        var minutes = d.getMinutes();
        var hours = d.getHours();
        var dateString1 = '';
        var dateString2 = '';
        var dateString3 = '';
        if (hours > 0) {
            dateString1 = (hours > 1) ? hours + ' ' + $filter('translate')('HOURS') : hours + ' ' + $filter('translate')('HOUR')
        }
        if (minutes > 0) {
            dateString2 = (minutes > 1) ? minutes + ' ' + $filter('translate')('MINUTES') : minutes + ' ' + $filter('translate')('MINUTE')
        }
        if (hours < 1 && minutes < 1) {
            dateString3 = $filter('translate')('LESS_THAN') + ' 1 ' + $filter('translate')('MINUTE');
        }

        if (hours > 0 && minutes > 0) {

            return dateString1 + ' ' + $filter('translate')('AND') + ' ' +dateString2;
        } else if (hours > 0) {

            return dateString1;
        } else if (minutes > 0) {

            return dateString2;
        } else {

            return dateString3;
        }
    };
}]);

app.filter('capitalize', function() {
    return function(input) {

        return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    }
});

app.filter('numberFixedLen', function () {
    return function (a, b) {

        return (1e4 + "" + a).slice(-b);
    };
});

app.filter('propsFilter', function() {
    return function(items, props) {
        var out = [];

        if (angular.isArray(items)) {
            var keys = Object.keys(props);

            items.forEach(function(item) {
                var itemMatches = false;

                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    };
});

angular.module('ecom.services', []);

ctrl.controller('accountCtrl', ["$scope", "$location", "$http", "$timeout", "activeProfile", "Upload", function ($scope, $location, $http, $timeout, activeProfile, Upload) {
    $scope.loading = true;
    $scope.flag = false;
    $scope.signatureUploaded = false;
    $scope.user = {
        id: null,
        user_type: null,
        authy_id: null,
        authy_status: 'unverified',
        shop: {
            data: {
                barcode_enabled: false
            }
        }
    };
    $scope.data = null;
    $scope.flagMessage = null;

    $scope.barcode = false;
    $scope.canUse2FA = false;

    $scope.getUser = function() {
        $http.get('/api/users/' + activeProfile.id + '?include=signature,shop').then(function onSuccess(response) {
            $scope.user = response.data.data;
            $scope.barcode = $scope.user.user_type === 'editor' && $scope.user.shop.data.barcode_enabled;
            $scope.canUse2FA = $scope.user.user_type !== 'editor' && $scope.user.user_type !== 'reader';
            $scope.loading = false;
        });
    };

    $scope.getUser();

    $scope.saveUser = function (isValid) {
        if (isValid) {
            var data = $scope.user;
            $http.put('/api/users/' + activeProfile.id, data).then(function () {
                $scope.flag = true;
                $timeout(function () {
                    $scope.flag = false;
                }, 3000);
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.onSignatureSelect = function ($files) {
        $scope.signatureUploaded = false;
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/users/' + $scope.user.id + '/signature',
                    data: {
                        owner_id: $scope.user.id,
                        owner_resource: 'user',
                        image_key: 'signature',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.signatureUploaded = true;
                        $scope.getUser();
                    }
                });
            }
        });
    };

    $scope.deleteSignature = function () {
        $http.delete('/api/users/' + $scope.user.id + '/signature/' + $scope.user.signature.data.id).then(function () {
            $scope.user.signature.data.src = null;
        });
    };

    $scope.loadingAuthy = false;
    $scope.authy = {
        email: null,
        cellphone: null,
        country_code: null,
        token: null
    };

    $scope.response = null;
    $scope.showVerify = false;
    $scope.twoFactorAuthStep = 1;

    $scope.enableTwoFactorAuth = function () {
        $scope.twoFactorAuthStep = 1;  // Step 1
        $scope.authy = {
            email: $scope.user.email,
            cellphone: null,
            country_code: null,
            token: null
        };
    };

    $scope.enable2FA = function() {
        $scope.data = null;
        $scope.loadingAuthy = false;
        if ($scope.twoFactorAuthStep === 1) {
            $scope.enableAuthy();
        } else if ($scope.twoFactorAuthStep === 2) {
            $scope.checkToken();
        } else {
            $scope.twoFactorAuthStep = 1; // Step 1
        }
    };

    $scope.enableAuthy = function() {
        $scope.data = null;
        $scope.loadingAuthy = true;
        $http.post('/api/2fa/enable', $scope.authy).then(function onSuccess() {
            $scope.authy.token = null;
            $scope.loadingAuthy = false;
            $scope.twoFactorAuthStep = 2; // Step 2
        }, function onError(response) {
            $scope.data = response.data;
            $scope.loadingAuthy = false;
        });
    };

    $scope.checkToken = function() {
        $scope.data = null;
        $scope.loadingAuthy = true;
        $http.post('/api/2fa/check-token', $scope.authy).then(function onSuccess(response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            $scope.twoFactorAuthStep = 3; // Step 3
            $scope.loadingAuthy = false;
            $scope.getUser(); // Success
        }, function onError(response) {
            $scope.data = response.data;
            $scope.loadingAuthy = false;
        });
    };

    $scope.authyRemoved = false;

    $scope.disableTwoFactorAuth = function() {
        $scope.authyRemoved = false;
    };

    $scope.disable2FA = function() {
        $scope.disableAuthy();
    };

    $scope.disableAuthy = function() {
        $scope.data = null;
        $scope.authyRemoved = false;
        $scope.loadingAuthy = true;
        $http.post('/api/2fa/disable').then(function onSuccess(response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            $scope.authyRemoved = true;
            $scope.loadingAuthy = false;
            $scope.getUser(); // Success
        }, function onError(response) {
            $scope.data = response.data;
            $scope.loadingAuthy = false;
        });
    };

    $scope.tokenSent = false;
    $scope.resendToken = function() {
        if ($scope.tokenSent === false) {
            $scope.tokenSent = true;
            $http.post('/api/2fa/resend-token', $scope.authy).then(function onSuccess() {}, function onError() {});
            $timeout(function () {
                $scope.tokenSent = false;
            }, 5000);
        }
    }

}]);

ctrl.controller('accountUsersCtrl', ["$scope", "$location", "$http", "$timeout", "activeProfile", "Upload", function ($scope, $location, $http, $timeout, activeProfile, Upload) {
    $scope.loading = true;
    $scope.focusAddUser = false;
    $scope.users = [];

    $scope.getUsers = function () {
        $scope.loading = true;
        $http.get('/api/account-users').then(function onSuccess(response) {
            $scope.users = response.data;
            $scope.loading = false;
        });
    };

    $scope.getUsers();

    $scope.countryList = [];
    $http.get('js/countries.json').then(function onSuccess(response) {
        $scope.countryList = response.data;
    });

    $scope.user = {
        account_id: '',
        shop_id: '',
        email: '',
        phone: '',
        address: '',
        postcode: '',
        city: '',
        password: null,
        password_confirmation: null,
        user_type: 'barcode',
        assigned_shop: '',
        lang: 'da',
        created_at_min: '',
        created_at_max: '',
        auto_update_privileges: 0,
        employee_code: ''
    };

    $scope.addUser = function () {
        $scope.data = null;
        $scope.shops = $scope.shopsList;
        $scope.user = {
            account_id: '',
            shop_id: '',
            email: '',
            phone: '',
            address: '',
            postcode: '',
            city: '',
            password: null,
            password_confirmation: null,
            user_type: 'barcode',
            assigned_shop: '',
            lang: 'da',
            created_at_min: '',
            created_at_max: '',
            auto_update_privileges: 0,
            employee_code: ''
        };
        $scope.focusAddUser = true;
    };

    $scope.saveNewUser = function () {
        $scope.data = null;
        var data = $scope.user;
        $http.post('/api/account-users', data).then(function () {
            $scope.getUsers();
            $scope.focusAddUser = false;
            $('#addUser').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editUser = function (user) {
        $scope.data = null;
        $scope.user = user;
        $scope.user.auto_update_privileges = ($scope.user.auto_update_privileges === true ? 1 : 0);
        $scope.shops = $scope.shopsList;
        $scope.focusEditUser = true;
        $scope.signatureUploaded = false;
    };

    $scope.updateUser = function (user) {
        $scope.data = null;
        $http.put('/api/account-users/' + user.id, user).then(function () {
            $scope.getUsers();
            $('#editUser').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteUser = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteUser = function (idx) {
        $http.delete('/api/account-users/' + $scope.users[idx].id).then(function () {
            $scope.users.splice(idx, 1);
            $('#deleteUser').modal('hide');
        });
    };

}]);

ctrl.controller("allocationCtrl", ["$scope", "$http", "$filter", function ($scope, $http, $filter) {
    var pagination = 50;
    $scope.loading = true;
    $scope.customers = true;
    $scope.search = "";
    $scope.forecast = true;
    $scope.allResults = false;
    $scope.shop_ids = "";
    $scope.refreshingProducts = true;
    $scope.message = "";
    $scope.take = pagination;
    $scope.propertyName = "allocated";
    $scope.reverse = true;

    $scope.getProductsList = function () {
        $scope.data = [];
        $scope.loading = true;
        $http.get("/api/allocation").then(function onSuccess(response) {
            $scope.data = response.data;
            $scope.loading = false;
            $scope.refreshingProducts = false;
        });
    };

    $scope.getProductsList();

    $scope.addRemoveShops = function (action) {
        $scope.loading = true;
        $http.post("/api/allocation", {
            action: action,
            shop_ids: $scope.shop_ids
        }).then(function onSuccess(response) {
            if (response.data.response === "success") {
                $scope.getProductsList();
                $scope.shop_ids = "";
            }
            $scope.loading = false;
            $scope.message = response.data.message;
        });
    };

    $scope.order = function () {
        $scope.data.products = $filter("orderBy")($scope.data.products, $scope.propertyName, $scope.reverse);
    };

    $scope.sortBy = function (propertyName) {
        $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
        $scope.propertyName = propertyName;
        $scope.order();
    };

    $scope.showHideCustomers = function () {
        $scope.customers = !$scope.customers;
    };

    $scope.searchProducts = function () {
        $scope.loading = true;
        $http.get("/api/allocation?s=" + $scope.search).then(function onSuccess(response) {
            $scope.data = response.data;
            $scope.refreshingProducts = false;
            $scope.loading = false;
        });
    };

    $scope.showHideForecast = function () {
        $scope.forecast = !$scope.forecast;
    };

    /*$scope.loadMoreProducts = function () {
        $scope.take += pagination;
        $scope.loadMore();
    };*/

    /*  $scope.loadMore = function () {
          $scope.refreshingProducts = true;

          $scope.loading = true;
          $http.get("/api/allocation", {
              params: {
                  page: $scope.take / pagination
              }
          }).then(function onSuccess(response) {
              var keys = Object.keys(response.data.products);

              if (keys.length) {
                  $scope.data.products = Object.assign($scope.data.products, response.data.products);
                  $scope.data.shops = Object.assign($scope.data.shops, response.data.shops);
              }

              if (!keys.length) {
                  $scope.allResults = true;
              }
              $scope.loading = false;
              $scope.refreshingProducts = false;
          });
      };*/

}]);

function dashCtrl($scope, $http, activeProfile) {
    $scope.shopState = 'open';
    $scope.productState = 'top';
    $scope.promotionProductState = 'top';
    $scope.orderState = 'week';
    $scope.loadingProducts = true;
    $scope.loadingPromotionProducts = true;
    $scope.loadingShops = true;
    $scope.loadingOffers = true;
    $scope.loadingChristmas = true;
    $scope.loadingOrders = true;
    $scope.role = activeProfile.type;

    if ($scope.role == 'p_editor') {
        window.location.href = '/#/products';
    }

    var currentMonth = new Date().getMonth() + 1;
    $scope.showGiftBlock = false;
    if (currentMonth >= 9 && currentMonth <= 1) {
        $scope.showGiftBlock = true;
    }

    $http.get('/api/stats?mode=christmas').then(function onSuccess(response) {
        $scope.christmas = {
            labels: response.data.labels,
            data: response.data.data,
            percentage: response.data.percentage,
            options: {
                showTooltips: false
            }
        };
        $scope.loadingChristmas = false;
    });

    if (activeProfile.type === 'admin' || activeProfile.type === 'kam') {
        $http.get('/api/stats?mode=offers').then(function onSuccess(response) {
            $scope.offers = {
                labels: response.data.labels,
                data: response.data.data,
                options: {
                    showTooltips: false
                }
            };
            $scope.loadingOffers = false;
        });

        $scope.$watch('shopState', function (newVal, oldVal) {
            if (typeof newVal !== 'undefined') {
                $scope.loadingShops = true;
                $scope.changeShops();
            }
        }, true);

        $scope.changeShops = function() {
            $http.get('/api/stats?mode=shops&filter='+$scope.shopState).then(function onSuccess(response) {
                $scope.shops = response.data;
                $scope.loadingShops = false;
            });
        };

        $scope.changeShops();
    }

    $scope.points = [];
    $scope.customers = [];
    $scope.refreshingCustomers = false;
    $scope.allResults = false;
    $scope.take = 15;

    $scope.loadMoreCustomers = function () {
        $scope.refreshingCustomers = true;
        $scope.take += 15;
        $http.get('/api/customers?include=available_points&orderBy=points', {
            params: { take: $scope.take }
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.customers.length) {
                $scope.customers = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingCustomers = false;
        });
    };

    if (activeProfile.type === 'editor') {
        $scope.loadingPoints = true;
        $http.get('/api/stats?mode=wallets').then(function onSuccess(response) {
            $scope.points = response.data;
            $scope.loadingPoints = false;
        });

        $scope.loadingCustomers = true;

        $http.get('api/customers?include=available_points&orderBy=points', {
            params: { take: $scope.take }
        }).then(function(response) {
            $scope.customers = response.data.data;
            $scope.loadingCustomers = false;
        });
    }

    $scope.changeProductsLoad = true;
    $scope.changeProducts = function() {
        $http.get('/api/stats?mode=products&filter='+$scope.productState).then(function onSuccess(response) {
            $scope.products = response.data;
            $scope.loadingProducts = false;
            $scope.changeProductsLoad = false;
        });
    };

    $scope.changeProducts();

    $scope.$watch('productState', function (newVal, oldVal) {
        if ($scope.changeProductsLoad === false) {
            if (typeof newVal !== 'undefined') {
                $scope.loadingProducts = true;
                $scope.changeProducts();
            }
        }
    }, true);

    $scope.changePromotionProductsLoad = true;
    $scope.changePromotionProducts = function() {
        $http.get('/api/stats?mode=promotion_products&filter='+$scope.promotionProductState).then(function onSuccess(response) {
            $scope.promotionProducts = response.data;
            $scope.loadingPromotionProducts = false;
            $scope.changePromotionProductsLoad = false;
        });
    };

    $scope.changePromotionProducts();

    $scope.$watch('promotionProductState', function (newVal, oldVal) {
        if ($scope.changePromotionProductsLoad === false) {
            if (typeof newVal !== 'undefined') {
                $scope.loadingPromotionProducts = true;
                $scope.changePromotionProducts();
            }
        }
    }, true);

    $scope.changeOrdersLoad = true;
    $scope.changeOrders = function() {
        $http.get('/api/stats?mode=orders&filter='+$scope.orderState).then(function onSuccess(response) {
            $scope.orders = {
                labels: response.data.labels,
                data: response.data.data,
                options: {
                    scaleShowGridLines : false,
                    barDatasetSpacing: 2
                }
            };
            $scope.loadingOrders = false;
            $scope.changeOrdersLoad = false;
        });
    };

    $scope.changeOrders();

    $scope.$watch('orderState', function (newVal, oldVal) {
        if ($scope.changeOrdersLoad === false) {
            if (typeof newVal !== 'undefined') {
                $scope.loadingOrders = true;
                $scope.changeOrders();
            }
        }
    }, true);

    $scope.toggleVariants = function(idx) {
        var close = false;
        if ($scope.products[idx].active === true) {
            close = true;
        }
        angular.forEach($scope.products, function(product, key) {
            $scope.products[key].active = false;
        });
        if (close === false) {
            $scope.products[idx].active = true;
        }
    };
    $scope.downloadCustomerReport = function (){
        if ($scope.customer.date_from!==null && $scope.customer.date_from!==null ){
            var startDate= $scope.customer.date_from.substring(0, $scope.customer.date_from.indexOf('T'));
            var endDate = $scope.customer.date_to.substring(0, $scope.customer.date_from.indexOf('T'));
            window.location.href = '/api/reports/customers-points-balances/0/'+startDate+'/'+ endDate;
        }else{
            window.location.href = '/api/reports/customers-points-balances/0';
        }
    };
    $scope.downloadProductReport = function (){
        if ($scope.product.date_from!==null && $scope.product.date_from!==null){
            var startDate= $scope.product.date_from.substring(0, $scope.product.date_from.indexOf('T'));
            var endDate = $scope.product.date_to.substring(0, $scope.product.date_from.indexOf('T'));
            window.location.href = '/api/reports/shop-products-overview/0/'+startDate+'/'+ endDate;
        }else{
            window.location.href = '/api/reports/shop-products-overview/0';
        }
    }

    $scope.datetimePickerConfig1 = {
        dropdownSelector: '#dropdown1',
        startView: 'year',
        minView: 'day'
    };
    $scope.customer={
        date_from: null,
        date_to: null
    };
    $scope.$watch('customer.date_from', function (newValue) {
        if (typeof newValue !== "object" || newValue) {
            $scope.datetimeFormatted1 = moment(newValue).format('L');
        } else {
            $scope.datetimeFormatted1 = '';
        }
    }, true);
    $scope.$watch('customer.date_to', function (newValue) {
        if (typeof newValue !== "object" || newValue) {
            $scope.datetimeFormatted2 = moment(newValue).format('L');
        } else {
            $scope.datetimeFormatted2 = '';
        }
    }, true);

    $scope.product={
        date_from: null,
        date_to: null
    };
    $scope.$watch('product.date_from', function (newValue) {
        if (typeof newValue !== "object" || newValue) {
            $scope.datetimeFormatted3 = moment(newValue).format('L');
        } else {
            $scope.datetimeFormatted3 = '';
        }
    }, true);
    $scope.$watch('product.date_to', function (newValue) {
        if (typeof newValue !== "object" || newValue) {
            $scope.datetimeFormatted4 = moment(newValue).format('L');
        } else {
            $scope.datetimeFormatted4 = '';
        }
    }, true);

    $scope.setToday = function(rep) {
        today= new Date();
        $scope[rep].date_from = moment(today).format();
        $scope[rep].date_to = moment(today).format();
    };


}

ctrl.controller('dashCtrl', ['$scope', '$http', 'activeProfile', dashCtrl]);

ctrl.controller('emailCtrl', ["$scope", "$http", "$timeout", "$filter", "$window", "activeProfile", function ($scope, $http, $timeout, $filter, $window, activeProfile) {
    $scope.loading = true;
    $scope.loadingShops = true;
    $scope.loadingRecipients = false;
    $scope.flag = false;
    $scope.data = null;
    $scope.totalRecipients = 0;
    $scope.campaign = {};
    var now = new Date();

    $scope.email = {
        id: '',
        owner_id: null,
        owner_title: null,
        subject: '',
        from_name: '',
        datetime: new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes()),
        message: '',
        filter: false,
        accepts_marketing: false,
        owner_resource: 'shop',
        recipient_count: 0,
        status: null,
        emails: ''
    };

    $scope.selected = {
        shop: {}
    };

    $scope.confirm = {
        shop_id: $scope.email.owner_id,
        shop_title: $scope.email.owner_title,
        recipients: $filter('number')($scope.totalRecipients, 0),
        date: $filter('capitalize')($filter('date')($scope.email.datetime, 'MMMM d, y')),
        hours: $filter('date')($scope.email.datetime, 'HH:mm'),
        time: $filter('dateTimeToHuman')($filter('secondsToDateTime')(($scope.totalRecipients * 3)))
    };

    $scope.getShops = function() {
        $scope.loadingShops = true;
        $http.get('/api/shops-compact').then(function onSuccess(response) {
            $scope.shops = [{id: 0, title: $filter('translate')('SELECT_SHOP')}];
            $scope.shops = $scope.shops.concat(response.data);
            $scope.selected.shop = $scope.shops[0];
            $scope.loadingShops = false;
        });
    };
    $scope.getShops();

    $scope.getCampaigns = function() {
        $scope.loading = true;
        $http.get('/api/campaigns?owner_resource=shop').then(function onSuccess(response) {
            $scope.campaigns = response.data.data;
            $scope.loading = false;
        });
    };
    $scope.getCampaigns();

    $scope.saveCampaign = function() {
        $scope.data = null;
        $scope.email.owner_id = $scope.selected.shop.id;
        var data = $scope.email;
        $http.post('/api/campaigns', data).then(function onSuccess(response) {
            $scope.email.id = response.data.created_id;
            $scope.campaigns.push($scope.email);
            var now = new Date();
            $scope.email = {
                id: '',
                owner_id: null,
                owner_title: null,
                subject: '',
                from_name: '',
                datetime: new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes()),
                message: '',
                filter: false,
                accepts_marketing: false,
                owner_resource: 'shop',
                recipient_count: 0,
                status: null,
                emails: ''
            };
            $scope.flag = true;
            $scope.selected.shop = $scope.shops[0];

            $scope.getShops();
            $scope.getCampaigns();
            $scope.totalRecipients = 0;

            $timeout(function () {
                $scope.flag = false;
            }, 3000);
        }, function onError(response) {
            $timeout(function () {
                $window.scrollTo(0, 0);
            }, 500);
            $scope.data = response.data;
        });
    };

    $scope.deleteEmail = function (idx) {
        $http.delete('/api/campaigns/' + $scope.campaigns[idx].id).then(function () {
            $scope.campaigns.splice(idx, 1);
        });
    };

    $scope.calculateRecipients = function() {
        $scope.data = null;
        $scope.loadingRecipients = true;
        $http.post('/api/campaigns/calculate-recipients', {
            shop_id: $scope.selected.shop.id,
            accepts_marketing: $scope.email.accepts_marketing,
            not_selected: $scope.email.filter
        }).then(function onSuccess(response) {
            $scope.totalRecipients = response.data.data.total_recipients;
            $scope.loadingRecipients = false;
        }, function onError(response) {
            $scope.data = response.data;
            $scope.loadingRecipients = false;
        });
    };

    $scope.openConfirmDialog = function() {
        $scope.data = null;
        $scope.email.owner_id = $scope.selected.shop.id;
        $scope.email.owner_title = $scope.selected.shop.title;
        $scope.confirm = {
            shop_id: $scope.email.owner_id,
            shop_title: $scope.email.owner_title,
            recipients: $filter('number')($scope.totalRecipients, 0),
            date: $filter('capitalize')($filter('date')($scope.email.datetime, 'MMMM d, y')),
            hours: $filter('date')($scope.email.datetime, 'HH:mm'),
            time: $filter('dateTimeToHuman')($filter('secondsToDateTime')(($scope.totalRecipients * 3)))
        };
    };

    $scope.confirmCampaign = function() {
        $scope.data = null;
        $("#confirmCampaign").modal('hide');
        $scope.saveCampaign();
    };

    $scope.sendTestEmails = function() {
        $scope.data = null;
        $scope.email.owner_id = $scope.selected.shop.id;
        var data = $scope.email;
        $http.post('/api/campaigns/send-test-email', data).then(function () {
            $("#sendTestEmail").modal('hide');
            $scope.flag = true;
            $timeout(function () {
                $scope.flag = false;
            }, 3000);
        }, function onError(response) {
            $timeout(function () {
                $window.scrollTo(0, 0);
            }, 500);
            $scope.data = response.data;
        });
    };

    $scope.previewEmail = function(idx) {
        $scope.campaign = $scope.campaigns[idx];
    };

}]);

ctrl.controller('filesCtrl', ["$scope", "$http", "Upload", function($scope, $http, Upload) {
    $scope.loading = true;
    $http.get('/api/files').then(function onSuccess(response) {
        $scope.files = response.data.data;
        $scope.loading = false;
        $scope.totalItems = response.data.meta.cursor.count+1;
        $scope.currentPage = 1;
        $scope.nextPage = response.data.meta.cursor.count+1-10;
        $scope.numPages = response.data.meta.cursor.count+1/10;
    });

    $scope.pageChanged = function() {
        $scope.loading = true;
        $http.get('/api/files', {
            params: {
                cursor: $scope.totalItems - ($scope.currentPage*10-10)
            }
        }).then(function onSuccess(response) {
            $scope.files = response.data.data;
            $scope.loading = false;
        });
    };

    $scope.onFileSelect = function($files) {
        angular.forEach($files, function(file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
                Upload.http({
                    url: '/api/files',
                    data: {
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function(response) {
                    if(response.status === 201) {
                        var fileObject = {
                            id: response.data.created_id,
                            content_type: file.type,
                            size: file.size,
                            src: response.data.file_path
                        };
                        $scope.files.push(fileObject);
                    }
                });
            }
        });
    };

    $scope.confirmDeleteFile = function(idx) {
        $scope.idx = idx;
    };

    $scope.deleteFile = function(idx) {
        $http.delete('/api/files/'+$scope.files[idx].id).then(function () {
            $scope.files.splice(idx, 1);
            $("#deleteFile").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    }
}]);
ctrl.controller('inventoryCtrl', ["$scope", function($scope) {
    $scope.variant = {quantity: 3, adjusted: 0, state: 'add'};
    $scope.saveStock = function() {
        var variant = $scope.variant;
        if(variant.state === 'add') {
            variant.quantity = variant.quantity+variant.adjusted;
            variant.adjusted = 0;
        } else {
            variant.quantity = variant.adjusted;
            variant.adjusted = 0;
        }

    }
}]);
ctrl.controller('navCtrl', ["$scope", "$location", "activeProfile", function ($scope, $location, activeProfile) {
    $scope.role = activeProfile.type;

    $scope.isActive = function (path) {
        var url = $location.path();

        return (url.indexOf(path) >= 0 );
    };
}]);

ctrl.controller('settingCtrl', ["$scope", "$location", "$http", "$timeout", "$filter", "activeProfile", "Upload", function ($scope, $location, $http, $timeout, $filter, activeProfile, Upload) {
    $scope.loading = true;
    $scope.loadingManage = false;
    $scope.flag = false;
    $scope.flagDeleted = false;
    $scope.idx = null;
    $scope.signatureUploaded = false;

    $scope.flagCurrencyInsert = false;
    $scope.flagCurrencyUpdate = false;
    $scope.flagCurrencyDelete = false;
    $scope.flagTestNotificationSent = false;

    $scope.countryList = [];
    $http.get('js/countries.json').then(function onSuccess(response) {
        $scope.countryList = response.data;
    });

    $scope.currencyTypesList = [
        {value: 'currency', name: $filter('translate')('CURRENCY_CURRENCY')},
        {value: 'points', name: $filter('translate')('CURRENCY_POINTS')}
    ];

    $scope.currencyRoundingTypesList = [
        {value: 'one', name: $filter('translate')('CURRENCY_ROUND_ONE')},
        {value: 'two', name: $filter('translate')('CURRENCY_ROUND_TWO')},
        {value: 'up', name: $filter('translate')('CURRENCY_ROUND_UP')},
        {value: 'down', name: $filter('translate')('CURRENCY_ROUND_DOWN')},
        {value: 'half', name: $filter('translate')('CURRENCY_ROUND_HALF')}
    ];

    $scope.businessAreaList = {
        '1': $filter('translate')('B2B_MERCHANDISE'),
        '2': $filter('translate')('B2B_SELECT_GIFT'),
        '3': $filter('translate')('B2B_JUBILEE_GIFT'),
        '4': $filter('translate')('B2B_POINT_SHOP'),
        '5': $filter('translate')('B2C_STORAGE_SALE'),
        '6': $filter('translate')('B2C_BENEFIT_SHOP'),
        '7': $filter('translate')('B2B_PROMOTION')
    };

    $scope.settings = {
        yoyo_account_number: null,
        yoyo_base_url: null,
        yoyo_username: null,
        yoyo_password: null,
        yoyo_test: 1,
        holiday_api_key: null,
        notifications: {},
        users: {}
    };

    $scope.getSettings = function () {
        $http.get('/api/accounts/' + activeProfile.account_id + '?include=address,notifications,users,users.shop,users.signature').then(function onSuccess(response) {
            $scope.settings = response.data.data;
            $scope.loading = false;
        });
    };

    $scope.getSettings();

    $scope.shopsList = [{id: 0, title: $filter('translate')('NOT_ASSIGNED_TO_SHOP')}];
    $scope.getShopsList = function() {
        $scope.shopsList = [{id: 0, title: $filter('translate')('NOT_ASSIGNED_TO_SHOP')}];
        $http.get('/api/shops-compact').then(function onSuccess(response) {
            $scope.shopsList = $scope.shopsList.concat(response.data);
        });
    };

    $scope.getShopsList();

    $scope.currencies = null;

    $http.get('/api/currencies?include=shops').then(function onSuccess(response) {
        $scope.currencies = response.data.data;
    });

    $scope.getUsers = function () {
        $scope.loading = true;
        $http.get('/api/accounts/' + activeProfile.account_id + '?include=users,users.shop,users.signature').then(function onSuccess(response) {
            $scope.settings.users.data = response.data.data.users.data;
            $scope.loading = false;
        });
    };

    $scope.getUser = function() {
        $http.get('/api/users/' + $scope.user.id + '?include=signature').then(function onSuccess(response) {
            $scope.user = response.data.data;
        });
    };

    $scope.user = {
        account_id: '',
        shop_id: '',
        email: '',
        phone: '',
        address: '',
        postcode: '',
        city: '',
        password: null,
        password_confirmation: null,
        user_type: 'admin',
        assigned_shop: '',
        lang: 'da',
        created_at_min: '',
        created_at_max: '',
        auto_update_privileges: 0,
        employee_code: ''
    };

    $scope.addUser = function () {
        $scope.data = null;
        $scope.shops = $scope.shopsList;
        $scope.user = {
            account_id: '',
            shop_id: '',
            email: '',
            phone: '',
            address: '',
            postcode: '',
            city: '',
            password: null,
            password_confirmation: null,
            user_type: 'admin',
            assigned_shop: '',
            lang: 'da',
            created_at_min: '',
            created_at_max: '',
            auto_update_privileges: 0,
            employee_code: ''
        };
        $scope.focusAddUser = true;
    };

    $scope.saveNewUser = function () {
        $scope.data = null;
        var data = $scope.user;
        $http.post('/api/users', data).then(function () {
            $scope.getUsers();
            $scope.focusAddUser = false;
            $("#addUser").modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editUser = function (user) {
        $scope.data = null;
        $scope.user = user;
        $scope.user.auto_update_privileges = ($scope.user.auto_update_privileges === true ? 1 : 0);
        $scope.shops = $scope.shopsList;
        $scope.focusEditUser = true;
        $scope.signatureUploaded = false;
    };

    $scope.updateUser = function (user) {
        $scope.data = null;
        $http.put('/api/users/' + user.id, user).then(function () {
            $scope.getUsers();
            $("#editUser").modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteUser = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteUser = function (idx) {
        $http.delete('/api/users/' + $scope.settings.users.data[idx].id).then(function () {
            $scope.settings.users.data.splice(idx, 1);
            $("#deleteUser").modal('hide');
        });
    };

    $scope.notification = {
        type: 'order',
        shop_id: '',
        name: '',
        email: '',
        shop_name: '',
        inventory_business_area: '',
        milestone: '',
        products: '',
        frequency: ''
    };

    $scope.addNotification = function () {
        $scope.data = null;
        $scope.shops = $scope.shopsList;
        $scope.notification = {
            type: 'order',
            shop_id: '',
            name: '',
            email: '',
            shop_name: '',
            inventory_business_area: '',
            milestone: '',
            products: '',
            frequency: ''
        };
        $scope.focusAddNotification = true;
    };

    $scope.saveNewNotification = function () {
        $scope.data = null;
        var data = $scope.notification;
        $http.post('/api/notifications', data).then(function onSuccess(response) {
            $scope.notification.id = response.data.created_id;
            $scope.settings.notifications.data.unshift($scope.notification);
            $scope.focusAddNotification = false;
            $("#addNotification").modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editNotification = function (notification) {
        $scope.data = null;
        $scope.shops = $scope.shopsList;
        $scope.notification = notification;
        $scope.focusEditNotification = true;
    };

    $scope.updateNotification = function (notification) {
        $scope.data = null;
        $http.put('/api/notifications/' + notification.id, notification).then(function () {
            $("#editNotification").modal('hide');
            $scope.flag = true;
            $timeout(function () {
                $scope.flag = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteNotification = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteNotification = function (idx) {
        $http.delete('/api/notifications/' + $scope.settings.notifications.data[idx].id).then(function () {
            $scope.settings.notifications.data.splice(idx, 1);
            $("#deleteNotification").modal('hide');
        });
    };

    $scope.manage = {
        type: '',
        shop_id: '',
        confirm: ''
    };

    $scope.saveSettings = function (isValid) {
        if (isValid) {
            $scope.settings.address.data.company = $scope.settings.name;
            var data = $scope.settings;
            $http.put('/api/accounts/' + $scope.settings.id, data).then(function () {
                $scope.flag = true;
                $timeout(function () {
                    $scope.flag = false;
                }, 3000);
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.manageData = function () {
        var data = $scope.manage;
        $scope.data = null;
        $scope.loadingManage = true;
        $http.post('/api/accounts/manage_data', data).then(function onSuccess(response) {
            $scope.data = response.data;
            $scope.manage = {
                type: '',
                shop_id: '',
                confirm: ''
            };
            $scope.flagDeleted = true;
            $timeout(function () {
                $scope.flagDeleted = false;
            }, 3000);
            $scope.loadingManage = false;
        }, function onError(response) {
            $scope.data = response.data;
            $scope.loadingManage = false;
        });
    };

    $scope.timezones = [];
    $scope.getTimezones = function () {
        $http.get('/api/timezones').then(function onSuccess(response) {
            $scope.timezones = response.data;
        });
    };

    $scope.getTimezones();

    $scope.setShopName = function (shop_id) {
        $http.get('/api/shops/' + shop_id).then(function onSuccess(response) {
            if (typeof response.data.data.title !== 'undefined') {
                $scope.notification.shop_name = response.data.data.title;
            }
        });
    };

    $scope.currency = {
        title: '',
        description: '',
        code: '',
        symbol_left: '',
        symbol_right: '',
        value: 1,
        primary: false,
        type: 'currency',
        rounding: 'two'
    };

    $scope.focusAddCurrency = false;

    $scope.addCurrency = function () {
        $scope.data = null;
        $scope.currency = {
            title: '',
            description: '',
            code: '',
            symbol_left: '',
            symbol_right: '',
            value: 1,
            primary: false,
            type: 'currency',
            rounding: 'two'
        };
        $scope.focusAddCurrency = true;
    };

    $scope.saveCurrency = function () {
        $scope.data = null;
        if ($scope.currency.type === 'points') {
            $scope.currency.code = '';
        }
        var data = $scope.currency;
        $http.post('/api/currencies', data).then(function onSuccess(response) {
            $scope.currency.id = response.data.created_id;
            $scope.currencies.unshift($scope.currency);
            $scope.focusAddCurrency = false;
            $scope.flagCurrencyInsert = true;
            $timeout(function () {
                $scope.flagCurrencyInsert = false;
            }, 5000);
            $('#addCurrency').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.focusEditCurrency = false;

    $scope.editCurrency = function (currency) {
        $scope.data = null;
        $scope.currency = currency;
        $scope.focusEditCurrency = true;
    };

    $scope.updateCurrency = function (currency) {
        $scope.data = null;
        $http.put('/api/currencies/' + currency.id, currency).then(function () {
            $scope.currency = {
                title: '',
                code: '',
                symbol_left: '',
                symbol_right: '',
                value: 1,
                primary: false
            };
            $scope.flagCurrencyUpdate = true;
            $timeout(function () {
                $scope.flagCurrencyUpdate = false;
            }, 5000);
            $("#editCurrency").modal('hide');
            $scope.focusEditCurrency = false;
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteCurrency = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteCurrency = function (idx) {
        $http.delete('/api/currencies/' + $scope.currencies[idx].id).then(function () {
            $scope.currencies.splice(idx, 1);
            $scope.flagCurrencyDelete = true;
            $timeout(function () {
                $scope.flagCurrencyDelete = false;
            }, 5000);
            $('#deleteCurrency').modal('hide');
        });
    };

    $scope.loadingAll = false;
    $scope.campaignAll = null;
    $scope.getCampaignAll = function () {
        $scope.loadingAll = true;
        $scope.campaignAll = null;
        $http.get('/api/campaign-monitor/all').then(function onSuccess(response) {
            $scope.campaignAll = response.data.data;
            $scope.loadingAll = false;
        });
    };

    $scope.campaignClients = null;
    $scope.getCampaignClients = function () {
        $scope.campaignClients = null;
        $http.get('/api/campaign-monitor/clients').then(function onSuccess(response) {
            $scope.campaignClients = response.data.data;
        });
    };
    //$scope.getCampaignClients();

    $scope.campaignLists = null;
    $scope.getCampaignLists = function (client_id) {
        $scope.campaignLists = null;
        $http.get('/api/campaign-monitor/lists/' + client_id).then(function onSuccess(response) {
            $scope.campaignLists = response.data.data;
        });
    };

    $scope.campaignWebhooks = null;
    $scope.getCampaignWebhooks = function (list_id) {
        $scope.campaignWebhooks = null;
        $http.get('/api/campaign-monitor/webhooks/' + list_id).then(function onSuccess(response) {
            $scope.campaignWebhooks = response.data.data;
        });
    };

    $scope.addWebhook = function (list_id) {
        $scope.loadingAll = true;
        $scope.campaignAll = null;
        $http.post('/api/campaign-monitor/webhooks/' + list_id, {}).then(function onSuccess() {
            $timeout(function () {
                $scope.getCampaignAll();
            }, 5000);
        });
    };

    $scope.removeWebhook = function (list_id) {
        $scope.loadingAll = true;
        $scope.campaignAll = null;
        $http.delete('/api/campaign-monitor/webhooks/' + list_id, {}).then(function onSuccess() {
            $timeout(function () {
                $scope.getCampaignAll();
            }, 5000);
        });
    };

    $scope.viewCurrencyShopList = function (currency) {
        $scope.data = null;
        $scope.currency = currency;
    };

    $scope.redirectToShop = function (shop) {
        $('#viewCurrencyShopList').modal('hide');
        $('.modal-backdrop').hide(500).remove();

        window.location = '#/shops/' + shop.id;
    };

    $scope.getBusinessArea = function (business_area) {
        if (business_area >= 1 && business_area <= 7) {

            return $scope.businessAreaList[business_area];
        }

        return '';
    };

    $scope.sendTestNotification = function (idx) {
        $scope.notification = angular.copy($scope.settings.notifications.data[idx]);
        $http.post('/api/notifications/' + $scope.notification.id + '/send-test-notification').then(function onSuccess() {
            $scope.flagTestNotificationSent = true;
            $timeout(function () {
                $scope.flagTestNotificationSent = false;
            }, 5000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.onSignatureSelect = function ($files) {
        $scope.signatureUploaded = false;
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/users/' + $scope.user.id + '/signature',
                    data: {
                        owner_id: $scope.user.id,
                        owner_resource: 'user',
                        image_key: 'signature',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.signatureUploaded = true;
                        $scope.getUser();
                    }
                });
            }
        });
    };

    $scope.deleteSignature = function () {
        $http.delete('/api/users/' + $scope.user.id + '/signature/' + $scope.user.signature.data.id).then(function () {
            $scope.user.signature.data.src = null;
        });
    };

}]);

var token = $('meta[name="_token"]').attr('content');

$(document).ready(function(){
    $('input[name=product-idea-image]').change(function(e){
        var fileName = e.target.files[0].name;

        $('div#productIdeaFile').parent().show();
        $('div#productIdeaFile').text(fileName);
    });
});

$('#createProductIdeaBtn').click(function (e) {
    e.preventDefault();

    var productNameField = $('input[name=product-idea-product]');
    var purposeField = $('input[name=product-idea-purpose]');
    var departmentField = $('input[name=product-idea-department]');
    var emailField = $('input[name=product-idea-email]');
    var imageField = $('input[name=product-idea-image]');

    var data = new FormData();

    data.append('product_name', productNameField.val());
    data.append('purpose', purposeField.val());
    data.append('department', departmentField.val());
    data.append('email', emailField.val());

    const image = imageField[0].files[0];
    if (image) {
        data.append('image', image);
    }

    $('#createProductIdeaSuccess').hide();
    $('#createProductIdeaErrors').hide();
    $('#createProductIdeaErrors div').empty();

    $.ajax({
        headers: {'X-CSRF-TOKEN': token},
        url: '/shop/product-idea/create',
        data: data,
        processData: false,
        contentType: false,
        type: 'POST',
        success: function (data) {
            productNameField.val('');
            purposeField.val('');
            departmentField.val('');
            emailField.val('');
            imageField.val(null);

            $('#createProductIdeaSuccess').show();
            $('div#productIdeaFile').parent().hide();
        },
        error: function (error) {
            if (error.status === 422) {
                $('#createProductIdeaErrors').show();

                var errors = error.responseJSON;
                errorHtml = '<div class="alert alert-danger"><ul class="list-unstyled">';

                $.each(errors, function(key, value) {
                    errorHtml += '<li>' + value[0] + '</li>';
                });

                errorHtml += '</ul></div>';
                $('#createProductIdeaErrors').html(errorHtml);
            }
        }
    });

});


ctrl.controller('categoryArchiveCtrl', ["$scope", "$http", "$location", "$timeout", function($scope, $http, $location, $timeout) {
    $scope.loading = true;
    $scope.searchParams = $location.search();
    $scope.searchParams.include = 'shops';
    $scope.searchParams.is_archived = '1';
    var tempQueryString = '', queryTimeout;

    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            tempQueryString = newValue;
            queryTimeout = $timeout(function () {
                $scope.searchParams.query = tempQueryString;
                $location.search($scope.searchParams);
                $scope.change();
            }, 1000);
        }
    }, true);

    $scope.change = function() {
        $scope.loading = true;
        $http.get('/api/categories', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.categories = response.data.data;
            $scope.loading = false;
        });
    };

    $scope.change();
}]);

ctrl.controller('categoryDetailCtrl', ["$scope", "$routeParams", "$http", "$location", "$timeout", "activeProfile", "$filter", "Upload", function($scope, $routeParams, $http, $location, $timeout, activeProfile, $filter, Upload) {
    $scope.account_langs = activeProfile.account_languages.split(',');
    $scope.countActiveLangs = activeProfile.account_languages.split(',').length;
    $scope.loading = true;
    $scope.flag = false;
    $scope.flagPivot = false;
    $scope.data = null;
    $scope.imageUploading = false;
    $scope.role = activeProfile.type;
    $scope.assigned_shop = activeProfile.assigned_shop;

    $scope.category = {
        login: 0,
        primaryImage: {
            id: 0,
            src: null
        },
        secondaryImage: {
            id: 0,
            src: null
        },
        separatorImage: {
            id: 0,
            src: null
        },
        parent: null,
        parent_category: {
            data: {
                id: null,
                title: null
            }
        },
        show_in_footer: false,
        sort_order: null,
        order_by_newest: false,
        robots: 'index,follow',
        metafields: {
            data: []
        }
    };

    $scope.categoryAsync = {
        value: ''
    };

    $scope.titleKey = -1;
    $scope.descriptionKey = -1;
    $scope.specificationsKey = -1;

    $scope.getCategory = function () {
        var $categoryId = $routeParams.categoryId;
        $http.get('/api/categories/' + $categoryId + '?include=metafields,products,products.images,shops,groups,parent_category').then(function onSuccess(response) {
            $scope.category = response.data.data;
            $scope.category.translations = {};
            $scope.category.meta = {};
            $scope.getPrimaryImage();
            $scope.getSecondaryImage();
            $scope.getSeparatorImage();
            $scope.getSlideshows();

            var titleObject = $filter('filter')($scope.category.metafields.data, {key: 'title', namespace: null}, undefined);
            $scope.titleKey = $scope.category.metafields.data.indexOf(titleObject[0]);
            var descriptionObject = $filter('filter')($scope.category.metafields.data, {key: 'description', namespace: null}, undefined);
            $scope.descriptionKey = $scope.category.metafields.data.indexOf(descriptionObject[0]);

            if (activeProfile.account_languages.split(',').length) {
                angular.forEach($scope.account_langs, function (langCode) {
                    var title = $filter('filter')($scope.category.metafields.data, {
                        key: "title",
                        namespace: langCode
                    }, undefined)[0];
                    var description = $filter('filter')($scope.category.metafields.data, {
                        key: "description",
                        namespace: langCode
                    }, undefined)[0];
                    title = (title !== undefined) ? title.value : '';
                    description = (description !== undefined) ? description.value : '';
                    $scope.category.translations[langCode] = {
                        title: title,
                        description: description,
                    };

                    var meta_description = $filter('filter')($scope.category.metafields.data, {
                        key: "meta_description",
                        namespace: langCode
                    }, undefined)[0];
                    meta_description = (meta_description !== undefined) ? meta_description.value : '';
                    $scope.category.meta[langCode] = {
                        meta_description: meta_description,
                    };
                });
            }

            $scope.categoryAsync.value = $scope.category.parent_category.data.title;
            $scope.loading = false;
        }, function onError(response) {
            window.location = '/#/dashboard';
        });
    };

    $scope.getCategory();

    $scope.saveCategory = function(isValid) {
        if (isValid) {
            $scope.data = null;
            var data = $scope.category;
            $http.put('/api/categories/'+$scope.category.id, data).then(function () {
                $scope.flag = true;
                $timeout(function() {
                    $scope.flag = false;
                }, 3000);
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.sortableOptions = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle',
        cancel: '',
        'ui-floating': false,
        stop: function() {
            var order = [];
            angular.forEach($scope.category.products.data, function(item) {
                order.push(item.id);
            });
            $http.post('/api/categories/' + $routeParams.categoryId + '/products/order', {
                productOrder: order
            }).then(function () {

            }, function onError() {

            });
        }
    };

    $scope.getProducts = function(val) {
        return $http.get('/api/products', {
            params: {
                query: val,
                show_images: 1
            }
        })
            .then(function(res) {
                var results = [];

                angular.forEach(res.data.data, function(item) {
                    var format_product_sku = '';
                    if (item.variants_sku) {
                        format_product_sku = '(' + item.variants_sku.split(';') + ')';
                    }

                    item.formatted_title_search = item.title + ' ' + format_product_sku;
                    item.images = {
                        data: [{ src: item.image }]
                    };
                    results.push(item);
            });

            return results;
        });
    };

    $scope.addProduct = function(item) {
        var categoryId = $scope.category.id;
        var productId = item.id;
        $http.post('/api/categories/'+categoryId+'/products/'+productId).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.category.products.data.splice(0, 0, item);
            } else {
                window.location = '/login';
            }
        }, function onError() {
            $scope.flagPivot = true;
            $timeout(function() {
                $scope.flagPivot = false;
            }, 3000);
        });
    };

    $scope.deleteProduct = function(idx) {
        var categoryId = $scope.category.id;
        var productId = $scope.category.products.data[idx].id;
        $http.delete('/api/categories/'+categoryId+'/products/'+productId).then(function () {
            $scope.category.products.data.splice(idx, 1);
        });
    };

    $scope.getShops = function(val) {
        return $http.get('/api/shops', {
            params: {
                query: val
            }
        }).then(function(res){
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.addShop = function(item) {
        var categoryId = $scope.category.id;
        var shopId = item.id;
        $http.post('/api/shops/'+shopId+'/categories/'+categoryId).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.category.shops.data.push(item);
            } else {
                window.location = '/login';
            }
        }, function onError() {
            $scope.flagPivot = true;
            $timeout(function() {
                $scope.flagPivot = false;
            }, 3000);
        });
    };

    $scope.deleteShop = function(idx) {
        var categoryId = $scope.category.id;
        var shopId = $scope.category.shops.data[idx].id;
        $http.delete('/api/shops/'+shopId+'/categories/'+categoryId).then(function () {
            $scope.category.shops.data.splice(idx, 1);
        });
    };

    $scope.deleteCategory = function() {
        $http.delete('/api/categories/'+$scope.category.id).then(function () {
            $location.path("/categories");
            $("#deleteCategory").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.getCategories = function(val) {
        let params = {
            query: val
        };

        if ($scope.role == 'p_editor') {
            params.shop_id = $scope.assigned_shop;
        }
        return $http.get('/api/categories', {
            params: params
        }).then(function(res){
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.setParentCategory = function(item) {
        $scope.category.parent = item.id;
        $scope.category.parent_category.data.id = item.id;
        $scope.category.parent_category.data.title = item.title;
        $scope.categoryAsync.value = item.title;
    };

    $scope.cleanParentCategory = function() {
        $scope.category.parent = null;
        $scope.category.parent_category.data.id = null;
        $scope.category.parent_category.data.title = null;
        $scope.categoryAsync.value = '';
    };

    $scope.copy = function () {
        $scope.updateHandle = function (title) {
            $scope.product.handle = getSlug(title);
        };
        var data1 = $scope.category;
        data1.handle = data1.handle + '-copy';
        $http.post('/api/categories', data1).then(function onSuccess(response) {
            if (response.status === 201) {
                data1.id = response.data.created_id;
                $http.get('/api/categories/' + data1.id + '?include=metafields,products,products.images,shops,groups,parent_category').then(function () {
                    $http.put('/api/categories/' + $scope.category.id, data1).then(function () {
                        $location.path('/categories/' + data1.id);
                    });
                });
            } else {
                window.location = '/';
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.archiveCategory = function() {
        $scope.category.is_archived = $scope.category.is_archived !== true;
        $timeout(function() {
            angular.element('#submitCategory').trigger('click');
        }, 100);
    };

    $scope.getGroups = function(val) {
        return $http.get('/api/groups', {
            params: {
                query: val,
                resource: 'customer'
            }
        }).then(function(res){
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.addGroup = function(item) {
        var postData = {
            categoryId: $scope.category.id,
            groupId: item.id
        };
        $http.post('/api/groups/pivot', postData).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.category.groups.data.push(item);
            } else {
                window.location = '/login';
            }
        });
    };

    $scope.deleteGroup = function(idx) {
        $http.delete('/api/groups/pivot?categoryId=' + $scope.category.id + '&groupId=' + $scope.category.groups.data[idx].id).then(function () {
            $scope.category.groups.data.splice(idx, 1);
        });
    };

    $scope.slideshows = {};

    $scope.getSlideshows = function() {
        $http.get('/api/slideshows').then(function onSuccess(response) {
            $scope.slideshows = response.data.data;
        });
    };

    $scope.slideshowLoading = false;
    $scope.$watch('category.slideshow_id', function (newValue, oldValue) {
        if (newValue > 0) {
            $scope.slideshowLoading = true;
            $http.get('/api/slideshows/' + newValue + '?include=slides,images').then(function onSuccess(response) {
                $scope.slideshow = response.data.data;
                setTimeout(function() {
                    $scope.slideshowLoading = false;
                }, 500);
            });
        }
    }, true);

    $scope.getImageSrc = function(imageId) {
        if ($scope.slideshow.images.data.length > 0 && imageId > 0) {
            var image = $filter('filter')($scope.slideshow.images.data, {id: imageId}, undefined);
            if (typeof image[0] !== 'undefined' && typeof image[0].src !== 'undefined') {

                return image[0].src;
            }

            return 'https://placehold.it/80';
        }
    };

    $scope.getPrimaryImage = function() {
        $http.get('/api/categories/' + $routeParams.categoryId + '/primary-image').then(function onSuccess(response) {
            $scope.category.primaryImage = response.data.data;
        });
    };

    $scope.getSecondaryImage = function() {
        $http.get('/api/categories/' + $routeParams.categoryId + '/secondary-image').then(function onSuccess(response) {
            $scope.category.secondaryImage = response.data.data;
        });
    };

    $scope.getSeparatorImage = function() {
        $http.get('/api/categories/' + $routeParams.categoryId + '/separator-image').then(function onSuccess(response) {
            $scope.category.separatorImage = response.data.data;
        });
    };

    $scope.imageUploading = false;
    $scope.onFileSelect = function($files, image_key) {
        $scope.imageUploading = true;
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.category.id,
                        owner_resource: 'category',
                        image_key: image_key,
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function(response) {
                    if (response.status === 201) {
                        $scope.imageUploading = false;

                        var $object = {
                            id: response.data.created_id,
                            content_type: file.type,
                            owner_id: $scope.category.id,
                            owner_resource: 'category',
                            position: 999,
                            size: file.size,
                            src: e.target.result
                        };

                        if (image_key === 'primary') {
                            $scope.category.primaryImage = $object;
                        } else if (image_key === 'secondary') {
                            $scope.category.secondaryImage = $object;
                        } else if (image_key === 'separator') {
                            $scope.category.separatorImage = $object;
                        }
                    }
                });
            }
        });
    };

    $scope.deletePrimaryImage = function () {
        $scope.imageUploading = false;
        $http.delete('/api/images/' + $scope.category.primaryImage.id).then(function () {
            $scope.category.primaryImage = null;
        });
    };

    $scope.deleteSecondaryImage = function () {
        $scope.imageUploading = false;
        $http.delete('/api/images/' + $scope.category.secondaryImage.id).then(function () {
            $scope.category.secondaryImage = null;
        });
    };

    $scope.deleteSeparatorImage = function () {
        $scope.imageUploading = false;
        $http.delete('/api/images/' + $scope.category.separatorImage.id).then(function () {
            $scope.category.separatorImage = null;
        });
    };

}]);

ctrl.controller('categoryCtrl', ["$scope", "$http", "$location", "$timeout", function($scope, $http, $location, $timeout) {
    $scope.loading = true;
    $scope.refreshingCategories = true;
    $scope.allResults = false;
    $scope.categories = [];
    $scope.data = null;

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.take;
    $scope.searchParams.include = 'shops,parent_category';
    $scope.sendNotificationEmail = true;

    $scope.category = {
        title: '',
        handle: '',
        description: '',
        parent: null,
        parent_category: {
            data: {
                id: null,
                title: null
            }
        }
    };

    $scope.categoryAsync = {
        value: ''
    };

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function () {
        $scope.loading = true;
        $scope.refreshingCategories = true;
        $http.get('/api/categories', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.categories = response.data.data;
            if (response.data.data.length !== $scope.categories.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingCategories = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.addCategory = function() {
        $scope.category = {
            title: '',
            handle: '',
            description: '',
            parent: null,
            parent_category: {
                data: {
                    id: null,
                    title: null
                }
            }
        };
        $scope.focusAddCategory = true;
        $scope.updateHandle = function(title) {
            $scope.category.handle = getSlug(title);
        }
    };

    $scope.saveCategory = function() {
        $scope.data = null;
        var data = $scope.category;
        $http.post('/api/categories', data).then(function onSuccess(response) {
            $("#addCategory").modal('hide');
            setTimeout(function() {
                $location.path("/categories/" + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.loadMoreCategories = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.loadMore = function() {
        $scope.refreshingCategories = true;
        $http.get('/api/categories', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.categories.length) {
                $scope.categories = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingCategories = false;
        });
    };

    $scope.getCategories = function(val) {
        return $http.get('/api/categories', {
            params: {
                query: val
            }
        }).then(function(res){
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.setParentCategory = function(item) {
        $scope.category.parent = item.id;
        $scope.category.parent_category.data.id = item.id;
        $scope.category.parent_category.data.title = item.title;
        $scope.categoryAsync.value = item.title;
    };

    $scope.cleanParentCategory = function() {
        $scope.category.parent = null;
        $scope.category.parent_category.data.id = null;
        $scope.category.parent_category.data.title = null;
        $scope.categoryAsync.value = '';
    };

}]);

ctrl.controller('customerDetailCtrl', ["$scope", "$routeParams", "$http", "$location", "$timeout", "$window", "activeProfile", function($scope, $routeParams, $http, $location, $timeout, $window, activeProfile) {
    $scope.loading = true;
    $scope.flag = false;
    $scope.data = null;
    $scope.customer = {
        accepts_marketing: false,
        receives_reports: false,
        block_orders: false,
        logins: []
    };
    $scope.address = null;
    $scope.shopPoints = null;
    $scope.shop = null;
    $scope.customerId = $routeParams.customerId;
    $scope.currency = null;

    $scope.flagMessage = null;
    $scope.showFlagMessage = function(message) {
        $scope.flagMessage = message;
        $timeout(function () {
            $scope.flagMessage = null;
        }, 3000);
    };

    $scope.take = 15;
    $scope.searchParams = {};
    $scope.searchParams.take = $scope.take;
    $scope.searchParams.dateFrom = null;
    $scope.searchParams.dateTo = null;
    $scope.errorMessage = null;
    $scope.isError = false;

    var initialize = true;
    var initCustomer = true;

    $scope.loadingOrders = true;
    $scope.refreshingOrders = true;
    $scope.allResults = false;
    $scope.orders = [];

    $scope.activeProfile = activeProfile;

    $scope.getShop = function(shop_id) {
        $http.get('/api/shops/' + shop_id).then(function onSuccess(response) {
            $scope.shop = response.data.data;
        }, function onError() {
            //
        });
    };

    if ($scope.activeProfile.assigned_shop > 0) {
        $scope.getShop($scope.activeProfile.assigned_shop);
    }

    $http.get('/api/customers/' + $scope.customerId + '?include=logins,logins.shop,address,groups,gift_cards,gift_cards.shop,points,points.shop,points.currency,coupons,coupons.shop').then(function onSuccess(response) {
        $scope.customer = response.data.data;

        $scope.loading = false;
        initCustomer = false;
        if (response.data.data.logins.data.length !== 0) {
            $http.get('/api/customers/' + $scope.customerId + '/address-shop-data').then(function onSuccess(response) {
                $scope.hiddenAddressFields = response.data.fields;
                $scope.addressDepartments = response.data.departments;
                $scope.addressBudget = response.data.budget;
                $scope.addressDelivery = response.data.delivery;
            });
        } else {
            $scope.hiddenAddressFields = null;
            $scope.addressDepartments = null;
            $scope.addressBudget = null;
            $scope.addressDelivery = null;

            if ($routeParams.showLoginPopup) {
                setTimeout(() => {
                    $("#addCustomerAccountButton").trigger('click');
                }, 500)
            }
        }

        $(".modal-backdrop").hide(100).remove();
        $scope.address = $scope.customer.address.data[0];
        $scope.loadStates();
        $scope.getCustomerPointsGrouped();
    });

    $scope.customerPointsGrouped = null;
    // $scope.getCustomerPointsGrouped = function() {
    //     $http.get('/api/customers/' + $scope.customer.id + '/point-totals').then(function onSuccess(response) {
    //         $scope.customerPointsGrouped = response.data.data;
    //     }, function onError() {
    //         //
    //     });
    // };

    $scope.getCustomerPointsGrouped = function() {
        $http.get('/api/customers/' + $scope.customer.id + '/point-totals-separated').then(function onSuccess(response) {
            $scope.customerPointsGrouped = response.data.data;
        }, function onError() {
            //
        });
    };

    $scope.countryList = null;
    $http.get('js/countries.json').then(function onSuccess(response) {
        $scope.countryList = response.data;
    });

    $scope.account = {
        account_id: '',
        customer_id: '',
        shop_id: '',
        email: '',
        password: '',
        login_type: '',
        accepts_marketing: 'false',
        block_orders: 'false',
        shop: {
            data: {
                title: ''
            }
        },
        expires_at: '',
        approval_required: false,
        approval_email: '',
        customer_number: ''
    };

    $scope.addAccount = function() {
        $scope.data = null;
        $scope.hideShop = (activeProfile.assigned_shop !== null);
        $scope.account = {
            account_id: '',
            customer_id: $scope.customerId,
            shop_id: (activeProfile.assigned_shop === null) ? null : activeProfile.assigned_shop,
            email: '',
            password: '',
            login_type: '',
            accepts_marketing: 'false',
            block_orders: 'false',
            shop: {
                data: {
                    title: ''
                }
            },
            expires_at: '',
            approval_required: false,
            approval_email: '',
            customer_number: ''
        };
        $scope.focusAddAccount = true;
    };

    $scope.saveNewAccount = function() {
        var data = $scope.account;
        $http.post('/api/logins', data).then(function onSuccess(response) {
            $scope.account.id = response.data.created_id;
            $scope.customer.logins.data.push($scope.account);
            $scope.focusAddAccount = false;
            $("#addCustomerAccount").modal('hide');
            $scope.showFlagMessage(response.data.message);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editAccount = function(account) {
        $scope.data = null;
        $scope.account = account;
        $scope.focusEditAccount = true;
    };

    $scope.sendInvitationEmail = function(login) {
        console.log(login);
        $http.put('/api/logins/send-invitation-email/' + login.id).then(function () {
        }, function onError(response) {
            console.log(response);
        });
    }

    $scope.saveAccount = function() {
        var data = $scope.account;
        $http.put('/api/logins/' + $scope.account.id, data).then(function () {
            $scope.focusEditAccount = false;
            $('#editCustomerAccount').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.updateAcceptsMarketing = function() {
        var data = {
            accepts_marketing: $scope.account.accepts_marketing
        };
        $http.put('/api/logins/' + $scope.account.id + '/accepts-marketing', data).then(function () {
            $scope.flagAcceptsMarketingUpdated = true;
            $timeout(function() {
                $scope.flagAcceptsMarketingUpdated = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteAccount = function(idx) {
        $scope.idx = idx;
    };

    $scope.deleteAccount = function(idx) {
        $http.delete('/api/logins/' + $scope.customer.logins.data[idx].id).then(function () {
            $scope.customer.logins.data.splice(idx, 1);
            $("#deleteCustomerAccount").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.deleteCustomer = function() {
        $http.delete('/api/customers/' + $scope.customer.id).then(function () {
            $location.path("/customers");
            $("#deleteCustomer").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.deleteCustomerGaveuniverset = function() {
        $http.delete('/api/customers/' + $scope.customer.id + '/delete-from-gaveuniverset').then(function () {
            $location.path('/customers');
            $('#deleteCustomerGaveuniverset').modal('hide');
            $(".modal-backdrop").hide(500).remove();
        }).catch(function(response) {
            $('#deleteCustomerGaveuniverset').modal('hide');
            $(".modal-backdrop").hide(500).remove();
            $scope.isError = true;
            $scope.errorMessage = response.data.message;
            console.log(response.data.message);
        });
    };

    $scope.getShops = function(val) {
        return $http.get('/api/shops', {
            params: {
                query: val
            }
        }).then(function(res){
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.setShop = function(item) {
        $scope.account.shop_id = item.id;
        $scope.account.shop.data = item;
    };

    $scope.getGroups = function(val) {
        return $http.get('/api/groups', {
            params: {
                query: val,
                resource: 'customer'
            }
        }).then(function(res){
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.addGroup = function(item) {
        var customerId = $scope.customer.id;
        var groupId = item.id;
        $http.post('/api/groups/pivot?customerId='+customerId+'&groupId='+groupId).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.customer.groups.data.push(item);
                $scope.showFlagMessage(response.data.message);
            } else {
                window.location = '/login';
            }
        });
    };

    $scope.deleteGroup = function(idx) {
        var customerId = $scope.customer.id;
        var groupId = $scope.customer.groups.data[idx].id;
        $http.delete('/api/groups/pivot?customerId='+customerId+'&groupId='+groupId).then(function () {
            $scope.customer.groups.data.splice(idx, 1);
        });
    };

    $scope.editAddress = function(address) {
        $scope.address = address;
        $scope.focusEditAddress = true;
        $scope.loadStates();
        $scope.hasShopHaveAddress();
    };

    $scope.updateAddress = function() {
        var data = $scope.address;
        $http.put('/api/addresses/'+$scope.address.id, data).then(function () {
            $scope.focusEditAddress = false;
            $("#editAddress").modal('hide');
        }, function onError(response) {
            if (response.status === 405) {
                window.location = '/';
            }
        });
    };

    $scope.editCustomer = function() {
        $scope.focusEditCustomer = true;
    };

    $scope.saveCustomer = function () {
        $scope.data = null;
        var data = $scope.customer;
        $http.put('/api/customers/' + $scope.customer.id, data).then(function () {
            $scope.flag = true;
            $("#editCustomer").modal('hide');
            $timeout(function () {
                $scope.flag = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.checkOne = function () {
        $scope.showActions = false;
        $scope.checkedCount = 0;
        angular.forEach($scope.customer.orders.data, function (item) {
            if (item.selected === true) {
                $scope.showActions = true;
                $scope.checkedCount += 1;
            }
        });
    };

    $scope.printDeliveryNote = function () {
        var checked = [];
        angular.forEach($scope.customer.orders.data, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        $window.open('api/orders/print_delivery_notes?ids=' + checked);
    };

    $scope.stateList = null;
    $scope.updateCountry = function() {
        $.each($scope.countryList, function(index, object) {
            if (typeof object.cca2 !== 'undefined' && object.cca2 === $scope.address.country_code) {
                $scope.address.country = object.cca2;
            }
        });
        $scope.loadStates();
    };

    $scope.loadStates = function() {
        if (typeof $scope.address !== 'undefined' && $scope.address.length) {
            $http.get('/api/countries/' + $scope.address.country + '/states').then(function onSuccess(response) {
                $scope.stateList = response.data;
                if (angular.equals($scope.stateList, {})) {
                    $scope.stateList = null;
                }
            });
        }
    };

    $scope.focusEditGiftCard = false;
    $scope.gift_card = null;

    $scope.editGiftCard = function(gift_card) {
        $scope.data = null;
        $scope.gift_card = gift_card;
        $scope.focusEditGiftCard = true;
    };

    $scope.saveGiftCard = function() {
        $scope.data = null;
        var data = $scope.gift_card;
        $http.put('/api/customers/' + $scope.customer.id + '/gift-cards/' + $scope.gift_card.id, data).then(function () {
            $scope.focusEditGiftCard = false;
            $("#editCustomerGiftCard").modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteGiftCard = function(idx) {
        $scope.idx = idx;
    };

    $scope.deleteGiftCard = function(idx) {
        $scope.data = null;
        $http.delete('/api/customers/' + $scope.customer.id + '/gift-cards/' + $scope.customer.gift_cards.data[idx].id).then(function () {
            $scope.customer.gift_cards.data.splice(idx, 1);
            $("#deleteCustomerGiftCard").modal('hide');
        }, function onError(response) {
            console.log(response.data);
        });
    };

    $scope.focusAddPoints = false;
    $scope.focusEditPoints = false;

    $scope.points = {
        customer_id: $scope.customerId,
        shop_id: null,
        currency_id: null,
        value: 0,
        shop: {
            data: {
                title: ''
            }
        },
        currency: {
            data: {
                title: ''
            }
        },
        can_expire: false,
        expire_date: null,
        expiration_date_object: new Date(),
        expiration_date: null,
        send_information: false,
        use_from_wallet: false
    };

    $scope.dateOptions = {
        dateDisabled: null,
        formatYear: 'yyyy',
        formatMonth: 'MM',
        formatDay: 'dd',
        maxDate: new Date(new Date().getFullYear() + 1, 11, 31),
        minDate: new Date(),
        startingDay: 1
    };

    $scope.popup = {
        opened: false
    };

    $scope.openDatePicker = function() {
        $scope.popup.opened = true;
    };

    $scope.getShopsWithPoints = function(val) {
        return $http.get('/api/shops', {
            params: {
                query: val,
                currency: 'points'
            }
        }).then(function(res) {
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });

            return results;
        });
    };

    $scope.setPointsShop = function(item) {
        $scope.points.shop_id = item.id;
        $scope.points.shop.data.title = item.title;
        $scope.getShopPoints($scope.points.shop_id);
        $scope.setAvailableProductGroups(item.id, $scope.points.customer_id);
    };

    $scope.setAvailableProductGroups = function(shopId, customerId){
        $http.get('/api/available-product-groups', {
            params: {
                shop_id: shopId,
                customer_id: customerId
            }
        }).then(function onSuccess(response) {
            $scope.points.product_groups = response.data.data;
        });
    };

    $scope.getShopPoints = function(val) {
        $scope.shopPoints = null;
        $http.get('/api/currencies', {
            params: {
                shop_id: val,
                type: 'points'
            }
        }).then(function onSuccess(response) {
            $scope.shopPoints = response.data.data;
        });
    };

    $scope.addPoints = function() {
        $scope.focusAddPoints = true;
        $scope.data = null;
        $scope.shopPoints =  null;

        $scope.points = {
            customer_id: $scope.customerId,
            shop_id: null,
            currency_id: null,
            value: 0,
            shop: {
                data: {
                    title: ''
                }
            },
            currency: {
                data: {
                    title: ''
                }
            },
            can_expire: false,
            expire_date: null,
            expiration_date_object: new Date(),
            expiration_date: null,
            send_information: false,
            use_from_wallet: false,
            product_groups: [],
            selected_product_groups: [],
            //TODO modify to be able to handle different product groups
        };

        $scope.popup.opened = false;

        if ($scope.activeProfile.assigned_shop > 0) {
            $scope.setPointsShop($scope.shop);
        }
    };

    $scope.saveNewPoints = function() {
        if ($scope.shopPoints && $scope.shopPoints.length === 1) {
            $scope.points.currency_id = $scope.shopPoints[0].id;
        }
        $scope.points.expiration_date = moment($scope.points.expiration_date_object).format('YYYY-MM-DD');
        var data = $scope.points;
        $http.post('/api/customers/' + $scope.customerId + '/points', data).then(function onSuccess(response) {
            $scope.focusAddPoints = false;
            $('#addPoints').modal('hide');
            $('.modal-backdrop').hide(500).remove();
            $scope.getCustomerPointsGrouped();
            $scope.showFlagMessage(response.data.message);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.selectedPoint = {
        id: null
    };

    $scope.pointsAction = {
        oldValue: 0,
        newValue: 0,
        expiration_date: null,
        value: 0,
        action: ''
    };

    $scope.pointGrouped = null;

    $scope.editPoints = function(pointGrouped) {
        $scope.data = null;
        $scope.pointGrouped = pointGrouped;

        $scope.selectedPoint.id = pointGrouped.values[0].id;

        $scope.pointsAction = {
            id: pointGrouped.values[0].id,
            oldValue: pointGrouped.values[0].value,
            newValue: pointGrouped.values[0].value,
            expiration_date: pointGrouped.values[0].expiration_date,
            value: 0
        };

        $scope.focusEditPoints = true;
        $scope.popup.opened = false;

        if ($scope.activeProfile.assigned_shop > 0) {
            $scope.setPointsShop($scope.shop);
        }
        $scope.getShopCurrency(pointGrouped.shop_id, pointGrouped.currency_id);
        $scope.setAvailableProductGroupsByPoints(pointGrouped.id, pointGrouped.shop_id, pointGrouped.customer_id);
    };

    $scope.setAvailableProductGroupsByPoints = function(pointsId, shopId, customerId){
        $http.get('/api/available-product-groups', {
            params: {
                points_id: pointsId,
                shop_id: shopId,
                customer_id: customerId
            }
        }).then(function onSuccess(response) {
            $scope.pointGrouped.available_product_groups = response.data.data;
            $scope.pointGrouped.selected_product_groups_ids = $scope.pointGrouped.selected_product_groups;
        });
    };

    $scope.customerPointLog = {
        id: null,
        created_at: null,
        value_difference: null,
        action: null,
        owner_type: null,
        owner_name: null,
        expiration_date: null,
        note: null,
        order_id: null
    };

    $scope.customerPointLogs = null;
    $scope.customerPointLogsLoading = false;

    $scope.viewPointsLog = function(points) {
        $scope.customerPointLogs = null;
        $scope.customerPointLogsLoading = true;
        var groups = points.product_groups !== "";
        var ids = points.values.map(function(a) {return a.id;}).join(',');
        var url = '/api/customers/'+points.customer_id+'/shops/'+points.shop_id+'/points/'+points.currency_id+'/logs?groups='+ groups +'&ids=' + ids;
        $http.get(url).then(function onSuccess(response) {
            $scope.customerPointLogs = response.data.data;
            $scope.customerPointLogsLoading = false;
        });
    };

    $scope.addPointsAction = function() {
        $scope.pointsAction.newValue = $scope.pointsAction.newValue || 0;
        $scope.pointsAction.newValue = parseFloat($scope.pointsAction.newValue) + parseFloat($scope.pointsAction.value);
        $scope.pointsAction.newValue = parseFloat(Math.max($scope.pointsAction.newValue, 0)).toFixed(4).toString();
        $scope.pointsAction.newValue = Number($scope.pointsAction.newValue);
    };

    $scope.subtractPointsAction = function() {
        $scope.pointsAction.newValue = $scope.pointsAction.newValue || 0;
        $scope.pointsAction.newValue = parseFloat($scope.pointsAction.newValue) - parseFloat($scope.pointsAction.value);
        $scope.pointsAction.newValue = parseFloat(Math.max($scope.pointsAction.newValue, 0)).toFixed(4).toString();
        $scope.pointsAction.newValue = Number($scope.pointsAction.newValue);
    };

    $scope.savePoints = function() {
        var data = {
            id: $scope.pointsAction.id,
            value: $scope.pointsAction.newValue,
            shop_id: $scope.pointGrouped.shop_id,
            currency_id: $scope.pointGrouped.currency_id,
            customer_id: $scope.pointGrouped.customer_id,
            expiration_date: $scope.pointsAction.expiration_date,
            use_from_wallet: $scope.points.use_from_wallet,
            product_groups_ids: $scope.pointGrouped.selected_product_groups_ids,
            send_information: $scope.points.send_information
        };
        $http.put('/api/customers/' + $scope.customerId + '/points/' + data.id, data).then(function () {
            $scope.focusEditPoints = false;
            $("#editPoints").modal('hide');
            $('.modal-backdrop').hide(500).remove();
            $scope.getCustomerPointsGrouped();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeletePoints = function(idx) {
        $scope.idx = idx;
    };

    $scope.deletePoints = function(idx) {
        var id = $scope.customerPointsGrouped[idx].values[0].id;
        $http.delete('/api/customers/' + $scope.customerId + '/points/' + id).then(function () {
            $scope.customer.points.data.splice(idx, 1);
            $scope.idx = 0;
            $('#deletePoints').modal('hide');
            $('.modal-backdrop').hide(500).remove();
            $scope.getCustomerPointsGrouped();
        });
    };

    $scope.selectPointForUpdate = function() {
        $scope.pointsAction.id = null;
        $scope.pointsAction.oldValue = 0;
        $scope.pointsAction.newValue = 0;
        $scope.pointsAction.expiration_date = null;
        $scope.pointsAction.value = 0;
        angular.forEach($scope.pointGrouped.values, function(point) {
            if (point.id === $scope.selectedPoint.id) {
                $scope.pointsAction.id = point.id;
                $scope.pointsAction.oldValue = point.value;
                $scope.pointsAction.newValue = point.value;
                $scope.pointsAction.expiration_date = point.expiration_date;
                $scope.pointsAction.value = 0;
            }
        });
    };

    $scope.change = function () {
        $scope.loadingOrders = true;
        $scope.refreshingOrders = true;

        $http.get('/api/customers/' + $scope.customerId + '/orders?include=shop', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.orders = response.data.data;
            if (response.data.data.length !== $scope.orders.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loadingOrders = false;
            $scope.refreshingOrders = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $scope.change();

    $scope.loadMoreOrders = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $scope.loadMore();
    };

    $scope.loadMore = function() {
        $scope.refreshingOrders = true;
        $http.get('/api/customers/' + $scope.customerId + '/orders?include=shop', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.orders = response.data.data;
            if (response.data.data.length !== $scope.orders.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingOrders = false;
        });
    };

    $scope.$watch('customer.privilege_budget_id', function (newVal, oldVal) {
        if (!initCustomer) {
            if (!angular.equals(newVal, oldVal)) {
                $scope.data = null;
                var data = $scope.customer;
                $http.put('/api/customers/' + $scope.customer.id + '/privileges', data).then(function() {
                    //
                }, function onError(response) {
                    $scope.data = response.data;
                });
            }
        }
    });

    $scope.$watch('points.currency_id', function (newVal, oldVal) {
        $scope.currency = null;
        $scope.points.use_from_wallet = false;
        if (newVal !== oldVal) {
            if ($scope.shopPoints) {
                for (var i = $scope.shopPoints.length - 1; i >= 0; i--) {
                    if ($scope.shopPoints[i].id === newVal) {
                        $scope.currency = $scope.shopPoints[i];
                        break;
                    }
                }
            }
        }
    });

    $scope.getShopCurrency = function(shop_id, currency_id) {
        $scope.currency = null;
        $scope.points.use_from_wallet = false;
        $http.get('/api/shops/' + shop_id + '/points/' + currency_id).then(function onSuccess(response) {
            $scope.currency = response.data.data;
        });
    };

    $scope.hasShopAddress = false;

    $scope.hasShopHaveAddress = function() {
        $scope.hasShopAddress = false;
        var $addressPromise = $scope.getShopDeliveryAddresses();
        $addressPromise.then(function(result) {
            if (result.length) {
                $scope.hasShopAddress = true;
            }
        });
    };

    $scope.showShopAddressSelection = false;
    $scope.loadAddressFromShop = function() {
        $scope.showShopAddressSelection = false;
        if ($scope.hasShopAddress === true) {
            var $addressPromise = $scope.getShopDeliveryAddresses();
            $addressPromise.then(function(result) {
                if (result.length === 1) {
                    $scope.setShopDeliveryAddress(result[0]);
                } else {
                    $scope.showShopAddressSelection = true;
                }
            });
        }
    };

    $scope.getShopDeliveryAddresses = function(val) {
        var shop_id = $scope.customer.logins.data[0].shop_id;

        return $http.get('/api/shops/' + shop_id + '/delivery-addresses', {
            params: {
                query: val
            }
        }).then(function onSuccess(res) {
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });

            return results;
        });
    };

    $scope.deliveryAddressAsync = null;

    $scope.setShopDeliveryAddress = function(item) {
        $scope.address.company = item.company;
        $scope.address.address1 = item.address;
        $scope.address.postcode = item.zip_code;
        $scope.address.city = item.city;
        $scope.address.state = null;
        $scope.address.country = item.country;
        $scope.address.customer_number = item.customer_number;
    };
}]);

ctrl.controller('customerGroupCtrl', ["$scope", "$http", "$location", "$timeout", function($scope, $http, $location, $timeout) {
    $scope.loading = true;

    $scope.searchParams = $location.search();
    $scope.searchParams.resource = 'customer';
    var tempQueryString = '', queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            tempQueryString = newValue;
            queryTimeout = $timeout(function () {
                $scope.searchParams.query = tempQueryString;
                $location.search($scope.searchParams);
                $scope.change();
            }, 1500);
        }
    }, true);

    $scope.change = function() {
        $scope.loading = true;
        $http.get('/api/groups', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.groups = response.data.data;
            $scope.loading = false;
        });
    };

    $scope.change();


    $scope.addGroup = function() {
        $scope.group = {name: '', resource: 'customers'};
        $scope.focusAddGroup = true;
    };

    $scope.saveGroup = function() {
        var data = $scope.group;
        $http.post('/api/groups', data).then(function onSuccess(response) {
            if (response.status === 201) {
                $location.path("/customers/groups/");
                $("#addGroup").modal('hide');
                $(".modal-backdrop").hide(500).remove();
            } else {
                window.location = '/';
            }
        });
        $scope.focusAddGroup = false;
    };

    $scope.editGroup = function (group) {
        $scope.group = group;
        $scope.focusEditGroup = true;
    };

    $scope.updateGroup = function (group) {
        $http.put('/api/groups/'+group.id, group).then(function () {
            $scope.flagUpdated = true;
            $("#editGroup").modal('hide');
            $(".modal-backdrop").hide(500).remove();
            $scope.focusEditGroup = false;
            $timeout(function () {
                $scope.flagUpdated = false;
            }, 3000);
        }, function onError(response) {
            if (response.status === 405) {
                window.location = '/login';
            }
        });
    };

    $scope.confirmDeleteGroup = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteGroup = function (idx) {
        $http.delete('/api/groups/' + $scope.groups[idx].id).then(function () {
            $scope.groups.splice(idx, 1);
            $("#deleteGroup").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    }
}]);

ctrl.controller('customerHandoutCtrl', ["$scope", "$http", "$filter", "activeProfile", function ($scope, $http, $filter, activeProfile) {
    $scope.loading = true;
    $scope.loadingSave = false;
    $scope.selectedAll = {checked: false};
    $scope.selectedShop = (activeProfile.assigned_shop === null) ? '' : activeProfile.assigned_shop;
    $scope.customers = [];
    $scope.selectedProduct = '';
    $scope.selectedVariant = '';
    $scope.selectedProductTitle = '';
    $scope.stock = '';
    $scope.checked = [];
    $scope.success = false;
    $scope.createdCount = 0;
    $scope.info = {key: '', value: ''};
    $scope.infoNotice = 'true';
    $scope.hiddenAddressFields = null;
    $scope.addressDepartments = null;
    $scope.addressBudget = null;
    $scope.addressDelivery = null;

    $http.get('/api/shops-compact').then(function onSuccess(response) {
        $scope.shops = [{id: '', title: $filter('translate')('SELECT_SHOP')}];
        $scope.shops = $scope.shops.concat(response.data);
        $scope.loading = false;

        if ($scope.selectedShop > 0) {
            $scope.changeShop();
        }
    });

    $scope.customersWhoHaveNotSelected = function () {
        $scope.success = false;
        $scope.loading = true;
        $scope.stock = '';
        // Get customers who has not selected
        $http.get('/api/customers/no-orders', {params: {shop: $scope.selectedShop}}).then(function onSuccess(response) {
            $scope.customers = response.data;
            angular.forEach($scope.customers, function (item) {
                item.selected = false;
            });
            $scope.infoNotice = 'true';
            $scope.loading = false;
            $scope.checked = [];
            $scope.selectedAll.checked = false;
        });
    };

    $scope.changeShop = function () {
        $scope.customersWhoHaveNotSelected();

        $scope.hiddenAddressFields = null;
        $scope.addressDepartments = null;
        $scope.addressBudget = null;
        $scope.addressDelivery = null;

        // Get available products
        $http.get('/api/shops/' + $scope.selectedShop + '/products').then(function onSuccess(response) {
            $scope.products = response.data.data;
        });
        $scope.selectedProduct = '';

        // Get shop related data
        $http.get('/api/shops/' + $scope.selectedShop + '/address-shop-data').then(function onSuccess(response) {
            $scope.hiddenAddressFields = response.data.fields;
            $scope.addressDepartments = response.data.departments;
            $scope.addressBudget = response.data.budget;
            $scope.addressDelivery = response.data.delivery;
        });
    };

    if (activeProfile.assigned_shop !== null) {
        $scope.changeShop();
    }

    $scope.$watch('info.key', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.info.val = '';
        }
    }, true);

    $scope.changeVariant = function () {
        // Get variants and their stock count
        $scope.stock = '';
        $http.get('/api/products/' + $scope.selectedProduct + '/variants/' + $scope.selectedShop).then(function onSuccess(response) {
            $scope.variants = response.data;
        });
        $scope.selectedVariant = '';
    };

    $scope.calculateStock = function () {
        $http.get('/api/variants/' + $scope.selectedVariant + '/stock/' + $scope.selectedShop).then(function onSuccess(response) {
            $scope.stock = response.data;
        });
    };

    $scope.checkAll = function () {
        $scope.checked = [];
        angular.forEach($scope.customers, function (item) {
            item.selected = $scope.selectedAll.checked;
            if ($scope.selectedAll.checked === true) $scope.checked.push(item.id);
        });
    };

    $scope.checkOne = function () {
        $scope.checked = [];
        angular.forEach($scope.customers, function (item) {
            if (item.selected === true) {
                $scope.checked.push(item.id);
            }
        });
        $scope.selectedAll.checked = ($scope.customers.length === $scope.checked.length);
    };

    $scope.data = null;
    $scope.submitHandout = function() {
        $scope.success = false;
        $scope.createdCount = 0;
        $scope.submitDisabled = true;
        $scope.loadingSave = true;
        $scope.data = null;

        $http.post('/api/orders/hand-out', {
            customers: $scope.checked,
            shop: $scope.selectedShop,
            variant: $scope.selectedVariant,
            stock: $scope.stock,
            infoKey: $scope.info.key,
            infoVal: $scope.info.val,
            infoNotice: $scope.infoNotice !== 'false'
        }).then(function onSuccess(response) {
            if (response.data.status_code === 201) {
                $scope.createdCount = response.data.message;
                if ($scope.createdCount > 0) {
                    $scope.calculateStock();
                    $scope.customersWhoHaveNotSelected();
                    $scope.loadingSave = false;
                    $scope.submitDisabled = false;
                    $scope.success = true;
                } else {
                    $scope.loadingSave = false;
                    $scope.submitDisabled = false;
                }
            }
        }, function onError(response) {
            $scope.data = response.data;
            $scope.submitDisabled = false;
            $scope.loadingSave = false;
            $scope.success = true;
            $scope.checked = [];
            $scope.selectedAll.checked = false;
        });
    };

    $scope.$watch('selectedShop', function (newValue, oldValue) {
        if (newValue !== oldValue && newValue > 0) {
            $scope.changeShop();
        }
    }, true);

    $scope.$watch('selectedProduct', function (newValue, oldValue) {
        if (newValue !== oldValue && newValue > 0) {
            $scope.changeVariant();
        }
    }, true);

    $scope.$watch('selectedVariant', function (newValue, oldValue) {
        if (newValue !== oldValue && newValue > 0) {
            $scope.calculateStock();
        }
    }, true);

    $scope.infoValues = [];
    $scope.$watch('info.key', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (newValue === 'department') {
                $scope.infoValues = $scope.addressDepartments;
            }
            if (newValue === 'budget_id') {
                $scope.infoValues = $scope.addressBudget;
            }
            if (newValue === 'delivery') {
                $scope.infoValues = $scope.addressDelivery;
            }
        }
    }, true);

}]);

ctrl.controller('customerImportCtrl', ["$scope", "$http", "$location", "$filter", "Upload", "$timeout", function($scope, $http, $location, $filter, Upload, $timeout) {
    $scope.csvLoaded = false;
    $scope.importData = [];
    $scope.data = null;
    $scope.customerImport = null;
    $scope.sendInformation = {
        email: false
    };
    var queryTimeout;

    $scope.countryList = [];
    $http.get('js/countries.json').then(function onSuccess(response) {
        $scope.countryList = response.data;
    });

    $scope.$on('$stateChangeStart', function() {
        if (queryTimeout) $timeout.cancel(queryTimeout);
    });

    $scope.$on('$destroy', function() {
        if (queryTimeout) $timeout.cancel(queryTimeout);
    });

    $scope.importCustomer = function(url, id) {
        $http.get(url).then(function(response) {
            setCSVData(response)
        }, function() {
            $http.get('/api/files/'+id).then(function (data) {
                $http.get(data.data.data.src).then(function(response) {
                    setCSVData(response);
                });
            });
        });
    };

    // Item order matters
    // Do not change
    $scope.defaultData = [
        'initials',
        'firstname',
        'lastname',
        'email',
        'company',
        'address',
        'zip',
        'city',
        'country',
        'phone',
        'username',
        'password',
        'department',
        'budget',
        'delivery',
        'marketing',
        'shopid',
        'group',
        'expires',
        'approval',
        'pointId',
        'pointValue',
        'ccEmail',
        'pointExpirationDate',
        'pointNote',
        'customerNumber',
        'customField1',
        'customField2',
        'reports',
        'individualShipping'
    ];

    function setCSVData(response) {
        $scope.setUpVariables();
        if (typeof response.data !== 'undefined') {
            $scope.csv = response.data;
            $scope.csvLoaded = true;
            $scope.importData =  CSVToArray(response.data);
            $scope.firmvaluePosition = [];
            for (var i = 0; i < $scope.defaultData.length; i++) {
                $scope.firmvaluePosition.push(0);
            }
            $scope.loadData = $scope.importData;
            $scope.selectedValue = [];
            $scope.selectedValue.push({ val: 0, text: $filter('translate')('EMPTY') });
            $scope.selectedValue.push({ val: 1, text: $filter('translate')('FIXED') });
            for (var j = 2 ; j < $scope.importData[0].length+2; j++) {
                $scope.selectedValue.push({ val: j, text: $filter('translate')('COL') + ' ' + (j - 1) });
            }

            angular.forEach($scope.defaultData, function(value, index) {
                $scope[value] = ($scope.loadData[0].length > index) ? index : -1;
            });

            angular.forEach($scope.defaultData, function(value, index) {
                var valueSelect = value + 'Select';
                $scope[valueSelect] = ($scope.loadData[0].length > index) ? (index + 2) : 0;
            });
        }
    }

    $scope.updateColumnFirm = function(i, val) {
        angular.forEach($scope.defaultData, function(value, index) {
            if (i === index) {
                angular.forEach($scope.loadData, function(row) {
                    if ($scope.firmvaluePosition[i]===0 || typeof $scope.firmvaluePosition[i] === 'undefined') {
                        $scope[value] = row.length;
                        $scope.firmvaluePosition[i] = row.length;
                    } else {
                        $scope[value] = $scope.firmvaluePosition[i];
                    }
                    row[$scope[value]] = val;
                });
            }
        });
    };

    $scope.updateColumn = function(i, val) {
        angular.forEach($scope.defaultData, function(value, index) {
            if (i === index) {
                var columnShow = value + 'Show';
                if (val === 1) {
                    $scope[columnShow] = true;
                } else {
                    $scope[columnShow] = false;
                    $scope[value] = val - 2;
                }
            }
        });

        $scope.firmrowShow = false;
        angular.forEach($scope.defaultData, function(value) {
            var columnShow = value + 'Show';
            if ($scope[columnShow]) {
                $scope.firmrowShow = true;
            }
        });
    };

    $scope.importError = false;
    $scope.submitImport = function() {
        var $postData = {
            csv: $scope.importData,
            sendInformationEmail: $scope.sendInformation.email
        };
        angular.forEach($scope.defaultData, function(value) {
            $postData[value] = $scope[value];
        });
        $scope.loading = true;
        $scope.csvLoaded = false;
        $scope.importError = false;
        $http.post('/api/customers', $postData).then(function onSuccess(response) {
            $scope.loading = false;
            $scope.success = true;
            $scope.customerImport = response.data.data;
            if (queryTimeout) $timeout.cancel(queryTimeout);
            if ($scope.customerImport) {
                $scope.checkStatus($scope.customerImport.job_id);
            }
        }, function onError(response) {
            $scope.loading = false;
            $scope.success = false;
            $scope.data = response.data;
            $scope.importError = true;
        });
    };

    $scope.onFileSelect = function($files) {
        if (queryTimeout) $timeout.cancel(queryTimeout);
        $scope.loading = true;
        $scope.success = false;
        $scope.importError = false;
        $scope.sendInformation.email = false;
        angular.forEach($files, function(file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
                Upload.http({
                    url: '/api/files',
                    data: {
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function(response) {
                    if (response.status === 201) {
                        $scope.loading = false;
                        $scope.importCustomer(response.data.file_path, response.data.created_id);
                    }
                });
            }
        });
    };

    function CSVToArray(strData, strDelimiter) {
        strDelimiter = (strDelimiter || ";");
        var objPattern = new RegExp(("(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" + "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi");
        var arrData = [[]];
        var arrMatches = null;
        if (arrMatches === null) {} // Lazy fix for variable definition validation
        while (arrMatches = objPattern.exec(strData)) {
            var strMatchedDelimiter = arrMatches[1];
            if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
                arrData.push([]);
            }
            var strMatchedValue;
            if (!arrMatches.index && arrMatches[0].charAt(0) === strDelimiter) {
                arrData[arrData.length - 1].push('');
            }
            if (typeof arrMatches[2] !== 'undefined') {
                strMatchedValue = arrMatches[2].replace(new RegExp( "\"\"", "g" ), "\"");
            } else {
                strMatchedValue = arrMatches[3];
            }
            arrData[arrData.length - 1].push(strMatchedValue);
        }

        return arrData;
    }

    $scope.checkStatus = function(job_id) {
        $http.get('/api/import-customers/'+job_id).then(function onSuccess(response) {
            $scope.customerImport = response.data.data;
            if ($scope.customerImport.job_status !== 'completed' && $scope.customerImport.job_status !== 'cancelled') {
                queryTimeout = $timeout(function() {
                    $scope.checkStatus(job_id);
                }, 2000);
            }
        }, function onError(response) {
            console.log(response.data);
        });
    };

    $scope.jobs = [];
    $scope.loadingJobList = true;
    $scope.showJobList = function() {
        $scope.loadingJobList = true;
        $http.get('/api/import-customers').then(function onSuccess(response) {
            $scope.jobs = response.data.data;
            $scope.loadingJobList = false;
        }, function onError(response) {
            $scope.loadingJobList = false;
            console.log(response.data);
        });
    };

    $scope.refreshJobs = function() {
        $scope.showJobList();
    };

    $scope.setUpVariables = function() {
        angular.forEach($scope.defaultData, function(value) {
            var valueFirm = value + 'Firm';
            var valueShow = value + 'Show';
            $scope[valueFirm] = null;
            $scope[valueShow] = null;
        });
    };

}]);

ctrl.controller('customerCtrl', ["$scope", "$http", "$location", "$timeout", "activeProfile", "$filter", "$window", function($scope, $http, $location, $timeout, activeProfile, $filter, $window) {
    $scope.loading = true;
    $scope.flagCustomersPoints = false;
    $scope.refreshingCustomers = true;
    $scope.allResults = false;
    $scope.data = null;
    $scope.customers = [];
    $scope.assignedShop = activeProfile.assigned_shop;
    if ($scope.assignedShop === null) {
        $scope.shops = [
            {id: '', title: $filter('translate')('ALL_SHOPS')}
        ];
        $http.get('/api/shops-compact').then(function onSuccess(response) {
            $scope.shops = $scope.shops.concat(response.data);
        });
    }
    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.take;
    $scope.selectedShop = $scope.searchParams.shop_id;

    var initialize = true;

    $scope.$watch('searchParams.shop_id', function (value) {
        if (!initialize) {
            $scope.selectedAll = false;
            $scope.showActions = false;
            $scope.checkedCount = 0;

            $scope.searchParams.shop_id = value;
            $location.search($scope.searchParams);
            $scope.change();
        }
    }, true);

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            $scope.selectedAll = false;
            $scope.showActions = false;
            $scope.checkedCount = 0;
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function() {
        $scope.loading = true;
        $scope.refreshingCustomers = true;
        $http.get('/api/customers', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.customers = response.data.data;
            if (response.data.data.length !== $scope.customers.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingCustomers = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.selectedAll = false;
    $scope.showActions = false;
    $scope.checkAll = function () {
        $scope.showActions = false;
        $scope.checkedCount = 0;
        angular.forEach($scope.customers, function (item) {
            item.selected = $scope.selectedAll;
            if (item.selected === true) {
                $scope.showActions = true;
                $scope.checkedCount += 1;
            }
        });
    };

    $scope.checkOne = function () {
        $scope.showActions = false;
        $scope.checkedCount = 0;
        angular.forEach($scope.customers, function (item) {
            if (item.selected === true) {
                $scope.showActions = true;
                $scope.checkedCount += 1;
            }
        });
    };

    $scope.loginOnGaveUniverset = function (user) {
        var page = "api/customers/" + user + "/login-on-gave-universet";
        $window.open(page, '_blank');
    }

    $scope.addCustomer = function() {
        $scope.customer = {first_name: '', last_name: '', email: ''};
        $scope.focusAddCustomer = true;
    };

    $scope.saveCustomer = function() {
        var data = $scope.customer;
        $scope.data = null;
        $http.post('/api/customers', data).then(function onSuccess(response) {
            if (!activeProfile.assigned_shop) {
                $location.path("/customers/" + response.data.created_id);
            } else {
                $location.path("/customers/" + response.data.created_id).search({"showLoginPopup" : 1});
            }
            $("#addCustomer").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        }, function onError(response) {
            $scope.data = response.data;
        });
        $scope.focusAddCustomer = false;
    };

    $scope.loadMoreCustomers = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.loadMore = function () {
        $scope.refreshingCustomers = true;
        $http.get('/api/customers', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.customers.length) {
                $scope.customers = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingCustomers = false;
        });
    };

    $scope.shop = null;
    $scope.getShop = function(shop_id) {
        $http.get('/api/shops/' + shop_id).then(function onSuccess(response) {
            $scope.shop = response.data.data;
        });
    };

    $scope.shopPoints = null;
    $scope.getShopPoints = function(val) {
        $http.get('/api/currencies', {params: {shop_id: val, type: 'points'}}).then(function onSuccess(response) {
            $scope.shopPoints = response.data.data;
        });
    };

    $scope.points = {
        action: 0,
        customer_id: $scope.customerId,
        shop_id: null,
        currency_id: null,
        value: 0,
        can_expire: false,
        expire_date: null,
        expiration_date_object: new Date(),
        expiration_date_object_1: new Date(),
        expiration_date_object_2: new Date(),
        expiration_date: null,
        expiration_date_1: null,
        expiration_date_2: null,
        include_empty: false,
        send_information: false
    };

    $scope.dateOptions = {
        dateDisabled: null,
        formatYear: 'yyyy',
        formatMonth: 'MM',
        formatDay: 'dd',
        maxDate: new Date(new Date().getFullYear() + 1, 11, 31),
        minDate: new Date(),
        startingDay: 1
    };

    $scope.popup = {
        opened: false,
        opened_1: false,
        opened_2: false
    };

    $scope.openDatePicker = function() {
        $scope.popup.opened = true;
    };

    $scope.openDatePicker1 = function() {
        $scope.popup.opened_1 = true;
    };

    $scope.openDatePicker2 = function() {
        $scope.popup.opened_2 = true;
    };

    $scope.editCustomersPoints = function() {
        $scope.data = null;
        $scope.shop = null;
        $scope.shopPoints = null;

        $scope.points = {
            action: 0,
            customer_id: $scope.customerId,
            shop_id: null,
            currency_id: null,
            value: 0,
            can_expire: false,
            expire_date: null,
            expiration_date_object: new Date(),
            expiration_date_object_1: new Date(),
            expiration_date_object_2: new Date(),
            expiration_date: null,
            expiration_date_1: null,
            expiration_date_2: null,
            include_empty: false,
            send_information: false
        };

        $scope.getShop($scope.searchParams.shop_id);
        $scope.getShopPoints($scope.searchParams.shop_id);
    };

    $scope.saveCustomersPoints = function() {
        $scope.data = null;
        if ($scope.shopPoints && $scope.shopPoints.length === 1) {
            $scope.points.currency_id = $scope.shopPoints[0].id;
        }
        $scope.points.expiration_date = moment($scope.points.expiration_date_object).format('YYYY-MM-DD');
        $scope.points.expiration_date_1 = moment($scope.points.expiration_date_object_1).format('YYYY-MM-DD');
        $scope.points.expiration_date_2 = moment($scope.points.expiration_date_object_2).format('YYYY-MM-DD');
        var data = $scope.points;

        var checked = [];
        angular.forEach($scope.customers, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        data.customers = checked;
        data.shop_id = $scope.shop.id;

        $http.post('/api/customers-points', data).then(function onSuccess() {
            $('#editCustomersPoints').modal('hide');
            $('.modal-backdrop').hide(500).remove();
            $scope.flagCustomersPoints = true;
            $timeout(function () {
                $scope.flagCustomersPoints = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

}]);

ctrl.controller('pendingCustomersCtrl', ["$scope", "$http", "$location", "$timeout", "activeProfile", "$filter", "$window", function($scope, $http, $location, $timeout, activeProfile, $filter, $window) {
    $scope.loading = true;
    $scope.flagCustomersPoints = false;
    $scope.refreshingCustomers = true;
    $scope.allResults = false;
    $scope.data = null;
    $scope.customers = [];
    $scope.assignedShop = activeProfile.assigned_shop;

    if ($scope.assignedShop === null) {
        $scope.shops = [
            {id: '', title: $filter('translate')('ALL_SHOPS')}
        ];
        $http.get('/api/shops-compact').then(function onSuccess(response) {
            $scope.shops = $scope.shops.concat(response.data);
        });
    }
    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.take;
    $scope.searchParams.admin_approved = false;

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            $scope.selectedAll = false;
            $scope.showActions = false;
            $scope.checkedCount = 0;
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function() {
        $scope.loading = true;
        $scope.refreshingCustomers = true;
        $http.get('/api/customers', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.customers = response.data.data;
            if (response.data.data.length !== $scope.customers.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingCustomers = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();


    $scope.approveCustomer = function(customer) {
        customer.admin_approved = true;
        $http.put('/api/customers/' + customer.id, customer).then(function () {
            $location.search($scope.searchParams);
            $scope.change();
            $http.post('/api/customers/send-approve-email', customer);
        }, function onError(response) {
            $scope.data = response.data;
        });
    }

    $scope.dismissCustomer = function() {
        var customer = $scope.customerToDelete;

        $http.delete('/api/customers/' + customer.id).then(function () {
            $location.search($scope.searchParams);
            $scope.change();
            $http.post('/api/customers/send-reject-email', customer);
        }, function onError(response) {
            $scope.data = response.data;
        });
        $("#dismissCustomerModal").modal('hide');
        $(".modal-backdrop").hide(500).remove();
        $scope.customerToDelete = null;
    }

    $scope.confirmDismissCustomer = function (customer) {
        $scope.customerToDelete = customer;
    }

    $scope.loadMoreCustomers = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.loadMore = function () {
        $scope.refreshingCustomers = true;
        $http.get('/api/customers', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.customers.length) {
                $scope.customers = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingCustomers = false;
        });
    };

    $scope.shop = null;
    $scope.getShop = function(shop_id) {
        $http.get('/api/shops/' + shop_id).then(function onSuccess(response) {
            $scope.shop = response.data.data;
        });
    };

}]);

ctrl.controller('discountCtrl', ["$scope", "$http", "$location", "$timeout", "$filter", function($scope, $http, $location, $timeout, $filter) {
    $scope.loading = true;
    $scope.refreshingDiscounts = true;
    $scope.allResults = false;
    $scope.discounts = [];
    $scope.loadingProducts = false;

    $scope.countryList = [];
    $http.get('js/countries.json').then(function onSuccess(response) {
        response.data.unshift({cca2: '', name: {common: $filter('translate')('ALL_COUNTRIES')}});
        $scope.countryList = response.data;
    });

    $scope.resourceOptions = [
        {value: 'all', name: $filter('translate')('ALL_ORDERS')},
        {value: 'product', name: $filter('translate')('PRODUCT')},
        {value: 'group', name: $filter('translate')('GROUP')},
        {value: 'vendor', name: $filter('translate')('VENDOR')},
        {value: 'category', name: $filter('translate')('CATEGORY')},
        {value: 'customer', name: $filter('translate')('CUSTOMER')},
        {value: 'all_customers', name: $filter('translate')('ALL_CUSTOMERS')}
    ];

    $scope.calculateFromOptions = [
        {value: 'sell_price', name: $filter('translate')('SELL_PRICE')},
        {value: 'retail_price', name: $filter('translate')('RETAIL_PRICE')}
    ];

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = ($scope.searchParams.take > 200 || $scope.searchParams.take < $scope.take) ? $scope.take : $scope.searchParams.take;

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1500);
            }
        }
    });

    $scope.change = function () {
        $scope.loading = true;
        $scope.refreshingDiscounts = true;
        $http.get('/api/discounts', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.discounts = response.data.data;
            if (response.data.data.length !== $scope.discounts.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingDiscounts = false;
            if (initialize === true) {
                initialize = false;
            }


            angular.forEach($scope.discounts, function(discount) {
                if(discount.product_id != 0) {
                    $scope.getProductTitle(discount.product_id).then(result => {
                        discount.product_name = result;
                    });
                }
            });

            if ($scope.searchParams.edit !== 'undefined') {
                $scope.searchParams.edit = parseInt($scope.searchParams.edit);
                angular.forEach($scope.discounts, function(discount) {
                    if (discount.id === $scope.searchParams.edit) {;
                        $scope.editDiscount(discount);
                        angular.element('#editDiscount').modal('show');
                    }
                });
                $scope.searchParams.edit = null;
            }


        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.discount = {
        code: '',
        usage_limit: null,
        value: '',
        applies_to_id: 0,
        applies_to_resource: 'all', // all, product, group
        type: 'percentage',
        starts_at: new Date(new Date().toDateString()),
        ends_at: new Date(new Date().toDateString()),
        never_expires: true,
        resource_name: '',
        country: '',
        country_name: '',
        shop_id: 0,
        shop_name: '',
        used: 0,
        calculate_from: 'sell_price',
        description: '',
        minimum_order_amount: 0,
        product_id: 0,
        product_name: '',
    };

    $scope.addDiscount = function() {
        $scope.data = null;
        $scope.discount = {
            code: '',
            usage_limit: null,
            customer_usage_limit: null,
            value: '',
            applies_to_id: 0,
            applies_to_resource: 'all', // all, product, group
            type: 'percentage',
            starts_at: new Date(new Date().toDateString()),
            ends_at: new Date(new Date().toDateString()),
            never_expires: true,
            resource_name: '',
            country: '',
            country_name: '',
            shop_id: 0,
            shop_name: '',
            used: 0,
            calculate_from: 'sell_price',
            description: '',
            product_id: 0,
            product_name: '',
        };
        $scope.focusAddDiscount = true;
    };

    $scope.saveDiscount = function() {
        var data = $scope.discount;
        var selectedCountry = $filter('filter')($scope.countryList, {cca2: $scope.discount.country});
        $scope.discount.country_name = selectedCountry[0].name.common;
        $http.post('/api/discounts', data).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.flagCreated = true;
                $scope.discount.id = response.data.created_id;
                $scope.discounts.unshift($scope.discount);
                $scope.focusAddDiscount = false;
                $('#addDiscount').modal('hide');
                $timeout(function () {
                    $scope.flagCreated = false;
                }, 3000);
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
        $scope.focusAddDiscount = false;
    };

    $scope.editDiscount = function (discount) {
        $scope.data = null;
        discount.starts_at = new Date(discount.starts_at);
        discount.ends_at = new Date(discount.ends_at);
        $scope.discount = discount;
        $scope.focusEditDiscount = true;
    };

    $scope.updateDiscount = function (discount) {
        var selectedCountry = $filter('filter')($scope.countryList, {cca2: discount.country});
        discount.country_name = selectedCountry[0].name.common;
        $http.put('/api/discounts/'+discount.id, discount).then(function () {
            $scope.flagUpdated = true;
            $scope.focusEditDiscount = false;
            $("#editDiscount").modal('hide');
            $timeout(function () {
                $scope.flagUpdated = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteDiscount = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteDiscount = function (idx) {
        $http.delete('/api/discounts/' + $scope.discounts[idx].id).then(function () {
            $scope.flagDeleted = true;
            $scope.discounts.splice(idx, 1);
            $("#deleteDiscount").modal('hide');
            $timeout(function () {
                $scope.flagDeleted = false;
            }, 3000);
        });
    };

    $scope.$watch('discount.applies_to_resource', function() {
        $scope.discount.applies_to_id = 0;
    }, true);

    $scope.getProducts = function(query) {
        let callUrl =  '/api/products';

        return $http.get(callUrl, {
            params: {
                query: query,
            }
        }).then(function(res) {
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push({
                    id: item.id,
                    name: item.title
                });
            });
            $scope.loadingProducts = false;

            return results;
        });
    };

    $scope.getProductTitle = function(id) {
        let callUrl =  '/api/products/' + id;

        return $http.get(callUrl).then(function(res) {
            let product = res.data.data;
            return product.title;
        });
    };

    $scope.getEntities = function(query) {
        $scope.loadingEntities = true;
        var callUrl = '';
        if ($scope.discount.applies_to_resource === 'product') {
            callUrl = '/api/products';
        } else if ($scope.discount.applies_to_resource === 'vendor') {
            callUrl = '/api/vendors';
        } else if ($scope.discount.applies_to_resource === 'category') {
            callUrl = '/api/categories';
        } else if ($scope.discount.applies_to_resource === 'customer') {
            callUrl = '/api/customers';
        } else {
            callUrl = 'api/groups';
        }

        return $http.get(callUrl, {
            params: {
                query: query,
                resource: 'customer',
                shop_id: $scope.discount.shop_id
            }
        }).then(function(res) {
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push({
                    id: item.id,
                    name: (item.name === undefined) ? ((item.title === undefined) ? item.first_name + ' ' + item.last_name + ' (ID: ' + item.id + ')' : item.title) : item.name
                });
            });
            $scope.loadingEntities = false;

            return results;
        });
    };

    $scope.setEntity = function(item) {
        $scope.discount.applies_to_id = item.id;
    };

    $scope.setProduct = function(item) {
        $scope.discount.product_id = item.id;
        $scope.discount.product_name = item.title;
    };

    $scope.loadingShops = false;
    $scope.getShops = function(val) {
        $scope.loadingShops = true;
        return $http.get('/api/shops', {
            params: {
                query: val
            }
        }).then(function(res) {
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });
            $scope.loadingShops = false;

            return results;
        });
    };

    $scope.loadingPoints = false;
    $scope.getPoints = function(query) {
        $scope.loadingPoints = true;
        var callUrl = '/api/currencies';
        return $http.get(callUrl, {
            params: {
                query: query,
                type: 'points'
            }
        }).then(function onSuccess(res) {
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push({
                    id: item.id,
                    title: item.title + ' (ID: ' + item.id + ')'
                });
            });
            $scope.loadingPoints = false;

            return results;
        });
    };

    $scope.setShop = function(item) {
        $scope.discount.shop_id = item.id;
        $scope.discount.shop_name = item.title;
    };

    $scope.setPoint = function(item) {
        $scope.discount.point_id = item.id;
        $scope.discount.point_name = item.title;
    };

    $scope.$watch('discount.type', function() {
        if ($scope.discount.type === '') {
            $scope.discount.country = '';
            $scope.discount.country_name = '';
            $scope.resourceOptions = [
                {value: 'all', name: $filter('translate')('ALL_ORDERS')},
                {value: 'product', name: $filter('translate')('PRODUCT')},
                {value: 'group', name: $filter('translate')('GROUP')},
                {value: 'vendor', name: $filter('translate')('VENDOR')},
                {value: 'category', name: $filter('translate')('CATEGORY')},
                {value: 'customer', name: $filter('translate')('CUSTOMER')},
                {value: 'all_customers', name: $filter('translate')('ALL_CUSTOMERS')}
            ];
        } else if ($scope.discount.type === 'free_shipping') {
            if ($scope.discount.applies_to_resource === 'product') {
                $scope.discount.applies_to_resource = $scope.resourceOptions[0].value;
                $scope.discount.applies_to_id = 0;
            }
            $scope.resourceOptions = [
                {value: 'all', name: $filter('translate')('ALL_ORDERS')},
                {value: 'group', name: $filter('translate')('GROUP')},
                {value: 'vendor', name: $filter('translate')('VENDOR')},
                {value: 'category', name: $filter('translate')('CATEGORY')},
                {value: 'customer', name: $filter('translate')('CUSTOMER')}
            ];
        } else if ($scope.discount.type === 'gift_card') {
            if ($scope.discount.applies_to_resource !== 'customer') {
                $scope.discount.applies_to_resource = 'customer';
                $scope.discount.applies_to_id = 0;
            }
            $scope.resourceOptions = [
                {value: 'customer', name: $filter('translate')('CUSTOMER')}
            ];
        } else if ($scope.discount.type === 'points') {
            if ($scope.discount.applies_to_resource === 'all_customers') {
                $scope.discount.applies_to_id = 0;
            } else if ($scope.discount.applies_to_resource !== 'customer') {
                $scope.discount.applies_to_resource = 'customer';
                $scope.discount.applies_to_id = 0;
            }
            $scope.resourceOptions = [
                {value: 'customer', name: $filter('translate')('CUSTOMER')},
                {value: 'all_customers', name: $filter('translate')('ALL_CUSTOMERS')}
            ];
            $scope.getPoints();
        } else {
            $scope.discount.country = '';
            $scope.discount.country_name = '';
            $scope.resourceOptions = [
                {value: 'all', name: $filter('translate')('ALL_ORDERS')},
                {value: 'product', name: $filter('translate')('PRODUCT')},
                {value: 'group', name: $filter('translate')('GROUP')},
                {value: 'vendor', name: $filter('translate')('VENDOR')},
                {value: 'category', name: $filter('translate')('CATEGORY')},
                {value: 'customer', name: $filter('translate')('CUSTOMER')}
            ];
        }
    }, true);

    $scope.loadMoreDiscounts = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.loadMore = function() {
        $scope.refreshingDiscounts = true;
        $http.get('/api/discounts', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.discounts.length) {
                $scope.discounts = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingDiscounts = false;
        });
    };

}]);

ctrl.controller('discountImportCtrl', ["$scope", "$http", "$location", "$filter", "Upload", "$timeout", function($scope, $http, $location, $filter, Upload, $timeout) {
    $scope.importData = [];
    $scope.data = null;
    $scope.discountImport = null;
    $scope.importError = false;
    var queryTimeout;

    $scope.$on('$stateChangeStart', function() {
        if (queryTimeout) $timeout.cancel(queryTimeout);
    });

    $scope.$on('$destroy', function() {
        if (queryTimeout) $timeout.cancel(queryTimeout);
    });

    $scope.onFileSelect = function($files) {
        if (queryTimeout) $timeout.cancel(queryTimeout);
        $scope.loading = true;
        $scope.success = false;
        $scope.importError = false;
        angular.forEach($files, function(file) {
            var fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = function() {
                $scope.importData =  CSVToArray(fileReader.result);
                $http.post('/api/discounts/import', {
                    csv: $scope.importData
                }).then(function onSuccess(response) {
                    $scope.loading = false;
                    $scope.success = true;
                    $scope.discountImport = response.data.data;
                    if (queryTimeout) $timeout.cancel(queryTimeout);
                    if ($scope.discountImport) {
                        $scope.checkStatus($scope.discountImport.job_id);
                    }
                }, function onError(response) {
                    $scope.loading = false;
                    $scope.success = false;
                    $scope.data = response.data;
                    $scope.importError = true;
                });
            };
        });
    };

    function CSVToArray(strData, strDelimiter) {
        strDelimiter = (strDelimiter || ";");
        var objPattern = new RegExp(("(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" + "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi");
        var arrData = [[]];
        var arrMatches = null;
        if (arrMatches === null) {} // Lazy fix for variable definition validation
        while (arrMatches = objPattern.exec(strData)) {
            var strMatchedDelimiter = arrMatches[1];
            if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
                arrData.push([]);
            }
            var strMatchedValue;
            if (!arrMatches.index && arrMatches[0].charAt(0) === strDelimiter) {
                arrData[arrData.length - 1].push('');
            }
            if (typeof arrMatches[2] !== 'undefined') {
                strMatchedValue = arrMatches[2].replace(new RegExp( "\"\"", "g" ), "\"");
            } else {
                strMatchedValue = arrMatches[3];
            }
            arrData[arrData.length - 1].push(strMatchedValue);
        }

        return arrData;
    }

    $scope.checkStatus = function(job_id) {
        $http.get('/api/import-discounts/'+job_id).then(function onSuccess(response) {
            $scope.discountImport = response.data.data;
            if ($scope.discountImport.job_status !== 'completed' && $scope.discountImport.job_status !== 'cancelled') {
                queryTimeout = $timeout(function() {
                    $scope.checkStatus(job_id);
                }, 2000);
            }
        }, function onError(response) {
            console.log(response.data);
        });
    };

    $scope.jobs = [];
    $scope.loadingJobList = true;
    $scope.showJobList = function() {
        $scope.loadingJobList = true;
        $http.get('/api/import-discounts').then(function onSuccess(response) {
            $scope.jobs = response.data.data;
            $scope.loadingJobList = false;
        }, function onError(response) {
            $scope.loadingJobList = false;
            console.log(response.data);
        });
    };

    $scope.refreshJobs = function() {
        $scope.showJobList();
    };

}]);

ctrl.controller('navVariantCtrl', ["$scope", "$http", "$timeout", "$location", "$filter", function($scope, $http, $timeout, $location, $filter) {
    $scope.loading = true;
    $scope.refreshingNavVariants = true;
    $scope.allResults = false;
    $scope.navVariants = [];
    $scope.data = null;

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = ($scope.searchParams.take % 15 !== 0) ? 15 : $scope.searchParams.take;

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function() {
        $scope.loading = true;
        $scope.refreshingNavVariants = true;
        $http.get('/api/nav-variants', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.navVariants = response.data.data;
            if (response.data.data.length !== $scope.navVariants.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingNavVariants = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.selectedAll = false;
    $scope.showActions = false;
    $scope.checkAll = function () {
        $scope.showActions = false;
        $scope.checkedCount = 0;
        angular.forEach($scope.navVariants, function (item) {
            item.selected = $scope.selectedAll;
            if (item.selected === true) {
                $scope.showActions = true;
                $scope.checkedCount += 1;
            }
        });
    };

    $scope.checkOne = function () {
        $scope.showActions = false;
        $scope.checkedCount = 0;
        angular.forEach($scope.navVariants, function (item) {
            if (item.selected === true) {
                $scope.showActions = true;
                $scope.checkedCount += 1;
            }
        });
    };

    $scope.loadMore = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.refreshingNavVariants = true;
        $http.get('/api/nav-variants', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.navVariants.length) {
                $scope.navVariants = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingNavVariants = false;
        });
    };

    $scope.focusAddProduct = false;
    $scope.addProduct = function() {
        $scope.product = {title: '', handle: '', number: ''};
        $scope.focusAddProduct = true;
    };

    $scope.updateHandle = function(title) {
        $scope.product.handle = getSlug(title);
    };

    $scope.saveProduct = function() {
        $scope.data = null;
        var data = $scope.product;

        var checked = [];
        angular.forEach($scope.navVariants, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        data.variant_ids = checked;

        $http.post('/api/nav-variants', data).then(function onSuccess(response) {
            $('#addProduct').modal('hide');
            $scope.focusAddProduct = false;
            setTimeout(function() {
                $location.path('/products/' + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

}]);

ctrl.controller('offersCtrl', ["$scope", "$routeParams", "$http", "$cookieStore", "$location", "$timeout", "$filter", "$rootScope", function ($scope, $routeParams, $http, $cookieStore, $location, $timeout, $filter, $rootScope) {
    $scope.loadingProducts = false;
    $scope.loadingShops = true;
    $scope.products = [];
    //the add-product modal is used to create a product idea
    $scope.selectedProductType = 'product_idea';
    $scope.allResults = false;
    $scope.imageUploaded = false;
    $scope.refreshingProducts = false;
    $scope.productIdeaOfferTypes = [
        {value: 4, name: $filter('translate')('PROMOTION') + " - " + $filter('translate')('ENABLED'), selected: 'true'},
        {value: 10, name: $filter('translate')('CHRISTMAS') + " - " + $filter('translate')('ENABLED'), selected: ''},
    ];
    $scope.colors =[
        {color: $filter('translate')('RED')},
        {color: $filter('translate')('BLUE')},
        {color: $filter('translate')('YELLOW')},
        {color: $filter('translate')('GREEN')},
        {color: $filter('translate')('BROWN')},
        {color: $filter('translate')('GREY')},
        {color: $filter('translate')('BLACK')},
        {color: $filter('translate')('WHITE')},
        {color: $filter('translate')('PINK')},
        {color: $filter('translate')('TURQUOISE')},
        {color: $filter('translate')('NATURE')},
        {color: $filter('translate')('PURPLE')},
        {color: $filter('translate')('ORANGE')},
        {color: $filter('translate')('SILVER')},
        {color: $filter('translate')('GOLD')},
        {color: $filter('translate')('BRONZE')},
        {color: $filter('translate')('STEEL')},
        {color: $filter('translate')('TRANSPARENT')},
    ];
    $scope.shop = {
        id: '',
        title: '',
        status: '',
        price_group: '',
        employee_count: ''
    };

    $scope.availableTypes = [
        {value: 'gift', title: $filter('translate')('GIFT')},
        {value: 'regular', title: $filter('translate')('PROMOTION')},
        {value: 'easter', title: $filter('translate')('EASTER')},
        {value: 'christmas', title: $filter('translate')('CHRISTMAS')},
        {value: 'occasion', title: $filter('translate')('OCCASION')},
    ];

    $scope.orderByList = [
        {value: {order_by:'products.id', asc: false}, title: $filter('translate')('NEWEST')},
        {value: {order_by:'products.id', asc: true}, title: $filter('translate')('OLDEST')},
        {value: {order_by:'products.title', asc: true}, title: $filter('translate')('TITLE_A_TO_Z')},
        {value: {order_by:'products.title', asc: false}, title: $filter('translate')('TITLE_Z_TO_A')},
        {value: {order_by:'variants.price', asc: true}, title: $filter('translate')('PRICE_LOW_TO_HIGH')},
        {value: {order_by:'variants.price', asc: false}, title: $filter('translate')('PRICE_HIGH_TO_LOW')},
        {value: {order_by:'variants.price_updated_at', asc: false}, title: $filter('translate')('LATEST_PRICE_UPDATE')},
        {value: {order_by:'variants.price_updated_at', asc: true}, title: $filter('translate')('OLDEST_PRICE_UPDATE')}
    ];

    var now = new Date();
    $scope.filterByCreated = [
        {value: null, title: 'ALL'},
        {value: $filter('date')(now.setDate(now.getDate() - 1), 'yyyy-MM-dd'), title: 'NEW_YESTERDAY'},
        {value: $filter('date')(now.setDate(now.getDate() - 6), 'yyyy-MM-dd'), title: 'NEW_LAST_WEEK'},
        {value: $filter('date')(now.setDate(now.getDate() - 23), 'yyyy-MM-dd'), title: 'NEW_LAST_MONTH'}
    ];

    $scope.rangeShop = {
        minPrice: (typeof $cookieStore.get('minPriceShop') === 'undefined') ? 0 : $cookieStore.get('minPriceShop'),
        maxPrice: (typeof $cookieStore.get('maxPriceShop') === 'undefined') ? 5000 : $cookieStore.get('maxPriceShop'),
        keyword: (typeof $cookieStore.get('keywordShop') === 'undefined') ? null : $cookieStore.get('keywordShop')
    };

    $scope.filterRangeShop = function(shop) {
        if($scope.rangeShop.keyword) {
            return shop.price_group >= $scope.rangeShop.minPrice && shop.price_group <= $scope.rangeShop.maxPrice && shop.title.toLowerCase().indexOf($scope.rangeShop.keyword.toLowerCase()) > -1;
        } else {
            return shop.price_group >= $scope.rangeShop.minPrice && shop.price_group <= $scope.rangeShop.maxPrice;
        }
    };

    $scope.rangeProd = {
        minPrice: (typeof $cookieStore.get('minPriceProd') === 'undefined') ? 0 : $cookieStore.get('minPriceProd'),
        maxPrice: (typeof $cookieStore.get('maxPriceProd') === 'undefined') ? 5000 : $cookieStore.get('maxPriceProd'),
        keyword: (typeof $cookieStore.get('keywordProd') === 'undefined') ? null : $cookieStore.get('keywordProd'),
        order: $scope.orderByList[0].value,
        created_at_max: null
    };

    $scope.shopParams = {
        include: 'users',
        includeTypes: 'gift',
        type: 'gift',
        status: '1,2,3',
        take: 100,
        query: $scope.rangeShop.keyword
    };

    $scope.setIncludeOfferTypes = function() {
        $scope.shopParams.offer_types = $scope.getSelectedOfferTypesValuesComma().replace(/[\s]+/g, '');
    };

    $scope.getSelectedOfferTypes = function() {
        return $filter('filter')($scope.availableTypes, {checked: true});
    };

    $scope.getSelectedOfferTypesComma = function() {
        return $scope.getSelectedOfferTypes().map(function(item) {
            return item.title;
        }).join(', ');
    };

    $scope.getSelectedOfferTypesValuesComma = function() {
        return $scope.getSelectedOfferTypes().map(function(item) {
            return item.value;
        }).join(', ');
    };

    $scope.resetOfferTypeSelection = function() {
        angular.forEach($scope.availableTypes, function(type) {
            type.checked = undefined;
        });
    };

    $scope.locationUpdate = function() {
        // Regular === Promotion
        var $types = ['gift', 'regular', 'easter', 'christmas', 'occasion'];
        var $type = $location.search().type;
        $scope.shopParams.type = $types.indexOf($type) === -1 ? 'gift' : $type;
        $scope.resetOfferTypeSelection();
        var objIndex = $scope.availableTypes.findIndex(function (obj) {
            return obj.value === $type;
        });
        $scope.availableTypes[objIndex].checked = true;
        $scope.setIncludeOfferTypes();
        $location.search('type', $scope.shopParams.type);
    };

    $scope.locationUpdate();

    $scope.$on('$routeUpdate', function(){
        $scope.locationUpdate();
    });

    $scope.filterRangeProd = function(prod) {
        if($scope.rangeProd.keyword) {
            return prod.price >= $scope.rangeProd.minPrice && prod.price <= $scope.rangeProd.maxPrice && prod.title.toLowerCase().indexOf($scope.rangeProd.keyword.toLowerCase()) > -1;
        } else {
            return prod.price >= $scope.rangeProd.minPrice && prod.price <= $scope.rangeProd.maxPrice;
        }
    };

    $scope.$watch('availableTypes', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.setIncludeOfferTypes();
            $scope.changeProducts();
        }
    }, true);

    $scope.$watch('rangeProd.order', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.changeProducts();
        }
    }, true);

    $scope.$watch('rangeProd.created_at_max', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.changeProducts();
        }
    }, true);

    var tempQueryStringProd = '', queryTimeout;

    $scope.$watch('rangeProd.keyword', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put('keywordProd', newValue);
            if (queryTimeout) $timeout.cancel(queryTimeout);
            tempQueryStringProd = newValue;
            queryTimeout = $timeout(function () {
                $scope.rangeProd.keyword = tempQueryStringProd;
                $scope.changeProducts();
            }, 1000);
        }
    }, true);

    $scope.$watch('rangeProd.minPrice', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            tempQueryStringProd = newValue;
            queryTimeout = $timeout(function () {
                $scope.rangeProd.minPrice = tempQueryStringProd;
                $scope.changeProducts();
            }, 1000);
        }
    }, true);

    $scope.$watch('rangeProd.maxPrice', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            tempQueryStringProd = newValue;
            queryTimeout = $timeout(function () {
                $scope.rangeProd.maxPrice = tempQueryStringProd;
                $scope.changeProducts();
            }, 1000);
        }
    }, true);

    $scope.take = 15;
    $scope.take = (typeof $cookieStore.get('takeOffersProducts') === 'undefined') ? $scope.take : $cookieStore.get('takeOffersProducts');
    $scope.searchParams = $location.search();
    $scope.searchParams.take = ($scope.searchParams.take > 200 || $scope.searchParams.take < $scope.take) ? $scope.take : $scope.searchParams.take;

    $scope.loadMoreProducts = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.changeProducts();
    };

    $scope.changeProducts = function() {
        $scope.refreshingProducts = true;
        $http.get('/api/products', {
            params: {
                include: 'variants,tags',
                take: $scope.take,
                is_offer: true,
                query: $scope.rangeProd.keyword,
                min_price: $scope.rangeProd.minPrice,
                max_price: $scope.rangeProd.maxPrice,
                show_images: 1,
                order_by: $scope.rangeProd.order.order_by,
                order_by_asc: $scope.rangeProd.order.asc,
                created_at_max: $scope.rangeProd.created_at_max,
                offer_type: $scope.shopParams.type,
                offer_types: $scope.shopParams.offer_types,
            }
        }).then(function onSuccess(response) {
            $scope.products = response.data.data;
            if (response.data.data.length !== $scope.products.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loadingProducts = false;
            $scope.refreshingProducts = false;
            $cookieStore.put('takeOffersProducts', $scope.take);
        });
    };

    $scope.changeProducts();

    var tempQueryStringShop = '', queryTimeoutShop;
    $scope.$watch('rangeShop.keyword', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put('keywordShop', newValue);
            if (queryTimeoutShop) $timeout.cancel(queryTimeoutShop);
            tempQueryStringShop = newValue;
            queryTimeoutShop = $timeout(function () {
                $scope.rangeShop.keyword = tempQueryStringShop;
                $scope.changeShops();
            }, 1000);
        }
    }, true);

    $scope.changeShops = function() {
        $scope.loadingShops = true;
        $scope.shopParams.query = $scope.rangeShop.keyword;
        $http.get('/api/shops', {
            params: $scope.shopParams
        }).then(function onSuccess(response) {
            $scope.shops = response.data.data;
            $scope.loadingShops = false;
        });
    };

    $scope.newOffer = {
        copy_products_id: '',
    };

    $scope.searchOffersParams = {
        include: 'users',
        includeTypes: 'gift,regular,easter,christmas,occasion',
        status: '1,2,3',
        take: 100,
    };

    $scope.offers = [];

    $scope.refreshOffers = function($search) {
        $scope.searchOffersParams.query = $search;

        $http.get('/api/shops', {
            params: $scope.searchOffersParams
        }).then(function onSuccess(response) {
            $scope.offers = response.data.data;
        });
    };

    $scope.changeShops();

    $scope.sortableOptions = {
        axis: 'y',
        cursor: 'move'
    };

    $scope.$watch('rangeShop.minPrice', function (newValue) {
        $cookieStore.put('minPriceShop', newValue);
    }, true);

    $scope.$watch('rangeShop.maxPrice', function (newValue) {
        $cookieStore.put('maxPriceShop', newValue);
    }, true);

    $scope.$watch('rangeProd.minPrice', function (newValue) {
        $cookieStore.put('minPriceProd', newValue);
    }, true);

    $scope.$watch('rangeProd.maxPrice', function (newValue) {
        $cookieStore.put('maxPriceProd', newValue);
    }, true);

    $scope.$watch('shopParams.type', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            $scope.changeShops();
            $scope.changeProducts();
        }
    }, true);

    $scope.promotionShop = {
        company: '',
        status: 1,
        customer_number: '',
        first_name: '',
        last_name: '',
        email: '',
        logo: null,
        domain: null,
    };

    $scope.easterShop = {
        company: '',
        status: 1,
        customer_number: '',
        first_name: '',
        last_name: '',
        email: '',
        logo: null,
        domain: null,
    };

    $scope.christmasShop = {
        company: '',
        status: 1,
        customer_number: '',
        first_name: '',
        last_name: '',
        email: '',
        logo: null,
        domain: null,
    };

    $scope.toggleShowCart = function() {
        $scope.occasionShop.show_cart = !$scope.occasionShop.show_cart;
    };

    $scope.occasionShop = {
        company: '',
        status: 1,
        customer_number: '',
        first_name: '',
        last_name: '',
        email: '',
        logo: null,
        show_cart: false,
        domain: null,
    };

    $scope.addOccasionShop = function() {
        $scope.occasionShop = {
            company: '',
            status: 1,
            customer_number: '',
            first_name: '',
            last_name: '',
            email: '',
            logo: null,
            show_cart: false,
            domain: null,
        };
        $scope.focusAddOccasionShop = true;
    };

    $scope.saveOccasionShop = function() {
        $scope.data = null;
        if ($scope.newOffer.copy_products_id) {
            $scope.occasionShop.copy_products_id = $scope.newOffer.copy_products_id;
        }
        var data = $scope.occasionShop;
        $http.post('/api/shops-occasion', data).then(function onSuccess(response) {
            $('#addOccasionShop').modal('hide');
            setTimeout(function() {
                $location.path('/offers/shop/' + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.addChristmasShop = function() {
        $scope.christmasShop = {
            company: '',
            status: 1,
            customer_number: '',
            first_name: '',
            last_name: '',
            email: '',
            logo: null,
            domain: null,
        };
        $scope.focusAddChristmasShop = true;
    };

    $scope.saveChristmasShop = function() {
        $scope.data = null;
        if ($scope.newOffer.copy_products_id) {
            $scope.christmasShop.copy_products_id = $scope.newOffer.copy_products_id;
        }
        var data = $scope.christmasShop;
        $http.post('/api/shops-christmas', data).then(function onSuccess(response) {
            $('#addChristmasShop').modal('hide');
            setTimeout(function() {
                $location.path('/offers/shop/' + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.addEasterShop = function() {
        $scope.easterShop = {
            company: '',
            status: 1,
            customer_number: '',
            first_name: '',
            last_name: '',
            email: '',
            logo: null,
            domain: null,
        };
        $scope.focusAddEasterShop = true;
    };

    $scope.saveEasterShop = function() {
        $scope.data = null;
        if ($scope.newOffer.copy_products_id) {
            $scope.easterShop.copy_products_id = $scope.newOffer.copy_products_id;
        }
        var data = $scope.easterShop;
        $http.post('/api/shops-easter', data).then(function onSuccess(response) {
            $('#addEasterShop').modal('hide');
            setTimeout(function() {
                $location.path('/offers/shop/' + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.addPromotionShop = function() {
        $scope.promotionShop = {
            company: '',
            status: 1,
            customer_number: '',
            first_name: '',
            last_name: '',
            email: '',
            logo: null,
            domain: null,
        };
        $scope.focusAddPromotionShop = true;
    };

    $scope.savePromotionShop = function() {
        $scope.data = null;
        if ($scope.newOffer.copy_products_id) {
            $scope.promotionShop.copy_products_id = $scope.newOffer.copy_products_id;
        }
        var data = $scope.promotionShop;
        $http.post('/api/shops-promotion', data).then(function onSuccess(response) {
            $('#addPromotionShop').modal('hide');
            setTimeout(function() {
                $location.path('/offers/shop/' + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.offerModuleClass = function(column) {
        if (column === 1) {
            if ($scope.shopParams.type === 'gift') {
                return 'col-md-6';
            } else {
                return 'col-md-8';
            }
        } else if (column === 2) {
            if ($scope.shopParams.type === 'gift') {
                return 'col-md-6';
            } else {
                return 'col-md-4';
            }
        } else {
            return 'col-md-6';
        }
    };

    $scope.onFileSelect1 = function ($files, $type) {
        var fileReader = new FileReader();
        if ($files && $files.length > 0) {
            var file = $files[0];

            fileReader.readAsDataURL(file);
            fileReader.onload = function(event) {
                var fileFormat = {
                    owner_resource: 'design',
                    image_key: 'logo',
                    fileName: file.name,
                    fileSize: file.size,
                    fileType: file.type,
                    value: fileReader.result.split(',')[1]
                };

                switch ($type) {
                    case 'occasion':
                        $scope.$apply($scope.occasionShop.logo = fileFormat);
                        break;
                    case 'christmas':
                        $scope.$apply($scope.christmasShop.logo = fileFormat);
                        break;
                    case 'easter':
                        $scope.$apply($scope.easterShop.logo = fileFormat);
                        break;
                    case 'promotion':
                        $scope.$apply($scope.promotionShop.logo = fileFormat);
                        break;
                }
            }
        }
    };

    //PRODUCT IDEA FUNCTIONALITY
    $scope.addProduct = function() {
        $scope.product = {title: '', handle: '', number: ''};
        $scope.focusAddProduct = true;
        $scope.updateHandle = function(title) {
            $scope.product.handle = getSlug(title);
        }
    };

    $scope.saveProduct = function() {
        var data = $scope.product;
        data.selectedProductType = $scope.selectedProductType;
        $http.post('/api/products', data).then(function onSuccess(response) {
            $("#addProduct").modal('hide');
            $scope.focusAddProduct = false;
            setTimeout(function() {
                $location.path("/offers/product/" + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.onProductImageSelect = function ($files) {
        var fileReader = new FileReader();
        if ($files && $files.length > 0) {
            var file = $files[0];

            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
                $scope.product.image = {
                    fileName : file.name,
                    fileSize : file.size,
                    fileType : file.type,
                    file : e.target.result.match(/,(.*)$/)[1],
                }
                $('#imageFilename').text(file.name);
            }
            $scope.imageUploaded = true;
        }
    };

    $scope.showCreateProductIdeaButton = function () {
        if (!$location.search()) return false;
        //It is only allowed to create product ideas on promotion (regular) and christmas offers
        const allowedShopTypes = ['regular', 'christmas'];
        const currentOfferType = $location.search().type;
        return allowedShopTypes.includes(currentOfferType);
    }

    $scope.onChangeShopName = function(shopType) {
        console.log($scope[shopType]);
        console.log(shopType);
        $scope[shopType].domain = $scope.slugify($scope[shopType].company, '');
    }

    $scope.slugify = function (text, slug) {
        return text.toString().toLowerCase()
            .replace(/\s+/g, slug)
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, slug)
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }
}]);

ctrl.controller('offersProductCtrl', ["$scope", "$routeParams", "$http", "$location", "$timeout", "$window", "$filter", function ($scope, $routeParams, $http, $location, $timeout, $window, $filter) {

    $scope.goBack = function() {
        if ($routeParams.shopId && $routeParams.productId) {
            $location.path('/offers/shop/' + $routeParams.shopId);
        } else {
            $window.history.back();
        }
    };

    $scope.loading = true;
    $scope.shopId = $routeParams.shopId;

    $http.get('/api/products/' + $routeParams.productId +'?include=images,variants,options').then(function onSuccess(response) {
        $scope.product = response.data.data;
        $scope.loading = false;
    });

    $scope.addProduct = function() {
        $http.post('/api/shops/' + $routeParams.shopId + '/products/' + $routeParams.productId).then(function () {
            $scope.loading = true;
            $timeout(function() {
                $location.path("/offers/shop/" + $routeParams.shopId);
            }, 2000);
        }, function onError(response) {
            if (response.status === 405) {
                window.location = '/login';
            }
            if (response.status === 500) {
                alert('Der mangler at blive tilknyttet en kategori til shoppen');
            }
            if (response.status === 422) {
                alert('Produktet er allerede tilknyttet tilbuddet')
            }
        });
    };

    // Tag management
    $scope.tag = '';
    $scope.productTags = [];
    $scope.loadingTags = false;
    $scope.tagAsync = '';

    $scope.getTags = function(val) {
        $scope.loadingTags = true;

        return $http.get('/api/tags', {
            params: {
                query: val,
                order_by: 'name'
            }
        }).then(function(res) {
            $scope.productTags = [];

            angular.forEach(res.data.data, function(item) {
                $scope.productTags.push(item);
            });
            $scope.loadingTags = false;

            console.log($scope.productTags);

            return $scope.productTags;
        });
    };

    $scope.addTag = function() {
        if (String($scope.tag).length > 1) {
            var item = {
                'id': 0,
                'name': $scope.tagAsync
            };
            $http
                .put('/api/products/' + $routeParams.productId + '/tags', item)
                .then(function onSuccess(response) {
                    $scope.flagMessage = response.data.message;
                    $scope.tagAsync = '';
                    $timeout(function () {
                        $scope.flagMessage = null;
                    }, 3000);
                    $scope.getProductTags();
                }, function onError(response) {
                    $scope.data = response.data;
                });
        }
    };

    $scope.tags = [];

    $scope.getProductTags = function() {
        $http.get('/api/products/' + $routeParams.productId + '/tags').then(function(response) {
            $scope.tags = response.data.data;
        });
    };
    $scope.getProductTags();

    $scope.setTag = function(item) {
        $http.put('/api/products/' + $routeParams.productId + '/tags', item).then(function onSuccess(response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            $scope.getProductTags();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.selectTag = function(item) {
        $scope.tag = item;
    };

    $scope.navigateToProductPage = function (idx) {
        $location.search('tag', $scope.tags[idx].name);
        $location.path('/offers/shop/' + $scope.shopId + '/products');
    };

    $scope.variantStepsPriceString = function(idx) {
        var price = '';
        var variant = $scope.product.variants.data[idx];
        if (variant.step_prices) {
            var min = Math.min(variant.step1_price, variant.step2_price, variant.step3_price);
            min = $filter('currency')(min, 'kr');
            var max = Math.max(variant.step1_price, variant.step2_price, variant.step3_price);
            max = $filter('currency')(max, 'kr');
            price = $filter('translate')('PRICE_FROM') + ' ' + min + ' ' + $filter('translate')('PRICE_TO') + ' ' + max;
        }

        return price;
    };

}]);

ctrl.controller('offersShopCtrl', ["$scope", "$routeParams", "$http", "Upload", "$timeout", "$filter", "$cookieStore", "moment", "$window", "activeProfile", "$location", function ($scope, $routeParams, $http, Upload, $timeout, $filter, $cookieStore, moment, $window, activeProfile, $location) {
    $scope.take = 400;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.searchParams.take === 'undefined' ? $scope.take : $scope.searchParams.take;
    $location.search($scope.searchParams);

    $scope.recipients = [];

    $scope.recipientsIndex = $scope.recipients.length + 1;

    $scope.addNewRecipient = function() {
        var newItemNo = ++$scope.recipientsIndex;
        $scope.recipients.push({ id: newItemNo, offer_name: '', offer_email: '' });
    };

    $scope.removeRecipient = function(id) {
        if ($scope.recipients.length <= 1) {
            alert("input cannot be less than 1");
            return;
        }

        $scope.recipients = $scope.recipients.filter(function (item) {
            return item.id !== id;
        });
    };

    $scope.loadingShop = true;
    $scope.loadingProducts = true;
    $scope.logoUploaded = false;
    $scope.flag = false;
    $scope.flagOffer = false;
    $scope.flagOfferWon = false;
    $scope.flagMessage = null;
    $scope.shopId = $routeParams.shopId;
    $scope.shop = {
        id: 0,
        status: 0,
        status_user_id: 0,
        status_user_name: null,
        status_date: null,
        domains: {},
        offer_title: null,
        logo: null,
    };
    $scope.data = null;

    $scope.productImages = null;
    $scope.product_id = 0;
    $scope.shop_id = 0;

    $scope.products = [];
    $scope.offerQuantityTotal = 0;
    $scope.setting = {
        selectAllForGiftWrap: false,
        selectAllForDefault: false,
        selectAllForBuffer: false
    };

    $scope.searchOffersParams = {
        include: 'users',
        includeTypes: 'gift,regular,easter,christmas',
        status: '1,2,3',
        take: 100,
    };
    $scope.offers = [];
    $scope.importProducts = {
        from_offer_id: '',
    };

    $scope.refreshOffers = function($search) {
        $scope.searchOffersParams.query = $search;

        $http.get('/api/shops', {
            params: $scope.searchOffersParams
        }).then(function onSuccess(response) {
            $scope.offers = response.data.data;
        });
    };

    $scope.refreshOffers();

    $scope.translationData = {
        name: $scope.shop.status_user_name,
        date: new Date($scope.shop.status_date)
    };

    $scope.offer = {
        title: '',
        message: '',
        recipient_name: '',
        recipient_email: '',
        sender_name: '',
        token: '',
        download_count :'',
        created_at: '',
        src: '',
        location: '',
        can_restore: false
    };

    $scope.importCategoryDefaults = {
        // Gift offers
        gift: [
            {'id': 1556, 'title': (activeProfile.lang === 'da' ? 'Fokus 400 kr.' : 'Focus 400 kr.')},
            {'id': 1558, 'title': (activeProfile.lang === 'da' ? 'Fokus 560 kr.' : 'Focus 560 kr.')},
            {'id': 1559, 'title': (activeProfile.lang === 'da' ? 'Fokus 640 kr. >' : 'Focus 640 kr. >')},
            {'id': 1593, 'title': (activeProfile.lang === 'da' ? 'Lagervarer' : 'Stock products')},
            {'id': 1560, 'title': (activeProfile.lang === 'da' ? 'Om Borg & Bigum' : 'About Borg & Bigum')},
            {'id': 1710, 'title': (activeProfile.lang === 'da' ? 'Gaveuniverset – 300 kr.' : 'Gift Universe - 300 kr.')},
            {'id': 1725, 'title': (activeProfile.lang === 'da' ? 'Gaveuniverset – 400 kr.' : 'Gift Universe - 400 kr.')},
            {'id': 1973, 'title': (activeProfile.lang === 'da' ? 'Gaveuniverset – 560 kr.' : 'Gift Universe - 560 kr.')},
            {'id': 2190, 'title': (activeProfile.lang === 'da' ? 'Gaveuniverset – 640 kr.' : 'Gift Universe – 640 kr.')}
        ],
        // Promotion offers
        regular: [
            {'id': 2093, 'title': 'Drikkedunke / Flasker'},
            {'id': 2094, 'title': 'Notesbøger'},
            {'id': 2179, 'title': 'Tasker'},
            {'id': 2180, 'title': 'Lanyards'},
            {'id': 2181, 'title': 'Skriveredskaber'},
            {'id': 2182, 'title': 'Paraplyer'},
            {'id': 2183, 'title': 'Krus / Termo'},
            {'id': 2184, 'title': 'Elektronik'},
            {'id': 2185, 'title': 'Spiseligt'},
            {'id': 2186, 'title': 'Caps'},
            {'id': 2187, 'title': 'Tekstil'},
            {'id': 2188, 'title': 'Børn'},
            {'id': 2189, 'title': 'Diverse / Nyheder'}
        ]
    };

    $scope.importCategories = [];
    $scope.viewImportProducts = function() {
        if ($scope.shop.type === 'gift') {
            $scope.importCategories = angular.copy($scope.importCategoryDefaults.gift);
        } else if ($scope.shop.type === 'regular') {
            $scope.importCategories = angular.copy($scope.importCategoryDefaults.regular);
        }
    };

    $scope.offerEmailText = {
        selected: ''
    };
    $scope.offerEmailTexts = [];

    $scope.$watch('offerEmailText.selected', function(newVal, oldVal) {
        if (newVal !== oldVal) {
            var findEmailText = $scope.offerEmailTexts.find(function ($emailText) {
                return $emailText.id === newVal;
            });

            if (findEmailText) {
                $scope.shop.offer_message = findEmailText.text;
            }
        }
    });

    $scope.fallbackToDefaultEmailText = function() {
        $scope.shop.offer_message = '';
        $scope.getOfferTextByType($scope.shop.type);
    };

    $scope.getOfferTextByType = function($type) {
        switch ($type) {
            case 'gift':
                $scope.shop.offer_title = '';
                $scope.shop.offer_message += 'Kære {{customerName}}\n\n';
                $scope.shop.offer_message += 'Tak for et rigtigt godt møde, jeg håber du fik inspiration til årets julegaver.\n\n';
                $scope.shop.offer_message += 'Vi har hermed fornøjelsen at fremsende oplæg på årets julegaver i henhold til aftale.\n\n';
                $scope.shop.offer_message += 'Alle gaverne finder du på nedenstående link og vedhæftede PDF, inklusive beskrivelser, priser og specifikationer.\n\n';
                $scope.shop.offer_message += 'Link: {{linkToOffer}}\n\n';
                $scope.shop.offer_message += 'Ønsker du at se tilbuddet igen, kan du logge ind med følgende:\n\n';
                $scope.shop.offer_message += 'Brugernavn: {{loginUsername}}\n';
                $scope.shop.offer_message += 'Adgangskode: {{loginPassword}}\n\n';
                $scope.shop.offer_message += 'Vi anbefaler, at du åbner linket i Google Chrome.\n\n';
                $scope.shop.offer_message += 'Herudover tilbyder vi adgang for dig og jeres øvrige medarbejdere til vores personalekøbsshop, hvor der kan købes gaver og designprodukter med op til 60% besparelse.\n\n';
                $scope.shop.offer_message += 'Jeg beder venligst om, at oplægget behandles fortroligt.\n\n';
                $scope.shop.offer_message += 'Hvis du har kommentarer, spørgsmål eller ændringer, er du selvfølgelig velkommen til at kontakte mig. Jeg håber, du synes om det, og glæder mig til den videre dialog.\n\n';
                $scope.shop.offer_message += 'Rigtig god fornøjelse.';
                break;
            case 'occasion':
                $scope.shop.offer_title = 'Fortroligt: Borg & Bigum oplæg på Gaver ' + new Date().getFullYear();
                $scope.shop.offer_message += 'Kære {{customerName}}\n\n';
                $scope.shop.offer_message += 'Er årets sommerfest, event eller kickoff aflyst, eller er der bare brug for lidt ekstra forkælelse af de ansatte?.\n\n';
                $scope.shop.offer_message += 'Jeg har samlet et mindre udsnit fra vores eksklusive gavesortiment, som jeg tror vil falde i god jord hos {{shopTitle}}. Du finder udsnittet via vedhæftede pdf eller nedenstående link, der også indeholder beskrivelser, priser og specifikationer.\n\n';

                $scope.shop.offer_message += '<strong>Link til oplæg: {{linkToOffer}}</strong>\n';
                $scope.shop.offer_message += '<strong>Brugernavn: {{loginUsername}}</strong>\n';
                $scope.shop.offer_message += '<strong>Adgangskode: {{loginPassword}}</strong>\n\n';

                $scope.shop.offer_message += "Fra et dynamisk sortiment af gaver med fokus på bæredygtighed, mærkevarer, designere og eksklusive samarbejder tilbyder vi vores kunder spændende og eksklusive gaver med høj ’value for the money’. Sortimentet afspejler den trend, der er gældende inden for bl.a. boligindretning, design, brugskunst, hi-fi og livsstilsprodukter.\n\n";

                $scope.shop.offer_message += 'Vores bæredygtige produkter er mærket med et grønt blad, mens de varer, vi handler helt eksklusivt hos Borg & Bigum har et tydeligt mørkegråt tag – så kan du nemt og hurtigt navigere rundt i sortimentet.\n\n';
                $scope.shop.offer_message += 'Jeg håber, du finder fremsendte interessant og ser frem til at høre nærmere fra dig.\n\n';
                $scope.shop.offer_message += 'Rigtig god fornøjelse.';
                break;
            case 'christmas':
                var currentYear = new Date().getFullYear();
                $scope.shop.offer_title = 'Fortroligt: Borg & Bigum oplæg på Julen ' + currentYear;
                $scope.shop.offer_message += 'Kære {{customerName}}\n\n';
                $scope.shop.offer_message += 'Jeg takker for behageligt julemøde og fremsender som aftalt årets julegaveoplæg for '+ currentYear +'.\n\n';
                $scope.shop.offer_message += 'Du finder alle udvalgte gaver via vedhæftede pdf eller nedenstående link, der også indeholder beskrivelser, priser og specifikationer.\n\n';
                $scope.shop.offer_message += '<strong>Link til oplæg: {{linkToOffer}}</strong>\n';
                $scope.shop.offer_message += '<strong>Brugernavn: {{loginUsername}}</strong>\n';
                $scope.shop.offer_message += '<strong>Adgangskode: {{loginPassword}}</strong>\n\n';
                $scope.shop.offer_message += 'Fra et dynamisk sortiment af gaver med fokus på bæredygtighed, mærkevarer, designere og eksklusive samarbejder tilbyder vi spændende og eksklusive gaver med høj ’value for the money’. Sortimentet afspejler den trend, der er gældende inden for bl.a. boligindretning, design, brugskunst, hi-fi og livsstilsprodukter.\n' +
                    'Vores bæredygtige produkter er mærket med et grønt blad, mens de varer, vi handler helt eksklusivt hos Borg & Bigum har et tydeligt mørkegråt tag – så kan du nemt og hurtigt navigere rundt i sortimentet.\n\n';
                $scope.shop.offer_message += 'Hos Borg & Bigum sætter vi en ære i, at du som kunde får en god oplevelse igennem alle faserne i forbindelse med valg af årets julegave. Lige fra udvælgelsen af gaven, emballage og julekort til pakning og distribution. På vores interne lagre håndterer og distribuerer vi mere end 200.000 julegaver årligt, hvilket kræver en enorm professionel logistik- og lagerstyring.\n\n';
                $scope.shop.offer_message += 'Rigtig god fornøjelse.';
                break;
            case 'easter':
                $scope.shop.offer_title = 'Fortroligt: Borg & Bigum oplæg på Påske ' + new Date().getFullYear();
                $scope.shop.offer_message += 'Kære {{customerName}}\n\n';
                $scope.shop.offer_message += 'Alle tilbudte produkter finder du på nedenstående link og vedhæftede PDF, inklusive beskrivelser, priser og specifikationer.\n\n';
                $scope.shop.offer_message += 'Link: {{linkToOffer}}\n\n';
                $scope.shop.offer_message += 'Ønsker du at se tilbuddet igen, kan du logge ind med følgende:\n\n';
                $scope.shop.offer_message += 'Brugernavn: {{loginUsername}}\n';
                $scope.shop.offer_message += 'Adgangskode: {{loginPassword}}\n\n';
                $scope.shop.offer_message += 'Vi anbefaler, at du åbner linket i Google Chrome.\n\n';
                $scope.shop.offer_message += 'Jeg beder venligst om, at oplægget behandles fortroligt.\n\n';
                $scope.shop.offer_message += 'Hvis du har kommentarer, spørgsmål eller ændringer, er du selvfølgelig velkommen til at kontakte mig. Jeg håber, du synes om det, og glæder mig til den videre dialog.\n\n';
                $scope.shop.offer_message += 'Rigtig god fornøjelse.';
                break;
            case 'regular':
                $scope.shop.offer_title = 'Fortroligt: Borg & Bigum oplæg på promotion';
                $scope.shop.offer_message += 'Kære {{customerName}}\n\n';
                $scope.shop.offer_message += 'Alle tilbudte produkter finder du på nedenstående link og vedhæftede PDF, inklusive beskrivelser, priser og specifikationer.\n\n';
                $scope.shop.offer_message += 'Link: {{linkToOffer}}\n\n';
                $scope.shop.offer_message += 'Ønsker du at se tilbuddet igen, kan du logge ind med følgende:\n\n';
                $scope.shop.offer_message += 'Brugernavn: {{loginUsername}}\n';
                $scope.shop.offer_message += 'Adgangskode: {{loginPassword}}\n\n';
                $scope.shop.offer_message += 'Vi anbefaler, at du åbner linket i Google Chrome.\n\n';
                $scope.shop.offer_message += 'Jeg beder venligst om, at oplægget behandles fortroligt.\n\n';
                $scope.shop.offer_message += 'Hvis du har kommentarer, spørgsmål eller ændringer, er du selvfølgelig velkommen til at kontakte mig. Jeg håber, du synes om det, og glæder mig til den videre dialog.\n\n';
                $scope.shop.offer_message += 'Rigtig god fornøjelse.';
                break;
        }
    };

    $scope.getShop = function() {
        $scope.loadingShop = true;
        $http.get('/api/shops/' + $routeParams.shopId + '?include=domains,offers,offer_email_texts,address').then(function onSuccess(response) {
            $scope.shop = response.data.data;
            $scope.shop.offer_message = '';
            $scope.shop.offer_title = '';

            $scope.recipients.push({
                id: 1,
                offer_name: $scope.shop.offer_name,
                offer_email: $scope.shop.offer_email
            });

            // set offer email texts
            $scope.offerEmailTexts = $scope.shop.offer_email_texts.data;

            $scope.getOfferTextByType($scope.shop.type);

            $scope.shop.import_category = null;
            if ($scope.shop.type === 'gift') {
                $scope.shop.import_category = $scope.importCategoryDefaults.gift[0].id;
            } else if ($scope.shop.type === 'regular') {
                $scope.shop.import_category = $scope.importCategoryDefaults.regular[0].id;
            }

            $scope.translationData = {
                name: $scope.shop.status_user_name,
                date: new Date($scope.shop.status_date).toDateString()
            };
            $scope.loadingShop = false;
        });
    };

    $scope.getShop();

    $scope.loadProducts = function() {
        $scope.loadingProducts = true;
        $http.get('/api/shops/' + $routeParams.shopId + '/products?take=400&show_images=1&include=options,variants').then(function onSuccess(response) {
            $scope.products = response.data.data;
            $scope.calculateOfferQuantityTotal();
            $scope.loadingProducts = false;
        });
    };

    $scope.loadProducts();

    $scope.saveShop = function() {
        console.log('shop save offers');
        $scope.data = null;
        var data = $scope.shop;
        $http.put('/api/shops/' + $scope.shop.id, data).then(function () {
            $scope.shop.status = parseInt($scope.shop.status);
            $scope.flag = true;
            $timeout(function () {
                $scope.flag = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data.data;
        });
    };

    $scope.updateLogo = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shopId,
                        owner_resource: 'design',
                        image_key: 'logo',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.logoUploaded = true;
                    }
                });
            }
        });
    };

    $scope.removeProduct = function(idx) {
        var productId = $scope.products[idx].id;
        $http.delete('/api/shops/' + $routeParams.shopId + '/products/' + productId).then(function () {
            $scope.products.splice(idx, 1);
        }, function onError(response) {
            if (response.status === 405) {
                window.location = '/login';
            }
        });
    };

    $scope.withOfferPdf = true;

    $scope.toggleWithOfferPdf = function() {
        $scope.withOfferPdf = !$scope.withOfferPdf;
    };

    $scope.sendOffer = function() {
        var domain_name = '';
        if ($scope.shop.domains.data[0] !== undefined && $scope.shop.domains.data[0].domain_name !== undefined) {
            angular.forEach($scope.shop.domains.data, function(domain) {
                if (domain.primary === true) {
                    domain_name = domain.domain_name;
                }
            });
            if (domain_name === '') {
                domain_name = $scope.shop.domains.data[0].domain_name
            }
        }

        var offer = {
            shop_id: $scope.shop.id,
            offer_title: $scope.shop.offer_title,
            message: $scope.shop.offer_message,
            recipients: $scope.recipients,
            shop_link: domain_name,
            send_with_pdf: $scope.withOfferPdf
        };
        $http.post('/api/offers/send-recipients', offer).then(function () {
            $scope.loadProducts();
            $scope.flagOffer = true;
            $timeout(function () {
                $scope.flagOffer = false;
            }, 3000);
            $("#sendOffer").modal('hide');
            $(".modal-backdrop").hide(500).remove();

            $scope.offerEmailText.selected = '';
            $scope.recipients = [];
            $scope.errors = [];
            $scope.withOfferPdf = true;

            $scope.getShop();
            $scope.loadProducts();
        }, function onError(response) {
            if (response.status === 405) {
                window.location = '/login';
            }
            if (response.status === 422) {
                $scope.errors = response.data.error.validation_errors;
            }
        });
    };

    $scope.sortableOptions = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle',
        cancel: '',
        'ui-floating': false,
        stop: function() {
            var order = [];
            angular.forEach($scope.products, function(item) {
                order.push(item.id);
            });
            $http.post('/api/shops/' + $routeParams.shopId + '/products/order', {productOrder: order}).then(function onSuccess(response) {
                $scope.flagMessage = response.data.message;
                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);
            }, function onError() {});
        }
    };

    $scope.productPrimaryTranslation = null;
    $scope.editProduct = function (product) {
        $scope.product = product;
        $http.get('/api/shops/' + $routeParams.shopId + '/products/' + $scope.product.id + '/translations').then(function onSuccess(response) {
            $scope.productPrimaryTranslation = response.data.data;
        });
        $scope.editImages($routeParams.shopId);
    };

    $scope.refreshProducts = function() {
        $scope.loadingProducts = true;
        $http.get('/api/shops/' + $routeParams.shopId + '/products?take=400&show_images=1&include=options,variants').then(function onSuccess(response) {
            $scope.products = response.data.data;
            $scope.calculateOfferQuantityTotal();
            $scope.loadingProducts = false;
        });
    };

    $scope.importProductsFromOffer = function () {
        $http.get('/api/offers/import-products/' + $routeParams.shopId + '?from_offer_id=' + $scope.importProducts.from_offer_id)
            .then(function () {
                $("#importProducts").modal('hide');
                $(".modal-backdrop").hide(500).remove();
                $timeout(function () {
                    $scope.importProducts.from_offer_id = '';
                    $scope.loadingProducts = true;
                    $http.get('/api/shops/' + $routeParams.shopId + '/products?take=400&show_images=1&include=options,variants')
                        .then(function onSuccess(response) {
                            $scope.products = response.data.data;
                            $scope.calculateOfferQuantityTotal();
                            $scope.loadingProducts = false;
                    });
                }, 3000);
        });
    };

    $scope.importProducts = function () {
        $http.get('/api/offers/load_products/' + $routeParams.shopId + '?load_id=' + $scope.shop.import_category).then(function () {
            $("#importProducts").modal('hide');
            $(".modal-backdrop").hide(500).remove();
            $timeout(function () {
                $scope.loadingProducts = true;
                $http.get('/api/shops/' + $routeParams.shopId + '/products?take=400&show_images=1&include=options,variants').then(function onSuccess(response) {
                    $scope.products = response.data.data;
                    $scope.calculateOfferQuantityTotal();
                    $scope.loadingProducts = false;
                });
            }, 3000);
        });
    };

    $scope.isDisabled = false;

    $scope.updatePrices = function(product) {
        $scope.isDisabled = true;
        $http.put('/api/products/'+ product.id +'/shop-prices', {
            shop_id: $routeParams.shopId,
            product_id: product.id,
            price: product.price,
            shop_price: product.shop_price,
            shop_compare_at_price: product.shop_compare_at_price,
            shop_title: product.shop_title,
            shop_description: product.shop_description,
            shop_step1_price: product.shop_step1_price,
            shop_step2_price: product.shop_step2_price,
            shop_step3_price: product.shop_step3_price
        }).then(function () {
            $scope.isDisabled = false;
            $("#editProduct").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        }, function onError(response) {
            if (response.status === 405) {
                window.location = '/login';
            }
        });
    };

    $scope.selectedAll = false;
    $scope.showMassDelete = false;

    $scope.checkAll = function() {
        $scope.showMassDelete = false;
        angular.forEach($scope.products, function (item) {
            item.selected = $scope.selectedAll;
            if (item.selected === true) $scope.showMassDelete = true;
        });
    };

    $scope.checkOne = function() {
        $scope.showMassDelete = false;
        angular.forEach($scope.products, function (item) {
            if (item.selected === true) $scope.showMassDelete = true;
        });
    };

    $scope.removeSelectedProducts = function() {
        $scope.data = null;

        var checked = [];
        var indexed = [];
        angular.forEach($scope.products, function (item, idx) {
            if (item.selected === true) {
                checked.push(item.id);
                indexed.push(idx);
            }
        });
        $scope.remove = { ids: checked };
        $http.post('/api/shops/' + $routeParams.shopId + '/products/remove', $scope.remove).then(function onSuccess(response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            $scope.showMassDelete = false;
            angular.forEach(indexed, function (idx) {
                $scope.products.splice(idx, 1);
            });
        }, function onError() {
            $scope.data = response.data.data;
        });
    };

    $scope.updateSalesPrice = function(product) {
        $scope.isDisabled = true;
        $http.put('/api/products/'+ product.id +'/shop-prices', {
            shop_id: $routeParams.shopId,
            product_id: product.id,
            shop_price: product.shop_price,
            customer_price: product.customer_price
        }).then(function () {
            $scope.isDisabled = false;
            $("#editProduct").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        }, function onError(response) {
            if (response.status === 405) {
                window.location = '/login';
            }
        });
    };

    $scope.defaultOfferDate = new Date(new Date().getFullYear(), 11, 31);
    if ($scope.defaultOfferDate.getDay() === 0) {
        $scope.defaultOfferDate = $scope.defaultOfferDate.setDate($scope.defaultOfferDate.getDate() - 2);
    } else if ($scope.defaultOfferDate.getDay() === 6) {
        $scope.defaultOfferDate = $scope.defaultOfferDate.setDate($scope.defaultOfferDate.getDate() - 1);
    }

    $scope.offer = {
        // Old fields
        gift: '0',
        gift_products: null,
        default_products: null,
        buffer_products: null,
        delivery: '1',
        gift_selection_shop: '1',
        christmas_cards: '2',
        expected_delivery_date_object: $scope.defaultOfferDate,
        expected_delivery_date: null,
        international_delivery: '2',
        floor_delivery: '2',
        time_delivery: '2',
        label_per_gift: '2',
        multiple_invoicing_addresses: '2',
        notes: '',
        // New fields #1
        request_shop_opening_period: moment().format('YYYY-MM-DD'),
        other_languages: {'da': true, 'uk': false, 'se': false, 'no': false, 'de': false},
        which_gift_to_not_selected: '1',
        buffer_presents_needed: '2',
        handout_via_qr: '2',
        // New fields #2
        christmas_packaging: '1',
        add_customers_on_the_shop: '1',
        add_voucher_of_100: '1'
    };

    $scope.$watch('offer', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put('offerWon', $scope.offer);
        }
    }, true);

    $scope.changeGiftWrap = function() {
        var $products = [];
        angular.forEach($scope.products, function (item) {
            if (item.selectedGiftWrap === true) {
                $products.push(item.id);
            }
        });
        $cookieStore.put('selectedGiftWrap', $products);
    };

    $scope.checkAllForGiftWrap = function() {
        angular.forEach($scope.products, function (item, index) {
            $scope.products[index].selectedGiftWrap = $scope.setting.selectAllForGiftWrap;
        });
        $scope.changeGiftWrap();
    };

    // 10.1
    $scope.changeDefault = function() {
        var $products = [];
        angular.forEach($scope.products, function (item) {
            if (item.selectedDefault === true) {
                $products.push(item.id);
            }
        });
        $cookieStore.put('selectedDefault', $products);
    };

    $scope.checkAllForDefault = function() {
        angular.forEach($scope.products, function (item, index) {
            $scope.products[index].selectedDefault = $scope.setting.selectAllForDefault;
        });
        $scope.changeDefault();
    };

    // 11.1
    $scope.changeBuffer = function() {
        var $products = [];
        angular.forEach($scope.products, function (item) {
            if (item.selectedBuffer === true) {
                var buffer = item.bufferNumber > 0 ? item.bufferNumber : 0;
                var data = {id: item.id, buffer: buffer};
                $products.push(data);
            }
        });
        $cookieStore.put('selectedBuffer', $products);
    };

    $scope.checkAllForBuffer = function() {
        angular.forEach($scope.products, function (item, index) {
            $scope.products[index].selectedBuffer = $scope.setting.selectAllForBuffer;
        });
        $scope.changeBuffer();
    };

    $scope.startsWithNeedle = true;

    $scope.showAsWon = function() {
        $scope.data = null;
        // Check cookie for old unsaved data
        var $cookieOffer = $cookieStore.get('offerWon');

        var $cookieGiftWrapSelected = $cookieStore.get('selectedGiftWrap');
        var $cookieDefaultSelected = $cookieStore.get('selectedDefault');
        var $cookieBufferSelected = $cookieStore.get('selectedBuffer');

        if ($cookieGiftWrapSelected instanceof Array) {
            if ($cookieGiftWrapSelected.length > 0) {
                angular.forEach($scope.products, function (item1, index1) {
                    angular.forEach($cookieGiftWrapSelected, function (item2, index2) {
                        if (item1.id === item2) {
                            $scope.products[index1].selectedGiftWrap = true;
                        }
                    });
                });
            }
        }

        if ($cookieDefaultSelected instanceof Array) {
            if ($cookieDefaultSelected.length > 0) {
                angular.forEach($scope.products, function (item1, index1) {
                    angular.forEach($cookieDefaultSelected, function (item2, index2) {
                        if (item1.id === item2) {
                            $scope.products[index1].selectedDefault = true;
                        }
                    });
                });
            }
        }

        if ($cookieBufferSelected instanceof Array) {
            if ($cookieBufferSelected.length > 0) {
                angular.forEach($scope.products, function (item1, index1) {
                    angular.forEach($cookieBufferSelected, function (item2, index2) {
                        if (item1.id === item2.id) {
                            $scope.products[index1].selectedBuffer = true;
                            $scope.products[index1].bufferNumber = item2.buffer
                        }
                    });
                });
            }
        }

        if ($cookieOffer instanceof Object) {
            $scope.offer = {
                // Old fields
                gift: $cookieOffer.hasOwnProperty('gift') ? $cookieOffer.gift : '1',
                gift_products: $cookieOffer.hasOwnProperty('gift_products') ? $cookieOffer.gift_products : null,
                default_products: $cookieOffer.hasOwnProperty('default_products') ? $cookieOffer.default_products : null,
                buffer_products: $cookieOffer.hasOwnProperty('buffer_products') ? $cookieOffer.buffer_products : null,
                delivery: $cookieOffer.hasOwnProperty('delivery') ? $cookieOffer.delivery : '1',
                gift_selection_shop: $cookieOffer.hasOwnProperty('gift_selection_shop') ? $cookieOffer.gift_selection_shop : '1',
                christmas_cards: $cookieOffer.hasOwnProperty('christmas_cards') ? $cookieOffer.christmas_cards : '2',
                expected_delivery_date_object: $cookieOffer.hasOwnProperty('expected_delivery_date_object') ? new Date($cookieOffer.expected_delivery_date_object) :  $scope.defaultOfferDate,
                expected_delivery_date: $cookieOffer.hasOwnProperty('expected_delivery_date') ? $cookieOffer.expected_delivery_date : null,
                international_delivery: $cookieOffer.hasOwnProperty('international_delivery') ? $cookieOffer.international_delivery : '2',
                floor_delivery: $cookieOffer.hasOwnProperty('floor_delivery') ? $cookieOffer.floor_delivery : '2',
                time_delivery: $cookieOffer.hasOwnProperty('time_delivery') ? $cookieOffer.time_delivery : '2',
                label_per_gift: $cookieOffer.hasOwnProperty('label_per_gift') ? $cookieOffer.label_per_gift : '2',
                multiple_invoicing_addresses: $cookieOffer.hasOwnProperty('multiple_invoicing_addresses') ? $cookieOffer.multiple_invoicing_addresses : '2',
                notes: $cookieOffer.hasOwnProperty('notes') ? $cookieOffer.notes : '',
                // New fields #1
                request_shop_opening_period: $cookieOffer.hasOwnProperty('request_shop_opening_period') ? $cookieOffer.request_shop_opening_period : '',
                other_languages: $cookieOffer.hasOwnProperty('other_languages') ? $cookieOffer.other_languages : {'da': true, 'uk': false, 'se': false, 'no': false, 'de': false},
                which_gift_to_not_selected: $cookieOffer.hasOwnProperty('which_gift_to_not_selected') ? $cookieOffer.which_gift_to_not_selected : '1',
                buffer_presents_needed: $cookieOffer.hasOwnProperty('buffer_presents_needed') ? $cookieOffer.buffer_presents_needed : '2',
                handout_via_qr: $cookieOffer.hasOwnProperty('handout_via_qr') ? $cookieOffer.handout_via_qr : '2',
                // New fields #2
                christmas_packaging: $cookieOffer.hasOwnProperty('christmas_packaging') ? $cookieOffer.christmas_packaging : '1',
                add_customers_on_the_shop: $cookieOffer.hasOwnProperty('add_customers_on_the_shop') ? $cookieOffer.add_customers_on_the_shop : '1',
                add_voucher_of_100: $cookieOffer.hasOwnProperty('add_voucher_of_100') ? $cookieOffer.add_voucher_of_100 : '1'
            };
        } else {
            $scope.offer = {
                gift: '1',
                gift_products: null,
                default_products: null,
                buffer_products: null,
                delivery: '1',
                gift_selection_shop: '1',
                christmas_cards: '2',
                expected_delivery_date_object: $scope.defaultOfferDate,
                expected_delivery_date: null,
                international_delivery: '2',
                floor_delivery: '2',
                time_delivery: '2',
                label_per_gift: '2',
                multiple_invoicing_addresses: '2',
                notes: '',
                // New fields #1

                request_shop_opening_period: moment().format('YYYY-MM-DD'),
                other_languages: {'da': true, 'uk': false, 'se': false, 'no': false, 'de': false},
                which_gift_to_not_selected: '1',
                buffer_presents_needed: '2',
                handout_via_qr: '2',
                // New fields #2
                christmas_packaging: '1',
                add_customers_on_the_shop: '1',
                add_voucher_of_100: '1'
            };
        }

        if ($scope.products.length) {
            $scope.startsWithNeedle = true;
            var needle = 'BBE';
            angular.forEach($scope.products, function(product) {
                if ($scope.startsWithNeedle === true) {
                    if (String(product.variants_sku).indexOf(needle) !== 0) {
                        $scope.startsWithNeedle = true; // false
                    }
                }
            });
        }
    };

    $scope.markAsWon = function() {
        $scope.data = null;

        $scope.offer.gift_products = [];
        $scope.offer.default_products = [];
        $scope.offer.buffer_products = [];

        $scope.offer.expected_delivery_date = moment($scope.offer.expected_delivery_date_object).format('YYYY-MM-DD');
        angular.forEach($scope.products, function(product) {
            if (typeof product.selectedGiftWrap !== 'undefined' && product.selectedGiftWrap === true) {
                $scope.offer.gift_products.push(product.id);
            }
            if (typeof product.selectedDefault !== 'undefined' && product.selectedDefault === true) {
                $scope.offer.default_products.push(product.id);
            }
            if (typeof product.selectedBuffer !== 'undefined' && product.selectedBuffer === true) {
                $scope.offer.buffer_products.push({id: product.id, buffer: product.bufferNumber});
            }
        });

        $http.post('/api/offers/shop/' + $routeParams.shopId + '/set-offer-as-won', $scope.offer).then(function (response) {
            $scope.getShop();

            // Remove saved data from Cookie
            $cookieStore.remove('offerWon');

            $cookieStore.remove('selectedGiftWrap');
            $cookieStore.remove('selectedDefault');
            $cookieStore.remove('selectedBuffer');

            $('#setOfferAsWon').modal('hide');
            $('.modal-backdrop').hide(500).remove();
            $scope.flagOfferWon = true;
            $timeout(function () {
                $scope.flagOfferWon = false;
            }, 3000);

            var data = response.data;

            $scope.downloadPDF(data.file_data, data.file_name);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.updateOfferQuantity = function(product) {
        $scope.isDisabled = true;
        $http.put('/api/products/'+ product.id +'/shop-offer-quantity', {
            shop_id: $routeParams.shopId,
            offer_quantity: product.offer_quantity
        }).then(function () {
            $scope.isDisabled = false;
            $scope.refreshProducts();
        }, function onError(response) {
            $scope.data = response.data.data;
        });
    };

    $scope.calculateOfferQuantityTotal = function() {
        $scope.offerQuantityTotal = 0;
        if ($scope.products.length) {
            angular.forEach($scope.products, function(product) {
                $scope.offerQuantityTotal += product.offer_quantity;
            });
        }
    };

    // Disable weekends
    function disabledWeekends(data) {
        var date = data.date;
        var mode = data.mode;
        return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    $scope.dateOptions = {
        dateDisabled: disabledWeekends,
        formatYear: 'yyyy',
        formatMonth: 'MM',
        formatDay: 'dd',
        maxDate: new Date(new Date().getFullYear() + 1, 11, 31),
        minDate: new Date(),
        startingDay: 1
    };

    $scope.popup = {
        opened: false
    };

    $scope.openDatePicker = function() {
        $scope.popup.opened = true;
    };

    $scope.loadingVariants = true;
    $scope.product = null;
    $scope.variants = null;

    $scope.editQuantities = function(idx) {
        $scope.product = $scope.products[idx];
        $scope.loadingVariants = true;
        $http.get('/api/shops/' + $scope.shop.id + '/products/' + $scope.product.id + '/variants').then(function onSuccess(response) {
            $scope.variants = response.data.data;
            $scope.loadingVariants = false;
        });
    };

    $scope.offerDownload = {
        template: 'default',
        products_to_download: 'all'
    };

    $scope.updateQuantities = function() {
        $scope.data = null;
        $http.post('/api/shops/' + $scope.shop.id + '/products/' + $scope.product.id + '/variants', {
            variants: $scope.variants
        }).then(function onSuccess(response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            $('#editQuantities').modal('hide');
            $('.modal-backdrop').hide(500).remove();
            $scope.loadProducts();
        }, function onError(response) {
            $scope.data = response.data.data;
        });
    };

    $scope.downloadOfferPdf = function() {
        var ids = [];

        if ($scope.offerDownload.products_to_download === 'selected') {
            angular.forEach($scope.products, function (item) {
                if (item.selected === true) {
                    ids.push(item.id);
                }
            });
        }

        if (ids.length > 0) {
            ids = ids.join(',');
            $window.location.href = '/api/offers/shop/' + $scope.shopId + '/preview?template=' + $scope.offerDownload.template + '&action=download&ids=' + ids;
        } else {
            $window.location.href = '/api/offers/shop/' + $scope.shopId + '/preview?template=' + $scope.offerDownload.template + '&action=download';
        }
    };

    $scope.editImages = function(shop_id) {
        $scope.productImages = null;
        $scope.product_id = $scope.product.id;
        $scope.shop_id = shop_id;
        $http.get('/api/products/' + $scope.product_id + '/shops/' + $scope.shop_id + '/images').then(function (response) {
            $scope.productImages = response.data.data;
        }, function onError(response) {
            $scope.data = response.data.data;
        });
    };

    $scope.updateImageOptions = function() {
        $http.post('/api/products/' + $scope.product_id + '/shops/' + $scope.shop_id + '/images', {images: $scope.productImages}).then(function (response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data.data;
        });
    };

    $scope.sortableImagesOptions = {
        cursor: 'move',
        handle: '.image-handle',
        stop: function() {
            $scope.updateImageOptions();
        }
    };

    $scope.hideImage = function (idx) {
        $scope.productImages[idx].is_active = false;
        $scope.updateImageOptions();
    };

    $scope.showImage = function (idx) {
        $scope.productImages[idx].is_active = true;
        $scope.updateImageOptions();
    };

    $scope.downloadPDF = function (file_data, file_name) {
        var linkSource = 'data:application/pdf;base64,' + file_data;
        var downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.target = '_blank';
        downloadLink.title = file_name;
        downloadLink.download = file_name;
        downloadLink.click();
    };

    $scope.offerId = null;
    $scope.openConfirmRestoreDialog = function(idx) {
        $scope.offerId = $scope.shop.offers.data[idx].id;
    };

    $scope.confirmRestoreDialog = function() {
        if (!$scope.offerId) {

            return false;
        }
        $http.post('/api/offers/shop/' + $routeParams.shopId + '/restore-offer/' + $scope.offerId).then(function (response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            $('#viewOffers').modal('hide');
            $('#restoreOffer').modal('hide');
            $('.modal-backdrop').hide(500).remove();

            $scope.getShop();
            $scope.loadProducts();
        }, function onError(response) {
            $scope.data = response.data.data;
        });
    }

    $scope.offerSettings = {
        show_promotion_quotes: false,
    };

    $scope.togglePromotionQuote = function() {
        $scope.offerSettings.show_promotion_quotes = !$scope.offerSettings.show_promotion_quotes;
    };

    $scope.getOfferSettings = function() {
        $http.get('/api/offer/settings/' + $routeParams.shopId)
            .then(function onSuccess(response) {
                var settings = response.data;

                $scope.offerSettings.show_promotion_quotes = settings.show_promotion_quotes;
            });
    };

    $scope.getOfferSettings();

    $scope.updateOfferSettings = function() {
        $http.post('/api/offer/settings/' + $routeParams.shopId, $scope.offerSettings)
            .then(function onSuccess(response) {
                $scope.flagMessage = response.data.message;

                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);

                $('#offerSettings').modal('hide');
                $('.modal-backdrop').hide(500).remove();
        });
    };

    $scope.groupSortOrderName = [];
    $scope.groupSortOrder = [];
    $scope.vendorSortOrder = [];
    $scope.priceSortOrder = [];
    $scope.priceSortDir = null;

    $scope.setPriceSortOrder = function(dir) {
        $scope.priceSortDir = dir;
        $scope.priceSortOrder = [];
        var temp = [];

        if (dir === '-1') {
            temp = $filter('orderBy')($scope.allProducts, '-price');
        }

        if (dir === '+1') {
            temp = $filter('orderBy')($scope.allProducts, '+price');
        }

        angular.forEach(temp, function(product) {
            $scope.priceSortOrder.push(product.id);
        });

        console.table($scope.priceSortOrder);
    };

    $scope.productGroupSortableOptions = {
        handle: '> .handle',
        items : '> li',
        axis: 'y',
        'ui-floating': true,
        stop: function(e, ui) {
            $scope.groupSortOrder = [];

            // get all the product ids with the right order
            angular.forEach($scope.productGroups, function(group) {
                angular.forEach(group.products, function(product) {
                    $scope.groupSortOrder.push(product.id);
                });
            });
        }
    };

    $scope.productVendorSortableOptions = {
        handle: '> .handle',
        items : '> li',
        axis: 'y',
        'ui-floating': true,
        stop: function(e, ui) {
            $scope.vendorSortOrder = [];

            // get all the product ids with the right order
            angular.forEach($scope.vendorGroups, function(group) {
                angular.forEach(group.products, function(product) {
                    $scope.vendorSortOrder.push(product.id);
                });
            });
        }
    };

    $scope.productGroups = [];
    $scope.vendorGroups = [];
    $scope.allProducts = [];

    $scope.getProductGroups = function() {
        $http.get('/api/offer/product-groups/' + $routeParams.shopId)
            .then(function onSuccess(response) {
                $scope.productGroups = response.data;
            });
    };

    $scope.getVendorGroups = function() {
        $http.get('/api/offer/product-vendor/' + $routeParams.shopId)
            .then(function onSuccess(response) {
                $scope.vendorGroups = response.data;
            });
    };

    $scope.getAllProducts = function() {
        $http.get('/api/offer/product-all/' + $routeParams.shopId)
            .then(function onSuccess(response) {
                $scope.allProducts = [];

                $scope.allProducts = response.data.map(function(product) {
                    return {
                        id: product.id,
                        price: parseFloat(product.price),
                    };
                });
            });
    };

    $scope.saveGroupOrder = function () {
        $http.post('/api/shops/' + $routeParams.shopId + '/products/order', {productOrder: $scope.groupSortOrder})
            .then(function onSuccess(response) {
                $scope.flagMessage = response.data.message;

                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);

                $scope.loadProducts();

                $('#sortByProductGroup').modal('hide');
                $('.modal-backdrop').hide(500).remove();
            }, function onError() {});
    };

    $scope.saveVendorOrder = function () {
        $http.post('/api/shops/' + $routeParams.shopId + '/products/order', {productOrder: $scope.vendorSortOrder})
            .then(function onSuccess(response) {
                $scope.flagMessage = response.data.message;

                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);

                $scope.loadProducts();

                $('#sortByProductVendor').modal('hide');
                $('.modal-backdrop').hide(500).remove();
            }, function onError() {});
    };

    $scope.savePriceOrder = function () {
        $http.post('/api/shops/' + $routeParams.shopId + '/products/order', {productOrder: $scope.priceSortOrder})
            .then(function onSuccess(response) {
                $scope.flagMessage = response.data.message;
                $scope.priceSortDir = null;
                $scope.priceSortOrder = [];

                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);

                $scope.loadProducts();

                $('#sortByProductPrice').modal('hide');
                $('.modal-backdrop').hide(500).remove();
            }, function onError() {});
    };
}]);

ctrl.controller('offersShopProductCtrl', ["$scope", "$routeParams", "$http", "$cookieStore", "$location", "$timeout", "$filter", "$window", function ($scope, $routeParams, $http, $cookieStore, $location, $timeout, $filter, $window) {

    $scope.goBack = function() {
        $window.history.back();
    };

    var $_cookiePreFix = 'offer_shop_products_' + $routeParams.shopId;

    // Cookie keys
    $scope.cookieTakeKey = $_cookiePreFix + '_take';
    $scope.cookieTagsKey = $_cookiePreFix + '_tags';
    $scope.cookieOrderByKey = $_cookiePreFix + '_order_by';
    $scope.cookieFilterByKey = $_cookiePreFix + '_filter_by';
    $scope.cookieTableViewKey = $_cookiePreFix + '_table_view';
    $scope.cookieOfferTypesKey = $_cookiePreFix + '_offer_types';
    $scope.cookieSearchQueryKey = $_cookiePreFix + '_search_query';
    $scope.cookieSelectedTagsKey = $_cookiePreFix + '_selected_tags';
    $scope.cookieRangeFiltersKey = $_cookiePreFix + '_range_filters';
    $scope.cookieToggleRangeFiltersKey = $_cookiePreFix + '_toggle_range_filters';

    $scope.cookieName = 'offer_shop_products_' + $routeParams.shopId;
    $scope.cookieValue = $cookieStore.get($scope.cookieName);
    if (!$scope.cookieValue) {
        $cookieStore.put($scope.cookieName, '');
        $scope.cookieValue = '';
    }

    $scope.flagMessage = null;

    $scope.filters = [
        { id: 'range_dg', name: $filter('translate')('COVERAGE_RATIO') },
        { id: 'range_cost_price', name: $filter('translate')('COST_PRICE') },
        { id: 'range_price', name: $filter('translate')('PRICE') },
    ];
    $scope.showFilter = false;
    $scope.activeFilters = [];
    $scope.rangeFilter = { id: '', name: '', min: 0, max: 0 };
    $scope.selectedFilter = {};

    var $cookieRangeFilters = $cookieStore.get($scope.cookieRangeFiltersKey);
    if ($cookieRangeFilters) {
        $scope.activeFilters = $cookieRangeFilters;
    }

    var $cookieToggleRangeFiltersKey = $cookieStore.get($scope.cookieToggleRangeFiltersKey);
    if ($cookieToggleRangeFiltersKey) {
        $scope.showFilter = $cookieToggleRangeFiltersKey;
    }

    $scope.addFilter = function() {
        var filter = $scope.rangeFilter;

        // Check if there is a selected filter
        if (Object.keys($scope.selectedFilter).length === 0) {
            $scope.flagMessage = $filter('translate')('REQUIRED_SELECT_RANGE_FILTER');
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            return;
        }

        filter.id = $scope.selectedFilter.filter.id;
        filter.name = $scope.selectedFilter.filter.name;

        if (filter.id === '' || filter.name === '') {
            return;
        }

        // min should always be lower than max
        if (filter.min > filter.max) {
            $scope.flagMessage = $filter('translate')('RANGE_MAX_LOWER_THAN_MIN');
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            return;
        }

        var hasFilterAlready = $scope.activeFilters.some(function(activeFilter) {
            return activeFilter.id === filter.id;
        });

        // only allow one of each range filter at a time
        if (hasFilterAlready) {
            $scope.flagMessage = $filter('translate')('RANGE_FILTER_ALREADY_ACTIVE');
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            return;
        }

        // add filter to active filters
        $scope.activeFilters.unshift(filter);

        $cookieStore.put($scope.cookieRangeFiltersKey, $scope.activeFilters);

        // reset filter after add to active filters
        $scope.rangeFilter = { id: '', name: '', min: 0, max: 0 };
        $scope.selectedFilter = {};
    };

    $scope.removeFilter = function(index) {
        if ($scope.activeFilters.length > 0) {
            $scope.activeFilters.splice(index, 1);
            $cookieStore.put($scope.cookieRangeFiltersKey, $scope.activeFilters);
        }
    };

    $scope.toggleShowFilter = function() {
        $scope.showFilter = !$scope.showFilter;
        $cookieStore.put($scope.cookieToggleRangeFiltersKey, $scope.showFilter);
    };

    $scope.loadingProducts = true;
    $scope.shopId = $routeParams.shopId;
    $scope.products = [];
    $scope.productsMeta = [];
    $scope.product = {
        id: '',
        variants_sku: '',
        price: '',
        title: ''
    };
    $scope.data = null;

    $scope.allResults = false;
    $scope.refreshingProducts = false;

    $scope.listView = 'list';

    var $cookieTableView = $cookieStore.get($scope.cookieTableViewKey);
    if ($cookieTableView) {
        $scope.listView = $cookieTableView;
    }

    $scope.orderByList = [
        {id: 1, value: 'products.id', title: 'Newest'},
        {id: 2, value: 'products.title', title: 'Title'},
        {id: 3, value: 'variants.price', title: 'Price'},
        {id: 4, value: 'products.quality_stamp_approved_at', title: 'Quality Stamp'},
    ];

    $scope.availableTypes = [
        {value: 'gift', title: $filter('translate')('GIFT')},
        {value: 'regular', title: $filter('translate')('PROMOTION')},
        {value: 'easter', title: $filter('translate')('EASTER')},
        {value: 'christmas', title: $filter('translate')('CHRISTMAS')},
        {value: 'occasion', title: $filter('translate')('OCCASION')},
    ];

    var now = new Date();
    $scope.filterByCreated = [
        {value: null, title: 'ALL'},
        {value: $filter('date')(now.setDate(now.getDate() - 1), 'yyyy-MM-dd'), title: 'NEW_YESTERDAY'},
        {value: $filter('date')(now.setDate(now.getDate() - 6), 'yyyy-MM-dd'), title: 'NEW_LAST_WEEK'},
        {value: $filter('date')(now.setDate(now.getDate() - 23), 'yyyy-MM-dd'), title: 'NEW_LAST_MONTH'}
    ];

    $scope.rangeProd = {
        keyword: (typeof $cookieStore.get($scope.cookieSearchQueryKey) === 'undefined')
            ? null
            : $cookieStore.get($scope.cookieSearchQueryKey),
        order: (typeof $cookieStore.get($scope.cookieOrderByKey) === 'undefined')
            ? 'products.id'
            : $cookieStore.get($scope.cookieOrderByKey),
        created_at_max: (typeof $cookieStore.get($scope.cookieFilterByKey) === 'undefined')
            ? null
            : $cookieStore.get($scope.cookieFilterByKey),
    };

    $scope.shopParams = {
        id: $routeParams.shopId,
        offer_types: ''
    };

    $scope.setIncludeOfferTypes = function() {
        $scope.shopParams.offer_types = $scope.getSelectedOfferTypesValuesComma().replace(/[\s]+/g, '');
        $scope.changeProducts();
    };

    $scope.getSelectedOfferTypes = function() {
        return $filter('filter')($scope.availableTypes, {checked: true});
    };

    $scope.getSelectedOfferTypesComma = function() {
        return $scope.getSelectedOfferTypes().map(function(item) {
            return item.title;
        }).join(', ');
    };

    $scope.getSelectedOfferTypesValuesComma = function() {
        return $scope.getSelectedOfferTypes().map(function(item) {
            return item.value;
        }).join(', ');
    };

    $scope.resetOfferTypeSelection = function() {
        angular.forEach($scope.availableTypes, function(type) {
            type.checked = undefined;
        });
    };

    $scope.filterRangeProd = function(prod) {
        if ($scope.rangeProd.keyword) {
            return prod.price >= $scope.rangeProd.minPrice && prod.price <= $scope.rangeProd.maxPrice && prod.title.toLowerCase().indexOf($scope.rangeProd.keyword.toLowerCase()) > -1;
        } else {
            return prod.price >= $scope.rangeProd.minPrice && prod.price <= $scope.rangeProd.maxPrice;
        }
    };

    $scope.$watch('activeFilters', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.changeProducts();
        }
    }, true);

    $scope.$watch('rangeProd.created_at_max', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put($scope.cookieFilterByKey, $scope.rangeProd.created_at_max);
            $scope.changeProducts();
        }
    }, true);

    $scope.$watch('rangeProd.order', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put($scope.cookieOrderByKey, $scope.rangeProd.order);
            $scope.changeProducts();
        }
    }, true);

    var queryTimeout = null;

    $scope.$watch('rangeProd.keyword', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put('keywordProduct', newValue);
            $cookieStore.put($scope.cookieSearchQueryKey, newValue);
            if (queryTimeout) $timeout.cancel(queryTimeout);
            var tempQueryStringProd = newValue;
            queryTimeout = $timeout(function () {
                $scope.rangeProd.keyword = tempQueryStringProd;
                $scope.changeProducts();
            }, 1000);
        }
    }, true);

    $scope.$watch('rangeProd.minPrice', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            var tempQueryStringProd = newValue;
            queryTimeout = $timeout(function () {
                $scope.rangeProd.minPrice = tempQueryStringProd;
                $scope.changeProducts();
            }, 1000);
        }
    }, true);

    $scope.$watch('rangeProd.maxPrice', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            var tempQueryStringProd = newValue;
            queryTimeout = $timeout(function () {
                $scope.rangeProd.maxPrice = tempQueryStringProd;
                $scope.changeProducts();
            }, 1000);
        }
    }, true);

    $scope.shop = {
        type: 'regular'
    };

    $scope.getShop = function() {
        $scope.loadingShop = true;
        $http.get('/api/shops/' + $routeParams.shopId + '?include=domains,offers,address').then(function onSuccess(response) {
            $scope.shop = response.data.data;
            var $type = $scope.shop.type;
            $scope.resetOfferTypeSelection();
            var offerTypes = $cookieStore.get($scope.cookieOfferTypesKey);

            if (offerTypes) {
                $scope.availableTypes = offerTypes;
            } else {
                var objIndex = $scope.availableTypes.findIndex(function (obj) {
                    return obj.value === $type;
                });
                $scope.availableTypes[objIndex].checked = true;
            }

            $scope.setIncludeOfferTypes();
        });
    };

    $scope.getShop();

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.searchParams.take === 'undefined' ? $scope.take : $scope.searchParams.take;
    $scope.searchParams.take = ($scope.searchParams.take > 200 || $scope.searchParams.take < $scope.take) ? $scope.take : $scope.searchParams.take;

    $scope.searchParams.tag = $scope.searchParams.tag ? $scope.searchParams.tag : '';

    $scope.loadMoreProducts = function() {
        $scope.take += 15;
        $cookieStore.put($scope.cookieTakeKey, $scope.take);
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.changeProducts();
    };

    $scope.changeProducts = function() {
        $scope.allResults = false;
        $scope.refreshingProducts = true;

        var activeFilters = $scope.activeFilters;

        var paramData = {
            include: 'variants,tags',
            take: (typeof $cookieStore.get($scope.cookieTakeKey) === 'undefined')
                ? $scope.take
                : $cookieStore.get($scope.cookieTakeKey),
            is_offer: true,
            query: $scope.rangeProd.keyword,
            show_images: 1,
            order_by: $scope.rangeProd.order,
            order_by_asc: $scope.rangeProd.order.asc,
            created_at_max: $scope.rangeProd.created_at_max,
            offer_shop: $scope.shopParams.id,
            offer_types: $scope.shopParams.offer_types,
            tag: (typeof $cookieStore.get($scope.cookieSelectedTagsKey) === 'undefined')
                ? ''
                : $cookieStore.get($scope.cookieSelectedTagsKey).join(',')
        };

        Object.keys(activeFilters).map(function(index) {
            paramData[activeFilters[index].id] = activeFilters[index].min + ',' + activeFilters[index].max;
        });

        $http.get('/api/products', { params: paramData}).then(function onSuccess(response) {
            $scope.products = response.data.data;
            $scope.productsMeta = response.data.meta;

            if (response.data.data.length !== $scope.products.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loadingProducts = false;
            $scope.refreshingProducts = false;

            var $list = String($scope.cookieValue).split(',');
            angular.forEach($scope.products, function (product, index) {
                if ($list.includes(String(product.id))) {
                    $scope.products[index].selected = true;
                    $scope.showMassAdd = true;
                }
            });
        });
    };
    //Outcommented because it messed up the sorting
    // $scope.changeProducts();

    $scope.$watch('availableTypes', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put($scope.cookieOfferTypesKey, $scope.availableTypes);
            $scope.setIncludeOfferTypes();
        }
    }, true);

    $scope.$watch('rangeProd.minPrice', function (newValue) {
        $cookieStore.put('minPriceProd', newValue);
    }, true);

    $scope.$watch('rangeProd.maxPrice', function (newValue) {
        $cookieStore.put('maxPriceProd', newValue);
    }, true);

    $scope.selectedAll = false;
    $scope.showMassAdd = false;

    $scope.checkAll = function() {
        $scope.showMassAdd = false;
        var $uniqueList = [];
        angular.forEach($scope.products, function (item) {
            item.selected = $scope.selectedAll;
            if (item.selected === true) {
                $scope.showMassAdd = true;
                $uniqueList.push(item.id);
            }
        });

        $scope.cookieValue = $uniqueList.join(',');
        $cookieStore.put($scope.cookieName, $scope.cookieValue);
    };

    $scope.checkOne = function(product) {
        $scope.showMassAdd = false;
        angular.forEach($scope.products, function (item) {
            if (item.selected === true) $scope.showMassAdd = true;
        });

        if (product) {
            $scope.rememberProcess(product);
        }
    };

    $scope.addSelectedProducts = function() {
        var checked = [];
        angular.forEach($scope.products, function (item) {
            if (item.selected === true) {
                checked.push(item.id);
            }
        });
        $scope.add = {
            ids: checked
        };
        $http.post('/api/shops/' + $routeParams.shopId + '/products/add', $scope.add).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.showMassAdd = false;
                $scope.loadingProducts = true;

                $scope.cookieValue = '';
                $cookieStore.put($scope.cookieName, $scope.cookieValue);

                $timeout(function() {
                    $location.path('/offers/shop/' + $routeParams.shopId);
                }, 2000);
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.addSelectedProduct = function(product) {
        var checked = [];
        checked.push(product.id);
        $scope.add = {
            ids: checked
        };
        $http.post('/api/shops/' + $routeParams.shopId + '/products/add', $scope.add).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.showMassAdd = false;
                $scope.loadingProducts = true;

                $scope.cookieValue = '';
                $cookieStore.put($scope.cookieName, $scope.cookieValue);

                $timeout(function() {
                    $location.path('/offers/shop/' + $routeParams.shopId);
                }, 2000);
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.rememberProcess = function(item) {
        if (item.selected) {
            $scope.rememberInsert(item);
        } else {
            $scope.rememberRemove(item);
        }
    };

    $scope.rememberInsert = function(item) {
        var $list = String($scope.cookieValue).split(',');
        $list.push(item.id);

        var $uniqueList = $list.filter(function(item, pos) {
            return $list.indexOf(item) === pos;
        });

        $scope.cookieValue = $uniqueList.join(',');
        $cookieStore.put($scope.cookieName, $scope.cookieValue);
    };

    $scope.rememberRemove = function(item) {
        var $list = String($scope.cookieValue).split(',');

        var $newList = [];
        for (var $i = 0; $i < $list.length; $i++) {
            if (parseInt($list[$i]) !== parseInt(item.id)) {
                $newList.push($list[$i]);
            }
        }

        var $uniqueList = $newList.filter(function(item, pos) {
            return $newList.indexOf(item) === pos;
        });

        $scope.cookieValue = $uniqueList.join(',');
        $cookieStore.put($scope.cookieName, $scope.cookieValue);
    };

    $scope.toggleListView = function(listView) {
        if ($scope.listView !== listView) {
            $scope.listView = listView;
            $cookieStore.put($scope.cookieTableViewKey, listView);
        }
    };

    $scope.selectedTags = [];
    $scope.tags = [];
    $scope.loadingTags = false;
    $scope.parentTags = [];

    $scope.loadMasterTags = function () {
        $scope.loadingTags = true;
        $http.get('/api/tags', {
            params: {
                take: 10000,
                shop_id: $scope.shopId,
                order_by: 'name',
                parent: ''
            }
        }).then(function onSuccess(response) {
            $scope.tags = response.data.data;

            $scope.selectedTags = $scope.searchParams.tag.split(',');
            if ($scope.selectedTags.length === 1) {
                if ($scope.selectedTags[0] === '') {
                    $scope.selectedTags = [];
                }
            }

            angular.forEach($scope.tags, function(tag, idx) {
                $scope.tags[idx].selected = $scope.selectedTags.indexOf(tag.name) !== -1;
                if ($scope.tags[idx].selected === true) {
                    $scope.parentTags.push($scope.tags[idx].id);
                }
            });

            var $cookieTagsKey = $cookieStore.get($scope.cookieTagsKey);
            if ($cookieTagsKey) {
                angular.forEach($scope.tags, function(tag, idx) {
                    angular.forEach($cookieTagsKey, function(cookieTag) {
                        if (cookieTag.name === tag.name) {
                            $scope.tags[idx].selected = true;
                        }
                    });
                });

                angular.forEach($cookieTagsKey, function(tag) {
                    $scope.selectedTags.push(tag.name);
                    $scope.parentTags.push(tag.id);
                });
            }

            $scope.loadingTags = false;

            if ($scope.parentTags.length) {
                $scope.loadMasterSubTags();
            }
        });
    };

    $scope.loadMasterTags();

    $scope.filteredTags = [];

    $scope.toggleTagFilter = function (idx) {
        $scope.tags[idx].selected = !$scope.tags[idx].selected;

        $scope.selectedTags = [];
        $scope.parentTags = [];
        angular.forEach($scope.tags, function(tag) {
            if (tag.selected === true) {
                $scope.selectedTags.push(tag.name);
                $scope.parentTags.push(tag.id);
            }
        });

        $cookieStore.put($scope.cookieTagsKey, $scope.tags.filter(function ($tag) { return $tag.selected === true; }));
        $cookieStore.put($scope.cookieSelectedTagsKey, $scope.selectedTags);

        $scope.searchParams.tag = $scope.selectedTags.join(',');
        $location.search($scope.searchParams);
        $scope.changeProducts();

        $scope.subTags = [];
        if ($scope.selectedTags.length) {
            $scope.loadMasterSubTags();
        }
    };

    $scope.variantStepsPriceString = function(variant) {
        var price = '';
        if (variant.step_prices) {
            var min = Math.min(variant.step1_price, variant.step2_price, variant.step3_price);
            min = $filter('currency')(min, 'kr');
            var max = Math.max(variant.step1_price, variant.step2_price, variant.step3_price);
            max = $filter('currency')(max, 'kr');
            price = $filter('translate')('PRICE_FROM') + ' ' + min + ' ' + $filter('translate')('PRICE_TO') + ' ' + max;
        }

        return price;
    };

    $scope.subTags = [];
    $scope.loadingSubTags = false;

    $scope.loadMasterSubTags = function () {
        if (!$scope.selectedTags.length) {
            return;
        }

        $scope.loadingSubTags = true;
        $http.get('/api/tags', {
            params: {
                take: 200,
                is_master: 1,
                shop_id: $scope.shopId,
                order_by: 'name',
                parent: $scope.parentTags.join(',')
            }
        }).then(function onSuccess(response) {
            $scope.subTags = response.data.data;

            $scope.selectedTags = $scope.searchParams.tag.split(',');
            angular.forEach($scope.subTags, function(tag, idx) {
                $scope.subTags[idx].selected = $scope.selectedTags.indexOf(tag.name) !== -1;
            });

            $scope.loadingSubTags = false;
        });
    };

    $scope.toggleSubTagFilter = function (idx) {
        $scope.subTags[idx].selected = !$scope.subTags[idx].selected;

        $scope.selectedTags = [];
        $scope.parentTags = [];
        angular.forEach($scope.tags, function(tag) {
            if (tag.selected === true) {
                $scope.selectedTags.push(tag.name);
                $scope.parentTags.push(tag.id);
            }
        });

        angular.forEach($scope.subTags, function(tag) {
            if (tag.selected === true) {
                $scope.selectedTags.push(tag.name);
            }
        });

        $scope.take = 15;
        $scope.searchParams.tag = $scope.selectedTags.join(',');
        $location.search($scope.searchParams);
        $scope.changeProducts();
    };

    $scope.resetCookies = function() {
        var $cookies = [
            $scope.cookieTagsKey,
            $scope.cookieTakeKey,
            $scope.cookieOrderByKey,
            $scope.cookieFilterByKey,
            $scope.cookieTableViewKey,
            $scope.cookieOfferTypesKey,
            $scope.cookieSearchQueryKey,
            $scope.cookieRangeFiltersKey,
            $scope.cookieSelectedTagsKey,
            $scope.cookieToggleRangeFiltersKey,
        ];

        angular.forEach($cookies, function($cookie) {
            $cookieStore.remove($cookie);
        });

        angular.forEach($scope.tags, function($tag) {
            $tag.selected = false;
        });

        $scope.take = 15;
        $scope.listView = 'list';
        $scope.activeFilters = [];
        $scope.selectedTags = [];
        $scope.parentTags = [];
        $location.search('tag', null);
        $location.search('take', 15);
        $scope.rangeProd = {
            keyword: null,
            order: 'products.id',
            created_at_max: null,
        };

        $scope.getShop();
        $scope.changeProducts();
    };
}]);

ctrl.controller('orderDetailCtrl', ["$scope", "$routeParams", "$http", "$timeout", "$location", "$filter", "$window", "Upload", "activeProfile", function ($scope, $routeParams, $http, $timeout, $location, $filter, $window, Upload, activeProfile) {
    $scope.loading = true;
    $scope.refreshingOrders = true;
    $scope.orderId = $routeParams.orderId;
    $scope.data = null;
    $scope.flag = false;
    $scope.flagCancelled = false;
    $scope.flagFulfilled = false;
    $scope.flagArchived = false;
    $scope.flagEmailed = false;
    $scope.flagApproved = false;
    $scope.yoyoLoading = false;
    $scope.order = null;
    $scope.orderLogs = null;
    $scope.focusAddress = false;
    $scope.products = [{id: '', title: $filter('translate')('ALL_PRODUCTS')}];
    $scope.variants = [];
    $scope.substitute = {old_product_id: '', new_product_id: '', new_variant_id: '', order_id: $scope.orderId};

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.take;

    $scope.activeProfile = activeProfile;

    $scope.parameters = {
        sendNotificationEmail: false
    };

    $scope.hiddenAddressFields = null;
    $scope.addressDepartments = null;
    $scope.addressBudget = null;
    $scope.addressDelivery = null;

    var initialize = true;

    $scope.change = function () {
        //$scope.loading = true;
        //$scope.refreshingProducts = true;
        $http.get('/api/products', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.products = response.data.data;
            $scope.allResults = false;
            //$scope.loading = false;
            //$scope.refreshingProducts = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $scope.setProductToSubstitute = function (value) {
        $scope.substitute.old_product_id = value;
    };

    $scope.refreshProducts = function ($search) {
        $scope.searchParams.query = $search;
        $http.get('/api/products', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.products = response.data.data;
        });
    };

    $scope.getVariants = function () {
        $scope.variants = [];
        $scope.substitute.new_variant_id = '';
        $http.get('api/products/' + $scope.substitute.new_product_id + '/variants')
            .then(function onSuccess(response) {
                $scope.variants = response.data.data;
            });
    };

    $scope.substituteProduct = function () {
        $http.post('/api/orders/substitute-product', $scope.substitute).then(function onSuccess(response) {
            window.location.reload();
        });
    };

    $http.get('/api/orders/' + $routeParams.orderId + '?include=shop,billing_address,shipping_address,line_items,shipping_lines,transactions,fulfilments,order_discounts').then(function onSuccess(response) {
        $scope.order = response.data.data;
        $scope.order.total_volume = 0;

        angular.forEach($scope.order.line_items.data, function (line_item) {
            $scope.order.total_volume += ((line_item['width'] * line_item['height'] * line_item['length']) * line_item['quantity']);
        });

        $scope.loading = false;

        $http.get('/api/shops/' + $scope.order.shop_id + '/address-shop-data').then(function onSuccess(response) {
            $scope.hiddenAddressFields = response.data.fields;
            $scope.addressDepartments = response.data.departments;
            $scope.addressBudget = response.data.budget;
            $scope.addressDelivery = response.data.delivery;
        });

        $scope.address = $scope.order.billing_address.data;
        $scope.loadStates();
        $scope.getOrderLogs();
    });


    $scope.countryList = null;
    $http.get('js/countries.json').then(function onSuccess(response) {
        $scope.countryList = response.data;
    });

    $scope.saveOrder = function () {
        var data = $scope.order;
        $http.put('/api/orders/' + $scope.order.id, data).then(function () {
            $scope.flag = true;
            $timeout(function () {
                $scope.flag = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.cancelOrders = function () {
        $http.post('/api/orders/cancel/' + $scope.order.id, {
            email: $scope.parameters.sendNotificationEmail
        }).then(function () {
            $scope.parameters.sendNotificationEmail = false;
            $scope.order.fulfilment_status = 'cancelled';
            $scope.flagCancelled = true;
            $timeout(function () {
                $scope.flagCancelled = false;
            }, 5000);
            $("#cancelOrders").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        }, function onError(response) {
            $scope.parameters.sendNotificationEmail = false;
            $scope.data = response.data;
        });
    };

    $scope.emailOrder = function () {
        $http.get('/api/orders/' + $scope.order.id + '/email').then(function () {
            $scope.flagEmailed = true;
            $timeout(function () {
                $scope.flagEmailed = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.stateList = null;
    $scope.updateCountry = function () {
        $.each($scope.countryList, function (index, object) {
            if (typeof object.cca2 !== 'undefined' && object.cca2 === $scope.address.country_code) {
                $scope.address.country = object.cca2;
            }
        });
        $scope.loadStates();
    };

    $scope.loadStates = function () {
        $http.get('/api/countries/' + $scope.address.country + '/states').then(function onSuccess(response) {
            $scope.stateList = response.data;
            if (angular.equals($scope.stateList, {})) {
                $scope.stateList = null;
            }
        });
    };

    $scope.editAddress = function (address) {
        $scope.address = address;
        $scope.focusEditAddress = true;
        $scope.loadStates();
    };

    $scope.updateAddress = function () {
        var data = $scope.address;
        $http.put('/api/addresses/' + $scope.address.id, data).then(function () {
            $scope.focusEditAddress = false;
            $("#editAddress").modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editShipping = function (shipping) {
        $scope.shipping = shipping;
        $scope.focusEditShipping = true;
    };

    $scope.updateShipping = function () {
        var data = $scope.shipping;
        $http.put('/api/shipping_lines/' + $scope.shipping.id, data).then(function () {
            $scope.focusEditShipping = false;
            $("#editShipping").modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editContact = function () {
        $scope.focusEditContact = true;
    };

    $scope.updateContact = function () {
        $http.put('/api/orders/' + $scope.order.id, $scope.order);
        $http.put('/api/addresses/' + $scope.order.billing_address.data.id, $scope.order.billing_address.data).then(function () {
            $scope.focusEditContact = false;
            $("#editContact").modal('hide');
        });
    };

    $scope.printDeliveryNote = function () {
        $window.open('api/orders/print_delivery_notes?ids=' + $scope.order.id);
    };

    $scope.printReturnNote = function () {
        $window.open('api/orders/print_return_notes?ids=' + $scope.order.id);
    };

    $scope.printCustomsPapers = function () {
        $window.open('api/orders/' + $scope.order.id + '/yoyo/customs-document');
    };

    $scope.sendApprovalEmail = function () {
        $http.get('/api/orders/' + $scope.order.id + '/send-approval-email').then(function () {
            $scope.flagEmailed = true;
            $timeout(function () {
                $scope.flagEmailed = false;
            }, 5000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.approveOrder = function () {
        $http.get('/api/orders/' + $scope.order.id + '/approve-order').then(function () {
            $scope.flagApproved = true;
            $scope.order.fulfilment_status = null;
            $timeout(function () {
                $scope.flagApproved = false;
                $scope.loading = true;
                $http.get('/api/orders/' + $routeParams.orderId + '?include=shop,billing_address,shipping_address,line_items,shipping_lines,transactions').then(function onSuccess(response) {
                    $scope.order = response.data.data;
                    $scope.loading = false;
                });
            }, 5000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.fulfillOrder = function () {
        var checked = [];
        checked.push($scope.order.id);
        $http.post('/api/orders/fulfill', {
            ids: checked
        }).then(function () {
            $scope.order.fulfilment_status = 'completed';
            $scope.flagFulfilled = true;
            $timeout(function () {
                $scope.flagFulfilled = false;
            }, 5000);
        });
    };

    $scope.archiveOrder = function () {
        var checked = [];
        checked.push($scope.order.id);
        $http.post('/api/orders/close', {
            ids: checked
        }).then(function () {
            $scope.order.closed_at = new Date();
            $scope.flagArchived = true;
            $timeout(function () {
                $scope.flagArchived = false;
            }, 5000);
        });
    };

    $scope.yoyoIsCreated = false;
    $scope.yoyoIsUploaded = false;
    $scope.yoyoLabelReceived = false;
    $scope.yoyoTrackingSent = false;
    $scope.yoyoTransactionId = null;

    $scope.yoyoLogsData = null;
    $scope.yoyoLogs = function () {
        $scope.yoyoIsCreated = false;
        $scope.yoyoIsUploaded = false;
        $scope.yoyoLabelReceived = false;
        $scope.yoyoTrackingSent = false;
        $scope.yoyoTransactionId = null;

        $scope.yoyoLoading = true;
        $scope.yoyoLogsData = null;
        $http.get('/api/orders/' + $scope.order.id + '/yoyo/logs').then(function onSuccess(response) {
            $scope.yoyoLoading = false;
            $scope.yoyoLogsData = response.data;
            angular.forEach($scope.yoyoLogsData, function (value) {
                if (value.action === 'shipment_create') {
                    $scope.yoyoIsCreated = true;
                    $scope.yoyoTransactionId = value.transaction_id;
                }
                if (value.action === 'shipment_upload') {
                    $scope.yoyoIsUploaded = true;
                }
                if (value.action === 'shipment_label') {
                    $scope.yoyoLabelReceived = true;
                }
                if (value.action === 'shipment_tracking_url') {
                    $scope.yoyoTrackingSent = true;
                }
            });
        }, function onError() {
            $scope.yoyoIsCreated = false;
            $scope.yoyoTransactionId = null;

            $scope.yoyoLoading = false;
            $scope.yoyoLogsData = null;
        });
    };

    $scope.yoyoOrder = function () {
        $scope.yoyoClear();
        $scope.yoyoLogs();
        $scope.yoyoCheckShippingCostsData = null;
        $scope.showAcceptPanel = false;
        $scope.matchedShipping = null;
    };

    $scope.yoyoValidateData = null;
    $scope.yoyoValidate = function () {
        $scope.yoyoClear();
        $scope.yoyoLoading = true;
        $scope.yoyoValidateData = null;
        $http.post('/api/orders/' + $scope.order.id + '/yoyo/validate').then(function onSuccess(response) {
            $scope.yoyoLoading = false;
            $scope.yoyoValidateData = response.data;
            $scope.yoyoLogs();
        }, function onError() {
            $scope.yoyoLoading = false;
            $scope.yoyoValidateData = null;
        });
    };

    $scope.yoyoCreateData = null;
    $scope.yoyoCreate = function () {
        $scope.yoyoClear();
        $scope.yoyoLoading = true;
        $http.post('/api/orders/' + $scope.order.id + '/yoyo/create').then(function onSuccess(response) {
            $scope.yoyoLoading = false;
            $scope.yoyoCreateData = response.data;
            $scope.yoyoLogs();
        }, function onError() {
            $scope.yoyoLoading = false;
            $scope.yoyoCreateData = null;
        });
    };

    $scope.yoyoLabelsData = null;
    $scope.yoyoLabels = function (transaction) {
        $scope.yoyoClear();
        $scope.yoyoLoading = true;
        $scope.yoyoLabelsData = null;
        $http.get('/api/orders/' + $scope.order.id + '/yoyo/labels/' + transaction).then(function onSuccess(response) {
            $scope.yoyoLoading = false;
            $scope.yoyoLabelsData = response.data;
            $scope.yoyoLogs();
        }, function onError() {
            $scope.yoyoLoading = false;
            $scope.yoyoLabelsData = null;
        });
    };

    $scope.yoyoDownloadPdf = function (transaction) {
        $window.location.href = '/api/orders/' + $scope.order.id + '/yoyo/download/' + transaction;
    };

    $scope.yoyoClear = function () {
        $scope.yoyoValidateData = null;
        $scope.yoyoCreateData = null;
        $scope.yoyoLabelsData = null;
        $scope.yoyoTrackingUrlsData = null;
    };

    $scope.yoyoTrackingUrlsData = null;
    $scope.yoyoTrackingUrls = function (transaction) {
        $scope.yoyoClear();
        $scope.yoyoLoading = true;
        $scope.yoyoTrackingUrlsData = null;
        $http.get('/api/orders/' + $scope.order.id + '/yoyo/tracking-urls/' + transaction).then(function onSuccess(response) {
            $scope.yoyoLoading = false;
            $scope.yoyoTrackingUrlsData = response.data;
            $scope.yoyoLogs();
        }, function onError() {
            $scope.yoyoLoading = false;
            $scope.yoyoTrackingUrlsData = null;
        });
    };

    $scope.yoyoFile = null;
    $scope.yoyoSelectFile = function (file) {
        $scope.yoyoFile = file;
    };

    $scope.yoyoUploadData = null;
    $scope.yoyoUploadFile = function (transaction) {
        $scope.yoyoLoading = true;
        Upload.upload({
            url: '/api/orders/' + $scope.order.id + '/yoyo/uploads/' + transaction,
            data: {
                file: $scope.yoyoFile
            }
        }).then(function (response) {
            $scope.yoyoLoading = false;
            $scope.yoyoUploadData = response.data;
            $scope.yoyoLogs();
            $scope.yoyoFile = null;
        }, function (response) {
            $scope.yoyoLoading = false;
            $scope.yoyoUploadData = response.data;
            $scope.yoyoFile = null;
        }, function (evt) {
            //
        });
    };

    $scope.yoyoCheckShippingCostsData = null;
    $scope.showAcceptPanel = false;
    $scope.matchedShipping = null;
    $scope.yoyoCheckPriceBeforeCreate = function () {
        $scope.yoyoClear();
        $scope.yoyoLoading = true;
        $http.post('/api/orders/' + $scope.order.id + '/yoyo/check-shipping-costs').then(function onSuccess(response) {
            $scope.yoyoLoading = false;
            $scope.yoyoCheckShippingCostsData = response.data;
            angular.forEach($scope.yoyoCheckShippingCostsData['Products'], function (value) {
                if (parseInt(value['ProductNumber']) === parseInt($scope.order.shipping_lines.data[0].yoyo_product_number)) {
                    $scope.matchedShipping = value;
                }
            });
            $scope.yoyoLogs();
            $scope.showAcceptPanel = true;
        }, function onError() {
            $scope.yoyoLoading = false;
            $scope.yoyoCheckShippingCostsData = null;
            $scope.showAcceptPanel = false;
        });
    };

    $scope.yoyoShipmentAccept = function () {
        $scope.yoyoClear();
        $scope.yoyoLoading = true;
        $http.post('/api/orders/' + $scope.order.id + '/yoyo/create', {
            price: $scope.matchedShipping.Price.BasePriceWithMarkup,
            currency: $scope.matchedShipping.Price.Currency
        }).then(function onSuccess(response) {
            $scope.yoyoLoading = false;
            $scope.yoyoCreateData = response.data;
            if ($scope.yoyoCreateData.Success === true) {
                var checked = [];
                checked.push($scope.order.id);
                $http.post('/api/orders/fulfill', {ids: checked}).then(function () {
                    $scope.order.fulfilment_status = 'completed';
                });
            }
            $scope.yoyoLogs();
            $scope.yoyoCheckShippingCostsData = null;
            $scope.showAcceptPanel = false;
        }, function onError() {
            $scope.yoyoLoading = false;
            $scope.yoyoCreateData = null;
            $scope.showAcceptPanel = true;
        });
    };

    $scope.yoyoShipmentDecline = function () {
        $scope.yoyoLoading = false;
        $scope.yoyoCheckShippingCostsData = null;
        $scope.showAcceptPanel = false;
    };

    $scope.yoyoUploadCancel = function () {
        $scope.yoyoFile = null;
        $scope.yoyoUploadData = null;
    };

    $scope.yoyoResendEmailData = null;
    $scope.yoyoResendEmail = function (transaction) {
        $scope.yoyoClear();
        $scope.yoyoLoading = true;
        $http.post('/api/orders/' + $scope.order.id + '/yoyo/tracking/' + transaction + '/resend-email').then(function onSuccess(response) {
            $scope.yoyoLoading = false;
            $scope.yoyoResendEmailData = response.data;
            $scope.yoyoLogs();
        }, function onError() {
            $scope.yoyoLoading = false;
            $scope.yoyoResendEmailData = null;
        });
    };

    $scope.generateOrderXml = function () {
        $window.open('api/orders/' + $scope.order.id + '/xml');
    };

    $scope.getOrderLogs = function () {
        $http.get('/api/orders/' + $scope.order.id + '/logs').then(function onSuccess(response) {
            $scope.orderLogs = response.data.data;
        }, function onError() {
            $scope.orderLogs = null;
        });
    };

    $scope.printOrderConfirmation = function () {
        $window.open('api/orders/print-order-confirmation?ids=' + $scope.order.id);
    };

}]);

ctrl.controller('orderCtrl', ["$scope", "$http", "$timeout", "$location", "$window", "$filter", "mwMultiSelectService", function ($scope, $http, $timeout, $location, $window, $filter, mwMultiSelectService) {
    $scope.loading = true;
    $scope.refreshingOrders = true;
    $scope.allResults = false;
    $scope.flagEmailed = false;
    $scope.orders = [];

    $scope.take = 15;

    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.take;
    $scope.searchParams.dateFrom = null;
    $scope.searchParams.dateTo = null;

    $scope.parameters = {
        sendNotificationEmail: false
    };

    $scope.shops = [{id: '', title: $filter('translate')('ALL_SHOPS')}];
    $scope.getShopsList = function() {
        $scope.shops = [{id: '', title: $filter('translate')('ALL_SHOPS')}];
        $http.get('/api/shops-compact').then(function onSuccess(response) {
            $scope.shops = $scope.shops.concat(response.data);
        });
    };

    $scope.products = [{id: '', title: $filter('translate')('ALL_PRODUCTS')}];
    $scope.getProductsList = function(shop_id) {
        $scope.products = [{id: '', title: $filter('translate')('ALL_PRODUCTS')}];
        $http.get('/api/products-compact?shop_id=' + shop_id).then(function onSuccess(response) {
            $scope.products = $scope.products.concat(response.data);
        });
    };

    $scope.getShopsList();

    $scope.areas = [
        {id: '', title: $filter('translate')('ALL_BUSINESS_AREAS')},
        {id:  1, title: 'B2B Merchandise'},
        {id:  2, title: 'B2B Valggave'},
        {id:  3, title: 'B2B jubilæum'},
        {id:  4, title: 'B2B pointshop'},
        {id:  5, title: 'B2C lagerslag'},
        {id:  6, title: 'B2C fordelshop'}
    ];

    var initialize = true;

    $scope.$watch('searchParams.shop_id', function (value) {
        if (!initialize) {
            $scope.searchParams.shop_id = value;
            $location.search($scope.searchParams);
            $scope.change();
            if (value > 0) {
                $scope.getProductsList(value);
            } else {
                $scope.products = [{id: '', title: $filter('translate')('ALL_PRODUCTS')}];
            }
        }
    }, true);

    $scope.$watch('searchParams.product_id', function (value) {
        if (!initialize) {
            $scope.searchParams.product_id = value;
            $location.search($scope.searchParams);
            $scope.change();
        }
    }, true);

    $scope.$watch('searchParams.business_area', function (value) {
        if (!initialize) {
            $scope.searchParams.business_area = value;
            $location.search($scope.searchParams);
            $scope.change();
        }
    }, true);

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function () {
        $scope.loading = true;
        $scope.refreshingOrders = true;
        $http.get('/api/orders?include=shipping_lines', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.orders = response.data.data;
            if (response.data.data.length !== $scope.orders.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingOrders = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.selectedAll = false;
    $scope.showActions = false;
    $scope.checkAll = function () {
        $scope.showActions = false;
        $scope.checkedCount = 0;
        angular.forEach($scope.orders, function (item) {
            item.selected = $scope.selectedAll;
            if (item.selected === true) {
                $scope.showActions = true;
                $scope.checkedCount += 1;
            }
        });
    };

    $scope.checkOne = function () {
        $scope.showActions = false;
        $scope.checkedCount = 0;
        angular.forEach($scope.orders, function (item) {
            if (item.selected === true) {
                $scope.showActions = true;
                $scope.checkedCount += 1;
            }
        });
    };

    $scope.printDeliveryNote = function () {
        var checked = [];
        angular.forEach($scope.orders, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        $window.open('api/orders/print_delivery_notes?ids=' + checked);
    };

    $scope.printReturnNote = function () {
        var checked = [];
        angular.forEach($scope.orders, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        $window.open('api/orders/print_return_notes?ids=' + checked);
    };

    $scope.exportGLS = function () {
        var checked = [];
        angular.forEach($scope.orders, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        $window.open('api/orders/export_gls?ids=' + checked);
    };

    $scope.exportXML = function () {
        var checked = [];
        angular.forEach($scope.orders, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        $window.open('api/orders/export_xml?ids=' + checked);
    };

    $scope.fulfillOrders = function() {
        var checked = [];
        angular.forEach($scope.orders, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        $http.post('/api/orders/fulfill', {
            ids: checked
        }).then(function () {
            angular.forEach($scope.orders, function (item) {
                if (item.selected === true && item.fulfilment_status !== 'cancelled') {
                    item.fulfilment_status = 'completed';
                }
            });
        });
    };

    $scope.archiveOrders = function() {
        var checked = [];
        angular.forEach($scope.orders, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        $http.post('/api/orders/close', {
            ids: checked
        }).then(function () {
            angular.forEach($scope.orders, function (item) {
                if (item.selected === true) item.closed_at = new Date();
            });
        });
    };

    $scope.cancelOrders = function() {
        var checked = [];
        angular.forEach($scope.orders, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        $http.post('/api/orders/cancel', {
            ids: checked,
            email: $scope.parameters.sendNotificationEmail
        }).then(function onSuccess(response) {
            $scope.parameters.sendNotificationEmail = false;
            angular.forEach($scope.orders, function (item) {
                if (item.selected === true) item.fulfilment_status = 'cancelled';
            });
            $("#cancelOrders").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        }, function onError(response) {
            $scope.parameters.sendNotificationEmail = false;
            $scope.data = response.data;
        });
    };

    $scope.mailReceipts = function() {
        var checked = [];
        angular.forEach($scope.orders, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
        $http.post('/api/orders/mail-receipts', {
            ids: checked
        }).then(function () {
            $scope.flagEmailed = true;
            $timeout(function () {
                $scope.flagEmailed = false;
            }, 3000);
        });
    };

    $scope.loadMoreOrders = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.loadMore = function() {
        $scope.refreshingOrders = true;
        $http.get('/api/orders?include=shipping_lines', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.orders.length) {
                $scope.orders = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingOrders = false;
        });
    };

    $scope.selectedDates = [];
    $scope.activeDate = null;

    $scope.$watch('selectedDates', function () {
        if (!initialize) {
            if ($scope.selectedDates.length > 0) {
                $scope.parsed_date = mwMultiSelectService.parse($scope.selectedDates);
                if ($scope.parsed_date.before && $scope.parsed_date.after) {
                    $scope.searchParams.dateFrom = $filter('date')($scope.parsed_date.before, 'yyyy-MM-dd');
                    $scope.searchParams.dateTo = $filter('date')($scope.parsed_date.after, 'yyyy-MM-dd');
                } else if ($scope.parsed_date.before && !$scope.parsed_date.after) {
                    $scope.searchParams.dateFrom = $filter('date')($scope.parsed_date.before, 'yyyy-MM-dd');
                    $scope.searchParams.dateTo = $filter('date')($scope.parsed_date.before, 'yyyy-MM-dd');
                } else if (!$scope.parsed_date.before && $scope.parsed_date.after) {
                    $scope.searchParams.dateFrom = $filter('date')($scope.parsed_date.after, 'yyyy-MM-dd');
                    $scope.searchParams.dateTo = $filter('date')($scope.parsed_date.after, 'yyyy-MM-dd');
                }
            } else {
                $scope.searchParams.dateFrom = null;
                $scope.searchParams.dateTo = null;
            }
            $location.search($scope.searchParams);
            $scope.change();
        }
    }, true);

    $scope.dateOptions = {
        formatYear: 'yyyy',
        formatMonth: 'MM',
        formatDay: 'dd',
        maxDate: new Date(),
        minDate: new Date(2010, 0, 31),
        startingDay: 1
    };

    $scope.popup = {
        opened: false
    };

    $scope.openDatePicker = function() {
        $scope.popup.opened = true;
    };

}]);

ctrl.controller('pageArchiveCtrl', ["$scope", "$http", "$location", "$timeout", function($scope, $http, $location, $timeout) {
    $scope.loading = false;
    $scope.searchParams = $location.search();
    $scope.searchParams.is_archived = '1';

    var tempQueryString = '', queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            tempQueryString = newValue;
            queryTimeout = $timeout(function () {
                $scope.searchParams.query = tempQueryString;
                $location.search($scope.searchParams);
                $scope.change();
            }, 1000);
        }
    }, true);

    $scope.change = function () {
        $scope.loading = true;
        $http.get('/api/pages', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.pages = response.data.data;
            $scope.loading = false;
        });
    };

    $scope.change();
}]);

ctrl.controller('pageDetailCtrl', ["$scope", "$routeParams", "$location", "$http", "Upload", "$timeout", "activeProfile", "$filter", function($scope, $routeParams, $location, $http, Upload, $timeout, activeProfile, $filter) {
    $scope.account_langs = activeProfile.account_languages.split(',');
    $scope.countActiveLangs = activeProfile.account_languages.split(',').length;
    $scope.loading = true;
    $scope.flag = false;
    $scope.data = null;
    $scope.flagMessage = null;

    $scope.page = {
        show_newsletter_form: false,
        type: 'standard',
        title: '',
        subtitle: '',
        body_html: '',
        title_1: '',
        subtitle_1: '',
        content_1: '',
        image_1: null,
        title_2: '',
        subtitle_2: '',
        content_2: '',
        image_2: null,
        promos: {data: []},
        images: {data: []},
        header_image: null,
        footer_title: '',
        footer_subtitle: '',
        footer_content: '',
        show_in_menu: false,
        show_in_footer: false,
        robots: 'index,follow',
        parent: null,
        parent_page: {
            data: {
                id: null,
                title: null
            }
        },
        sort_order: null
    };

    $scope.categoryAsync = {
        value: ''
    };

    $scope.pageAsync = {
        value: ''
    };

    var langs = activeProfile.account_languages.split(',');
    langs.push(activeProfile.account_default_lang);
    langs.sort();
    $scope.langs = langs;
    // texts for 'indhold / content' tab
    $scope.texts = {
        special_title1: {},
        special_subtitle1: {},
        special_content1: {},
        special_title2: {},
        special_subtitle2: {},
        special_content2: {},
        special_footer_title: {},
        special_footer_subtitle: {},
        special_footer_content: {},
    };
    $scope.getPage = function () {
        var $pageId = $routeParams.pageId;
        $http.get('/api/pages/' + $pageId + '?include=shops,shops.domains,metafields,images,promos,parent_page').then(function onSuccess(response) {
            $scope.page = response.data.data;
            $scope.page.translations = {};
            $scope.getSlideshows();
            if (activeProfile.account_languages.split(',').length > 1) {
                angular.forEach($scope.account_langs, function (langCode) {
                    var title = $filter('filter')($scope.page.metafields.data, {
                        key: "title",
                        namespace: langCode
                    }, undefined)[0];
                    var description = $filter('filter')($scope.page.metafields.data, {
                        key: "description",
                        namespace: langCode
                    }, undefined)[0];
                    title = (title !== undefined) ? title.value : '';
                    description = (description !== undefined) ? description.value : '';
                    $scope.page.translations[langCode] = {
                        title: title,
                        description: description
                    };
                });
                angular.forEach(langs, function (langCode) {
                    var field = ($filter('filter')($scope.page.metafields.data, {
                        key: 'special_title1',
                        namespace: langCode
                    }, undefined)[0]);
                    $scope.texts.special_title1[langCode] = (field !== undefined) ? field.value : '';
                });
                angular.forEach(langs, function (langCode) {
                    var field = ($filter('filter')($scope.page.metafields.data, {
                        key: 'special_subtitle1',
                        namespace: langCode
                    }, undefined)[0]);
                    $scope.texts.special_subtitle1[langCode] = (field !== undefined) ? field.value : '';
                });
                angular.forEach(langs, function (langCode) {
                    var field = ($filter('filter')($scope.page.metafields.data, {
                        key: 'special_content1',
                        namespace: langCode
                    }, undefined)[0]);
                    $scope.texts.special_content1[langCode] = (field !== undefined) ? field.value : '';
                });
                angular.forEach(langs, function (langCode) {
                    var field = ($filter('filter')($scope.page.metafields.data, {
                        key: 'special_title2',
                        namespace: langCode
                    }, undefined)[0]);
                    $scope.texts.special_title2[langCode] = (field !== undefined) ? field.value : '';
                });
                angular.forEach(langs, function (langCode) {
                    var field = ($filter('filter')($scope.page.metafields.data, {
                        key: 'special_subtitle2',
                        namespace: langCode
                    }, undefined)[0]);
                    $scope.texts.special_subtitle2[langCode] = (field !== undefined) ? field.value : '';
                });
                angular.forEach(langs, function (langCode) {
                    var field = ($filter('filter')($scope.page.metafields.data, {
                        key: 'special_content2',
                        namespace: langCode
                    }, undefined)[0]);
                    $scope.texts.special_content2[langCode] = (field !== undefined) ? field.value : '';
                });
                angular.forEach(langs, function (langCode) {
                    var field = ($filter('filter')($scope.page.metafields.data, {
                        key: 'special_footer_title',
                        namespace: langCode
                    }, undefined)[0]);
                    $scope.texts.special_footer_title[langCode] = (field !== undefined) ? field.value : '';
                });
                angular.forEach(langs, function (langCode) {
                    var field = ($filter('filter')($scope.page.metafields.data, {
                        key: 'special_footer_subtitle',
                        namespace: langCode
                    }, undefined)[0]);
                    $scope.texts.special_footer_subtitle[langCode] = (field !== undefined) ? field.value : '';
                });
                angular.forEach(langs, function (langCode) {
                    var field = ($filter('filter')($scope.page.metafields.data, {
                        key: 'special_footer_content',
                        namespace: langCode
                    }, undefined)[0]);
                    $scope.texts.special_footer_content[langCode] = (field !== undefined) ? field.value : '';
                });
            }
            $scope.pageAsync.value = $scope.page.parent_page.data.title;
            $scope.loading = false;
        });
    };

    $scope.getPage();

    $scope.savePage = function(isValid) {
        if(isValid) {
            $scope.data = null;
            $scope.page.texts = $scope.texts;
            var data = $scope.page;
            $http.put('/api/pages/'+$scope.page.id, data).then(function () {
                $scope.flag = true;
                $timeout(function() {
                    $scope.flag = false;
                }, 3000);
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.deletePage = function() {
        $http.delete('/api/pages/'+$scope.page.id).then(function () {
            $location.path("/pages");
            $("#deletePage").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.getPages = function(val) {
        return $http.get('/api/pages', {
            params: {
                query: val
            }
        }).then(function(res){
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.setParentPage = function(item) {
        $scope.page.parent = item.id;
        $scope.page.parent_page.data.id = item.id;
        $scope.page.parent_page.data.title = item.title;
        $scope.pageAsync.value = item.title;
    };

    $scope.cleanParentPage = function() {
        $scope.page.parent = null;
        $scope.page.parent_page.data.id = null;
        $scope.page.parent_page.data.title = null;
        $scope.pageAsync.value = '';
    };

    $scope.getShops = function(val) {
        return $http.get('/api/shops', {
            params: {
                query: val
            }
        }).then(function(res){
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });

            return results;
        });
    };

    $scope.addShop = function(item) {
        var pageId = $scope.page.id;
        var shopId = item.id;
        $http.post('/api/shops/'+shopId+'/pages/'+pageId).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.page.shops.data.splice(0, 0, item);
            } else {
                window.location = '/';
            }
        });
    };

    $scope.deleteShop = function(idx) {
        var pageId = $scope.page.id;
        var shopId = $scope.page.shops.data[idx].id;
        $http.delete('/api/shops/'+shopId+'/pages/'+pageId).then(function () {
            $scope.page.shops.data.splice(idx, 1);
        });
    };

    //Text editor options
    $scope.tinymceOptions = {
        resize: false,
        menubar : false,
        plugins : 'image',
        toolbar: "undo redo bold italic image alignleft aligncenter alignright"
    };

    $scope.copy = function () {
        $scope.updateHandle = function (title) {
            $scope.page.handle = getSlug(title);
        };
        var data1 = $scope.page;
        data1.handle = data1.handle + '-copy';
        $http.post('/api/pages', data1).then(function onSuccess(response) {
            if (response.status === 201) {
                data1.id = response.data.created_id;
                $http.get('/api/pages/' + data1.id + '?include=shops,shops.domains,metafields,images,promos,parent_page').then(function () {
                    $http.put('/api/pages/' + $scope.page.id, data1).then(function () {
                        $location.path('/pages/' + data1.id);
                    });
                });
            } else {
                window.location = '/';
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.archivePage = function() {
        $scope.page.is_archived = $scope.page.is_archived !== true;
        $timeout(function() {
            angular.element('#submitPage').trigger('click');
        }, 100);
    };

    $scope.slideshows = {};

    $scope.getSlideshows = function() {
        $http.get('/api/slideshows').then(function onSuccess(response) {
            $scope.slideshows = response.data.data;
        });
    };

    $scope.slideshowLoading = false;
    $scope.$watch('page.slideshow_id', function (newValue) {
        if (newValue > 0) {
            $scope.slideshowLoading = true;
            $http.get('/api/slideshows/' + newValue + '?include=slides,images').then(function onSuccess(response) {
                $scope.slideshow = response.data.data;
                setTimeout(function() {
                    $scope.slideshowLoading = false;
                }, 500);
            });
        }
    }, true);

    // *** Images ****

    $scope.getImageSrc = function(imageId) {
        if ($scope.slideshow.images.data.length > 0 && imageId > 0) {
            var image = $filter('filter')($scope.slideshow.images.data, {id: imageId}, undefined);
            if (typeof image[0] !== 'undefined' && typeof image[0].src !== 'undefined') {

                return image[0].src;
            }

            return 'https://placehold.it/80';
        }
    };

    $scope.getPromoImageSrc = function(imageId) {
        if ($scope.page.images.data.length > 0 && imageId > 0) {
            var image = $filter('filter')($scope.page.images.data, {id: imageId}, undefined);
            if (typeof image[0] !== 'undefined' && typeof image[0].src !== 'undefined') {

                return image[0].src;
            }

            return 'https://placehold.it/80';
        }
    };

    $scope.sortableImages = {
        cursor: 'move',
        handle: '.handle'
    };

    $scope.onImageSelect = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.page.id,
                        owner_resource: 'page',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        var image = {
                            id: response.data.created_id,
                            content_type: file.type,
                            owner_id: $scope.page.id,
                            owner_resource: 'page',
                            position: 999,
                            size: file.size,
                            src: e.target.result
                        };
                        $scope.page.images.data.push(image);
                    }
                });
            }
        });
    };

    $scope.confirmDeleteImage = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteImage = function (idx) {
        $http.delete('/api/images/' + $scope.page.images.data[idx].id).then(function () {
            $scope.page.images.data.splice(idx, 1);
            $("#deleteImage").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    // *** Promos ****

    $scope.promo = null;

    $scope.sortablePromos = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle',
        cancel: '',
        'ui-floating': false,
        stop: function() {
            var order = [];
            angular.forEach($scope.page.promos.data, function(item) {
                order.push(item.id);
            });
            $http.post('/api/promos/order', {promoOrder: order}).then(function () {}, function onError() {});
        }
    };

    $scope.selectPromoImage = function(promo) {
        $scope.promo = promo;
    };

    $scope.updatePromoImage = function(promo) {
        $http.put('/api/promos/' + promo.id, promo).then(function () {
            $('#selectPromoImage').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.clearPromoImage = function(promo) {
        promo.image_id = null;
        $http.put('/api/promos/' + promo.id, promo).then(function () {
            $('#selectPromoImage').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.addPromo = function () {
        $scope.promo = {
            page_id: $scope.page.id,
            image_id: null,
            title: '',
            description: '',
            link: '',
            position: 999
        };
        $scope.focusAddPromo = true;
    };

    $scope.saveNewPromo = function () {
        var data = $scope.promo;
        $http.post('/api/promos', data).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.promo.id = response.data.created_id;
                $scope.page.promos.data.push($scope.promo);
                $scope.focusAddPromo = false;
                $("#addPromo").modal('hide');
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editPromo = function (promo) {
        $scope.promo = promo;
        $scope.focusEditPromo = true;
    };

    $scope.confirmDeletePromo = function (idx) {
        $scope.idx = idx;
    };

    $scope.deletePromo = function (idx) {
        $http.delete('/api/promos/' + $scope.page.promos.data[idx].id).then(function () {
            $scope.page.promos.data.splice(idx, 1);
            $("#deletePromo").modal('hide');
        });
    };

    $scope.updatePromo = function(promo) {
        $http.put('/api/promos/' + promo.id, promo).then(function () {
            $('#editPromo').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    // *** Content ****

    $scope.getPageImageSrc = function(imageId) {

        return $scope.getPromoImageSrc(imageId);
    };

    $scope.selectContentImage = function(contentIndex) {
        $scope.contentIndex = contentIndex;
    };

    $scope.clearContentImage = function() {
        $scope.page[$scope.contentIndex] = null;
        $scope.contentIndex = null;
    };

    // *** Page Shop Category ***

    $scope.savePageShopCategory = function(idx) {
        var pageId = $scope.page.id;
        var shop = $scope.page.shops.data[idx];
        $http.put('/api/shops/' + shop.id + '/pages/' + pageId, shop).then(function onSuccess(response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

}]);

ctrl.controller('pageCtrl', ["$scope", "$http", "$location", "$timeout", function ($scope, $http, $location, $timeout) {
    $scope.loading = true;
    $scope.refreshingPages = true;
    $scope.allResults = false;
    $scope.pages = [];
    $scope.data = null;

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.take;
    $scope.searchParams.include = 'shops,parent_page';

    $scope.page = {
        title: '',
        handle: '',
        body_html: '',
        parent: null,
        parent_page: {
            data: {
                id: null,
                title: null
            }
        }
    };

    $scope.pageAsync = {
        value: ''
    };

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function () {
        $scope.loading = true;
        $scope.refreshingPages = true;
        $http.get('/api/pages', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.pages = response.data.data;
            if (response.data.data.length !== $scope.pages.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingPages = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.addPage = function () {
        $scope.page = {
            title: '',
            handle: '',
            body_html: '',
            parent: null,
            parent_page: {
                data: {
                    id: null,
                    title: null
                }
            }
        };
        $scope.focusPage = true;
        $scope.updateHandle = function (title) {
            $scope.page.handle = getSlug(title);
        }
    };

    $scope.data = null;
    $scope.savePage = function () {
        var data = $scope.page;
        $scope.data = null;
        $http.post('/api/pages', data).then(function onSuccess(response) {
            $("#addPage").modal('hide');
            $scope.focusAddCustomer = false;
            setTimeout(function() {
                $location.path("/pages/" + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.loadMorePages = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.loadMore = function() {
        $scope.refreshingPages = true;
        $http.get('/api/pages', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.pages.length) {
                $scope.pages = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingPages = false;
        });
    };

    $scope.getPages = function(val) {
        return $http.get('/api/pages', {
            params: {
                query: val
            }
        }).then(function(res){
            var results = [];
            angular.forEach(res.data.data, function(item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.setParentPage = function(item) {
        $scope.page.parent = item.id;
        $scope.page.parent_page.data.id = item.id;
        $scope.page.parent_page.data.title = item.title;
        $scope.pageAsync.value = item.title;
    };

    $scope.cleanParentPage = function() {
        $scope.page.parent = null;
        $scope.page.parent_page.data.id = null;
        $scope.page.parent_page.data.title = null;
        $scope.pageAsync.value = '';
    };

}]);

ctrl.controller('productArchiveCtrl', ["$scope", "$http", "$location", "$timeout", function($scope, $http, $location, $timeout) {
    $scope.loading = false;
    $scope.searchParams = $location.search();
    $scope.searchParams.include = 'categories';
    $scope.searchParams.take = '15';
    $scope.searchParams.is_archived = '1';

    var tempQueryString = '', queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            tempQueryString = newValue;
            queryTimeout = $timeout(function () {
                $scope.searchParams.query = tempQueryString;
                $location.search($scope.searchParams);
                $scope.change();
            }, 1000);
        }
    }, true);

    $scope.change = function() {
        $scope.loading = true;
        $http.get('/api/products', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.products = response.data.data;
            $scope.loading = false;
        });
    };

    $scope.change();
}]);

ctrl.controller('productDetailCtrl', ["$scope", "$routeParams", "$http", "$location", "Upload", "$timeout", "activeProfile", "$filter", "$window", "copyToClipboard", function ($scope, $routeParams, $http, $location, Upload, $timeout, activeProfile, $filter, $window, copyToClipboard) {

        $scope.goBack = function () {
            $window.history.back();
        };

        $scope.env = activeProfile.env;
        $scope.account_langs = activeProfile.account_languages.split(',');
        $scope.countActiveLangs = activeProfile.account_languages.split(',').length;
        $scope.loading = true;
        $scope.flag = false;
        $scope.flagPivot = false;
        $scope.flagRelation = false;
        $scope.flagMessage = null;
        $scope.data = null;
        $scope.selectedAll = false;
        $scope.loadingImages = false;
        $scope.shopCategories = [];
        $scope.role = activeProfile.type;
        $scope.assigned_shop = activeProfile.assigned_shop;

        $scope.productImages = null;
        $scope.product_id = 0;
        $scope.shop_id = 0;

        $scope.product = {
            group_id: 0,
            group_name: null,
            customisation_is_enabled: 0,
            customisation_help_text: '',
            show_active_orders: false,
            search_keywords: '',
            is_offer: 0,
            colors: null,
            is_active: true,
            inventory_status: true,
            inventory_date: null,
            expected_delivery: null,
            description: '',
            vendor_id: 0,
            vendor_sku: '',
            vendor_link: '',
            unspsc: ''
        };

        $scope.image = {
            id: '',
            src: '',
            src_md: '',
            src_og: ''
        };

        var total = 4;
        $scope.optionsList = [];
        for (var i = 0; i < total; i++) {
            $scope.optionsList.push(i);
        }

        $scope.options = [
            {id: 'title', title: $filter('translate')('TITLE')},
            {id: 'size', title: $filter('translate')('SIZE')},
            {id: 'color', title: $filter('translate')('COLOR_CODE')},
            {id: 'material', title: $filter('translate')('MATERIAL')},
            {id: 'style', title: $filter('translate')('STYLE')}
        ];

        $scope.colors = [
            {color: $filter('translate')('RED')},
            {color: $filter('translate')('BLUE')},
            {color: $filter('translate')('YELLOW')},
            {color: $filter('translate')('GREEN')},
            {color: $filter('translate')('BROWN')},
            {color: $filter('translate')('GREY')},
            {color: $filter('translate')('BLACK')},
            {color: $filter('translate')('WHITE')},
            {color: $filter('translate')('PINK')},
            {color: $filter('translate')('TURQUOISE')},
            {color: $filter('translate')('NATURE')},
            {color: $filter('translate')('PURPLE')},
            {color: $filter('translate')('ORANGE')},
            {color: $filter('translate')('SILVER')},
            {color: $filter('translate')('GOLD')},
            {color: $filter('translate')('BRONZE')},
            {color: $filter('translate')('STEEL')},
            {color: $filter('translate')('TRANSPARENT')},
        ];
        $scope.approvalStatuses = [
            {value: 0, title: $filter('translate')('STATUS_MISSING')},
            {value: 1, title: $filter('translate')('STATUS_CREATION')},
            {value: 2, title: $filter('translate')('STATUS_PROOFREADING')},
            {value: 3, title: $filter('translate')('STATUS_APPROVED')},
        ];
        $scope.mappedColors = function (arr) {
            if (arr) {
                return arr.map(function (obj) {
                    return obj.color;
                }).join(', ');
            }
            return
        }

        $http.get('/api/product-categories').then(function onSuccess(response) {
            $scope.productCategories = response.data;
            $scope.productCategories1 = $scope.productCategories.filter(function (productCategories) {
                return productCategories.type == 1;
            });
            $scope.productCategories2 = $scope.productCategories.filter(function (productCategories) {
                return productCategories.type == 2;
            });
            $scope.productCategories3 = $scope.productCategories.filter(function (productCategories) {
                return productCategories.type == 3;
            });
        });

        $scope.checkCategoryId = function (categoryId) {
            if (categoryId == null) return;

            $scope.tempArr = $scope.productCategories.filter(function (category) {
                return category.id == categoryId;
            })
            if ($scope.tempArr == undefined || $scope.tempArr == null) return;
            if ($scope.tempArr[0].type == 3) {
                $scope.product.category_id3 = $scope.tempArr[0].id;
            }
            if ($scope.tempArr[0].type == 2) {
                $scope.product.category_id2 = $scope.tempArr[0].id;
                $scope.changeCategoryOnParentId2($scope.product.category_id2, $scope.productCategories3);
            }
            if ($scope.tempArr[0].type == 1) {
                $scope.product.category_id1 = $scope.tempArr[0].id;
                $scope.changeCategoryOnParentId($scope.product.category_id1, $scope.productCategories2);
            }
            $scope.checkCategoryId($scope.tempArr[0].parent_id);
        }
        $scope.getProduct = function () {
            $scope.loading = true;
            $http.get('/api/products/' + $routeParams.productId + '?include=variants,variants.allocations,variants.guAllocation,options,images,categories,metafields,shops,shops.domains,relations').then(function onSuccess(response) {
                $scope.product = response.data.data;
                $scope.product.translations = {};
                $scope.product.meta = {};
                $scope.product.youtube = '';
                $scope.product.customisation_is_enabled = $scope.product.customisation_is_enabled == 1 ? '1' : '0';
                $scope.loading = false;
                $scope.checkCategoryId($scope.product.category_id);
                // Hiding the 'Farvekode' option if no hex-color is already chosen, enforcing the use of the new color system, but ensuring the legacy products still work.
                if ($scope.product.variants.data) {
                    var gotValue = false;
                    angular.forEach($scope.product.variants.data, function (variant) {
                            if (gotValue !== true && variant.color1 === null && variant.color2 === null && variant.color3 === null && variant.color4 === null && $scope.options['color'] !== null) {
                                $scope.options.splice(2, 1);
                                gotValue = true;
                                return;
                            }
                        }
                    )
                }
                if (activeProfile.account_languages.split(',').length) {
                    angular.forEach($scope.account_langs, function (langCode) {
                        var title = $filter('filter')($scope.product.metafields.data, {
                            key: "title",
                            namespace: langCode
                        }, undefined)[0];
                        var description = $filter('filter')($scope.product.metafields.data, {
                            key: "description",
                            namespace: langCode
                        }, undefined)[0];
                        title = (title !== undefined) ? title.value : '';
                        description = (description !== undefined) ? description.value : '';
                        $scope.product.translations[langCode] = {
                            title: title,
                            description: description
                        };

                        var meta_description = $filter('filter')($scope.product.metafields.data, {
                            key: "meta_description",
                            namespace: langCode
                        }, undefined)[0];
                        meta_description = (meta_description !== undefined) ? meta_description.value : '';
                        $scope.product.meta[langCode] = {
                            meta_description: meta_description,
                        };
                    });
                }

                var youtube = $filter('filter')($scope.product.metafields.data, {
                    key: "youtube",
                    namespace: 'general'
                }, undefined)[0];
                youtube = (youtube !== undefined) ? youtube.value : '';

                $scope.product.youtube = youtube;
            }, function onError(response) {
                $scope.loading = false;
                $scope.data = response.data;
                $scope.goBack();
            });


        };

        $scope.getProduct();
        $http.get('js/countries.json').then(function onSuccess(response) {
            $scope.countryList = response.data;
        });

        $scope.changeCategoryOnParentId = function ($parentId, $arr) {
            $scope.filteredCategories2 = $arr.filter(function ($arr) {
                return $arr.parent_id == $parentId;
            })
        };
        $scope.changeCategoryOnParentId2 = function ($parentId, $arr) {
            $scope.filteredCategories3 = $arr.filter(function ($arr) {
                return $arr.parent_id == $parentId;
            })
        };

        $http.get('/api/vendors?take=9999').then(function onSuccess(response) {
            $scope.vendors = response.data.data;
            $scope.vendors.splice(0, 0, {'id': 0, 'name': 'Vælg leverandør'});
        });

        $scope.qualityStampApprove = function (value) {
            $scope.product.quality_stamp_approved_at = value;
            if (value === 0) {
                $scope.product.approval_status = 0;
            }
            if (value === 1) {
                $scope.product.approval_status = 3;
            }

            $timeout(function () {
                angular.element('#submitProduct').trigger('click');
            }, 100);
        };

        $scope.saveProduct = function (isValid) {
            if (isValid) {
                var data = $scope.product;
                $scope.data = null;
                $http.put('/api/products/' + $scope.product.id, data).then(function onSuccess(response) {
                    $scope.getProduct();
                    $scope.getProductTags();

                    $scope.flagMessage = response.data.message;
                    $timeout(function () {
                        $scope.flagMessage = null;
                    }, 3000);
                }, function onError(response) {
                    $scope.data = response.data;
                });
            }
        };

        $scope.variant = {
            product_id: '',
            number: '',
            inventory_management: 'ignore',
            grams: '',
            inventory_policy: '',
            inventory_quantity: '',
            inventory_start: '',
            inventory_reorder: '',
            inventory_offer: '',
            old_inventory_quantity: 0,
            position: 0,
            price: 0,
            cost_price: 0,
            compare_at_price: 0,
            option1: '',
            option2: '',
            option3: '',
            option4: '',
            color1: '#000000',
            color2: '#000000',
            color3: '#000000',
            color4: '#000000',
            required_shipping: '',
            sku: '',
            taxable: '',
            title: '',
            allocate: '0',
            quantity_management: 'ignore',
            minimum: 0,
            maximum: 0,
            steps: '',
            width: '',
            height: 0,
            length: 0,
            volume: 0,
            weight_factor: 0,
            country_of_origin: null,
            hs_code: null,
            step_prices: false,
            step1_price: '',
            step2_price: '',
            step3_price: '',
            step1_qty: '',
            step2_qty: '',
            step3_qty: '',
            deduct_price_on_checkout: 0
        };

        $scope.addVariant = function () {
            $scope.variant = {
                product_id: $scope.product.id,
                number: $scope.product.number,
                inventory_management: 'ignore',
                grams: '',
                inventory_policy: '',
                inventory_quantity: '',
                inventory_start: '',
                inventory_reorder: '',
                inventory_offer: '',
                old_inventory_quantity: 0,
                position: 0,
                price: $scope.product.variants.data[0].price,
                cost_price: 0,
                compare_at_price: 0,
                option1: '',
                option2: '',
                option3: '',
                option4: '',
                color1: '#000000',
                color2: '#000000',
                color3: '#000000',
                color4: '#000000',
                required_shipping: '',
                sku: '',
                taxable: '',
                title: '',
                allocate: '0',
                quantity_management: 'ignore',
                minimum: 0,
                maximum: 0,
                steps: '',
                width: '',
                height: 0,
                length: 0,
                volume: 0,
                weight_factor: 0,
                country_of_origin: null,
                hs_code: null,
                step_prices: false,
                step1_price: '',
                step2_price: '',
                step3_price: '',
                step1_qty: '',
                step2_qty: '',
                step3_qty: '',
                deduct_price_on_checkout: 0
            };
            $scope.focusAddVariant = true;
        };

        $scope.saveNewVariant = function () {
            var data = $scope.variant;
            data.price = data.price || 0;
            data.cost_price = data.cost_price || 0;
            data.compare_at_price = data.compare_at_price || 0;
            $scope.variant = data;
            $http.post('/api/variants', data).then(function onSuccess(response) {
                $scope.variant.id = response.data.created_id;
                $scope.product.variants.data.push($scope.variant);
                $scope.focusAddVariant = false;
                $('#addVariant').modal('hide');
            }, function onError(response) {
                $scope.data = response.data;
            });
        };

        $scope.editVariant = function (idx) {
            $scope.idx = idx;
            $scope.variant = angular.copy($scope.product.variants.data[$scope.idx]);
            $scope.focusEditVariant = true;
        };

        $scope.editColors = function () {
            $scope.focusEditColors = true;
        };
        $scope.confirmDeleteVariant = function (idx) {
            $scope.idx = idx;
        };

        $scope.deleteVariant = function (idx) {
            $http.delete('/api/variants/' + $scope.product.variants.data[idx].id).then(function () {
                $scope.product.variants.data.splice(idx, 1);
                $('#deleteVariant').modal('hide');
            });
        };

        $scope.addAllocateShop = null;
        $scope.editAllocations = function (variantIndex, variant, shops) {
            $scope.variantIndex = variantIndex;
            $scope.variant = variant;
            $scope.shops = shops;
            $scope.addAllocateShop = null;
            $scope.focusEditAllocations = true;
            $scope.calculateVariantsTotals();

            angular.forEach($scope.variant.allocations.data, function (item, idx) {
                $scope.variant.allocations.data[idx].customer_count = 0;
                $scope.variant.allocations.data[idx].order_ratio = 0;
                $scope.variant.allocations.data[idx].product_count = 0;
                $scope.getShopInfo(idx, item.shop_id, item.variant_id);
            });
        };


        $scope.addGaveuniversetToAllocationList = function () {
            $http.post('/api/allocations/gu', {variant_id: $scope.variant.id}).then(function onSuccess(response) {
                $scope.flagMessage = response.data.message;
                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);
                let item = {
                    'id': response.data.created_id,
                    'variant_id': $scope.variant.id,
                    'quantity': 20,
                    'start': 20,
                    'reorder': 10
                };
                $scope.variant.guAllocation = {
                    data: item
                };
            }, function onError() {
                alert('Allokeringen til shoppen er allerede oprettet');
            });
        };

        $scope.deleteGuAllocation = function (allocationId) {
            $http.delete('/api/allocations/gu/' + allocationId).then(function () {
                $scope.variant.guAllocation.data = null;
            });
        };

        $scope.addShopToAllocationsList = function (shop) {
            if (shop !== null && typeof shop.id !== 'undefined') {
                $http.post('/api/allocations', {
                    shop_id: shop.id,
                    variant_id: $scope.variant.id
                }).then(function onSuccess(response) {
                    $scope.flagMessage = response.data.message;
                    $timeout(function () {
                        $scope.flagMessage = null;
                    }, 3000);
                    var item = {
                        'id': response.data.created_id,
                        'variant_id': $scope.variant.id,
                        'shop_title': shop.title,
                        'shop_id': shop.id,
                        'quantity': response.data.data.quantity,
                        'start': response.data.data.start,
                        'reorder': response.data.data.reorder,
                        'offer': response.data.data.offer,
                    };
                    $scope.variant.allocations.data.push(item);

                    var idx = $scope.variant.allocations.data.length - 1;
                    $scope.getShopInfo(idx, shop.id, $scope.variant.id);

                }, function onError() {
                    alert('Allokeringen til shoppen er allerede oprettet');
                });
            }
        };

        $scope.saveAllocations = function (variant) {
            angular.forEach(variant.allocations.data, function (item) {
                $http.put('/api/allocations/' + item.id, item);
            });

            $http.put('api/allocations/gu/'+ variant.id, variant.guAllocation.data)
        };

        $scope.updateVariant = function (variant) {
            $scope.data = [];
            $http.put('/api/variants/' + variant.id, variant).then(function onSuccess(response) {
                $scope.flagMessage = response.data.message;
                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);
                $scope.product.variants.data[$scope.idx] = $scope.variant;
                $("#editVariant").modal('hide');
                $(".modal-backdrop").hide(500).remove();
            }, function onError(response) {
                $scope.data = response.data;
            });
        };

        $scope.deleteAllocation = function (variantIndex, allocationIndex, allocationId) {
            $http.delete('/api/allocations/' + allocationId).then(function () {
                $scope.product.variants.data[variantIndex].allocations.data.splice(allocationIndex, 1);
            });
        };

        $scope.editShopPrices = function (variant) {
            $scope.loadingShopPrices = true;
            $scope.variantId = variant;
            $http.get('/api/variants/' + variant + '/shop-prices').then(function onSuccess(response) {
                $scope.shops = response.data;
                $scope.loadingShopPrices = false;
            });
        };

        $scope.updateShopPrices = function (variant, prices) {
            $http.put('/api/variants/' + variant + '/shop-prices', {shops: prices});
        };

        $scope.deleteProduct = function () {
            $http.delete('/api/products/' + $scope.product.id).then(function () {
                $("#deleteProduct").modal('hide');
                setTimeout(function () {
                    $location.path("/products");
                }, 300);
            });
        };

        $scope.editOptions = function () {
            $scope.focusEditOptions = true;
        };

        $scope.updateOption = function (value, option) {
            if (value === 'disabled') {
                angular.forEach($scope.product.variants.data, function (value) {
                    value[option] = '';
                });
            }
        };

        $scope.getCategories = function (val) {
            let params = {
                query: val,
                is_active: 1,
            };

            if ($scope.role == 'p_editor') {
                params.shop_id = $scope.assigned_shop;
            }
            return $http.get('/api/categories', {
                params: params
            }).then(function (res) {
                var results = [];
                angular.forEach(res.data.data, function (item) {
                    results.push(item);
                });
                return results;
            });
        };

        $scope.populateShopCategoriesSelection = function () {
            if ($scope.shopCategories.length) {
                angular.forEach($scope.shopCategories, function (item) {
                    item.selected = $scope.product.categories.data.filter(function (object) {
                        return object.id === item.id;
                    }).length > 0;
                });
            }
        };

        $scope.addCategory = function (item) {
            var categoryId = item.id;
            $http.post('/api/categories/' + categoryId + '/products/' + $scope.product.id).then(function onSuccess() {
                $scope.product.categories.data.push(item);
                $scope.populateShopCategoriesSelection();
            }, function onError() {
                $scope.flagPivot = true;
                $timeout(function () {
                    $scope.flagPivot = false;
                }, 3000);
            });
        };

        $scope.deleteCategory = function (idx) {
            var categoryId = $scope.product.categories.data[idx].id;
            $http.delete('/api/categories/' + categoryId + '/products/' + $scope.product.id).then(function onSuccess() {
                $scope.product.categories.data.splice(idx, 1);
                $scope.populateShopCategoriesSelection();
            }, function onError() {
                $scope.flagPivot = true;
                $timeout(function () {
                    $scope.flagPivot = false;
                }, 3000);
            });
        };

        $scope.confirmDeleteImage = function (idx) {
            $scope.idx = idx;
        };

        $scope.deleteImage = function (idx) {
            $http.delete('/api/images/' + $scope.product.images.data[idx].id).then(function () {
                $scope.product.images.data.splice(idx, 1);
                $("#deleteImage").modal('hide');
            });
        };

        $scope.placeholderImages = [];

        $scope.loadProductImages = function () {
            $scope.product.images.data = [];
            $http.get('/api/products/' + $routeParams.productId + '/images').then(function onSuccess(response) {
                $scope.product.images.data = response.data.data;
                if ($scope.placeholderImages.length && $scope.product.images.data.length) {
                    angular.forEach($scope.placeholderImages, function (placeholderImage) {
                        angular.forEach($scope.product.images.data, function (productImage, index) {
                            if (placeholderImage.id === productImage.id) {
                                $scope.product.images.data[index].src = placeholderImage.src;
                            }
                        });
                    });
                    $scope.placeholderImages = [];
                }
            });
        };

        $scope.refreshProductImages = function () {
            $scope.product.images.data = [];
            $scope.loadingImages = true;
            $timeout(function () {
                $http.get('/api/products/' + $routeParams.productId + '/images').then(function onSuccess(response) {
                    $scope.product.images.data = response.data.data;
                    $scope.loadingImages = false;
                });
            }, 3000);
        };


        $scope.onFileSelect = function ($files) {
            $scope.product.images.data = [];
            var $totalFiles = $files.length;
            var $indexFile = 0;
            $scope.loadingImages = true;
            angular.forEach($files, function (file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = function (e) {
                    Upload.http({
                        url: '/api/images',
                        data: {
                            owner_id: $scope.product.id,
                            owner_resource: 'product',
                            fileName: file.name,
                            fileSize: file.size,
                            fileType: file.type,
                            file: e.target.result.match(/,(.*)$/)[1]
                        }
                    }).then(function (response) {
                        $scope.placeholderImages.push({id: response.data.created_id, src: e.target.result});
                        $indexFile += 1;
                        if ($totalFiles === $indexFile) {
                            $scope.loadProductImages();
                            $scope.loadingImages = false;
                        }
                    });
                }
            });
        };

        $scope.copyProduct = function () {
            $http.post('/api/products', {
                source: $scope.product.id
            }).then(function onSuccess(response) {
                $location.path('/products/' + response.data.created_id);
            });
        };

        $scope.sortableOptions = {
            cursor: 'move',
            handle: '.handle'
        };

        $scope.sortableOptionsVariants = {
            axis: 'y',
            cursor: 'move',
            handle: '.handle',
            cancel: '',
            'ui-floating': false,
            stop: function () {
                var order = [];
                angular.forEach($scope.product.variants.data, function (item) {
                    order.push(item.id);
                });
                $http.post('/api/variants/order', {variantOrder: order}).then(function () {
                }, function onError() {
                });
            }
        };

        $scope.checkAll = function () {
            $scope.selectedAll = !$scope.selectedAll;
            $scope.showDuplicate = false;
            angular.forEach($scope.product.variants.data, function (item) {
                item.selected = $scope.selectedAll;
                if (item.selected === true) $scope.showDuplicate = true;
            });
        };

        $scope.showDuplicate = false;
        $scope.checkOne = function () {
            $scope.showDuplicate = false;
            angular.forEach($scope.product.variants.data, function (item) {
                if (item.selected === true) $scope.showDuplicate = true;
            });
        };

        $scope.duplicateVariants = function () {
            var checked = [];
            angular.forEach($scope.product.variants.data, function (item) {
                if (item.selected === true) checked.push(item.id);
            });
            $scope.duplicate = {option: $scope.product.options.data[1].name, title: '', ids: checked}
        };

        $scope.saveDuplicates = function () {
            $http.post('/api/variants/duplicate', $scope.duplicate).then(function () {
                $http.get('/api/products/' + $routeParams.productId + '/variants').then(function onSuccess(response) {
                    $scope.product.variants.data = response.data.data;
                });
            });
        };

        $scope.archiveProduct = function () {
            $scope.product.is_archived = $scope.product.is_archived !== true;
            $timeout(function () {
                angular.element('#submitProduct').trigger('click');
            }, 100);
        };

        $scope.editVariation = function (productId, shopId, namespace) {
            $scope.loadingVariation = true;
            $http.get('/api/products/' + productId + '/variations/' + shopId + '?namespace=' + namespace).then(function onSuccess(response) {
                $scope.variation = response.data;
                $scope.loadingVariation = false;
            });
        };

        $scope.updateVariation = function (variation) {
            $http.put('/api/products/' + variation.product_id + '/variations/' + variation.shop_id, variation);
        };

        $scope.selectVariantImage = function (variant) {
            $scope.variant = variant;
        };

        $scope.getImageSrc = function (imageId) {
            if ($scope.product.images.data.length > 0 && imageId > 0) {
                var image = $filter('filter')($scope.product.images.data, {id: imageId}, undefined);
                if (typeof image[0] !== 'undefined' && typeof image[0].src !== 'undefined') {

                    return image[0].src;
                }

                return 'https://placehold.it/80';
            }
        };

        $scope.showOffersToggles = false;

        $scope.toggleOffersImages = function () {
            $scope.showOffersToggles = $scope.showOffersToggles !== true;
        };

        $scope.toggleOffersImage = function (idx) {
            $scope.product.images.data[idx].key = $scope.product.images.data[idx].key === null ? 'offer' : null;
            $http.put('/api/products/' + $routeParams.productId + '/images/' + $scope.product.images.data[idx].id + '/toggle-offer');
        };

        $scope.variant_id = null;

        $scope.editCalculations = function (variantId) {
            $scope.variant_id = null;
            $scope.loadingCalculations = true;
            $http.get('/api/variants/' + variantId + '/calculations').then(function onSuccess(response) {
                $scope.calculations = response.data;
                $scope.variant_id = variantId;
                $scope.loadingCalculations = false;
                $scope.getTotals(variantId);
            });
        };

        $scope.addCalculation = function (variantId) {
            $scope.calculation = {
                variant_id: variantId,
                vendor_id: '',
                vendors_product_id: '',
                title: '',
                normal_price: 0,
                purchase_price: 0,
                discount_percentage: 0
            };
        };

        $scope.saveNewCalculation = function () {
            var data = $scope.calculation;
            $http.post('/api/calculations', data).then(function onSuccess(response) {
                if (response.status === 201) {
                    $scope.calculation.id = response.data.created_id;
                    $scope.calculation.cost_price = (($scope.calculation.purchase_price - (($scope.calculation.purchase_price * $scope.calculation.discount_percentage / 100))) * 1.07).toFixed(4);
                    $scope.calculation.sales_price = ($scope.calculation.cost_price * 1.42).toFixed(4);
                    if ($scope.calculation.sales_price > 0) {
                        $scope.calculation.coverage_ratio = ((($scope.calculation.sales_price - $scope.calculation.cost_price) / $scope.calculation.sales_price) * 100).toFixed(4);
                    } else {
                        $scope.calculation.coverage_ratio = 0;
                    }
                    $scope.calculations.data.push($scope.calculation);
                    $scope.getTotals($scope.calculation.variant_id);
                    $('#addCalculation').modal('hide');
                } else {
                    window.location = '/';
                }
            });
        };

        $scope.deleteCalculation = function (idx) {
            $http.delete('/api/calculations/' + $scope.calculations.data[idx].id).then(function () {
                $scope.calculations.data.splice(idx, 1);
                $scope.getTotals($scope.variant_id);
            });
        };

        $scope.loadingCalculationsTotals = false;

        $scope.getTotals = function (variantId) {
            $scope.totals = {
                purchase_price: 0,
                cost_price: 0,
                sales_price: 0,
                coverage_ratio: 0
            };
            $scope.loadingCalculationsTotals = true;
            setTimeout(function () {
                $http.get('/api/variants/' + variantId + '/calculations/totals').then(function onSuccess(response) {
                    $scope.totals = response.data.data;
                    $scope.loadingCalculationsTotals = false;
                }, function onError() {
                    $scope.totals = {
                        purchase_price: 0,
                        cost_price: 0,
                        sales_price: 0,
                        coverage_ratio: 0
                    };
                    $scope.loadingCalculationsTotals = false;
                });
            }, 500);
        };

        $scope.loadingCalculation = false;

        $scope.editCalculation = function (idx) {
            $scope.loadingCalculation = true;
            $http.get('/api/calculations/' + $scope.calculations.data[idx].id).then(function onSuccess(response) {
                $scope.calculation = response.data.data;
                $scope.loadingCalculation = false;
            });
        };

        $scope.updateCalculation = function (calculation) {
            $http.put('/api/calculations/' + calculation.id, calculation).then(function () {
                angular.forEach($scope.calculations.data, function (data, idx) {
                    if (data.id === calculation.id) {
                        $scope.calculations.data[idx] = calculation;
                    }
                });
                $scope.getTotals($scope.variant_id);
                $('#editCalculation').modal('hide');
            });
        };

        $scope.getProducts = function (val) {
            return $http.get('/api/products', {
                params: {
                    query: val
                }
            }).then(function (res) {
                var results = [];

                angular.forEach(res.data.data, function (item) {
                    var format_product_sku = '';
                    if (item.variants_sku) {
                        format_product_sku = '(' + item.variants_sku.split(';') + ')';
                    }

                    item.formatted_title_search = item.title + ' ' + format_product_sku;

                    results.push(item);
                });
                return results;
            });
        };

        $scope.addRelatedProduct = function (item) {
            var productId = $scope.product.id;
            var relationId = item.id;
            $http.post('/api/products/' + productId + '/relations/' + relationId).then(function onSuccess(response) {
                if (response.status === 201) {
                    $scope.product.relations.data.push(item);
                }
            }, function onError() {
                $scope.flagRelation = true;
                $timeout(function () {
                    $scope.flagRelation = false;
                }, 3000);
            });
        };

        $scope.deleteRelatedProduct = function (idx) {
            var productId = $scope.product.id;
            var relationId = $scope.product.relations.data[idx].id;
            $http.delete('/api/products/' + productId + '/relations/' + relationId).then(function () {
                $scope.product.relations.data.splice(idx, 1);
            });
        };

        $scope.variantTotals = {
            totalStart: 0,
            totalQuantity: 0,
            totalReorder: 0,
            totalOffer: 0
        };

        $scope.calculateVariantsTotals = function () {
            var totalStart = 0;
            var totalQuantity = 0;
            var totalReorder = 0;
            var totalOffer = 0;
            for (var i = 0; i < $scope.variant.allocations.data.length; i++) {
                var quantity = $scope.variant.allocations.data[i];
                totalStart += quantity.start;
                totalQuantity += quantity.quantity;
                totalReorder += quantity.reorder;
                totalOffer += quantity.offer;
            }
            $scope.variantTotals = {
                totalStart: totalStart,
                totalQuantity: totalQuantity,
                totalReorder: totalReorder,
                totalOffer: totalOffer
            };
        };

        $scope.checkIfOptionIsUsed = function (option, index) {
            var used = false;
            for (var i = 0; i < 4; i++) {
                if (i !== index) {
                    if (typeof $scope.product.options !== 'undefined' && $scope.product.options.data[i].name === option) {
                        used = true;
                    }
                }
            }

            return used;
        };

        $scope.editImage = function () {

        };

        $scope.editImages = function (shop_id) {
            $scope.productImages = null;
            $scope.product_id = $scope.product.id;
            $scope.shop_id = shop_id;
            $http.get('/api/products/' + $scope.product_id + '/shops/' + $scope.shop_id + '/images').then(function (response) {
                $scope.productImages = response.data.data;
            }, function onError(response) {
                $scope.data = response.data.data;
            });
        };

        $scope.updateImageOptions = function () {
            $http.post('/api/products/' + $scope.product_id + '/shops/' + $scope.shop_id + '/images', {images: $scope.productImages}).then(function (response) {
                $scope.flagMessage = response.data.message;
                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);
            }, function onError(response) {
                $scope.data = response.data.data;
            });
        };

        $scope.sortableImagesOptions = {
            cursor: 'move',
            handle: '.image-handle',
            stop: function () {
                $scope.updateImageOptions();
            }
        };

        $scope.hideImage = function (idx) {
            $scope.productImages[idx].is_active = false;
            $scope.updateImageOptions();
        };

        $scope.showImage = function (idx) {
            $scope.productImages[idx].is_active = true;
            $scope.updateImageOptions();
        };

        $scope.getShopInfo = function (idx, shop_id, variant_id) {
            $scope.variant.allocations.data[idx].customer_count = 0;
            $scope.variant.allocations.data[idx].order_ratio = 0;
            $scope.variant.allocations.data[idx].product_count = 0;
            $http.get('api/shops/' + shop_id + '/variants/' + variant_id + '/info').then(function onSuccess(response) {
                $scope.variant.allocations.data[idx].customer_count = response.data.data.customer_count;
                $scope.variant.allocations.data[idx].order_ratio = response.data.data.order_ratio;
                $scope.variant.allocations.data[idx].product_count = response.data.data.product_count;
            }, function onError() {
                // error
            });
        };

        $scope.toggleSustainability = function (idx) {
            var $data = $scope.product.shops.data[idx].pivot;
            $http.post('/api/products/' + $data.product_id + '/shops/' + $data.shop_id + '/pivot', $data).then(function (response) {
            }, function onError(response) {
            });
        };

        $scope.toggleAll = function (value, key) {
            let requestData = [];
            $scope.product.shops.data.forEach((data) => {
                data.pivot[key] = value.target.checked;
                requestData.push(data.pivot);
            });

            $http.post('/api/products/' + $scope.product.id + '/shops/all/pivot', {'shops': requestData}).then(function (response) {
            }, function onError(response) {
            });
        };

        $scope.toggleExclusivity = function (idx) {
            var $data = $scope.product.shops.data[idx].pivot;
            $http.post('/api/products/' + $data.product_id + '/shops/' + $data.shop_id + '/pivot', $data).then(function (response) {
            }, function onError(response) {
            });
        };

        $scope.toggleNew = function (idx) {
            var $data = $scope.product.shops.data[idx].pivot;
            $http.post('/api/products/' + $data.product_id + '/shops/' + $data.shop_id + '/pivot', $data).then(function (response) {
            }, function onError(response) {
            });
        };

        $scope.datetimePickerConfig1 = {
            dropdownSelector: '#dropdown1',
            startView: 'year',
            minView: 'minute',
            minuteStep: 1
        };

        $scope.datetimePickerConfig2 = {
            dropdownSelector: '#dropdown2',
            startView: 'year',
            minView: 'day',
        };

        $scope.datetimeFormatted1 = '';
        $scope.datetimeFormatted2 = '';

        $scope.$watch('product.inventory_date', function (newValue) {
            $scope.datetimeFormatted1 = moment(newValue).format('L LT');
        }, true);

        $scope.$watch('product.expected_delivery', function (newValue) {
            if (typeof newValue !== "object" || newValue !== null) {
                $scope.datetimeFormatted2 = moment(newValue).format('L');
            } else {
                $scope.datetimeFormatted2 = '';
            }
        }, true);

        $scope.copyToClipboard = function (idx) {
            var source = $scope.product.images.data[idx].src_og;

            if (String(source).indexOf('//') === 0) {
                source = 'https:' + source;
            }
            if (String(source).indexOf('/uploads/') === 0) {
                source = window.location.hostname + source;
            }

            copyToClipboard(source);

            $scope.flagMessage = $filter('translate')('IMAGE_COPIED');
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
        };

        // Tag management
        $scope.tag = '';
        $scope.loadingTags = false;

        $scope.getTags = function (val) {
            $scope.loadingTags = true;

            return $http.get('/api/tags', {
                params: {
                    query: val,
                    order_by: 'name'
                }
            }).then(function (res) {
                var results = [];
                angular.forEach(res.data.data, function (item) {
                    results.push(item);
                });
                $scope.loadingTags = false;

                return results;
            });
        };

        $scope.tags = [];

        $scope.getProductTags = function () {
            $http.get('/api/products/' + $routeParams.productId + '/tags').then(function (response) {
                $scope.tags = response.data.data;
            });
        };
        $scope.getProductTags();

        $scope.setTag = function (item) {
            $http.put('/api/products/' + $routeParams.productId + '/tags', item).then(function onSuccess(response) {
                $scope.flagMessage = response.data.message;
                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);
                $scope.getProductTags();
            }, function onError(response) {
                $scope.data = response.data;
            });
        };

        $scope.removeTag = function (idx) {
            $http.delete('/api/products/' + $routeParams.productId + '/tags/' + $scope.tags[idx].id).then(function () {
                $scope.tags.splice(idx, 1);
            });
        };

        $scope.selectTag = function (item) {
            $scope.tag = item;
        };

        $scope.addTag = function () {
            if (String($scope.tag).length > 1) {
                var item = {'id': 0, 'name': $scope.tag};
                $http.put('/api/products/' + $routeParams.productId + '/tags', item).then(function onSuccess(response) {
                    $scope.flagMessage = response.data.message;
                    $timeout(function () {
                        $scope.flagMessage = null;
                    }, 3000);
                    $scope.getProductTags();
                }, function onError(response) {
                    $scope.data = response.data;
                });
            }
        };

        $scope.loadingShops = false;

        $scope.getShops = function (val) {
            return $http.get('/api/shops', {
                params: {
                    query: val
                }
            }).then(function (res) {
                var results = [];
                angular.forEach(res.data.data, function (item) {
                    results.push(item);
                });
                return results;
            });
        };

        $scope.shop = null;
        $scope.selectShop = function (item) {
            $scope.shop = item;
            $scope.listShopCategories($scope.shop.id);
        };

        $scope.listShopCategories = function (shopId) {
            $scope.catLoader = true;
            $http.get('/api/shops/' + shopId + '/categories').then(function onSuccess(response) {
                $scope.shopCategories = response.data.data;
                angular.forEach($scope.shopCategories, function (item) {
                    item.selected = false;
                });
                $scope.populateShopCategoriesSelection();
            });
        };

        $scope.changeShopCategory = function (idx) {
            var item = $scope.shopCategories[idx];
            if (item.selected) {
                $http.post('/api/categories/' + item.id + '/products/' + $scope.product.id).then(function onSuccess(response) {
                    $scope.product.categories.data.push(item);
                }, function onError() {
                    $scope.flagPivot = true;
                    $timeout(function () {
                        $scope.flagPivot = false;
                    }, 3000);
                });
            } else {
                $http.delete('/api/categories/' + item.id + '/products/' + $scope.product.id).then(function onSuccess() {
                    angular.forEach($scope.product.categories.data, function (category, index) {
                        if (category.id === item.id) {
                            $scope.product.categories.data.splice(index, 1);
                        }
                    });
                }, function onError() {
                    $scope.flagPivot = true;
                    $timeout(function () {
                        $scope.flagPivot = false;
                    }, 3000);
                });
            }
        };

        $scope.setDirty = function (element) {
            element.isTitleDirty = element.option1 !== element.oldTitle;
            $scope.toggleTitleError = null;
            if (element.isTitleDirty && element.oldTitle.length >= 30 || element.option1.length == 30) {
                $scope.toggleTitleError = true;
            } else {
                $scope.toggleTitleError = false;
            }

        };

        $scope.initOldTitle = function (element) {
            element.oldTitle = element.option1;
        }
    }]
)
;

ctrl.controller('productImportCtrl', ["$scope", "$http", "$location", "$filter", "Upload", "$timeout", function($scope, $http, $location, $filter, Upload, $timeout) {
    $scope.csvLoaded = false;
    $scope.importData = [];
    $scope.data = null;
    $scope.productImport = null;
    var queryTimeout;

    $scope.$on('$stateChangeStart', function() {
        if (queryTimeout) $timeout.cancel(queryTimeout);
    });

    $scope.$on('$destroy', function() {
        if (queryTimeout) $timeout.cancel(queryTimeout);
    });

    $scope.importProduct = function(url, id) {
        $http.get(url).then(function(response) {
            setCSVData(response)
        }, function() {
            $http.get('/api/files/'+id).then(function (data) {
                $http.get(data.data.data.src).then(function(response) {
                    setCSVData(response);
                });
            });
        });
    };

    // Item order matters
    // Do not change
    $scope.defaultData = [
        'title',
        'sku',
        'vendor_sku',
        'vendor_name',
        'vendor_link',
        'price',
        'cost_price',
        'compare_at_price',
        'step1_qty',
        'step1_price',
        'step2_qty',
        'step2_price',
        'step3_qty',
        'step3_price',
        'description',
        'specifications',
        'notes',
        'tags',
        'image1',
        'image2',
        'image3',
        'image4',
        'image5',
        'image6',
        'image7',
        'image8',
        'image9',
        'is_offer',
        'english_title',
        'english_description'
    ];

    $scope.defaultColumns = [];
    angular.forEach($scope.defaultData, function(column) {
        $scope.defaultColumns.push($filter('translate')(String(column).toUpperCase()));
    });

    function setCSVData(response) {
        $scope.setUpVariables();
        if (typeof response.data !== 'undefined') {
            $scope.csv = response.data;
            $scope.csvLoaded = true;
            $scope.importData =  CSVToArray(response.data);
            $scope.firmvaluePosition = [];
            for (var i = 0; i < $scope.defaultData.length; i++) {
                $scope.firmvaluePosition.push(0);
            }
            $scope.loadData = $scope.importData;
            $scope.selectedValue = [];
            $scope.selectedValue.push({ val: 0, text: $filter('translate')('EMPTY') });
            $scope.selectedValue.push({ val: 1, text: $filter('translate')('FIXED') });
            for (var j = 2 ; j < $scope.importData[0].length+2; j++) {
                $scope.selectedValue.push({ val: j, text: $filter('translate')('COL') + ' ' + (j - 1) });
            }

            angular.forEach($scope.defaultData, function(value, index) {
                $scope[value] = ($scope.loadData[0].length > index) ? index : -1;
            });

            angular.forEach($scope.defaultData, function(value, index) {
                var valueSelect = value + 'Select';
                $scope[valueSelect] = ($scope.loadData[0].length > index) ? (index + 2) : 0;
            });
        }
    }

    $scope.updateColumnFirm = function(i, val) {
        angular.forEach($scope.defaultData, function(value, index) {
            if (i === index) {
                angular.forEach($scope.loadData, function(row) {
                    if ($scope.firmvaluePosition[i]===0 || typeof $scope.firmvaluePosition[i] === 'undefined') {
                        $scope[value] = row.length;
                        $scope.firmvaluePosition[i] = row.length;
                    } else {
                        $scope[value] = $scope.firmvaluePosition[i];
                    }
                    row[$scope[value]] = val;
                });
            }
        });
    };

    $scope.updateColumn = function(i, val) {
        angular.forEach($scope.defaultData, function(value, index) {
            if (i === index) {
                var columnShow = value + 'Show';
                if (val === 1) {
                    $scope[columnShow] = true;
                } else {
                    $scope[columnShow] = false;
                    $scope[value] = val - 2;
                }
            }
        });

        $scope.firmrowShow = false;
        angular.forEach($scope.defaultData, function(value) {
            var columnShow = value + 'Show';
            if ($scope[columnShow]) {
                $scope.firmrowShow = true;
            }
        });
    };

    $scope.importError = false;
    $scope.submitImport = function() {
        var $postData = {
            csv: $scope.importData
        };
        angular.forEach($scope.defaultData, function(value) {
            $postData[value] = $scope[value];
        });
        $scope.loading = true;
        $scope.csvLoaded = false;
        $scope.importError = false;
        $http.post('/api/products', $postData).then(function onSuccess(response) {
            $scope.loading = false;
            $scope.success = true;
            $scope.productImport = response.data.data;
            if (queryTimeout) $timeout.cancel(queryTimeout);
            if ($scope.productImport) {
                $scope.checkStatus($scope.productImport.job_id);
            }
        }, function onError(response) {
            $scope.loading = false;
            $scope.success = false;
            $scope.data = response.data;
            $scope.importError = true;
        });
    };

    $scope.onFileSelect = function($files) {
        if (queryTimeout) $timeout.cancel(queryTimeout);
        $scope.loading = true;
        $scope.success = false;
        $scope.importError = false;
        angular.forEach($files, function(file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
                Upload.http({
                    url: '/api/files',
                    data: {
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function(response) {
                    if (response.status === 201) {
                        $scope.loading = false;
                        $scope.importProduct(response.data.file_path, response.data.created_id);
                    }
                });
            }
        });
    };

    function CSVToArray(strData, strDelimiter) {
        strDelimiter = (strDelimiter || ";");
        var objPattern = new RegExp(("(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" + "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi");
        var arrData = [[]];
        var arrMatches = null;
        if (arrMatches === null) {} // Lazy fix for variable definition validation
        while (arrMatches = objPattern.exec(strData)) {
            var strMatchedDelimiter = arrMatches[1];
            if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
                arrData.push([]);
            }
            var strMatchedValue;
            if (!arrMatches.index && arrMatches[0].charAt(0) === strDelimiter) {
                arrData[arrData.length - 1].push('');
            }
            if (typeof arrMatches[2] !== 'undefined') {
                strMatchedValue = arrMatches[2].replace(new RegExp( "\"\"", "g" ), "\"");
            } else {
                strMatchedValue = arrMatches[3];
            }
            arrData[arrData.length - 1].push(strMatchedValue);
        }

        return arrData;
    }

    $scope.checkStatus = function(job_id) {
        $http.get('/api/import-products/'+job_id).then(function onSuccess(response) {
            $scope.productImport = response.data.data;
            if ($scope.productImport.job_status !== 'completed' && $scope.productImport.job_status !== 'cancelled') {
                queryTimeout = $timeout(function() {
                    $scope.checkStatus(job_id);
                }, 2000);
            }
        }, function onError(response) {
            console.log(response.data);
        });
    };

    $scope.jobs = [];
    $scope.loadingJobList = true;
    $scope.showJobList = function() {
        $scope.loadingJobList = true;
        $http.get('/api/import-products').then(function onSuccess(response) {
            $scope.jobs = response.data.data;
            $scope.loadingJobList = false;
        }, function onError(response) {
            $scope.loadingJobList = false;
            console.log(response.data);
        });
    };

    $scope.refreshJobs = function() {
        $scope.showJobList();
    };

    $scope.setUpVariables = function() {
        angular.forEach($scope.defaultData, function(value) {
            var valueFirm = value + 'Firm';
            var valueShow = value + 'Show';
            $scope[valueFirm] = null;
            $scope[valueShow] = null;
        });
    };

}]);

ctrl.controller('productCtrl', ["$scope", "$http", "$timeout", "$location", "$filter", "$cookieStore", "$rootScope", function($scope, $http, $timeout, $location, $filter, $cookieStore, $rootScope) {
    $scope.loading = true;
    $scope.refreshingProducts = true;
    $scope.allResults = false;
    $scope.products = [];
    $scope.product = {};
    $scope.imageUploaded = false;
    $scope.data = null;
    $scope.selectedProductType = null;
    $scope.notSelectedYet = true;
    $scope.imageUploaded = false;
    $scope.shopNewestProducts = '';
    $scope.flagMessage = null;
    $scope.tags = [
        {id: 0, name: 'sommer', selected: ''},
        {id: 1, name: 'vinter', selected: ''},
        {id: 2, name: 'regn', selected: ''},
        {id: 3, name: 'sol', selected: ''},
        {id: 4, name: 'baby', selected: ''},
        {id: 5, name: 'børn', selected: ''},
        {id: 6, name: 'dame', selected: ''},
        {id: 7, name: 'mand', selected: ''},
        {id: 8, name: 'løb', selected: ''},
        {id: 9, name: 'cykling', selected: ''},
        {id: 10, name: 'bæredygtigt', selected: ''},
        {id: 11, name: 'messe/give away', selected: ''},
        {id: 12, name: 'gadget', selected: ''},
        {id: 13, name: 'påske', selected: ''},
        {id: 14, name: 'jul', selected: ''},
        {id: 15, name: 'fastelavn', selected: ''},
        {id: 16, name: 'festival', selected: ''},
        {id: 17, name: 'strand', selected: ''},
        {id: 18, name: 'picnic', selected: ''},
        {id: 19, name: 'wellness', selected: ''},
        {id: 20, name: 'rejse', selected: ''},
        {id: 21, name: 'ferie', selected: ''},
        {id: 22, name: 'sport', selected: ''},
        {id: 23, name: 'yoga', selected: ''},
        {id: 24, name: 'bamser', selected: ''},
        {id: 25, name: 'DIY', selected: ''},
        {id: 26, name: 'udendørs', selected: ''},
        {id: 27, name: 'hjemmekontor', selected: ''},
        {id: 28, name: 'møde corona', selected: ''},
        {id: 29, name: 'golf', selected: ''},
        {id: 30, name: 'køkken', selected: ''},
        {id: 31, name: 'vand', selected: ''},
        {id: 32, name: 'sikkerhed', selected: ''},
        {id: 33, name: 'stress', selected: ''},
    ];

    $scope.colors =[
        {color: $filter('translate')('RED')},
        {color: $filter('translate')('BLUE')},
        {color: $filter('translate')('YELLOW')},
        {color: $filter('translate')('GREEN')},
        {color: $filter('translate')('BROWN')},
        {color: $filter('translate')('GREY')},
        {color: $filter('translate')('BLACK')},
        {color: $filter('translate')('WHITE')},
        {color: $filter('translate')('PINK')},
        {color: $filter('translate')('TURQUOISE')},
        {color: $filter('translate')('NATURE')},
        {color: $filter('translate')('PURPLE')},
        {color: $filter('translate')('ORANGE')},
        {color: $filter('translate')('SILVER')},
        {color: $filter('translate')('GOLD')},
        {color: $filter('translate')('BRONZE')},
        {color: $filter('translate')('STEEL')},
        {color: $filter('translate')('TRANSPARENT')},
    ];

    $scope.sizes = [
        {id:0, size: $filter('translate')('NOT_RELEVANT'), selected: ''},
        {id:1, size: 'XXXS', selected: ''},
        {id:2, size: 'XXS', selected: ''},
        {id:3, size: 'XS', selected: ''},
        {id:4, size: 'S', selected: ''},
        {id:5, size: 'M', selected: ''},
        {id:6, size: 'L', selected: ''},
        {id:7, size: 'XL', selected: ''},
        {id:8, size: 'XXL', selected: ''},
        {id:9, size: 'XXXL', selected: ''},
        {id:10, size: 'XXXXL', selected: ''},
        {id:11, size: '34', selected: ''},
        {id:12, size: '36', selected: ''},
        {id:13, size: '38', selected: ''},
        {id:14, size: '40', selected: ''},
        {id:15, size: '42', selected: ''},
        {id:16, size: '44', selected: ''},
        {id:17, size: '46', selected: ''},
        {id:18, size: '48', selected: ''},
        {id:19, size: '50', selected: ''}
    ];
    $scope.offers = [
        {value: 0, name: $filter('translate')('DISABLED'), selected: ''},
        {value: 4, name: $filter('translate')('PROMOTION') + " - " + $filter('translate')('ENABLED'), selected: 'true'},
        {value: 5, name: $filter('translate')('PROMOTION') + " - " + $filter('translate')('ENABLED_WITHOUT_PRICE'), selected: ''},
        {value: 6, name: $filter('translate')('PROMOTION') + " - " + $filter('translate')('ENABLED_WITHOUT_BOXES'), selected: ''},
        {value: 7, name: $filter('translate')('EASTER') + " - " + $filter('translate')('ENABLED'), selected: ''},
        {value: 8, name: $filter('translate')('EASTER') + " - " + $filter('translate')('ENABLED_WITHOUT_PRICE'), selected: ''},
        {value: 9, name: $filter('translate')('EASTER') + " - " + $filter('translate')('ENABLED_WITHOUT_BOXES'), selected: ''},
        {value: 10, name: $filter('translate')('CHRISTMAS') + " - " + $filter('translate')('ENABLED'), selected: ''},
        {value: 11, name: $filter('translate')('CHRISTMAS') + " - " + $filter('translate')('ENABLED_WITHOUT_PRICE'), selected: ''},
        {value: 12, name: $filter('translate')('CHRISTMAS') + " - " + $filter('translate')('ENABLED_WITHOUT_BOXES'), selected: ''},
        {value: 13, name: $filter('translate')('OCCASION') + " - " + $filter('translate')('ENABLED'), selected: ''},
        {value: 14, name: $filter('translate')('OCCASION') + " - " + $filter('translate')('ENABLED_WITHOUT_PRICE'), selected: ''},
        {value: 15, name: $filter('translate')('OCCASION') + " - " + $filter('translate')('ENABLED_WITHOUT_BOXES'), selected: ''}
    ];
    $scope.productIdeaOfferTypes = [
        $scope.offers[1],
        $scope.offers[7],
    ]
    $scope.shops = [
        {id: '', title: $filter('translate')('ALL_SHOPS')}
    ];
    $scope.vendors = [
        {id: '', name: $filter('translate')('ALL_VENDORS')}
    ];
    // Creating another list, because of different options
    $scope.productCreationGiftVendors = [];
    $scope.productCreationPromotionVendors = [];
    $http.get('/api/vendors?take=9999').then(function onSuccess(response) {
        $scope.vendors = $scope.vendors.concat(response.data.data);
        var notRelevant = {
            id: 0, name: $filter('translate')('UNKNOWN_MATERIAL')
        }
        $scope.productCreationGiftVendors = $scope.productCreationGiftVendors.concat(response.data.data);
        $scope.productCreationPromotionVendors = response.data.data;
        $scope.productCreationPromotionVendors.splice(0, 0, notRelevant);

    });
    $http.get('/api/shops-compact').then(function onSuccess(response) {
        $scope.shops = $scope.shops.concat(response.data);
    });

    $http.get('js/countries.json').then(function onSuccess(response) {
        $scope.countryList = response.data;
        var east = {
            name:{ common:$filter('translate')('EAST')},
            cca2:'EAST',
        }
        var eu = {
            name: {common:$filter('translate')('EU')},
            cca2:'EU',
        }
        var europe = {
            name: {common:$filter('translate')('EUROPE')},
            cca2:'EUR',
        }
        var unknown = {
            name: {common:$filter('translate')('UNKNOWN_COUNTRY')},
            cca2:'UNKNOWN',
        }
        $scope.countryList.unshift(unknown,eu,europe,east);
    });
    $http.get('/api/product-categories').then(function onSuccess(response) {
        $scope.productCategories = response.data;
        $scope.productCategories1 = $scope.productCategories.filter(function(productCategories) {
            return productCategories.type == 1;
          });
        $scope.productCategories2 = $scope.productCategories.filter(function(productCategories) {
            return productCategories.type == 2;
          });
        $scope.productCategories3 = $scope.productCategories.filter(function(productCategories) {
            return productCategories.type == 3;
          });
    });

    $scope.changeCategoryOnParentId = function($parentId, $arr){
        $scope.filteredCategories2 = $arr.filter(function($arr){
            return $arr.parent_id == $parentId;
        })
    };
    $scope.changeCategoryOnParentId2 = function($parentId, $arr){
        $scope.filteredCategories3 = $arr.filter(function($arr){
            return $arr.parent_id == $parentId;
        })
    };


    $scope.approvalStatuses = [
        {value: 'missing', title: $filter('translate')('STATUS_MISSING')},
        {value: 'creation', title: $filter('translate')('STATUS_CREATION')},
        {value: 'proofreading', title: $filter('translate')('STATUS_PROOFREADING')},
        {value: 'approved', title: $filter('translate')('STATUS_APPROVED')},
    ];
    $scope.availableTypes = [
        {value: 'gift', title: $filter('translate')('GIFT')},
        {value: 'regular', title: $filter('translate')('PROMOTION')},
        {value: 'easter', title: $filter('translate')('EASTER')},
        {value: 'christmas', title: $filter('translate')('CHRISTMAS')},
        {value: 'occasion', title: $filter('translate')('OCCASION')},
    ];

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = ($scope.searchParams.take % 15 !== 0) ? 15 : $scope.searchParams.take;

    // If present in the cookie, recheck all statuses
    $scope.searchParams.approval_statuses = (typeof $cookieStore.get('productApproval') === 'undefined') ? '' : $cookieStore.get('productApproval');
    if(typeof  $cookieStore.get('productApproval') !== 'undefined') {
        cookieArr = $cookieStore.get('productApproval').split(',');
        angular.forEach($scope.approvalStatuses, function(status){
            angular.forEach(cookieArr, function(cookieStatus){
                if(cookieStatus == status.value)
                    return status.checked = true;
            })
        })
    }
    // If present in the cookie, recheck all offer_types
    $scope.searchParams.offer_types = (typeof $cookieStore.get('productOfferType') === 'undefined') ? '' : $cookieStore.get('productOfferType');
    if(typeof $cookieStore.get('productOfferType') !== 'undefined') {
        cookieArr = $cookieStore.get('productOfferType').split(',');
        angular.forEach($scope.availableTypes, function (type) {
            angular.forEach(cookieArr, function (cookieType) {
                if(cookieType == type.value)
                    return type.checked = true;
            })
        })
    }

    $scope.searchParams.shop_id = (typeof $cookieStore.get('productShop') === 'undefined') ? '' : $cookieStore.get('productShop');
    $scope.searchParams.shop_id = $scope.searchParams.shop_id ? $scope.searchParams.shop_id : '';

    $scope.searchParams.vendor_id = (typeof $cookieStore.get('productVendor') === 'undefined') ? '' : $cookieStore.get('productVendor');
    $scope.searchParams.vendor_id = $scope.searchParams.vendor_id ? $scope.searchParams.vendor_id : '';

    $scope.searchParams.offer = (typeof $cookieStore.get('productOffer') === 'undefined') ? '' : $cookieStore.get('productOffer');
    $scope.searchParams.offer = $scope.searchParams.offer ? $scope.searchParams.offer : '';

    // clear all search parameters, from the cookie and the dropdowns.
    $scope.clearSearchParams = function () {
        $location.search('');
        $scope.take = 15;
        $scope.searchParams.take = 15;
        $scope.searchParams.shop_id = '';
        $scope.searchParams.vendor_id = '';
        $scope.searchParams.offer = '';
        $scope.searchParams.offer_types = '';
        $scope.searchParams.approval_statuses = '';
        $scope.offerFilter = '';
        $scope.offer_types = '';
        $cookieStore.remove('productApproval');
        $cookieStore.remove('productOfferType');
        $cookieStore.remove('productShop');
        $cookieStore.remove('productVendor');
        $cookieStore.remove('productOffer');
        // unchecking the dropdowns
        angular.forEach($scope.approvalStatuses, function (status) {
            status.checked = false;
        })
        angular.forEach($scope.availableTypes, function (status) {
            status.checked = false;
        })
        $scope.change();
    }

    $scope.offerFilters = [
        {id: '', title: $filter('translate')('SHOW_ALL_PRODUCTS')},
        {id: 1, title: $filter('translate')('SHOW_ONLY_ACTIVE_PRODUCTS')},
        {id: 2, title: $filter('translate')('SHOW_ONLY_INACTIVE_PRODUCTS')},
        {id: 3, title: $filter('translate')('SHOW_ONLY_GIFT_PRODUCTS')},
        {id: 4, title: $filter('translate')('SHOW_ONLY_PROMOTION_PRODUCTS')}
    ];

    var initialize = true;

    $scope.$watch('searchParams.shop_id', function (value) {
        if (!initialize) {
            $cookieStore.put('productShop', value);
            $scope.searchParams.shop_id = value;
            $location.search($scope.searchParams);
            $scope.change();
        }
    }, true);
    $scope.$watch('searchParams.vendor_id', function (value) {
        if (!initialize) {
            $cookieStore.put('productVendor', value);
            $scope.searchParams.vendor_id = value;
            $location.search($scope.searchParams);
            $scope.change();
        }
    }, true);

    $scope.$watch('searchParams.offer', function (value) {
        if (!initialize) {
            $cookieStore.put('productOffer', value);
            $scope.searchParams.offer = value;
            $location.search($scope.searchParams);
            $scope.change();
        }
    }, true);

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function() {
        $scope.loading = true;
        $scope.refreshingProducts = true;
        $http.get('/api/products', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.products = response.data.data;
            if (response.data.data.length !== $scope.products.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }else{
                $scope.allResults = false;
            }
            $scope.loading = false;
            $scope.refreshingProducts = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.selectedAll = false;
    $scope.showActions = false;
    $scope.checkAll = function () {
        $scope.showActions = false;
        $scope.checkedCount = 0;
        angular.forEach($scope.products, function (item) {
            item.selected = $scope.selectedAll;
            if (item.selected === true) {
                $scope.showActions = true;
                $scope.checkedCount += 1;
            }
        });
    };

    $scope.checkOne = function () {
        $scope.showActions = false;
        $scope.checkedCount = 0;
        angular.forEach($scope.products, function (item) {
            if (item.selected === true) {
                $scope.showActions = true;
                $scope.checkedCount += 1;
            }
        });
    };

    $scope.addToCategory = function() {
        var checked = [];
        angular.forEach($scope.products, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
    };

    $scope.removeFromCategory = function() {
        var checked = [];
        angular.forEach($scope.products, function (item) {
            if (item.selected === true) checked.push(item.id);
        });
    };

    $scope.addProduct = function() {
        $scope.product = {title: '', handle: '', number: '', external_link: ''};
        $scope.addProductPanelNumber = 1;
        $scope.focusAddProduct = true;
        $scope.updateHandle = function(title) {
            $scope.product.handle = getSlug(title);
        }
    };



    $scope.checkFirstModal = function () {
        $http.post('api/products/checkfirst', {selectedProductType: $scope.selectedProductType, handle: $scope.product.handle, title: $scope.product.title, number: $scope.product.number, vendor_sku: $scope.product.vendor_sku}).then(function () {
            $scope.addProductNext();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };
    $scope.checkSecondModal = function () {
        $http.post('api/products/checksecond', {selectedProductType: $scope.selectedProductType, cost_price: $scope.product.cost_price, unit_type: $scope.product.unit_type, unit_price_point: $scope.product.unit_price_point, price: $scope.product.price}).then(function () {
            $scope.addProductNext();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };
    $scope.checkThirdModal = function () {
        $http.post('api/products/checkthird', {selectedProductType: $scope.selectedProductType, category_id1: $scope.product.category_id1, is_offer: $scope.product.is_offer}).then(function () {
            if($scope.selectedProductType === 'gift'){
                $scope.addProductPanelNumber = 4;
            }
            $scope.addProductNext();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };
    $scope.checkSixthModal = function () {
        $http.post('api/products/checksixth', {selectedProductType: $scope.selectedProductType, vendor_id: $scope.product.vendor_id, vendor_link: $scope.product.vendor_link, country_of_origin: $scope.product.country_of_origin}).then(function () {
            $scope.addProductNext();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };
    $scope.selectProductFlow = function(selectedProductType) {
        $scope.selectedProductType = selectedProductType;
        if($scope.selectedProductType === 'promotion'){
            $scope.product.is_offer = $scope.offers[1];
        }
        if($scope.selectedProductType === 'gift'){
            $scope.product.is_offer = $scope.offers[7];
        }
        $scope.notSelectedYet = false;
    };
    $scope.backToProductFlow = function() {
        $scope.notSelectedYet = true;
    }
    $scope.updatePrice = function(cost_price) {
        $scope.product.price = cost_price * 2;
        $scope.product.cost_price = cost_price;
    };
    $scope.updateCostPrice = function(purchase_price) {
        var temp = (purchase_price * 1.07).toFixed(4);
        $scope.product.cost_price = parseFloat(temp);
        $scope.product.purchase_price = purchase_price;
    };
    $scope.addProductNext = function() {
        if($scope.addProductPanelNumber < 7) $scope.addProductPanelNumber++;
        else $scope.addProductPanelNumber = 1;
    };
    $scope.addProductPrev = function() {
        if($scope.selectedProductType === 'gift' && $scope.addProductPanelNumber === 5){
            return $scope.addProductPanelNumber = 3;
        }
        if($scope.addProductPanelNumber > 1) $scope.addProductPanelNumber--;
        else $scope.addProductPanelNumber = 7;
    };

    $scope.updateCategories = function() {
        $scope.catLoader = false;
        $scope.category = {action: '', shop: '', products: [], categories: []};
    };

    $scope.changeCategoryAction = function() {
        $scope.categories = [];
        if($scope.category.action === 'remove') {
            $scope.catLoader = true;
            var checked = [];
            angular.forEach($scope.products, function (item) {
                if (item.selected === true) checked.push(item.id);
            });
            $http.get('/api/categories/compact?product_ids=' + checked).then(function onSuccess(response) {
                $scope.categories = response.data;
                $scope.catLoader = false;
            });
        }
    };

    $scope.changeCategoryShop = function() {
        $scope.catLoader = true;
        $scope.categories = [];
         $http.get('/api/categories/compact?shop_id=' + $scope.category.shop).then(function onSuccess(response) {
            $scope.categories = response.data;
            $scope.catLoader = false;
         });
    };

    $scope.saveCategories = function() {
        angular.forEach($scope.products, function (item) {
            if (item.selected === true) $scope.category.products.push(item.id);
        });
        angular.forEach($scope.categories, function (item) {
            if (item.checked === true) $scope.category.categories.push(item.id);
        });
        $http.post('/api/categories/manage-product-pivots', $scope.category).then(function () {
            $("#updateCategories").modal('toggle');
            $(".modal-backdrop").hide(500).remove();
            $scope.categories = null;
        });
    };

    $scope.saveProduct = function() {
        if($scope.selectedProductType === 'gift' || $scope.selectedProductType === 'promotion') {
            $scope.product.is_offer = $scope.product.is_offer.value;
        }
        var data = $scope.product;
        data.selectedProductType = $scope.selectedProductType;
        $http.post('/api/products', data).then(function onSuccess(response) {
            $("#addProduct").modal('hide');
            $scope.focusAddProduct = false;
            setTimeout(function() {
                $location.path("/products/" + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.listCategories = function(product) {
        $scope.catLoader = true;
        $http.get('/api/products/' + product + '/categories').then(function onSuccess(response) {
            $scope.categories = response.data;
            $http.get('/api/products/' + product + '/shops').then(function onSuccess(response) {
                $scope.productShops = response.data;
                $scope.catLoader = false;
            });
        });
    };

    $scope.loadMore = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.refreshingProducts = true;
        $http.get('/api/products', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.products.length) {
                $scope.products = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }else{
                $scope.allResults = false;
            }
            $scope.refreshingProducts = false;
        });
    };





    $scope.setIncludeApprovalStatuses = function() {
        $scope.searchParams.approval_statuses = $scope.getSelectedApprovalStatusesValuesComma().replace(/[\s]+/g, '');
        $cookieStore.put('productApproval', $scope.searchParams.approval_statuses);
        $scope.change();
    };

    $scope.getSelectedApprovalStatuses = function() {
        return $filter('filter')($scope.approvalStatuses, {checked: true});
    };

    $scope.getSelectedApprovalStatusesComma = function() {
        return $scope.getSelectedApprovalStatuses().map(function(item) {
            return item.title;
        }).join(', ');
    };

    $scope.getSelectedApprovalStatusesValuesComma = function() {
        return $scope.getSelectedApprovalStatuses().map(function(item) {
            return item.value;
        }).join(', ');
    };

    $scope.$watch('approvalStatuses', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.setIncludeApprovalStatuses();
        }
    }, true);

    $scope.setIncludeOfferTypes = function() {
        $scope.searchParams.offer_types = $scope.getSelectedOfferTypesValuesComma().replace(/[\s]+/g, '');
        $cookieStore.put('productOfferType', $scope.searchParams.offer_types);
        $scope.change();
    };

    $scope.getSelectedOfferTypes = function() {
        return $filter('filter')($scope.availableTypes, {checked: true});
    };

    $scope.getSelectedOfferTypesComma = function() {
        return $scope.getSelectedOfferTypes().map(function(item) {
            return item.title;
        }).join(', ');
    };

    $scope.getSelectedOfferTypesValuesComma = function() {
        return $scope.getSelectedOfferTypes().map(function(item) {
            return item.value;
        }).join(', ');
    };

    $scope.$watch('availableTypes', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.setIncludeOfferTypes();
        }
    }, true);

    $scope.onProductImageSelect = function ($files) {
        var fileReader = new FileReader();
        if ($files && $files.length > 0) {
            var file = $files[0];

            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
                $scope.product.image = {
                    fileName : file.name,
                    fileSize : file.size,
                    fileType : file.type,
                    file : e.target.result.match(/,(.*)$/)[1],
                }
            }
            $scope.imageUploaded = true;
        }
    };

    $http.get('/api/shop-newest-products').then(function onSuccess(response) {
        $scope.shopNewestProducts = response.data.data;
    });

    $scope.saveNewestProducts = function() {
        $http.put('api/shop-newest-products-update', {
            newest_products: $scope.shopNewestProducts})
        .then(function (response) {
            $("#editNewestProducts").modal('hide');

            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
        }, function onError(response) {
            alert('Error');
        });
    }
}]);
ctrl.controller('addProductsToAssortmentCtrl', ["$scope", "$routeParams", "$http", "$cookieStore", "$location", "$timeout", "$filter", "$window", function ($scope, $routeParams, $http, $cookieStore, $location, $timeout, $filter, $window) {

    $scope.goBack = function() {
        $window.history.back();
    };

    var $_cookiePreFix = 'offer_shop_products_' + $routeParams.shopId;

    // Cookie keys
    $scope.cookieTakeKey = $_cookiePreFix + '_take';
    $scope.cookieTagsKey = $_cookiePreFix + '_tags';
    $scope.cookieOrderByKey = $_cookiePreFix + '_order_by';
    $scope.cookieFilterByKey = $_cookiePreFix + '_filter_by';
    $scope.cookieTableViewKey = $_cookiePreFix + '_table_view';
    $scope.cookieOfferTypesKey = $_cookiePreFix + '_offer_types';
    $scope.cookieSearchQueryKey = $_cookiePreFix + '_search_query';
    $scope.cookieSelectedTagsKey = $_cookiePreFix + '_selected_tags';
    $scope.cookieRangeFiltersKey = $_cookiePreFix + '_range_filters';
    $scope.cookieToggleRangeFiltersKey = $_cookiePreFix + '_toggle_range_filters';

    $scope.cookieName = 'offer_shop_products_' + $routeParams.shopId;
    $scope.cookieValue = $cookieStore.get($scope.cookieName);
    if (!$scope.cookieValue) {
        $cookieStore.put($scope.cookieName, '');
        $scope.cookieValue = '';
    }

    $scope.flagMessage = null;

    $scope.filters = [
        { id: 'range_dg', name: $filter('translate')('COVERAGE_RATIO') },
        { id: 'range_cost_price', name: $filter('translate')('COST_PRICE') },
        { id: 'range_price', name: $filter('translate')('PRICE') },
    ];
    $scope.showFilter = false;
    $scope.activeFilters = [];
    $scope.rangeFilter = { id: '', name: '', min: 0, max: 0 };
    $scope.selectedFilter = {};

    var $cookieRangeFilters = $cookieStore.get($scope.cookieRangeFiltersKey);
    if ($cookieRangeFilters) {
        $scope.activeFilters = $cookieRangeFilters;
    }

    var $cookieToggleRangeFiltersKey = $cookieStore.get($scope.cookieToggleRangeFiltersKey);
    if ($cookieToggleRangeFiltersKey) {
        $scope.showFilter = $cookieToggleRangeFiltersKey;
    }

    $scope.addFilter = function() {
        var filter = $scope.rangeFilter;

        // Check if there is a selected filter
        if (Object.keys($scope.selectedFilter).length === 0) {
            $scope.flagMessage = $filter('translate')('REQUIRED_SELECT_RANGE_FILTER');
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            return;
        }

        filter.id = $scope.selectedFilter.filter.id;
        filter.name = $scope.selectedFilter.filter.name;

        if (filter.id === '' || filter.name === '') {
            return;
        }

        // min should always be lower than max
        if (filter.min > filter.max) {
            $scope.flagMessage = $filter('translate')('RANGE_MAX_LOWER_THAN_MIN');
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            return;
        }

        var hasFilterAlready = $scope.activeFilters.some(function(activeFilter) {
            return activeFilter.id === filter.id;
        });

        // only allow one of each range filter at a time
        if (hasFilterAlready) {
            $scope.flagMessage = $filter('translate')('RANGE_FILTER_ALREADY_ACTIVE');
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            return;
        }

        // add filter to active filters
        $scope.activeFilters.unshift(filter);

        $cookieStore.put($scope.cookieRangeFiltersKey, $scope.activeFilters);

        // reset filter after add to active filters
        $scope.rangeFilter = { id: '', name: '', min: 0, max: 0 };
        $scope.selectedFilter = {};
    };

    $scope.removeFilter = function(index) {
        if ($scope.activeFilters.length > 0) {
            $scope.activeFilters.splice(index, 1);
            $cookieStore.put($scope.cookieRangeFiltersKey, $scope.activeFilters);
        }
    };

    $scope.toggleShowFilter = function() {
        $scope.showFilter = !$scope.showFilter;
        $cookieStore.put($scope.cookieToggleRangeFiltersKey, $scope.showFilter);
    };

    $scope.loadingProducts = true;
    $scope.shopId = $routeParams.shopId;
    $scope.products = [];
    $scope.productsMeta = [];
    $scope.product = {
        id: '',
        variants_sku: '',
        price: '',
        title: ''
    };
    $scope.data = null;

    $scope.allResults = false;
    $scope.refreshingProducts = false;

    $scope.listView = 'list';

    var $cookieTableView = $cookieStore.get($scope.cookieTableViewKey);
    if ($cookieTableView) {
        $scope.listView = $cookieTableView;
    }

    $scope.orderByList = [
        {id: 1, value: 'products.id', title: 'Newest'},
        {id: 2, value: 'products.title', title: 'Title'},
        {id: 3, value: 'variants.price', title: 'Price'},
        {id: 4, value: 'products.quality_stamp_approved_at', title: 'Quality Stamp'},
    ];

    $scope.availableTypes = [
        {value: 'gift', title: $filter('translate')('GIFT')},
        {value: 'regular', title: $filter('translate')('PROMOTION')},
        {value: 'easter', title: $filter('translate')('EASTER')},
        {value: 'christmas', title: $filter('translate')('CHRISTMAS')},
        {value: 'occasion', title: $filter('translate')('OCCASION')},
    ];

    var now = new Date();
    $scope.filterByCreated = [
        {value: null, title: 'ALL'},
        {value: $filter('date')(now.setDate(now.getDate() - 1), 'yyyy-MM-dd'), title: 'NEW_YESTERDAY'},
        {value: $filter('date')(now.setDate(now.getDate() - 6), 'yyyy-MM-dd'), title: 'NEW_LAST_WEEK'},
        {value: $filter('date')(now.setDate(now.getDate() - 23), 'yyyy-MM-dd'), title: 'NEW_LAST_MONTH'}
    ];

    $scope.rangeProd = {
        keyword: (typeof $cookieStore.get($scope.cookieSearchQueryKey) === 'undefined')
            ? null
            : $cookieStore.get($scope.cookieSearchQueryKey),
        order: (typeof $cookieStore.get($scope.cookieOrderByKey) === 'undefined')
            ? 'products.id'
            : $cookieStore.get($scope.cookieOrderByKey),
        created_at_max: (typeof $cookieStore.get($scope.cookieFilterByKey) === 'undefined')
            ? null
            : $cookieStore.get($scope.cookieFilterByKey),
    };

    $scope.shopParams = {
        id: $routeParams.shopId,
        offer_types: ''
    };

    $scope.setIncludeOfferTypes = function() {
        $scope.shopParams.offer_types = $scope.getSelectedOfferTypesValuesComma().replace(/[\s]+/g, '');
        $scope.changeProducts();
    };

    $scope.getSelectedOfferTypes = function() {
        return $filter('filter')($scope.availableTypes, {checked: true});
    };

    $scope.getSelectedOfferTypesComma = function() {
        return $scope.getSelectedOfferTypes().map(function(item) {
            return item.title;
        }).join(', ');
    };

    $scope.getSelectedOfferTypesValuesComma = function() {
        return $scope.getSelectedOfferTypes().map(function(item) {
            return item.value;
        }).join(', ');
    };

    $scope.resetOfferTypeSelection = function() {
        angular.forEach($scope.availableTypes, function(type) {
            type.checked = undefined;
        });
    };

    $scope.filterRangeProd = function(prod) {
        if ($scope.rangeProd.keyword) {
            return prod.price >= $scope.rangeProd.minPrice && prod.price <= $scope.rangeProd.maxPrice && prod.title.toLowerCase().indexOf($scope.rangeProd.keyword.toLowerCase()) > -1;
        } else {
            return prod.price >= $scope.rangeProd.minPrice && prod.price <= $scope.rangeProd.maxPrice;
        }
    };

    $scope.$watch('activeFilters', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.changeProducts();
        }
    }, true);

    $scope.$watch('rangeProd.created_at_max', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put($scope.cookieFilterByKey, $scope.rangeProd.created_at_max);
            $scope.changeProducts();
        }
    }, true);

    $scope.$watch('rangeProd.order', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put($scope.cookieOrderByKey, $scope.rangeProd.order);
            $scope.changeProducts();
        }
    }, true);

    var queryTimeout = null;

    $scope.$watch('rangeProd.keyword', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put('keywordProduct', newValue);
            $cookieStore.put($scope.cookieSearchQueryKey, newValue);
            if (queryTimeout) $timeout.cancel(queryTimeout);
            var tempQueryStringProd = newValue;
            queryTimeout = $timeout(function () {
                $scope.rangeProd.keyword = tempQueryStringProd;
                $scope.changeProducts();
            }, 1000);
        }
    }, true);

    $scope.$watch('rangeProd.minPrice', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            var tempQueryStringProd = newValue;
            queryTimeout = $timeout(function () {
                $scope.rangeProd.minPrice = tempQueryStringProd;
                $scope.changeProducts();
            }, 1000);
        }
    }, true);

    $scope.$watch('rangeProd.maxPrice', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            var tempQueryStringProd = newValue;
            queryTimeout = $timeout(function () {
                $scope.rangeProd.maxPrice = tempQueryStringProd;
                $scope.changeProducts();
            }, 1000);
        }
    }, true);

    $scope.shop = {
        type: 'regular'
    };

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.searchParams.take === 'undefined' ? $scope.take : $scope.searchParams.take;
    $scope.searchParams.take = ($scope.searchParams.take > 200 || $scope.searchParams.take < $scope.take) ? $scope.take : $scope.searchParams.take;

    $scope.searchParams.tag = $scope.searchParams.tag ? $scope.searchParams.tag : '';

    $scope.loadMoreProducts = function() {
        $scope.take += 15;
        $cookieStore.put($scope.cookieTakeKey, $scope.take);
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.changeProducts();
    };

    $scope.changeProducts = function() {
        $scope.allResults = false;
        $scope.refreshingProducts = true;

        var activeFilters = $scope.activeFilters;

        var paramData = {
            include: 'variants,tags',
            take: (typeof $cookieStore.get($scope.cookieTakeKey) === 'undefined')
                ? $scope.take
                : $cookieStore.get($scope.cookieTakeKey),
            is_offer: true,
            query: $scope.rangeProd.keyword,
            show_images: 1,
            order_by: $scope.rangeProd.order,
            order_by_asc: $scope.rangeProd.order.asc,
            created_at_max: $scope.rangeProd.created_at_max,
            offer_shop: $scope.shopParams.id,
            tag: (typeof $cookieStore.get($scope.cookieSelectedTagsKey) === 'undefined')
                ? ''
                : $cookieStore.get($scope.cookieSelectedTagsKey).join(',')
        };

        Object.keys(activeFilters).map(function(index) {
            paramData[activeFilters[index].id] = activeFilters[index].min + ',' + activeFilters[index].max;
        });

        $http.get('/api/products', { params: paramData}).then(function onSuccess(response) {
            $scope.products = response.data.data;
            $scope.productsMeta = response.data.meta;

            if (response.data.data.length !== $scope.products.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loadingProducts = false;
            $scope.refreshingProducts = false;

            var $list = String($scope.cookieValue).split(',');
            angular.forEach($scope.products, function (product, index) {
                if ($list.includes(String(product.id))) {
                    $scope.products[index].selected = true;
                    $scope.showMassAdd = true;
                }
            });
        });
    };

    $scope.changeProducts();

    $scope.$watch('availableTypes', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $cookieStore.put($scope.cookieOfferTypesKey, $scope.availableTypes);
            $scope.setIncludeOfferTypes();
        }
    }, true);

    $scope.$watch('rangeProd.minPrice', function (newValue) {
        $cookieStore.put('minPriceProd', newValue);
    }, true);

    $scope.$watch('rangeProd.maxPrice', function (newValue) {
        $cookieStore.put('maxPriceProd', newValue);
    }, true);

    $scope.selectedAll = false;
    $scope.showMassAdd = false;

    $scope.checkAll = function() {
        $scope.showMassAdd = false;
        var $uniqueList = [];
        angular.forEach($scope.products, function (item) {
            item.selected = $scope.selectedAll;
            if (item.selected === true) {
                $scope.showMassAdd = true;
                $uniqueList.push(item.id);
            }
        });

        $scope.cookieValue = $uniqueList.join(',');
        $cookieStore.put($scope.cookieName, $scope.cookieValue);
    };

    $scope.checkOne = function(product) {
        $scope.showMassAdd = false;
        angular.forEach($scope.products, function (item) {
            if (item.selected === true) $scope.showMassAdd = true;
        });

        if (product) {
            $scope.rememberProcess(product);
        }
    };

    $scope.isFavourite = false;

    $scope.toggleIsFavourite = function () {
        $scope.isFavourite = !$scope.isFavourite;
    };

    $scope.addSelectedProducts = function() {
        var checked = [];
        angular.forEach($scope.products, function (item) {
            if (item.selected === true) {
                checked.push(item.id);
            }
        });

        $scope.add = {
            ids: checked,
            'is_favorite': $scope.isFavourite,
        };

        var url = '/api/product-assortments/' + $routeParams.productAssortmentId + '/products/add';

        $http.post(url, $scope.add)
            .then(
                function onSuccess(response) {
                    $("#confirmAddProductsToAssortment").modal('hide');

                    $scope.showMassAdd = false;
                    $scope.loadingProducts = true;

                    $scope.cookieValue = '';
                    $cookieStore.put($scope.cookieName, $scope.cookieValue);

                    $timeout(function() {
                        $location.path('/product-assortments/' + $routeParams.productAssortmentId);
                    }, 1000);
            }, function onError(response) {
                $scope.data = response.data;
            });
    };

    $scope.addSelectedProduct = function(product) {
        var checked = [];
        checked.push(product.id);
        $scope.add = {
            ids: checked
        };

        $http.post('/api/product-assortments/' + $routeParams.productAssortmentId + '/products/add', $scope.add)
            .then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.showMassAdd = false;
                $scope.loadingProducts = true;

                $scope.cookieValue = '';
                $cookieStore.put($scope.cookieName, $scope.cookieValue);

                $timeout(function() {
                    $location.path('/product-assortments/' + $routeParams.productAssortmentId);
                }, 2000);
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.rememberProcess = function(item) {
        if (item.selected) {
            $scope.rememberInsert(item);
        } else {
            $scope.rememberRemove(item);
        }
    };

    $scope.rememberInsert = function(item) {
        var $list = String($scope.cookieValue).split(',');
        $list.push(item.id);

        var $uniqueList = $list.filter(function(item, pos) {
            return $list.indexOf(item) === pos;
        });

        $scope.cookieValue = $uniqueList.join(',');
        $cookieStore.put($scope.cookieName, $scope.cookieValue);
    };

    $scope.rememberRemove = function(item) {
        var $list = String($scope.cookieValue).split(',');

        var $newList = [];
        for (var $i = 0; $i < $list.length; $i++) {
            if (parseInt($list[$i]) !== parseInt(item.id)) {
                $newList.push($list[$i]);
            }
        }

        var $uniqueList = $newList.filter(function(item, pos) {
            return $newList.indexOf(item) === pos;
        });

        $scope.cookieValue = $uniqueList.join(',');
        $cookieStore.put($scope.cookieName, $scope.cookieValue);
    };

    $scope.toggleListView = function(listView) {
        if ($scope.listView !== listView) {
            $scope.listView = listView;
            $cookieStore.put($scope.cookieTableViewKey, listView);
        }
    };

    $scope.selectedTags = [];
    $scope.tags = [];
    $scope.loadingTags = false;
    $scope.parentTags = [];

    $scope.loadMasterTags = function () {
        $scope.loadingTags = true;
        $http.get('/api/tags', {
            params: {
                take: 10000,
                shop_id: $scope.shopId,
                order_by: 'name',
                parent: ''
            }
        }).then(function onSuccess(response) {
            $scope.tags = response.data.data;

            $scope.selectedTags = $scope.searchParams.tag.split(',');
            if ($scope.selectedTags.length === 1) {
                if ($scope.selectedTags[0] === '') {
                    $scope.selectedTags = [];
                }
            }

            angular.forEach($scope.tags, function(tag, idx) {
                $scope.tags[idx].selected = $scope.selectedTags.indexOf(tag.name) !== -1;
                if ($scope.tags[idx].selected === true) {
                    $scope.parentTags.push($scope.tags[idx].id);
                }
            });

            var $cookieTagsKey = $cookieStore.get($scope.cookieTagsKey);
            if ($cookieTagsKey) {
                angular.forEach($scope.tags, function(tag, idx) {
                    angular.forEach($cookieTagsKey, function(cookieTag) {
                        if (cookieTag.name === tag.name) {
                            $scope.tags[idx].selected = true;
                        }
                    });
                });

                angular.forEach($cookieTagsKey, function(tag) {
                    $scope.selectedTags.push(tag.name);
                    $scope.parentTags.push(tag.id);
                });
            }

            $scope.loadingTags = false;

            if ($scope.parentTags.length) {
                $scope.loadMasterSubTags();
            }
        });
    };

    $scope.loadMasterTags();

    $scope.filteredTags = [];

    $scope.toggleTagFilter = function (idx) {
        $scope.tags[idx].selected = !$scope.tags[idx].selected;

        $scope.selectedTags = [];
        $scope.parentTags = [];
        angular.forEach($scope.tags, function(tag) {
            if (tag.selected === true) {
                $scope.selectedTags.push(tag.name);
                $scope.parentTags.push(tag.id);
            }
        });

        $cookieStore.put($scope.cookieTagsKey, $scope.tags.filter(function ($tag) { return $tag.selected === true; }));
        $cookieStore.put($scope.cookieSelectedTagsKey, $scope.selectedTags);

        $scope.searchParams.tag = $scope.selectedTags.join(',');
        $location.search($scope.searchParams);
        $scope.changeProducts();

        $scope.subTags = [];
        if ($scope.selectedTags.length) {
            $scope.loadMasterSubTags();
        }
    };

    $scope.variantStepsPriceString = function(variant) {
        var price = '';
        if (variant.step_prices) {
            var min = Math.min(variant.step1_price, variant.step2_price, variant.step3_price);
            min = $filter('currency')(min, 'kr');
            var max = Math.max(variant.step1_price, variant.step2_price, variant.step3_price);
            max = $filter('currency')(max, 'kr');
            price = $filter('translate')('PRICE_FROM') + ' ' + min + ' ' + $filter('translate')('PRICE_TO') + ' ' + max;
        }

        return price;
    };

    $scope.subTags = [];
    $scope.loadingSubTags = false;

    $scope.loadMasterSubTags = function () {
        if (!$scope.selectedTags.length) {
            return;
        }

        $scope.loadingSubTags = true;
        $http.get('/api/tags', {
            params: {
                take: 200,
                is_master: 1,
                shop_id: $scope.shopId,
                order_by: 'name',
                parent: $scope.parentTags.join(',')
            }
        }).then(function onSuccess(response) {
            $scope.subTags = response.data.data;

            $scope.selectedTags = $scope.searchParams.tag.split(',');
            angular.forEach($scope.subTags, function(tag, idx) {
                $scope.subTags[idx].selected = $scope.selectedTags.indexOf(tag.name) !== -1;
            });

            $scope.loadingSubTags = false;
        });
    };

    $scope.toggleSubTagFilter = function (idx) {
        $scope.subTags[idx].selected = !$scope.subTags[idx].selected;

        $scope.selectedTags = [];
        $scope.parentTags = [];
        angular.forEach($scope.tags, function(tag) {
            if (tag.selected === true) {
                $scope.selectedTags.push(tag.name);
                $scope.parentTags.push(tag.id);
            }
        });

        angular.forEach($scope.subTags, function(tag) {
            if (tag.selected === true) {
                $scope.selectedTags.push(tag.name);
            }
        });

        $scope.take = 15;
        $scope.searchParams.tag = $scope.selectedTags.join(',');
        $location.search($scope.searchParams);
        $scope.changeProducts();
    };

    $scope.resetCookies = function() {
        var $cookies = [
            $scope.cookieTagsKey,
            $scope.cookieTakeKey,
            $scope.cookieOrderByKey,
            $scope.cookieFilterByKey,
            $scope.cookieTableViewKey,
            $scope.cookieOfferTypesKey,
            $scope.cookieSearchQueryKey,
            $scope.cookieRangeFiltersKey,
            $scope.cookieSelectedTagsKey,
            $scope.cookieToggleRangeFiltersKey,
        ];

        angular.forEach($cookies, function($cookie) {
            $cookieStore.remove($cookie);
        });

        angular.forEach($scope.tags, function($tag) {
            $tag.selected = false;
        });

        $scope.take = 15;
        $scope.listView = 'list';
        $scope.activeFilters = [];
        $scope.selectedTags = [];
        $scope.parentTags = [];
        $location.search('tag', null);
        $location.search('take', 15);
        $scope.rangeProd = {
            keyword: null,
            order: 'products.id',
            created_at_max: null,
        };

        $scope.getShop();
        $scope.changeProducts();
    };
}]);

ctrl.controller('productAssortmentCtrl', ["$scope", "$routeParams", "$http", "$cookieStore", "$location", "$timeout", "$filter", function ($scope, $routeParams, $http, $cookieStore, $location, $timeout, $filter) {
    $scope.state = {
        productAssortmentId: null,
        productAssortment: null,
        productAssortmentLoading: false,
        assortmentProductsLoading: false,
        products: [],
        selectedAll: false,
    };

    $scope.showMassDelete = false;
    $scope.flagMessage = null;

    $scope.init = function () {
        $scope.fetchProductAssortment();
        $scope.fetchAssortmentProducts();
    }

    $scope.sortableAssortmentProductsOptions = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle-product',
        cancel: '',
        'ui-floating': false,
        update: function(event, ui) {},
        stop: function(event, ui) {
            var ids = [];

            angular.forEach($scope.state.products, function(item) {
                ids.push(item.id);
            });

            var url = 'api/product-assortments/' + $scope.state.productAssortmentId + '/products/update-positions'

            $http.post(url, { ids: ids }).then(function onSuccess() {}, function onError() {});
        }
    };

    // assign product assortment id from route to the state
    $scope.state.productAssortmentId = $routeParams.productAssortmentId;

    $scope.toggleIsFavourite = function (product) {
        var url = '/api/product-assortments/' +  $scope.state.productAssortmentId + '/products/' + product.id + '/favourite';

        $http.get(url).then(function (response) {
            var idx = $scope.state.products.findIndex(function(item) {
                return item.id === product.id;
            });

            $scope.state.products[idx].pivot.is_prepared_product = response.data.is_prepared_product;
        });
    };

    $scope.removeProduct = function(idx) {
        var productId = $scope.state.products[idx].id;

        $http.delete('/api/product-assortments/' +  $scope.state.productAssortmentId + '/products/' + productId)
            .then(function () {
                $scope.state.products.splice(idx, 1);
            }, function onError(response) {
                if (response.status === 405) {
                    window.location = '/login';
            }
        });
    };

    $scope.removeSelectedProducts = function() {
        var checked = [];
        var indexed = [];

        angular.forEach($scope.state.products, function (item, idx) {
            if (item.selected === true) {
                checked.push(item.id);
                indexed.push(idx);
            }
        });

        $scope.remove = { ids: checked };

        $http.post('/api/product-assortments/' + $scope.state.productAssortmentId + '/products/remove', $scope.remove)
            .then(function onSuccess(response) {
                $scope.flagMessage = response.data.message;
                $timeout(function () { $scope.flagMessage = null; }, 3000);
                $scope.showMassDelete = false;

                angular.forEach(indexed, function (idx) {
                    $scope.state.products.splice(idx, 1);
                });
            }, function onError() {
                //
            });
    };

    $scope.selectAllProducts = function() {
        angular.forEach($scope.state.products, function (product) {
            product.selected = $scope.state.selectedAll;
        });

        $scope.setMassDeleteState();
    };

    $scope.setMassDeleteState = function () {
        var selectedProducts = $scope.state.products.filter(function (product) {
            return product.selected === true;
        });

        if (selectedProducts.length > 0) {
            $scope.showMassDelete = true;
        } else {
            $scope.showMassDelete = false;
        }
    }

    $scope.selectOne = function (product) {
        var findProduct = $scope.state.products.filter(function (item) {
            return item.id === product.id;
        });

        findProduct.selected = !findProduct.selected;

        if (findProduct.selected === true) {
            $scope.showMassDelete = true;
        } else {
            $scope.showMassDelete = false;
        }
    };

    $scope.refreshAssortmentProducts = function() {
        $scope.fetchAssortmentProducts();
    }

    $scope.fetchProductAssortment = function() {
        $scope.state.productAssortmentLoading = true;

        $http.get('/api/product-assortments/' + $scope.state.productAssortmentId)
            .then(function onSuccess(response) {
                $scope.state.productAssortment = response.data.data;
                $scope.state.productAssortmentLoading = false;
        });
    };

    $scope.fetchAssortmentProducts = function() {
        $http.get('/api/product-assortments/' + $scope.state.productAssortmentId + '/products?take=400&show_images=1&include=options,variants')
            .then(function onSuccess(response) {
                $scope.state.products = response.data.data;
            });
    };

    // run everything in init.
    $scope.init();
}]);

ctrl.controller('productAssortmentsCtrl', ["$scope", "$routeParams", "$http", "$cookieStore", "$location", "$timeout", "$filter", function ($scope, $routeParams, $http, $cookieStore, $location, $timeout, $filter) {
    $scope.state = {
        errors: [],
        productAssortments: [],
        productAssortmentsLoading: false,
        productAssortmentsRefreshing: false,
        productAssortmentsAllResults: false,
        take: 15,
    };

    $scope.TYPES = {
        CHRISTMAS: 'christmas',
        EASTER: 'easter',
        OCCASION: 'occasion'
    }

    $scope.onProductAssortmentImageSelect = function ($files, $type) {
        var fileReader = new FileReader();
        if ($files && $files.length > 0) {
            var file = $files[0];

            fileReader.readAsDataURL(file);
            fileReader.onload = function(event) {
                $scope.productAssortment.preview = fileReader.result;
                $scope.productAssortment.image = file;
            }
        }
    };

    $scope.sortableProductAssortmentsOptions = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle',
        cancel: '',
        'ui-floating': false,
        update: function(event, ui) {},
        stop: function(event, ui) {
            var ids = [];

            angular.forEach($scope.state.productAssortments, function(item) {
                ids.push(item.id);
            });

            $http.post('/api/product-assortments/update-positions/', { ids: ids })
                .then(
                    function onSuccess() {},
                    function onError() {}
                );
        }
    };

    $scope.toggleIsPublished = function() {
        $scope.productAssortment.is_published = !$scope.productAssortment.is_published;
    };

    $scope.removeProductAssortment = function (id) {
        $http.delete('/api/product-assortments/' + id)
            .then(function onSuccess(response) {
                $scope.fetchProductAssortments();
            });
    };

    $scope.updateProductAssortment = function () {
        $scope.data = null;
        $scope.errors = [];
        var data = $scope.productAssortment;

        var configs = {
            headers: { 'Content-Type': undefined },
            transformRequest: function (data) {
                var formData = new FormData();

                formData.append('_method', 'PUT')

                angular.forEach(data, function (value, key) {
                    formData.append(key, value);
                });

                return formData;
            }
        };

        var formatData = {
            name: data.name,
            favorite_title: data.favorite_title,
            budget_title: data.budget_title,
            price: data.price,
            max_retail_price: data.max_retail_price,
            image: data.image,
            is_published: data.is_published ? 1 : 0,
            type: data.type
        };

        $http.post('/api/product-assortments/' + $scope.productAssortment.id, formatData, configs)
            .then(function onSuccess(response) {
                $scope.productAssortment = null;
                $('#updateProductAssortment').modal('hide');

                setTimeout(function() {
                    $scope.fetchProductAssortments();
                }, 300);
            }, function onError(response) {
                if (response.status === 422) {
                    $scope.errors = response.data.error.validation_errors;
                }
            });
    };

    $scope.productAssortmentLoading = false;
    $scope.productAssortment = null;

    $scope.fetchProductAssortment = function(id) {
        $scope.productAssortmentLoading = true;

        $http.get('/api/product-assortments/' + id)
            .then(function onSuccess(response) {
                $scope.productAssortment = response.data.data;
                $scope.productAssortmentLoading = false;
            });
    };

    $scope.addProductAssortment = function() {
        $scope.productAssortment = {
            name: '',
        };
        $scope.focusAddProductAssortment = true;
    };

    $scope.saveProductAssortment = function() {
        $scope.data = null;
        $scope.errors = [];
        var data = $scope.productAssortment;

        var configs = {
            headers: { 'Content-Type': undefined },
            transformRequest: function (data) {
                var formData = new FormData();
                angular.forEach(data, function (value, key) {
                    formData.append(key, value);
                });

                return formData;
            }
        };

        $http.post('/api/product-assortments', data, configs)
            .then(function onSuccess(response) {
                $('#addProductAssortment').modal('hide');

                setTimeout(function() {
                    $location.path('/product-assortments/' + response.data.created_id);
                }, 300);
            }, function onError(response) {
                if (response.status === 422) {
                    $scope.errors = response.data.error.validation_errors;
                }
            });
    };

    $scope.fetchProductAssortments = function() {
        $scope.state.refreshingProducts = true;

        $http.get('/api/giftuniverse/product-assortments', {
            params: {
                take: $scope.state.take,
            }
        }).then(function onSuccess(response) {
            $scope.state.productAssortments = response.data.data;

            if (response.data.data.length !== $scope.state.productAssortments.length) {
                $scope.state.productAssortmentsAllResults = false;
            }

            if (response.data.data.length < $scope.state.take) {
                $scope.state.productAssortmentsAllResults = true;
            }

            $scope.state.productAssortmentsLoading = false;
            $scope.state.productAssortmentsRefreshing = false;
        });
    };

    $scope.fetchProductAssortments();

    $scope.loadMoreProductAssortments = function() {
        $scope.state.take += 15;
        $scope.fetchProductAssortments();
    };

    $scope.locationUpdate = function() {
        //
    };

    $scope.locationUpdate();

    $scope.$on('$routeUpdate', function() {
        $scope.locationUpdate();
    });
}]);

ctrl.controller('reportCtrl', ["$scope", "$location", "$http", "$timeout", "$filter", function ($scope, $location, $http, $timeout, $filter) {
    $scope.loading = true;
    $scope.refreshingReports = true;
    $scope.allResults = false;
    $scope.reports = [];
    $scope.flag = false;
    $scope.flagUpdated = false;
    $scope.flagSchedule = false;
    $scope.flagMessage = null;
    $scope.scheduleLoading = false;
    $scope.data = null;
    $scope.sendDisabled = false;
    $scope.errorMessageDateNotSelected = false;

    $scope.report = {
        title: '',
        type: 'orderTotals',
        shop_id: '',
        shops: [],
        distribution: 'address1',
        export_format: 'xls',
        created_at_min: '',
        created_at_max: '',
        business_area: 0,
        type_id: '',
        type_name: '',
        all_shops: false,
        price: 300,
        has_orders: false
    };

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.take;

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.getCustomers = function (query) {
        $scope.loadingCustomers = true;
        var callUrl = '/api/customers';
        return $http.get(callUrl, {
            params: {
                query: query
            }
        }).then(function (res) {
            var results = [];
            angular.forEach(res.data.data, function (item) {
                results.push({
                    id: item.id,
                    email: item.email
                });
            });
            $scope.loadingCustomers = false;

            return results;
        });
    };

    $scope.getProducts = function (query) {
        $scope.loadingProducts = true;
        var callUrl = '/api/products';
        return $http.get(callUrl, {
            params: {
                query: query
            }
        }).then(function (res) {
            var results = [];
            angular.forEach(res.data.data, function (item) {
                results.push({
                    id: item.id,
                    title: item.title
                });
            });
            $scope.loadingProducts = false;

            return results;
        });
    };

    $scope.getCompanies = function (query) {
        $scope.loadingCompanies = true;
        var callUrl = '/api/addresses';
        return $http.get(callUrl, {
            params: {
                company: query
            }
        }).then(function (res) {
            var results = [];
            angular.forEach(res.data.data, function (item) {
                results.push({
                    company_name: item.company
                });
            });
            $scope.loadingCompanies = false;

            return results;
        });
    };

    $scope.change = function () {
        $scope.loading = true;
        $scope.refreshingReports = true;
        $http.get('/api/reports', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.reports = response.data.data;
            if (response.data.data.length !== $scope.reports.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingReports = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.shops = [];
    $scope.getShops = function () {
        $http.get('/api/shops-compact').then(function onSuccess(response) {
            $scope.shops = response.data;
        });
    };

    $scope.getShops();

    $scope.addEditorReport = function () {
        $scope.reportAsync = '';
        $scope.customer = null;
        $scope.data = null;
        $scope.report = {
            title: '',
            type: 'customersPointsBalances',
            shop_id: '',
            shops: [],
            distribution: 'address1',
            export_format: 'xls',
            created_at_min: '',
            created_at_max: '',
            business_area: 0,
            type_id: '',
            type_name: '',
            all_shops: false,
            price: 300,
            has_orders: false
        };
        $scope.focusReport = true;
    };

    $scope.saveEditorReport = function () {
        $scope.data = null;
        var data = $scope.report;

        $http.post('/api/reports', data).then(function onSuccess(response) {
            $scope.report.id = response.data.created_id;
            var title = $.grep($scope.shops, function (e) {
                return e.id === $scope.report.shop_id;
            })[0];

            $scope.report.shop_id = $scope.shops[0].id
            $scope.report.shop_title = typeof title !== "undefined" ? title.title : '';
            $scope.report.updated_at = new Date();
            $scope.reports.unshift($scope.report);

            $("#addEditorReport").modal('hide');
            $scope.focusReport = false;
            $scope.flag = true;
            $timeout(function () {
                $scope.flag = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.addReport = function () {
        $scope.reportAsync = '';
        $scope.customer = null;
        $scope.data = null;
        $scope.report = {
            title: '',
            type: 'orderTotals',
            shop_id: '',
            shops: [],
            distribution: 'address1',
            export_format: 'xls',
            created_at_min: '',
            created_at_max: '',
            business_area: 0,
            type_id: '',
            type_name: '',
            all_shops: false,
            price: 300,
            has_orders: false
        };
        $scope.focusReport = true;
    };

    $scope.saveReport = function () {
        $scope.data = null;
        var data = $scope.report;
        if (data.type === 'allGiftsInGiftuniverse') {
            data.shop_id = 0;
            data.all_shops = true;
        }
        $http.post('/api/reports', data).then(function onSuccess(response) {
            $scope.report.id = response.data.created_id;
            var title = $.grep($scope.shops, function (e) {
                return e.id === $scope.report.shop_id;
            })[0];
            $scope.report.shop_id = data.all_shops === true ? 0 : data.shop_id;
            $scope.report.shop_id = $scope.report.all_shops === true ? 0 : $scope.report.shop_id;
            $scope.report.shop_title = typeof title !== "undefined" ? title.title : '';
            $scope.report.updated_at = new Date();
            $scope.reports.unshift($scope.report);
            $("#addReport").modal('hide');
            $scope.focusReport = false;
            $scope.flag = true;
            $timeout(function () {
                $scope.flag = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editReport = function (report) {
        $scope.reportAsync = '';
        $scope.data = null;
        $scope.report = report;
        $scope.report.all_shops = !$scope.report.shop_id;
        if ($scope.report.type === 'orderStatisticsByCustomer') {
            $scope.getCustomer($scope.report.type_id);
        }
        if ($scope.report.type === 'orderStatisticsByCompany') {
            $scope.getCompany($scope.report.type_name);
        }
        if ($scope.report.type === 'orderStatisticsByProduct') {
            $scope.getProduct($scope.report.type_id);
        }
        $scope.focusEditReport = true;
    };

    $scope.updateReport = function (report) {
        $scope.data = null;
        $http.put('/api/reports/' + report.id, report).then(function () {
            $scope.flagUpdated = true;
            $("#editReport").modal('hide');
            $scope.focusEditReport = false;
            $timeout(function () {
                $scope.flagUpdated = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteReport = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteReport = function (idx) {
        $http.delete('/api/reports/' + $scope.reports[idx].id).then(function () {
            $scope.reports.splice(idx, 1);
            $("#deleteReport").modal('hide');
        });
    };

    $scope.schedule = {
        id: '',
        owner_id: 0,
        subject: '',
        datetime: new Date,
        message: '',
        filter: false,
        owner_resource: 'report',
        recipient_name: '',
        recipient_email: ''
    };
    $scope.scheduleFormShow = false;

    $scope.showHideScheduleForm = function () {
        $scope.scheduleFormShow = $scope.scheduleFormShow !== true;
    };

    $scope.reportSchedule = function (idx) {
        $scope.data = null;
        $scope.scheduleFormShow = false;
        $scope.index = idx;
        $scope.scheduleLoading = true;
        $scope.schedule = {
            id: '',
            owner_id: $scope.reports[idx].id,
            subject: '',
            datetime: new Date,
            message: '',
            filter: false,
            owner_resource: 'report',
            recipient_name: '',
            recipient_email: ''
        };
        $http.get('/api/campaigns/report/' + $scope.reports[idx].id).then(function onSuccess(response) {
            $scope.campaigns = response.data.data;
            $scope.scheduleLoading = false;
        });
    };

    $scope.scheduleReport = function () {
        $scope.data = null;
        var data = $scope.schedule;
        $http.post('/api/campaigns', data).then(function onSuccess(response) {
            $scope.schedule.id = response.data.created_id;
            $scope.campaigns.push($scope.schedule);
            $scope.flagSchedule = true;
            $scope.scheduleFormShow = false;
            $timeout(function () {
                $scope.flagSchedule = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.deleteCampaign = function (idx) {
        $http.delete('/api/campaigns/' + $scope.campaigns[idx].id).then(function () {
            $scope.campaigns.splice(idx, 1);
        });
    };

    $scope.isScheduleOld = function (datetime) {
        return Date.parse(datetime) < Date.now();
    };

    $scope.checkSelectedValue = function (field) {
        if ($scope.report.type === 'productsSale') {
            if (field === 'shop_id' && $scope.report.shop_id > 0) {
                $scope.report.business_area = null;
            }
            if (field === 'business_area' && $scope.report.business_area > 0) {
                $scope.report.shop_id = null;
            }
        }
    };

    $scope.setFilterId = function (item) {
        $scope.report.type_id = item.id;
    };

    $scope.setFilterName = function (item) {
        $scope.report.type_name = item.company_name;
    };

    $scope.getCustomer = function (customer_id) {
        $http.get('/api/customers/' + customer_id).then(function onSuccess(response) {
            $scope.reportAsync = response.data.data.email;
        });
    };

    $scope.getCompany = function (company_name) {
        if (company_name.length > 0) {
            $http.get('/api/addresses', {
                params: {
                    company: company_name
                }
            }).then(function onSuccess(response) {
                $scope.reportAsync = response.data.data[0].company;
            });
        }
    };

    $scope.getProduct = function (product_id) {
        $http.get('/api/products/' + product_id).then(function onSuccess(response) {
            $scope.reportAsync = response.data.data.title;
        });
    };

    $scope.saveLastRun = function (id) {
        $http.post(`/api/reports/save-last-run/${id}`).then(function onSuccess(response) {
            console.log(response.data);
        }, function onError(response) {
            console.log(response.data);
        });
    };

    $scope.loadMoreReports = function () {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.loadMore = function () {
        $scope.refreshingReports = true;
        $http.get('/api/reports', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.reports.length) {
                $scope.reports = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingReports = false;
        });
    };

    $scope.addReportEmail = function () {
        $scope.sendDisabled = false;
        $scope.reportAsync = '';
        $scope.customer = null;
        $scope.data = null;
        $scope.report = {
            title: '',
            type: 'orderListsDetailed',
            shop_id: '',
            shops: [],
            distribution: 'address1',
            export_format: 'xls',
            created_at_min: '',
            created_at_max: '',
            business_area: 0,
            type_id: '',
            type_name: '',
            all_shops: false,
            price: 300,
            has_orders: false
        };
        $scope.getShops();
    };

    $scope.recipients = {
        customers: 0,
        orders: 0
    };

    $scope.checkReportEmailRecipients = function () {
        $scope.recipients = {
            customers: 0,
            orders: 0
        };
        if ($scope.report.type === 'orderListsDetailed' && $scope.report.shops.length && $scope.report.created_at_min && $scope.report.created_at_max) {
            $scope.data = null;
            var data = $scope.report;

            $http.post('/api/reports/emails/recipients', data).then(function onSuccess(response) {
                $scope.recipients = response.data;
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    var reportTimeout;
    $scope.$watch('report', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (reportTimeout) $timeout.cancel(reportTimeout);
            reportTimeout = $timeout(function () {
                $scope.checkReportEmailRecipients();
            }, 1000);
        }
    }, true);

    $scope.sendReportEmail = function () {
        $scope.sendDisabled = true;
        $scope.data = null;
        var data = $scope.report;

        $http.post('/api/reports/emails', data).then(function onSuccess(response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
                $scope.sendDisabled = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
            $scope.sendDisabled = false;
        });
    };
    $scope.downloadCustomerReport = function () {
        if ($scope.filterDate.date_from !== null && $scope.filterDate.date_from !== null) {
            var startDate = $scope.filterDate.date_from.substring(0, $scope.filterDate.date_from.indexOf('T'));
            var endDate = $scope.filterDate.date_to.substring(0, $scope.filterDate.date_from.indexOf('T'));
            window.location.href = '/api/reports/customers-points-balances/0/' + startDate + '/' + endDate;
        } else {
            window.location.href = '/api/reports/customers-points-balances/0';
        }
    };
    $scope.downloadProductReport = function () {
        if ($scope.filterDate.date_from !== null && $scope.filterDate.date_from !== null) {
            var startDate = $scope.filterDate.date_from.substring(0, $scope.filterDate.date_from.indexOf('T'));
            var endDate = $scope.filterDate.date_to.substring(0, $scope.filterDate.date_from.indexOf('T'));
            window.location.href = '/api/reports/shop-products-overview/0/' + startDate + '/' + endDate;
        } else {
            window.location.href = '/api/reports/shop-products-overview/0';
        }
    }

    $scope.datetimePickerConfig1 = {
        dropdownSelector: '#dropdown1',
        startView: 'year',
        minView: 'day'
    };
    $scope.filterDate = {
        date_from: null,
        date_to: null
    };
    $scope.$watch('filterDate.date_from', function (newValue) {
        if (typeof newValue !== "object" || newValue) {
            $scope.datetimeFormatted1 = moment(newValue).format('L');
        } else {
            $scope.datetimeFormatted1 = '';
        }
    }, true);
    $scope.$watch('filterDate.date_to', function (newValue) {
        if (typeof newValue !== "object" || newValue) {
            $scope.datetimeFormatted2 = moment(newValue).format('L');
        } else {
            $scope.datetimeFormatted2 = '';
        }
    }, true);

    $scope.setToday = function () {
        today = new Date();
        $scope['filterDate'].date_from = moment(today).format();
        $scope['filterDate'].date_to = moment(today).format();
    };

    $scope.downloadAcceptMarketingReport = function () {

        var startDate = null;
        var endDate = null;
        if ($scope.filterDate.date_from !== null && $scope.filterDate.date_to !== null) {
            startDate = $scope.filterDate.date_from.substring(0, $scope.filterDate.date_from.indexOf('T'));
            endDate = $scope.filterDate.date_to.substring(0, $scope.filterDate.date_from.indexOf('T'));
        }

        var data = {};
        data['startDate'] = startDate;
        data['endDate'] = endDate;

        if (startDate != null && endDate != null) {
            $http.get('/api/reports/accepts-marketing/' + data['startDate'] + '/' + data['endDate']).then(function onSuccess(response) {
                var csv = response.data;

                var downloadLink = document.createElement("a");
                var blob = new Blob(["\ufeff", csv]);
                var url = URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = "data-" + Date.now() + ".csv";

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }, function onError(error) {
                $scope.errorMessageDateNotSelected = "Something went wrong";
            });
        } else {
            $scope.errorMessageDateNotSelected = "Please select a date first";
        }
    }

}]);

ctrl.controller('shopArchiveCtrl', ["$scope", "$http", "$location", "$timeout", function($scope, $http, $location, $timeout) {
    $scope.loading = false;
    $scope.refreshingShops = true;
    $scope.allResults = false;
    $scope.shops = [];

    $scope.take = 15;
    $scope.searchParams = {query: null};
    $scope.searchParams.take = $scope.take;
    $scope.searchParams.include = 'domains';
    $scope.searchParams.is_archived = '1';

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;

    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function () {
        $scope.loading = true;
        $scope.refreshingShops = true;
        $http.get('/api/shops', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.shops = response.data.data;
            if (response.data.data.length !== $scope.shops.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingShops = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.loadMoreShops = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.loadMore = function() {
        $scope.refreshingShops = true;
        $http.get('/api/shops', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.shops.length) {
                $scope.shops = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingShops = false;
        });
    };

}]);

ctrl.controller('shopDetailCtrl', ["$scope", "$routeParams", "$location", "$http", "Upload", "$timeout", "$filter", "activeProfile", "$route", "moment", function ($scope, $routeParams, $location, $http, Upload, $timeout, $filter, activeProfile, $route, moment) {
    $scope.loading = true;
    $scope.flag = false;
    $scope.tableRateCollapsed = false;
    $scope.glsCollapsed = true;
    $scope.pickupCollapsed = false;
    $scope.handlingRatesCollapsed = false;
    $scope.yoyoCollapsed = true;
    $scope.flagPivot = false;
    $scope.flagAllowToBuy = false;
    $scope.flagMessage = null;
    $scope.data = null;
    $scope.usersList = null;
    $scope.usersList = null;
    $scope.currencies = null;
    $scope.payment = null;
    $scope.gls_carrier_service = null;
    $scope.yoyo_carrier_service = null;

    $scope.logoUploaded = false;
    $scope.logoEmailUploaded = false;
    $scope.logoMobileUploaded = false;
    $scope.logoStickyMenuUploaded = false;
    $scope.flagPointsUpdatedSuccessfully = false;

    $scope.flagFillDates = null;

    $scope.tokenTypes = [];

    $scope.shop = {
        id: null,
        status: 1,
        cc_email: '',
        business_area: 1,
        languages: {},
        currencies: {},
        pickup_active: false,
        pickup_price: 0.00,
        pickup_name: '',
        pickup_description: '',
        pickup_countries: 'DK',
        pickup_position: 0,
        primary_currency_id: 0,
        primary_language_id: 0,
        confirm_delivery_address: '1',
        timezone: 'UTC+1',
        timezoneName: 'Europe/Copenhagen',
        price_based_handling_rates: {data: []},
        weight_based_handling_rates: {data: []},
        handling_label: 'handling',
        carrier_services: {data: []},
        cart_limit_management: 'ignore',
        cart_max_limit: 0,
        taxes_included_on_limit: '1',
        slideshow_id: null,
        slideshow_pages: false,
        slideshow_categories: false,
        send_xml: 'disabled',
        inventory_management: 'inventory',
        design: {},
        payment_gateways: {},
        email_point_status_interval: null,
        email_point_status_day: 'MONDAY',
        invoice_text: '',
        offer_pdf_text: '',
        giftuniverse_active: false,
        giftuniverse_default_product_id: null,
        giftuniverse_week: 48,
        giftuniverse_delivery_date: '',
        giftuniverse_delivery_type: 'business',
        giftuniverse_price: 300,
        giftuniverse_bb_product_number: '',
        giftuniverse_type: null,
        contact_email: '',
        promos: {data: []},
        images: {data: []},
        footer_title: '',
        footer_content: '',
        hide_username_pp: false,
        barcode_enabled: false,
        taxes_included: false,
        user_id: null,
        login_placement: 'home',
        taxes_on_shipping: false,
        taxes_included_on_retail: false,
        product_idea_notification_emails: [
            {'id': 1, 'email': 'lars@'},
            {'id': 2, 'email': 'john@'}
        ],
        user_approval: false,
        user_approval_notification_emails: [
            {'id': 1, 'email': 'lars@'},
            {'id': 2, 'email': 'john@'}
        ],
        is_concept: false,
        meta_title: '',
        meta_description: '',
        delivery: '',
        department: '',
        budget: '',
        is_special_billing: 0,
        use_navision_quantity: 0
    };

    $scope.flagPrimaryCurrencySet = false;
    $scope.flagPrimaryLanguageSet = false;

    $scope.TYPES = {
        CHRISTMAS: 'christmas',
        EASTER: 'easter',
        OCCASION: 'occasion'
    }

    // Get all active languages as an array
    var langs = activeProfile.account_languages.split(',');
    //langs.push(activeProfile.account_default_lang);
    langs.sort();
    $scope.langs = langs;
    $scope.texts = {
        email_confirmation: {},
        testimonial: {},
        title: {},
        content: {}
    };

    $scope.email_templates = {
        order_confirmation: {},
        point_update: {},
        gift_confirmation: {}
    };

    $scope.getCarrierService = function (type) {
        var service = null;
        if (typeof $scope.shop.carrier_services.data !== 'undefined' && $scope.shop.carrier_services.data.length) {
            angular.forEach($scope.shop.carrier_services.data, function (value, key) {
                if (type === value.type) {
                    service = value;
                }
            });
        }

        if (type === 'yoyo') {
            var yoyoIgnored = [];
            if (service !== null && typeof service.carrier_service_rates.data !== 'undefined') {
                angular.forEach(service.carrier_service_rates.data, function (value, index) {
                    if (yoyoIgnored.indexOf(value.name) !== -1) {
                        service.carrier_service_rates.data.splice(index, 1);
                    }
                });
            }
        }

        return service;
    };

    $scope.slideshows = {};

    $scope.getSlideshows = function () {
        $http.get('/api/slideshows').then(function onSuccess(response) {
            $scope.slideshows = response.data.data;
        });
    };

    $http.get('/api/shops/' + $routeParams.shopId + '?include=product_ideas,checkout_agreements,countries,countries.price_based_shipping_rates,countries.weight_based_shipping_rates,payment_gateways,languages,design,design.metafields,address,domains,metafields,offers,carrier_services,carrier_services.carrier_service_rates,categories,pages,delivery_addresses,currencies,price_based_handling_rates,weight_based_handling_rates,promos,images,auto_gift,send_product_idea_notification,product_idea_notifications,user_approval_notifications,shop_email_templates').then(function onSuccess(response) {
        $scope.shop = response.data.data;

        $scope.loading = false;
        $scope.shop.published_at = response.data.data.published_at;
        $scope.shop.unpublished_at = response.data.data.unpublished_at;
        $scope.shop.product_idea_notification_emails = response.data.data.product_idea_notifications.data;
        $scope.shop.user_approval_notification_emails = response.data.data.user_approval_notifications.data;
        $scope.shop.autoGiftName = '';
        $scope.is_concept = response.data.data.is_concept;
        $scope.tokenTypes = response.data.data.token_types;
        if (response.data.data.giftuniverse_default_product_id) {
            var autoGift = response.data.data.auto_gift.data;

            $scope.shop.autoGiftName = autoGift.title + ' (' + autoGift.id + ')';
        }

        $scope.getSlideshows();

        angular.forEach(langs, function (langCode) {
            var field = ($filter('filter')($scope.shop.metafields.data, {
                key: 'email_confirmation',
                namespace: langCode
            }, undefined)[0]);
            $scope.texts.email_confirmation[langCode] = (field !== undefined) ? field.value : '';
        });

        angular.forEach(langs, function (langCode) {
            var field = ($filter('filter')($scope.shop.metafields.data, {
                key: 'testimonial',
                namespace: langCode
            }, undefined)[0]);
            $scope.texts.testimonial[langCode] = (field !== undefined) ? field.value : '';
        });

        angular.forEach(langs, function (langCode) {
            var field = ($filter('filter')($scope.shop.metafields.data, {
                key: 'title',
                namespace: langCode
            }, undefined)[0]);
            $scope.texts.title[langCode] = (field !== undefined) ? field.value : '';
        });

        angular.forEach(langs, function (langCode) {
            var field = ($filter('filter')($scope.shop.metafields.data, {
                key: 'content',
                namespace: langCode
            }, undefined)[0]);
            $scope.texts.content[langCode] = (field !== undefined) ? field.value : '';
        });

        $scope.gls_carrier_service = $scope.getCarrierService('gls');
        $scope.yoyo_carrier_service = $scope.getCarrierService('yoyo');

        if ($scope.gls_carrier_service !== null && typeof $scope.gls_carrier_service.id !== 'undefined') {
            $scope.glsCollapsed = false;
        }
        if ($scope.yoyo_carrier_service !== null && typeof $scope.yoyo_carrier_service.id !== 'undefined') {
            $scope.yoyoCollapsed = false;
        }

        if (response.data.data.shop_email_templates.data) {
            angular.forEach(response.data.data.shop_email_templates.data, function (shopEmailTemplate, index) {
                let template = shopEmailTemplate.template != '' ? shopEmailTemplate.template : {};
                let options = shopEmailTemplate.options != '' ? shopEmailTemplate.options : {};
                $scope.email_templates[shopEmailTemplate.type] = {
                    template: template,
                    options: options
                };
            });
        }

        var meta_title = ($filter('filter')($scope.shop.metafields.data, {
            key: 'meta_title',
            namespace: 'general'
        }, undefined)[0]);
        $scope.shop.meta_title = (meta_title !== undefined) ? meta_title.value : '';

        var meta_description = ($filter('filter')($scope.shop.metafields.data, {
            key: 'meta_description',
            namespace: 'general'
        }, undefined)[0]);
        $scope.shop.meta_description = (meta_description !== undefined) ? meta_description.value : '';

        var delivery = ($filter('filter')($scope.shop.metafields.data, {
            key: 'delivery',
            namespace: 'general'
        }, undefined)[0]);
        $scope.shop.delivery = (delivery !== undefined) ? delivery.value : '';

        var department = ($filter('filter')($scope.shop.metafields.data, {
            key: 'department',
            namespace: 'general'
        }, undefined)[0]);
        $scope.shop.department = (department !== undefined) ? department.value : '';

        var budget = ($filter('filter')($scope.shop.metafields.data, {
            key: 'budget',
            namespace: 'general'
        }, undefined)[0]);
        $scope.shop.budget = (budget !== undefined) ? budget.value : '';
    });

    $http.get('js/countries.json').then(function onSuccess(response) {
        $scope.countryList = response.data;
    });

    $http.get('/api/users?user_type=kam,admin').then(function onSuccess(response) {
        $scope.usersList = response.data.data;
    });

    $http.get('/api/currencies').then(function onSuccess(response) {
        $scope.currencies = response.data.data;
    });

    $scope.saveShop = function (isValid) {
        $scope.changeGls();
        $scope.data = null;
        var $shop = null;
        var accepted_cards = [];
        angular.forEach($('.ac'), function (ac) {
            if (ac.checked) accepted_cards.push(ac.value);
        });
        $scope.shop.accepted_cards = accepted_cards;
        var confirmation_fields = [];
        angular.forEach($('.cp'), function (cp) {
            if (cp.checked) {
                if (document.getElementById(cp.value + 'HiddenInCheckout').checked) {
                    confirmation_fields.push(cp.value + ':5');
                }
                if (cp.value === 'vat' && document.getElementById(cp.value + 'Validate').checked) {
                    confirmation_fields.push(cp.value + ':4');
                }
                if (document.getElementById(cp.value + 'Required').checked) {
                    confirmation_fields.push(cp.value + ':3');
                }
                if (document.getElementById(cp.value + 'Disabled').checked) {
                    confirmation_fields.push(cp.value + ':2');
                }
                if (document.getElementById(cp.value + 'Hidden').checked) {
                    confirmation_fields.push(cp.value + ':1');
                } else {
                    confirmation_fields.push(cp.value + ':0');
                }
            }
        });

        $scope.shop.carrier_services.data = [
            $scope.gls_carrier_service,
            $scope.yoyo_carrier_service
        ];

        var pickup_countries = [];
        angular.forEach($('.pf'), function (pf) {
            if (pf.checked) pickup_countries.push(pf.value);
        });
        $scope.shop.pickup_countries = pickup_countries;
        $scope.shop.texts = $scope.texts;
        $scope.shop.email_templates = $scope.email_templates;

        $shop = angular.copy($scope.shop);
        $shop.design.data.confirmation_fields = confirmation_fields;

        if (isValid) {
            $http.put('/api/shops/' + $scope.shop.id, $shop).then(function () {
                $scope.shop.design.data.confirmation_fields = $shop.design.data.confirmation_fields.toString();
                $scope.flag = true;
                $timeout(function () {
                    $scope.flag = false;
                }, 3000);
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.deleteShop = function () {
        $http.delete('/api/shops/' + $scope.shop.id).then(function () {
            $location.path("/shops");
            $("#deleteShop").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.deleteShopGaveuniverset = function () {
        $http.delete('api/shops/' + $scope.shop.id + '/delete-gaveuniverset').then(function () {
            $location.path("/shops");
            $("#deleteShopGaveuniverset").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    }

    $scope.addPayment = function () {
        $scope.payment = {
            shop_id: $scope.shop.id,
            type: 'custom',
            name: '',
            message: '',
            login: '',
            credential1: '',
            credential2: '',
            credential3: '',
            credential4: ''
        };
        $scope.focusCustomPayment = true;
    };

    $scope.saveNewPayment = function () {
        var data = $scope.payment;
        $http.post('/api/payment_gateways', data).then(function onSuccess(response) {
            $scope.payment.id = response.data.created_id;
            $scope.shop.payment_gateways.data.push($scope.payment);
            $scope.focusCustomPayment = false;
            $("#addPayment").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.editPayment = function (payment) {
        $scope.payment = payment;
        $scope.focusEditPayment = true;
    };

    $scope.savePayment = function () {
        $("#editPayment").modal('hide');
        $(".modal-backdrop").hide(500).remove();
    };

    $scope.confirmDeletePayment = function (idx) {
        $scope.idx = idx;
    };

    $scope.deletePayment = function (idx) {
        $http.delete('/api/payment_gateways/' + $scope.shop.payment_gateways.data[idx].id).then(function () {
            $scope.shop.payment_gateways.data.splice(idx, 1);
            $("#deletePayment").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.addCountry = function () {
        $scope.country = {
            shop_id: $scope.shop.id,
            code: 'DK',
            name: 'Denmark',
            tax: '25',
            price_based_shipping_rates: {data: []},
            weight_based_shipping_rates: {data: []}
        };
        $scope.focusCountry = true;
    };

    $scope.saveNewCountry = function () {
        var data = $scope.country;
        var selectedCountry = $filter('filter')($scope.countryList, {cca2: $scope.country.code});
        $scope.country.name = selectedCountry[0].name.common;
        $http.post('/api/countries', data).then(function onSuccess(response) {
            $scope.country.id = response.data.created_id;
            $scope.shop.countries.data.push($scope.country);
            $scope.focusCountry = false;
            $("#addCountry").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editCountry = function (country, idx) {
        $scope.country = country;
        $scope.idx = idx;
        $scope.focusEditCountry = true;
    };

    $scope.saveCountry = function () {
        $("#editCountry").modal('hide');
        $(".modal-backdrop").hide(500).remove();
        $scope.focusEditCountry = false;
    };

    $scope.deleteCountry = function (idx) {
        $http.delete('/api/countries/' + $scope.shop.countries.data[idx].id).then(function () {
            $scope.shop.countries.data.splice(idx, 1);
            $("#editCountry").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.focusRate = false;
    $scope.addRate = function (country, idx) {
        $scope.idx = idx;
        $scope.rate = {
            shop_id: $scope.shop.id,
            country_id: country,
            name: '',
            range: 'price',
            min_order_subtotal: '',
            max_order_subtotal: '',
            weight_low: '',
            weight_high: '',
            price: '',
            currency_management: 'all',
            currencies: []
        };
        $scope.focusRate = true;
    };

    $scope.saveRate = function (idx) {
        var data = $scope.rate;
        var countryIndex = idx;
        if ($scope.rate.range === 'price') {
            $http.post('/api/price_based_shipping_rates', data).then(function onSuccess(response) {
                $scope.rate.id = response.data.created_id;
                $scope.shop.countries.data[countryIndex].price_based_shipping_rates.data.push($scope.rate);
                $scope.focusRate = false;
                $("#addRate").modal('hide');
            }, function onError(response) {
                $scope.data = response.data;
            });
        } else {
            $http.post('/api/weight_based_shipping_rates', data).then(function onSuccess(response) {
                $scope.rate.id = response.data.created_id;
                $scope.shop.countries.data[countryIndex].weight_based_shipping_rates.data.push($scope.rate);
                $scope.focusRate = false;
                $("#addRate").modal('hide');
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.focusEditRate = false;
    $scope.editRate = function (rate, range) {
        $scope.data = null;
        $scope.rate = rate;
        $scope.rate.range = range;
        $scope.focusEditRate = true;
    };

    $scope.updateRate = function () {
        $scope.data = null;
        var data = $scope.rate;
        if ($scope.rate.range === 'price') {
            $http.put('/api/price_based_shipping_rates/' + $scope.rate.id, data).then(function () {
                $scope.focusEditHandlingRate = false;
                $("#editRate").modal('hide');
            }, function onError(response) {
                $scope.data = response.data;
            });
        } else {
            $http.put('/api/weight_based_shipping_rates/' + $scope.rate.id, data).then(function () {
                $scope.focusEditHandlingRate = false;
                $("#editRate").modal('hide');
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.confirmDeleteRate = function (idx, idz, range) {
        $scope.idx = idx;
        $scope.idz = idz;
        $scope.range = range;
    };

    $scope.deleteRate = function (idx, idz, range) {
        if (range === 'price') {
            $http.delete('/api/price_based_shipping_rates/' + $scope.shop.countries.data[idx].price_based_shipping_rates.data[idz].id).then(function () {
                $scope.shop.countries.data[idx].price_based_shipping_rates.data.splice(idz, 1);
                $("#deleteRate").modal('hide');
                $(".modal-backdrop").hide(500).remove();
            });
        } else {
            $http.delete('/api/weight_based_shipping_rates/' + $scope.shop.countries.data[idx].weight_based_shipping_rates.data[idz].id).then(function () {
                $scope.shop.countries.data[idx].weight_based_shipping_rates.data.splice(idz, 1);
                $("#deleteRate").modal('hide');
                $(".modal-backdrop").hide(500).remove();
            });
        }
    };

    // Languages
    $scope.language = {
        id: '',
        shop_id: $scope.shop.id,
        country_code: '',
        name: ''
    };
    $scope.lang = {
        code: '',
        default_currency_id: ''
    };
    $scope.addLanguage = function () {
        $scope.language = {
            id: '',
            shop_id: $scope.shop.id,
            country_code: '',
            name: '',
            default_currency_id: ''
        };
        $scope.focusLanguage = true;
    };

    $scope.saveNewLanguage = function () {
        $scope.language.country_code = $scope.lang['code'];
        $scope.language.default_currency_id = $scope.lang['default_currency_id'];
        $http.post('/api/languages', $scope.language).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.language.id = response.data.created_id;
                $scope.language.name = $filter('translate')($scope.language.country_code);
                $scope.shop.languages.data.push($scope.language);
                $scope.focusLanguage = false;
                $("#addLanguage").modal('hide');
                $(".modal-backdrop").hide(500).remove();
            } else {
                window.location = '/login';
            }
        });
    };

    $scope.confirmDeleteLanguage = function (idx) {
        $scope.data = null;
        $scope.idx = idx;
    };

    $scope.deleteLanguage = function (idx) {
        $scope.data = null;
        $http.delete('/api/languages/' + $scope.shop.languages.data[idx].id).then(function () {
            $scope.shop.languages.data.splice(idx, 1);
            $("#deleteLanguage").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.setPrimaryLanguage = function (idx) {
        $scope.data = null;
        $http.post('/api/shops/' + $scope.shop.id + '/languages/' + $scope.shop.languages.data[idx].id + '/primary').then(function () {
            $scope.shop.primary_language_id = $scope.shop.languages.data[idx].id;
            $scope.flagPrimaryLanguageSet = true;
            $timeout(function () {
                $scope.flagPrimaryLanguageSet = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.checkForLanguage = function (code) {
        if (typeof $scope.shop.languages.data !== 'undefined') {
            return $scope.shop.languages.data.filter(function (e) {
                return e.country_code == code;
            }).length > 0;
        }
    };

    $scope.editLanguage = function (idx) {
        $scope.data = null;
        $scope.lang = $scope.shop.languages.data[idx];
    };

    $scope.updateLanguage = function (lang) {
        $scope.data = null;
        $http.put('/api/languages/' + lang.id, lang).then(function () {
            angular.forEach($scope.shop.languages.data, function (language, index) {
                if (language.id === lang.id) {
                    $scope.shop.languages.data[index].default_currency_id = lang.default_currency_id;
                }
            });
            $("#editLanguage").modal('hide');
            $timeout(function () {
                $scope.flagUpdated = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.getCurrencyName = function (id) {
        var currency_name = '-';
        angular.forEach($scope.currencies, function (currency) {
            if (currency.id == id) {
                if (!currency.code || currency.code.length !== 3) {
                    currency.code = currency.title;
                }
                currency_name = currency.code;
            }
        });

        return currency_name;
    };

    // Currencies
    $scope.currency = {
        id: 0,
        allow_to_buy: false,
        points_enabled: false
    };

    $scope.addCurrency = function () {
        $scope.currency = {
            id: 0,
            allow_to_buy: false,
            points_enabled: false
        };
        $scope.focusCurrency = true;
    };

    $scope.saveNewCurrency = function () {
        $scope.data = null;
        $http.post('/api/shops/' + $scope.shop.id + '/currencies', {
            currency_id: $scope.currency.id
        }).then(function onSuccess() {
            angular.forEach($scope.currencies, function (currency) {
                if (currency.id === $scope.currency.id) {
                    $scope.shop.currencies.data.push(currency);
                }
            });
            $scope.focusCurrency = false;
            $('#addCurrency').modal('hide');
            $('.modal-backdrop').hide(500).remove();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteCurrency = function (idx) {
        $scope.data = null;
        $scope.idx = idx;
    };

    $scope.deleteCurrency = function (idx) {
        $scope.data = null;
        $http.delete('/api/shops/' + $scope.shop.id + '/currencies/' + $scope.shop.currencies.data[idx].id).then(function () {
            $scope.shop.currencies.data.splice(idx, 1);
            $('#deleteCurrency').modal('hide');
            $('.modal-backdrop').hide(500).remove();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.setPrimaryCurrency = function (idx) {
        $scope.data = null;
        $http.post('/api/shops/' + $scope.shop.id + '/currencies/' + $scope.shop.currencies.data[idx].id + '/primary').then(function () {
            $scope.shop.primary_currency_id = $scope.shop.currencies.data[idx].id;
            $scope.flagPrimaryCurrencySet = true;
            $timeout(function () {
                $scope.flagPrimaryCurrencySet = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.setAllowToBuy = function (idx) {
        $scope.data = null;
        $http.post('/api/shops/' + $scope.shop.id + '/currencies/' + $scope.shop.currencies.data[idx].id + '/allow-to-buy').then(function () {
            $scope.shop.currencies.data[idx].allow_to_buy = !$scope.shop.currencies.data[idx].allow_to_buy;
            $scope.flagAllowToBuy = true;
            $timeout(function () {
                $scope.flagAllowToBuy = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.processingPoints = false;
    $scope.setPoints = function () {
        if ($scope.processingPoints === false) {
            $scope.processingPoints = true;
            $scope.data = null;

            var data = $scope.currency;

            data.shop_points = {
                id: $scope.pointsAction.id,
                value: $scope.pointsAction.newValue,
                shop_id: $scope.pointGrouped.shop_id,
                currency_id: $scope.pointGrouped.currency_id,
                customer_id: $scope.pointGrouped.customer_id,
                expiration_date: $scope.pointsAction.expiration_date
            };

            $http.post('/api/shops/' + $scope.shop.id + '/currencies/' + $scope.currency.id + '/points', data).then(function (response) {
                $scope.flagMessage = response.data.message;
                $timeout(function () {
                    $scope.flagMessage = null;
                }, 3000);
                $timeout(function () {
                    $scope.processingPoints = false;
                }, 300);
                $scope.getCustomerPointsGrouped();
            }, function onError(response) {
                $scope.data = response.data;
                $timeout(function () {
                    $scope.processingPoints = false;
                }, 300);
            });
        }
    };

    $scope.updateCustomerPoints = function () {

        if ($scope.customerExpirationPointsDate === '' || $scope.updateCustomerExpirationPointsDate === '') {
            $scope.flagFillDates = "Please fill the dates";
            return;
        }

        let data = {
            expiration_date: $scope.customerExpirationPointsDate,
            update_expiration_date: $scope.updateCustomerExpirationPointsDate
        }

        $http.put('/api/shops/' + $scope.shop.id + '/points/update', data).then(function (response) {

            if (response.status === 200) {
                $scope.flagPointsUpdatedSuccessfully = true;
            }

        }, function onError(error) {
            $scope.isPointsUpdateSuccessful = false;
            $scope.pointsUpdateMessage = 'Customer could not be updated';
        });
    };

    $scope.checkForCurrency = function (id) {
        if (typeof $scope.shop.currencies.data !== 'undefined') {
            return $scope.shop.currencies.data.filter(function (e) {
                return e.id === id;
            }).length > 0;
        }
    };

    // Domains
    $scope.addDomain = function () {
        $scope.domain = {shop_id: $scope.shop.id, domain_name: '', error: false};
        $scope.focusDomain = true;
    };

    $scope.getDomains = function () {
        $scope.loading = true;
        $http.get('/api/shops/' + $routeParams.shopId + '/domains').then(function onSuccess(response) {
            $scope.shop.domains.data = response.data.data;
            $scope.loading = false;
        }, function onError(response) {
            $scope.data = response.data;
            $scope.loading = false;
        });
    };

    $scope.saveDomain = function () {
        $scope.domain.error = false;
        var data = $scope.domain;
        $http.post('/api/domains', data).then(function onSuccess(response) {
            $scope.domain.id = response.data.created_id;
            $scope.getDomains();
            $scope.focusDomain = false;
            $('#addDomain').modal('hide');
            $('.modal-backdrop').hide(500).remove();
        }, function onError(response) {
            if (response.data.error.validation_errors.domain_name[0] !== undefined) {
                $scope.domain.error = response.data.error.validation_errors.domain_name[0]
            } else {
                $scope.domain.error = response.data.error.message
            }
            $scope.loading = false;
        });
    };

    $scope.confirmDeleteDomain = function (idx) {
        $scope.idx = idx;
    };

    $scope.productIdea = {
        'product_name': '',
        'purpose': '',
        'department': '',
        'email': '',
        'is_approved': '',
    };

    $scope.getProductIdeas = function () {
        $scope.loading = true;

        $http.get('/api/shops/' + $routeParams.shopId + '/product-ideas')
            .then(function onSuccess(response) {
                $scope.shop.product_ideas = response.data
                $scope.loading = false;
            }, function onError(response) {
                $scope.data = response.data;
                $scope.loading = false;
            });
    };


    $scope.updateProductIdea = function () {
        $scope.editProductIdeaLoading = true;

        $http.post('/api/shop/' + $scope.shop.id + '/product-idea/' + $scope.productIdea.id, $scope.productIdea)
            .then(function () {
                $("#editProductIdea").modal('hide');
                $scope.getProductIdeas();
                $scope.productIdea = null;

                $timeout(function () {
                    $scope.editProductIdeaLoading = false;
                }, 3000);
            });

        $scope.editProductIdeaLoading = false;
    };

    $scope.editProductIdea = function (productIdea) {
        $scope.productIdea = productIdea;
        $scope.focusEditProductIdea = true;
    };

    $scope.editDomain = function (domain) {
        $scope.domain = domain;
        $scope.focusEditDomain = true;
    };

    $scope.deleteDomain = function (idx) {
        $http.delete('/api/domains/' + $scope.shop.domains.data[idx].id).then(function () {
            $scope.getDomains();
            $("#deleteDomain").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.onFileSelect1 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'logo',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.logoUploaded = true;
                    }
                });
            }
        });
    };

    $scope.onFileSelect2 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'logo_email',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.logoEmailUploaded = true;
                    }
                });
            }
        });
    };

    $scope.onFileSelect3 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'favicon',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.faviconUploaded = true;
                    }
                });
            }
        });
    };

    $scope.onFileSelect4 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'graphics_primary',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.graphicsPrimaryUploaded = true;
                    }
                });
            }
        });
    };

    $scope.onFileSelect10 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'header_image',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.headerGraphicsUploaded = true;
                    }
                });
            }
        });
    };

    $scope.onFileSelect5 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'graphics_secondary',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.graphicsSecondaryUploaded = true;
                    }
                });
            }
        });
    };

    $scope.graphicsOgImageUploaded = false;
    $scope.onFileSelect6 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'og_image',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.graphicsOgImageUploaded = true;
                    }
                });
            }
        });
    };

    $scope.onFileSelect7 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'logo_mobile',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.logoMobileUploaded = true;
                    }
                });
            }
        });
    };

    $scope.onFileSelect8 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'logo_sticky_menu',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.logoStickyMenuUploaded = true;
                    }
                });
            }
        });
    };

    $scope.deleteStickyMenuLogo = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/logo_sticky_menu').then(function () {
            $scope.shop.design.data.logo_sticky_menu = null;
        });
    };

    $scope.emailPointBannerUploaded = false;
    $scope.onFileSelect9 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.design.data.id,
                        owner_resource: 'design',
                        image_key: 'email_point_banner',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.emailPointBannerUploaded = true;
                    }
                });
            }
        });
    };

    $scope.deleteLogo = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/logo').then(function () {
            $scope.shop.design.data.logo = null;
        });
    };

    $scope.deleteEmailPointBanner = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/email_point_banner').then(function () {
            $scope.shop.design.data.logo_email = null;
        });
    };

    $scope.deleteLogoEmail = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/logo_email').then(function () {
            $scope.shop.design.data.logo_email = null;
        });
    };

    $scope.deleteFavicon = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/favicon').then(function () {
            $scope.shop.design.data.favicon = null;
        });
    };

    $scope.deleteGraphicsPrimary = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/graphics_primary').then(function () {
            $scope.shop.design.data.graphics_primary = null;
        });
    };

    $scope.deleteHeaderGraphics = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/header_image').then(function () {
            $scope.shop.design.data.header_image = null;
        });
    };

    $scope.deleteGraphicsSecondary = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/graphics_seconda').then(function () {
            $scope.shop.design.data.graphics_secondary = null;
        });
    };

    $scope.deleteGraphicsOgImage = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/og_image').then(function () {
            $scope.shop.design.data.og_image = null;
        });
    };
    $scope.deleteLogoMobile = function () {
        $http.delete('/api/design/' + $scope.shop.design.data.id + '/images/logo_mobile').then(function () {
            $scope.shop.design.data.logo_mobile = null;
        });
    };

    $scope.archiveShop = function () {
        $scope.shop.is_archived = $scope.shop.is_archived !== true;
        $timeout(function () {
            angular.element('#submitShop').trigger('click');
        }, 100);
    };
    $scope.customerFields = function (type) {
        $scope.fields = [];
        $scope.fields.loading = true;
        $scope.fields.type = type;
        $scope.fields.input = '';
        $http.get('/api/metafields?owner_id=' + $routeParams.shopId + '&owner_resource=shop&key=' + type).then(function onSuccess(response) {
            $scope.fields.content = response.data.data;
            $scope.fields.loading = false;
        });
    };

    $scope.loadCustomerFields = function () {
        $scope.fields.loading = true;
        let data = {
            owner_id: $routeParams.shopId,
            owner_resource: 'shop',
            key: $scope.fields.type,
            input: $scope.fields.input,
            value_type: 'string'
        };
        if ($scope.fields.type === 'department' || $scope.fields.type === 'budget' || $scope.fields.type === 'delivery') {
            data.namespace = 'general';
        }
        $http.post('/api/metafields', data).then(function () {
            $http.get('/api/metafields?owner_id=' + $routeParams.shopId + '&owner_resource=shop&key=' + $scope.fields.type).then(function onSuccess(response) {
                $scope.fields.content = response.data.data;
                $scope.fields.loading = false;
            });
        });
    };

    $scope.updateCustomerFields = function () {
        $scope.fields.loading = true;
        angular.forEach($scope.fields.content, function (field) {
            $http.put('/api/metafields/' + field.id, field);
        });
        $scope.fields.loading = false;
    };

    $scope.deleteAllCustomerFields = function () {
        $scope.fields.loading = true;
        var selected = [];
        angular.forEach($scope.fields.content, function (field) {
            selected.push(field.id);
        });
        $http.post('/api/metafields/delete', {
            ids: selected,
            owner_id: $routeParams.shopId,
            owner_resource: 'shop',
            key: $scope.fields.type
        }).then(function () {
            $('#deleteAllCustomerFields').modal('hide');
            $http.get('/api/metafields?owner_id=' + $routeParams.shopId + '&owner_resource=shop&key=' + $scope.fields.type).then(function onSuccess(response) {
                $scope.fields.content = response.data.data;
                $scope.fields.loading = false;
            });
        });
    };

    $scope.deleteField = function (idx) {
        var fieldId = $scope.fields.content[idx].id;
        $http.delete('/api/metafields/' + fieldId).then(function () {
            $scope.fields.content.splice(idx, 1);
        });
    };

    $scope.sortableOptions = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle',
        cancel: '',
        'ui-floating': false,
        stop: function () {
            var order = [];
            angular.forEach($scope.fields.content, function (item) {
                order.push(item.id);
            });
            $http.post('/api/metafields/order', {order: order}).then(function () {
            }, function onError() {
            });
        }
    };

    $scope.editAddress = function (idx) {
        $scope.editAddressLoading = true;
        $http.get('/api/addresses?owner_id=' + $scope.fields.content[idx].id + '&owner_resource=department').then(function onSuccess(response) {
            if (response.data.data.length === 0) {
                $scope.editAddressItem = {
                    id: 0,
                    owner_id: $scope.fields.content[idx].id,
                    owner_resource: 'department'
                };
            } else {
                $scope.editAddressItem = response.data.data[0];
            }
            $scope.editAddressLoading = false;
        });
    };

    $scope.marketingSettings = {
        mailchimp_list_id: '',
    };

    $scope.getMarketingSettings = function () {
        $http.get('/api/marketing-settings/' + $scope.shop.id)
            .then(function (response) {
                $scope.marketingSettings = response.data;
            });
    };

    $scope.updateMarketingSettings = function () {
        $scope.editMarketingSettingsLoading = true;
        $http.post('/api/marketing-settings/' + $scope.shop.id, $scope.marketingSettings)
            .then(function () {
                $("#marketingSettings").modal('hide');
                $timeout(function () {
                    $scope.editMarketingSettingsLoading = false;
                    $scope.marketingSettings = null;
                }, 3000);
            });
        $scope.editMarketingSettingsLoading = false;
    };

    $scope.updateAddress = function () {
        $scope.editAddressLoading = true;
        if ($scope.editAddressItem.id === 0) {
            $http.post('/api/addresses', $scope.editAddressItem).then(function () {
                $scope.editAddressLoading = false;
                $scope.editAddressItem = null;
            });
        } else {
            $http.put('/api/addresses/' + $scope.editAddressItem.id, $scope.editAddressItem).then(function () {
                $scope.editAddressLoading = false;
                $scope.editAddressItem = null;
            });
        }
        $scope.editAddressLoading = false;
    };

    $scope.addCarrierShipping = function (type, name) {
        $http.post('/api/carrier_services', {
            shop_id: $scope.shop.id,
            is_active: null,
            type: type,
            name: name
        }).then(function () {
            $timeout(function () {
                $route.reload();
            }, 500);
        });
    };

    $scope.sortableCategoryOptions = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle',
        cancel: '',
        'ui-floating': false,
        stop: function () {
            var order = [];
            angular.forEach($scope.shop.categories.data, function (item) {
                order.push(item.id);
            });
            $http.post('/api/categories/order', {shop_id: $scope.shop.id, categoryOrder: order}).then(function () {
            }, function onError() {
            });
        }
    };

    $scope.getCategories = function (val) {
        return $http.get('/api/categories', {
            params: {
                query: val,
                is_active: 1
            }
        }).then(function (res) {
            var results = [];
            angular.forEach(res.data.data, function (item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.addCategory = function (item) {
        $http.post('/api/shops/' + $scope.shop.id + '/categories/' + item.id).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.shop.categories.data.splice(0, 0, item);
            } else {
                window.location = '/login';
            }
        }, function onError() {
            $scope.flagPivot = true;
            $timeout(function () {
                $scope.flagPivot = false;
            }, 3000);
        });
    };

    $scope.deleteCategory = function (idx) {
        $http.delete('/api/shops/' + $scope.shop.id + '/categories/' + $scope.shop.categories.data[idx].id).then(function () {
            $scope.shop.categories.data.splice(idx, 1);
        });
    };

    $scope.sortablePageOptions = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle',
        cancel: '',
        'ui-floating': false,
        stop: function () {
            var order = [];
            angular.forEach($scope.shop.pages.data, function (item) {
                order.push(item.id);
            });
            $http.post('/api/pages/order', {shop_id: $scope.shop.id, pageOrder: order}).then(function () {
            }, function onError() {
            });
        }
    };

    $scope.getPages = function (val) {
        return $http.get('/api/pages', {
            params: {
                query: val
            }
        }).then(function (res) {
            var results = [];
            angular.forEach(res.data.data, function (item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.addPage = function (item) {
        $http.post('/api/shops/' + $scope.shop.id + '/pages/' + item.id).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.shop.pages.data.splice(0, 0, item);
            } else {
                window.location = '/login';
            }
        }, function onError() {
            $scope.flagPivot = true;
            $timeout(function () {
                $scope.flagPivot = false;
            }, 3000);
        });
    };

    $scope.deletePage = function (idx) {
        $http.delete('/api/shops/' + $scope.shop.id + '/pages/' + $scope.shop.pages.data[idx].id).then(function () {
            $scope.shop.pages.data.splice(idx, 1);
        });
    };

    $scope.remove = {
        confirm: null
    };

    $scope.removeLogins = function () {
        var data = $scope.remove;
        $http.post('/api/shops/' + $scope.shop.id + '/logins', data).then(function () {
            $scope.remove = {
                confirm: null
            };
            $('#removeLogins').modal('hide');
        });
    };

    $scope.addDeliveryAddress = function () {
        $scope.delivery_address = {
            shop_id:        $scope.shop.id,
            name:           '',
            company_name:   '',
            address:        '',
            zip_code:       '',
            city:           '',
            country:        '',
            country_code:   '',
            department:     '',
            contact_person: '',
            is_billing:     false,
            billing_email:  null
        };
        $scope.focusAddDeliveryAddress = true;
    };

    $scope.saveDeliveryAddress = function () {
        var selectedCountry = $filter('filter')($scope.countryList, {cca2: $scope.delivery_address.country_code});
        $scope.delivery_address.country = selectedCountry[0].name.common;
        var data = $scope.delivery_address;
        $http.post('/api/shops/' + $scope.shop.id + '/delivery-addresses', data).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.flagDeliveryAddressCreated = true;
                $scope.focusAddDeliveryAddress = false;
                $scope.delivery_address.id = response.data.created_id;
                $scope.delivery_address.updated_at = new Date();
                $scope.shop.delivery_addresses.data.push($scope.delivery_address);
                $("#addDeliveryAddress").modal('hide');
                $timeout(function () {
                    $scope.flagDeliveryAddressCreated = false;
                }, 3000);
            } else {
                window.location = '/login';
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteDeliveryAddress = function (idx) {
        $scope.idx = idx;
    };

    $scope.editDeliveryAddress = function (delivery_address) {
        $scope.delivery_address = delivery_address;
        $scope.focusEditDeliveryAddress = true;
    };

    $scope.updateDeliveryAddress = function (delivery_address) {
        var selectedCountry = $filter('filter')($scope.countryList, {cca2: delivery_address.country_code});
        delivery_address.country = selectedCountry[0].name.common;
        $http.put('/api/shops/' + $scope.shop.id + '/delivery-addresses/' + delivery_address.id, delivery_address).then(function () {
            $scope.flagDeliveryAddressUpdated = true;
            $scope.focusEditDeliveryAddress = false;
            $("#editDeliveryAddress").modal('hide');
            $timeout(function () {
                $scope.flagDeliveryAddressUpdated = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.deleteDeliveryAddress = function (idx) {
        $http.delete('/api/shops/' + $scope.shop.id + '/delivery-addresses/' + $scope.shop.delivery_addresses.data[idx].id).then(function () {
            $scope.flagDeliveryAddressDeleted = true;
            $scope.shop.delivery_addresses.data.splice(idx, 1);
            $("#deleteDeliveryAddress").modal('hide');
            $timeout(function () {
                $scope.flagDeliveryAddressDeleted = false;
            }, 3000);
        });
    };

    $scope.deliveryAddressCsvLoaded = false;
    $scope.importData = [];
    $scope.deliveryAddressCsvsuccess = false;
    $scope.deliveryAddressCsvloading = false;

    $scope.triggerDiscountAddressImport = function () {
        $scope.deliveryAddressCsvLoaded = false;
        $scope.importData = [];
        $scope.deliveryAddressCsvsuccess = false;
        $scope.deliveryAddressCsvloading = false;
    };

    $scope.onFileSelectForDiscountAddressImport = function ($files) {
        $scope.deliveryAddressCsvloading = true;
        $scope.deliveryAddressCsvsuccess = false;
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/files',
                    data: {
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.deliveryAddressCsvloading = false;
                        $scope.importDeliveryAddresses(response.data.file_path);
                    }
                });
            }
        });
    };

    $scope.importDeliveryAddresses = function (fileUrl) {
        $http.get(fileUrl, {
            headers: {
                'Content-Type': 'text/csv;charset=UTF-8',
                'Accept': 'text/csv;charset=UTF-8'
            }
        }).then(function (response) {
            $scope.csv = response.data;
            $scope.deliveryAddressCsvLoaded = true;

            $scope.importData = CSVToArray(response.data);
            $scope.firmvaluePosition = [];
            for (i = 0; i <= 8; i++) {
                $scope.firmvaluePosition.push(0);
            }
            $scope.loadData = $scope.importData;
            $scope.importData.shift();

            $scope.selectedValue = [];
            $scope.selectedValue.push({val: 0, text: $filter('translate')('IMPORT_EMPTY')});
            $scope.selectedValue.push({val: 1, text: $filter('translate')('IMPORT_FIXED')});
            for (i = 2; i < $scope.importData[0].length + 2; i++) {
                $scope.selectedValue.push({val: i, text: $filter('translate')('IMPORT_COLUMN') + ' ' + (i - 1)});
            }
            $scope.name = ($scope.loadData[0].length > 0) ? 0 : -1;
            $scope.companyName = ($scope.loadData[0].length > 1) ? 1 : -1;
            $scope.address = ($scope.loadData[0].length > 3) ? 2 : -1;
            $scope.zipCode = ($scope.loadData[0].length > 2) ? 3 : -1;
            $scope.city = ($scope.loadData[0].length > 4) ? 4 : -1;
            $scope.country = ($scope.loadData[0].length > 5) ? 5 : -1;
            $scope.countryCode = ($scope.loadData[0].length > 6) ? 6 : -1;
            $scope.department = ($scope.loadData[0].length > 7) ? 7 : -1;
            $scope.customerNumber = ($scope.loadData[0].length > 8) ? 8 : -1;
        });
    };

    $scope.updateColumnFirm = function (i, val) {
        if (i === 0) {
            angular.forEach($scope.loadData, function (row) {
                if ($scope.firmvaluePosition[0] === 0) {
                    $scope.firmvaluePosition[0] = i;
                    $scope.name = i;
                } else {
                    $scope.name = $scope.firmvaluePosition[i];
                }
                row[$scope.name] = val;
            });
        }
        if (i === 1) {
            angular.forEach($scope.loadData, function (row) {
                if ($scope.firmvaluePosition[1] === 0) {
                    $scope.firmvaluePosition[1] = i;
                    $scope.companyName = i;
                } else {
                    $scope.companyName = $scope.firmvaluePosition[i];
                }
                row[$scope.companyName] = val;
            });
        }
        if (i === 2) {
            angular.forEach($scope.loadData, function (row) {
                if ($scope.firmvaluePosition[2] === 0) {
                    $scope.firmvaluePosition[2] = i;
                    $scope.address = i;
                } else {
                    $scope.address = $scope.firmvaluePosition[i];
                }
                row[$scope.address] = val;
            });
        }
        if (i === 3) {
            angular.forEach($scope.loadData, function (row) {
                if ($scope.firmvaluePosition[3] === 0) {
                    $scope.firmvaluePosition[3] = i;
                    $scope.zipCode = i;
                } else {
                    $scope.zipCode = $scope.firmvaluePosition[i];
                }
                row[$scope.zipCode] = val;
            });
        }
        if (i === 4) {
            angular.forEach($scope.loadData, function (row) {
                if ($scope.firmvaluePosition[4] === 0) {
                    $scope.firmvaluePosition[4] = i;
                    $scope.city = i;
                } else {
                    $scope.city = $scope.firmvaluePosition[i];
                }
                row[$scope.city] = val;
            });
        }
        if (i === 5) {
            angular.forEach($scope.loadData, function (row) {
                if ($scope.firmvaluePosition[5] === 0) {
                    $scope.firmvaluePosition[5] = i;
                    $scope.country = i;
                } else {
                    $scope.country = $scope.firmvaluePosition[i];
                }
                row[$scope.country] = val;
            });
        }
        if (i === 6) {
            angular.forEach($scope.loadData, function (row) {
                if ($scope.firmvaluePosition[6] === 0) {
                    $scope.firmvaluePosition[6] = i;
                    $scope.countryCode = i;
                } else {
                    $scope.countryCode = $scope.firmvaluePosition[i];
                }
                row[$scope.countryCode] = val;
            });
        }
        if (i === 7) {
            angular.forEach($scope.loadData, function (row) {
                if ($scope.firmvaluePosition[7] === 0) {
                    $scope.firmvaluePosition[7] = i;
                    $scope.department = i;
                } else {
                    $scope.department = $scope.firmvaluePosition[i];
                }
                row[$scope.department] = val;
            });
        }
        if (i === 8) {
            angular.forEach($scope.loadData, function (row) {
                if ($scope.firmvaluePosition[8] === 0) {
                    $scope.firmvaluePosition[8] = i;
                    $scope.customerNumber = i;
                } else {
                    $scope.customerNumber = $scope.firmvaluePosition[i];
                }
                row[$scope.customerNumber] = val;
            });
        }
    };

    $scope.updateColumn = function (i, val) {
        if (i === 0) {
            if (val === 1) {
                $scope.nameShow = true;
            } else {
                $scope.nameShow = false;
                $scope.name = val - 2;
            }
        }
        if (i === 1) {
            if (val === 1) {
                $scope.companyNameShow = true;
            } else {
                $scope.companyNameShow = false;
                $scope.companyName = val - 2;
            }
        }
        if (i === 2) {
            if (val === 1) {
                $scope.addressShow = true;
            } else {
                $scope.addressShow = false;
                $scope.address = val - 2;
            }
        }
        if (i === 3) {
            if (val === 1) {
                $scope.zipCodeShow = true;
            } else {
                $scope.zipCodeShow = false;
                $scope.zipCode = val - 2;
            }
        }
        if (i === 4) {
            if (val === 1) {
                $scope.cityShow = true;
            } else {
                $scope.cityShow = false;
                $scope.city = val - 2;
            }
        }
        if (i === 5) {
            if (val === 1) {
                $scope.countryShow = true;
            } else {
                $scope.countryShow = false;
                $scope.country = val - 2;
            }
        }
        if (i === 6) {
            if (val === 1) {
                $scope.countryCodeShow = true;
            } else {
                $scope.countryCodeShow = false;
                $scope.countryCode = val - 2;
            }
        }
        if (i === 7) {
            if (val === 1) {
                $scope.departmentShow = true;
            } else {
                $scope.departmentShow = false;
                $scope.department = val - 2;
            }
        }
        if (i === 8) {
            if (val === 1) {
                $scope.customerNumberShow = true;
            } else {
                $scope.customerNumberShow = false;
                $scope.customerNumber = val - 2;
            }
        }
        $scope.firmrowShow = ($scope.nameShow || $scope.companyNameShow || $scope.addressShow || $scope.zipCodeShow || $scope.cityShow || $scope.countryShow || $scope.countryCodeShow || $scope.departmentShow || $scope.customerNumberShow);
    };

    $scope.submitDeliveryAddressImport = function () {
        $scope.deliveryAddressCsvloading = true;
        $scope.deliveryAddressCsvLoaded = false;
        $http.post('/api/shops/' + $scope.shop.id + '/delivery-addresses', {
            csv: $scope.importData
        }).then(function onSuccess(response) {
            $scope.deliveryAddressCsvloading = false;
            $scope.deliveryAddressCsvsuccess = true;
            $scope.deliveryAddressCount = response.data.created_id;
            $scope.getDeliveryAddresses();
        });
    };

    function CSVToArray(strData, strDelimiter) {
        strDelimiter = (strDelimiter || ";");
        var objPattern = new RegExp(("(\\" + strDelimiter + "|\\r?\\n|\\r|^)" + "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" + "([^\"\\" + strDelimiter + "\\r\\n]*))"), "gi");
        var arrData = [[]];
        var arrMatches = null;
        if (arrMatches === null) {
        } // Lazy fix for variable definition validation
        while (arrMatches = objPattern.exec(strData)) {
            var strMatchedDelimiter = arrMatches[1];
            if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
                arrData.push([]);
            }
            var strMatchedValue;
            if (!arrMatches.index && arrMatches[0].charAt(0) === strDelimiter) {
                arrData[arrData.length - 1].push('');
            }
            if (typeof arrMatches[2] !== 'undefined') {
                strMatchedValue = arrMatches[2].replace(new RegExp("\"\"", "g"), "\"");
            } else {
                strMatchedValue = arrMatches[3];
            }
            arrData[arrData.length - 1].push(strMatchedValue);
        }

        return arrData;
    }

    $scope.searchParams = {
        query: null
    };
    var tempQueryString = '', queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) {
                $timeout.cancel(queryTimeout);
            }
            tempQueryString = newValue;
            queryTimeout = $timeout(function () {
                $scope.searchParams.query = tempQueryString;
                $scope.getDeliveryAddresses();
            }, 1500);
        }
    }, true);

    $scope.getDeliveryAddresses = function () {
        $http.get('/api/shops/' + $routeParams.shopId + '/delivery-addresses', {
            params: {
                query: $scope.searchParams.query
            }
        }).then(function onSuccess(response) {
            $scope.shop.delivery_addresses = response.data;
        });
    };

    $scope.sortableDeliveryAddressOptions = {
        axis: 'y',
        cursor: 'move',
        handle: '.delivery-address-handle',
        cancel: '',
        'ui-floating': false,
        stop: function () {
            var order = [];
            angular.forEach($scope.shop.delivery_addresses.data, function (item) {
                order.push(item.id);
            });
            $http.post('/api/shops/' + $routeParams.shopId + '/delivery-addresses/order', {
                order: order
            }).then(function () {

            }, function onError() {

            });
        }
    };

    $scope.fileCsv = {file: null};
    $scope.submitCsv = function () {
        if ($scope.fileCsv.file) {
            $scope.uploadCsv($scope.fileCsv.file);
        }
    };

    $scope.errorCsv = null;
    $scope.uploadCsv = function (file) {
        Upload.upload({
            url: 'api/shops/' + $scope.shop.id + '/import-weight-based-shipping',
            data: {
                file: file
            },
            method: 'POST'
        }).then(function () {
            $http.get('/api/shops/' + $routeParams.shopId + '?include=checkout_agreements,countries,countries.price_based_shipping_rates,countries.weight_based_shipping_rates,payment_gateways,languages,design,design.metafields,address,domains,metafields,offers,carrier_services,carrier_services.carrier_service_rates,categories,pages,delivery_addresses,currencies')
                .then(function onSuccess(response) {
                    $scope.shop = response.data.data;
                    $scope.loading = false;
                    $scope.shop.published_at = new Date(response.data.data.published_at);
                    $scope.shop.unpublished_at = new Date(response.data.data.unpublished_at);

                    angular.forEach(langs, function (langCode) {
                        var field = ($filter('filter')($scope.shop.metafields.data, {
                            key: 'email_confirmation',
                            namespace: langCode
                        }, undefined)[0]);
                        $scope.texts.email_confirmation[langCode] = (field !== undefined) ? field.value : '';
                    });

                    angular.forEach(langs, function (langCode) {
                        var field = ($filter('filter')($scope.shop.metafields.data, {
                            key: 'testimonial',
                            namespace: langCode
                        }, undefined)[0]);
                        $scope.texts.testimonial[langCode] = (field !== undefined) ? field.value : '';
                    });

                    if ($scope.shop.carrier_services.data.length !== 0) {
                        $scope.glsCollapsed = false;
                    }
                });
            $scope.fileCsv = {file: null};
        }, function (resp) {
            $scope.fileCsv = {file: null};
            $scope.errorCsv = resp.data;
        }, function (evt) {
            //
        });
    };

    $scope.rates = {
        name: ''
    };

    $scope.checkedCount = 0;
    $scope.checkAll = function (country_index) {
        angular.forEach($scope.shop.countries.data[country_index].price_based_shipping_rates.data, function (rate) {
            rate.selected = $scope.shop.countries.data[country_index].selectedAll;
        });
        angular.forEach($scope.shop.countries.data[country_index].weight_based_shipping_rates.data, function (rate) {
            rate.selected = $scope.shop.countries.data[country_index].selectedAll;
        });
        $scope.countChecked();
    };

    $scope.checkOne = function (country_index, rate_index, type) {
        if (type === 'price') {
            $scope.shop.countries.data[country_index].price_based_shipping_rates.data[rate_index].selected = $scope.shop.countries.data[country_index].price_based_shipping_rates.data[rate_index].selected !== true;
        }
        if (type === 'weight') {
            $scope.shop.countries.data[country_index].weight_based_shipping_rates.data[rate_index].selected = $scope.shop.countries.data[country_index].weight_based_shipping_rates.data[rate_index].selected !== true;
        }
        $scope.countChecked();
    };

    $scope.countChecked = function () {
        $scope.checkedCount = 0;
        angular.forEach($scope.shop.countries.data, function (country, country_index) {
            angular.forEach($scope.shop.countries.data[country_index].price_based_shipping_rates.data, function (rate) {
                if (rate.selected === true) {
                    $scope.checkedCount += 1;
                }
            });
            angular.forEach($scope.shop.countries.data[country_index].weight_based_shipping_rates.data, function (rate) {
                if (rate.selected === true) {
                    $scope.checkedCount += 1;
                }
            });
        });
    };

    $scope.saveRatesName = function () {
        if ($scope.checkedCount && $scope.rates.name.length) {
            angular.forEach($scope.shop.countries.data, function (country, country_index) {
                angular.forEach($scope.shop.countries.data[country_index].price_based_shipping_rates.data, function (rate, rate_index) {
                    if (rate.selected === true) {
                        $scope.shop.countries.data[country_index].price_based_shipping_rates.data[rate_index].name = $scope.rates.name;
                    }
                });
                angular.forEach($scope.shop.countries.data[country_index].weight_based_shipping_rates.data, function (rate, rate_index) {
                    if (rate.selected === true) {
                        $scope.shop.countries.data[country_index].weight_based_shipping_rates.data[rate_index].name = $scope.rates.name;
                    }
                });
            });
            $('#editRates').modal('hide');
            $scope.rates.name = '';
        }
    };

    // Handling
    $scope.handling_rates = {
        name: ''
    };

    $scope.checkedCountHandling = 0;
    $scope.checkAllHandling = function () {
        angular.forEach($scope.shop.price_based_handling_rates.data, function (rate) {
            rate.selected = $scope.shop.selectedAllHandling;
        });
        angular.forEach($scope.shop.weight_based_handling_rates.data, function (rate) {
            rate.selected = $scope.shop.selectedAllHandling;
        });
        $scope.countCheckedHandling();
    };

    $scope.countCheckedHandling = function () {
        $scope.checkedCountHandling = 0;
        angular.forEach($scope.shop.price_based_handling_rates.data, function (rate) {
            if (rate.selected === true) {
                $scope.checkedCountHandling += 1;
            }
        });
        angular.forEach($scope.shop.weight_based_handling_rates.data, function (rate) {
            if (rate.selected === true) {
                $scope.checkedCountHandling += 1;
            }
        });
    };

    $scope.checkOneHandling = function (rate_index, type) {
        if (type === 'price') {
            $scope.shop.price_based_handling_rates.data[rate_index].selected = $scope.shop.price_based_handling_rates.data[rate_index].selected !== true;
        }
        if (type === 'weight') {
            $scope.shop.weight_based_handling_rates.data[rate_index].selected = $scope.shop.weight_based_handling_rates.data[rate_index].selected !== true;
        }
        $scope.countCheckedHandling();
    };

    $scope.focusAddHandlingRate = false;
    $scope.addHandlingRate = function () {
        $scope.data = null;
        $scope.rate = {
            shop_id: $scope.shop.id,
            name: '',
            range: 'price',
            min_order_subtotal: '',
            max_order_subtotal: '',
            weight_low: '',
            weight_high: '',
            price: '',
            currency_management: 'all',
            currencies: []
        };
        $scope.focusAddHandlingRate = true;
    };

    $scope.saveHandlingRate = function () {
        $scope.data = null;
        var data = $scope.rate;
        if ($scope.rate.range === 'price') {
            $http.post('/api/price_based_handling_rates', data).then(function onSuccess(response) {
                $scope.rate.id = response.data.created_id;
                $scope.shop.price_based_handling_rates.data.push($scope.rate);
                $scope.focusRate = false;
                $("#addHandlingRate").modal('hide');
            }, function onError(response) {
                $scope.data = response.data;
            });
        } else {
            $http.post('/api/weight_based_handling_rates', data).then(function onSuccess(response) {
                $scope.rate.id = response.data.created_id;
                $scope.shop.weight_based_handling_rates.data.push($scope.rate);
                $scope.focusRate = false;
                $("#addHandlingRate").modal('hide');
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.focusEditHandlingRate = false;
    $scope.editHandlingRate = function (rate, range) {
        $scope.data = null;
        $scope.rate = rate;
        $scope.rate.range = range;
        $scope.focusEditHandlingRate = true;
    };

    $scope.updateHandlingRate = function () {
        $scope.data = null;
        var data = $scope.rate;
        if ($scope.rate.range === 'price') {
            $http.put('/api/price_based_handling_rates/' + $scope.rate.id, data).then(function () {
                $scope.focusEditHandlingRate = false;
                $("#editHandlingRate").modal('hide');
            }, function onError(response) {
                $scope.data = response.data;
            });
        } else {
            $http.put('/api/weight_based_handling_rates/' + $scope.rate.id, data).then(function () {
                $scope.focusEditHandlingRate = false;
                $("#editHandlingRate").modal('hide');
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.confirmDeleteHandlingRate = function (idx, range) {
        $scope.idx = idx;
        $scope.range = range;
    };

    $scope.deleteHandlingRate = function (idx, range) {
        if (range === 'price') {
            $http.delete('/api/price_based_handling_rates/' + $scope.shop.price_based_handling_rates.data[idx].id).then(function () {
                $scope.shop.price_based_handling_rates.data.splice(idx, 1);
                $("#deleteHandlingRate").modal('hide');
                $(".modal-backdrop").hide(500).remove();
            });
        } else {
            $http.delete('/api/weight_based_handling_rates/' + $scope.shop.weight_based_handling_rates.data[idx].id).then(function () {
                $scope.shop.weight_based_handling_rates.data.splice(idx, 1);
                $("#deleteHandlingRate").modal('hide');
                $(".modal-backdrop").hide(500).remove();
            });
        }
    };

    $scope.saveHandlingRatesName = function () {
        $scope.data = null;
        var priceIds = [];
        var weightIds = [];
        if ($scope.checkedCountHandling && $scope.handling_rates.name.length) {
            angular.forEach($scope.shop.price_based_handling_rates.data, function (rate, rate_index) {
                if (rate.selected === true) {
                    $scope.shop.price_based_handling_rates.data[rate_index].name = $scope.handling_rates.name;
                    priceIds.push($scope.shop.price_based_handling_rates.data[rate_index].id);
                }
            });
            angular.forEach($scope.shop.weight_based_handling_rates.data, function (rate, rate_index) {
                if (rate.selected === true) {
                    $scope.shop.weight_based_handling_rates.data[rate_index].name = $scope.handling_rates.name;
                    weightIds.push($scope.shop.weight_based_handling_rates.data[rate_index].id);
                }
            });

            if (priceIds.length) {
                $http.put('/api/price_based_handling_rates', {
                    ids: priceIds,
                    name: $scope.handling_rates.name
                }).then(function () {
                    $scope.focusEditHandlingRate = false;
                    $("#editHandlingRate").modal('hide');
                }, function onError(response) {
                    $scope.data = response.data;
                });
            }

            if (weightIds.length) {
                $http.put('/api/weight_based_handling_rates', {
                    ids: weightIds,
                    name: $scope.handling_rates.name
                }).then(function () {
                    $scope.focusEditHandlingRate = false;
                    $("#editHandlingRate").modal('hide');
                }, function onError(response) {
                    $scope.data = response.data;
                });
            }

            $("#editHandlingRates").modal('hide');
            $scope.handling_rates.name = '';
        }
    };

    $scope.getCarrierServiceRateTranslation = function (value) {
        var translations = {
            'YOYO SPIN': $filter('translate')('YOYO_SPIN'),
            'YOYO YELLOW': $filter('translate')('YOYO_YELLOW'),
            'YOYO BUDGET': $filter('translate')('YOYO_BUDGET'),
            'YOYO YELLOW PARCEL SHOP': $filter('translate')('YOYO_YELLOW_PARCEL_SHOP'),
            'YOYO YELLOW PRIVAT': $filter('translate')('YOYO_YELLOW_PRIVAT'),
            'YOYO AIR': $filter('translate')('YOYO_AIR'),
            'YOYO AIR (DOOR-DOOR)': $filter('translate')('YOYO_AIR_(DOOR-DOOR)'),
            'YOYO SEA (DOOR-PIER)': $filter('translate')('YOYO_SEA_(DOOR-PIER)'),
            'YOYO SEA (DOOR-DOOR)': $filter('translate')('YOYO_SEA_(DOOR-DOOR)'),
            'YOYO AIR (DOOR-PIER)': $filter('translate')('YOYO_AIR_(DOOR-PIER)')
        };

        if (typeof translations[value] !== 'undefined') {
            value = translations[value];
        }

        return value;
    };

    $scope.checkRateCurrency = function (id) {
        var value = id + '';

        if ($scope.rate.currencies.indexOf(value) !== -1) {
            angular.forEach($scope.rate.currencies, function (val, index) {
                if (val === value) {
                    $scope.rate.currencies.splice(index, 1);
                }
            });
        } else {
            $scope.rate.currencies.push(value);
        }
    };

    $scope.pointsUpdate = {
        expiration_date: null,
        update_expiration_date: null,
    };

    $scope.pointsAction = {
        oldValue: 0,
        newValue: 0,
        expiration_date: null,
        value: ''
    };

    $scope.shopPointLogs = null;
    $scope.shopPointLogsLoading = false;

    $scope.pointGrouped = null;

    $scope.getCustomerPointsGrouped = function () {
        $scope.data = null;
        $http.get('/api/shops/' + $scope.shop.id + '/currencies/' + $scope.currency.id + '/point-totals').then(function onSuccess(response) {
            $scope.pointGrouped = response.data.data;

            $scope.pointsAction = {
                id: $scope.pointGrouped.values[0].id,
                oldValue: $scope.pointGrouped.values[0].value,
                newValue: $scope.pointGrouped.values[0].value,
                expiration_date: $scope.pointGrouped.values[0].expiration_date,
                value: ''
            };
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editCurrency = function (idx) {
        $scope.data = null;
        $scope.shopPointLogs = null;
        $scope.shopPointLogsLoading = false;
        $scope.pointGrouped = null;

        $scope.pointsAction = {
            oldValue: 0,
            newValue: 0,
            expiration_date: null,
            value: null,
        };

        $scope.currency = $scope.shop.currencies.data[idx];
        $scope.getCustomerPointsGrouped();
    };

    $scope.saveCurrency = function (idx) {
        if ($scope.currency.points_enabled === true) {
            $scope.pointsAction.newValue = parseFloat($scope.pointsAction.newValue || 0) + parseFloat($scope.pointsAction.value || 0);
            $scope.pointsAction.newValue = parseFloat(Math.max($scope.pointsAction.newValue, 0)).toFixed(4).toString();
            $scope.pointsAction.newValue = Number($scope.pointsAction.newValue);
            $scope.pointsAction.value = '';
        }

        $scope.setPoints(idx);
        $scope.shop.currencies.data[idx] = $scope.currency;
    };

    $scope.viewPointsLog = function (idx) {
        $scope.data = null;
        $scope.shopPointLogs = null;
        $scope.shopPointLogsLoading = true;
        $scope.currency = $scope.shop.currencies.data[idx];
        $http.get('/api/shops/' + $scope.shop.id + '/points/' + $scope.currency.id + '/logs').then(function onSuccess(response) {
            $scope.shopPointLogs = response.data.data;
            $scope.shopPointLogsLoading = false;
        }, function onError(response) {
            $scope.data = response.data;
            $scope.shopPointLogsLoading = false;
        });
    };

    $scope.datetimePickerConfig1 = {
        dropdownSelector: '#dropdown1',
        startView: 'year',
        minView: 'minute',
        minuteStep: 1
    };

    $scope.datetimePickerConfig2 = {
        dropdownSelector: '#dropdown2',
        startView: 'year',
        minView: 'minute',
        minuteStep: 1
    };

    $scope.datetimePickerConfigCustomerPoints = {
        dropdownSelector: '#dropdown_customer_points_expiration',
        startView: 'year',
        minView: 'day',
        modelType: 'Date'
    };

    $scope.datetimePickerConfigUpdateCustomerPoints = {
        dropdownSelector: '#dropdown_update_customer_points_expiration',
        startView: 'year',
        minView: 'day',
        modelType: 'Date'
    };

    $scope.giftUniverseDeliveryDateConfig = {
        dropdownSelector: '#giftuniverse_delivery_date',
        startView: 'year',
        minView: 'day',
        modelType: 'Y-M-D'
    };

    $scope.giftUniverseDeliveryDate = '';
    $scope.datetimeFormatted1 = '';
    $scope.datetimeFormatted2 = '';
    $scope.customerExpirationPointsDate = '';
    $scope.updateCustomerExpirationPointsDate = '';

    $scope.$watch('shop.giftuniverse_delivery_date', function (newValue) {
        if (typeof newValue !== "object" || newValue) {
            $scope.giftUniverseDeliveryDate = moment(newValue).format('D-M-Y');
            const week = moment(new Date(newValue)).isoWeek();
            if (week) {
                $scope.shop.giftuniverse_week = week;
            }
        } else {
            $scope.giftUniverseDeliveryDate = '';
        }
    }, true);

    $scope.$watch('shop.published_at', function (newValue) {
        if (typeof newValue !== "object" || newValue) {
            $scope.datetimeFormatted1 = moment(newValue).format('L LT');
        } else {
            $scope.datetimeFormatted1 = '';
        }
    }, true);

    $scope.$watch('shop.unpublished_at', function (newValue) {
        if (typeof newValue !== "object" || newValue) {
            $scope.datetimeFormatted2 = moment(newValue).format('L LT');
        } else {
            $scope.datetimeFormatted2 = '';
        }
    }, true);

    $scope.$watch('pointsUpdate.expiration_date', function (newValue) {
        if (newValue !== null) {
            $scope.customerExpirationPointsDate = moment(newValue).add(1, 'hours').format('Y-M-D');
        } else {
            $scope.customerExpirationPointsDate = '';
        }
    }, true);

    $scope.$watch('pointsUpdate.update_expiration_date', function (newValue) {
        if (newValue !== null) {
            $scope.updateCustomerExpirationPointsDate = moment(newValue).add(1, 'hours').format('Y-M-D');
        } else {
            $scope.updateCustomerExpirationPointsDate = '';
        }
    })

    // *** Promos ****

    $scope.promo = null;

    $scope.sortablePromos = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle',
        cancel: '',
        'ui-floating': false,
        stop: function () {
            var order = [];
            angular.forEach($scope.shop.promos.data, function (item) {
                order.push(item.id);
            });
            $http.post('/api/promos/order', {promoOrder: order}).then(function () {
            }, function onError() {
            });
        }
    };

    $scope.selectPromoImage = function (promo) {
        $scope.promo = promo;
    };

    $scope.updatePromoImage = function (promo) {
        $http.put('/api/promos/' + promo.id, promo).then(function () {
            $('#selectPromoImage').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.clearPromoImage = function (promo) {
        promo.image_id = null;
        $http.put('/api/promos/' + promo.id, promo).then(function () {
            $('#selectPromoImage').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.addPromo = function () {
        $scope.promo = {
            shop_id: $scope.shop.id,
            image_id: null,
            title: '',
            description: '',
            link: '',
            position: 999
        };
        $scope.focusAddPromo = true;
    };

    $scope.saveNewPromo = function () {
        var data = $scope.promo;
        $http.post('/api/promos', data).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.promo.id = response.data.created_id;
                $scope.shop.promos.data.push($scope.promo);
                $scope.focusAddPromo = false;
                $("#addPromo").modal('hide');
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editPromo = function (promo) {
        $scope.promo = promo;
        $scope.focusEditPromo = true;
    };

    $scope.confirmDeletePromo = function (idx) {
        $scope.idx = idx;
    };

    $scope.deletePromo = function (idx) {
        $http.delete('/api/promos/' + $scope.shop.promos.data[idx].id).then(function () {
            $scope.shop.promos.data.splice(idx, 1);
            $("#deletePromo").modal('hide');
        });
    };

    $scope.updatePromo = function (promo) {
        $http.put('/api/promos/' + promo.id, promo).then(function () {
            $('#editPromo').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    // *** Images ****

    $scope.getImageSrc = function (imageId) {
        if ($scope.shop.images.data.length > 0 && imageId > 0) {
            var image = $filter('filter')($scope.shop.images.data, {id: imageId}, undefined);
            if (typeof image[0] !== 'undefined' && typeof image[0].src !== 'undefined') {

                return image[0].src;
            }

            return 'https://placehold.it/80';
        }
    };

    $scope.sortableImages = {
        cursor: 'move',
        handle: '.handle'
    };

    $scope.onImageSelect = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.shop.id,
                        owner_resource: 'shop',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        var image = {
                            id: response.data.created_id,
                            content_type: file.type,
                            owner_id: $scope.shop.id,
                            owner_resource: 'shop',
                            position: 999,
                            size: file.size,
                            src: e.target.result
                        };
                        $scope.shop.images.data.push(image);
                    }
                });
            }
        });
    };

    $scope.confirmDeleteImage = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteImage = function (idx) {
        $http.delete('/api/images/' + $scope.shop.images.data[idx].id).then(function () {
            $scope.shop.images.data.splice(idx, 1);
            $("#deleteImage").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    // *** Categories ****

    $scope.toggleLoadNewest = function (idx) {
        var $data = $scope.shop.categories.data[idx].pivot;
        var $shop_id = $scope.shop.id;
        var $category_id = $scope.shop.categories.data[idx].id;
        $http.post('/api/shops/' + $shop_id + '/categories/' + $category_id + '/pivot', $data).then(function (response) {
        }, function onError(response) {
        });
    };

    $scope.addUserApprovalEmailInput = function () {
        $scope.shop.user_approval_notification_emails.push({
            'id': null,
            'name': '',
            'email': ''
        });
    };
    $scope.deleteUserApprovalEmail = function (idx) {
        const notification = $scope.shop.user_approval_notification_emails[idx];
        if (notification.id) {
            $http.delete('/api/notifications/' + notification.id).then(function () {
                $scope.shop.user_approval_notification_emails.splice(idx, 1);
            }, function onError(response) {
                $scope.data = response.data;
            });
        } else {
            $scope.shop.user_approval_notification_emails.splice(idx, 1);
        }

    };

    $scope.addNotificationEmailInput = function () {
        $scope.shop.product_idea_notification_emails.push({
            'id': null,
            'name': '',
            'email': ''
        });
    };
    $scope.deleteNotificationEmail = function (idx) {
        const notification = $scope.shop.product_idea_notification_emails[idx];
        if (notification.id) {
            $http.delete('/api/notifications/' + notification.id).then(function () {
                $scope.shop.product_idea_notification_emails.splice(idx, 1);
            }, function onError(response) {
                $scope.data = response.data;
            });
        } else {
            $scope.shop.product_idea_notification_emails.splice(idx, 1);
        }

    };
    $scope.duplicateShop = function () {
        $http.post('/api/shops-duplicate', $scope.shop).then(function onSuccess(response) {
            if (response.status === 201) {
                window.open("/#/shops/" + response.data.created_id);
            } else {
                window.location = '/';
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.checkIfGlsShippingsEnabled = function (data) {
        return data.some(function (obj) {
            return obj !== null && obj.type === "gls" &&
                obj.carrier_service_rates &&
                obj.carrier_service_rates.data.some(function (rate) {
                    return ((rate.name === "GLS Pakkeshop" || rate.name === "GLS Privat") && (rate.is_active == 1));
                });
        });
    };

    $scope.changeGls = function() {
        var added = false;

        $scope.shop.carrier_services.data.some(function (obj) {
            if (obj !== null && obj.type === "gls" &&
                obj.carrier_service_rates &&
                obj.carrier_service_rates.data.some(function (rate) {
                    return ((rate.name === "GLS Pakkeshop" || rate.name === "GLS Privat") && (rate.is_active == 1));
                })) {
                added = true;
            }
        });

        if (added) {
            $('.required-gls').prop('checked', true);
        }
    }
}]);

ctrl.controller('shopCtrl', ["$scope", "$http", "$location", "$timeout", function($scope, $http, $location, $timeout) {
    $scope.loading = true;
    $scope.refreshingShops = true;
    $scope.allResults = false;
    $scope.shops = [];
    $scope.data = null;

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.take;
    $scope.searchParams.include = 'domains';
    $scope.deleteGDPRLoading = false;

    $scope.deleteGDPRErrorText = '';

    $scope.deleteGDPRSuccess = false;

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function () {
        $scope.loading = true;
        $scope.refreshingShops = true;
        $http.get('/api/shops', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.shops = response.data.data;
            if (response.data.data.length !== $scope.shops.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingShops = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.addShop = function() {
        $scope.shop = {type: 'regular', title: '', email: '', domain: ''};
        $scope.focusAddShop = true;
    };

    $scope.deleteShopsGDPR = function() {
        $scope.deleteGDPRLoading = true;
        $http.delete('/api/destroyGDPR').then(function onSuccess(data) {
            $scope.deleteGDPRLoading = false;
            $location.path("/shops");
            $("#deleteGDPR").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        }, function onError(response) {
            $scope.deleteGDPRLoading = false;
            $scope.deleteGDPRErrorText = response.data.error.message;
        })
    };

    $scope.saveShop = function() {
        $scope.data = null;
        var data = $scope.shop;
        $http.post('/api/shops', data).then(function onSuccess(response) {
            if (response.status === 201) {
                $location.path("/shops/" + response.data.created_id);
                $("#addShop").modal('hide');
                $(".modal-backdrop").hide(500).remove();
            } else {
                window.location = '/';
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.loadMoreShops = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.loadMore = function() {
        $scope.refreshingShops = true;
        $http.get('/api/shops', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.shops.length) {
                $scope.shops = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingShops = false;
        });
    };

    $scope.onChangeShopTitle = function() {
        $scope.shop.domain = $scope.slugify($scope.shop.title, '');
    }

    $scope.slugify = function (text, slug) {
        return text.toString().toLowerCase()
            .replace(/\s+/g, slug)
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, slug)
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }
}]);

ctrl.controller('slideshowDetailCtrl', ["$scope", "$routeParams", "$location", "$http", "$timeout", "Upload", "$filter", function($scope, $routeParams, $location, $http, $timeout, Upload, $filter) {
    $scope.loading = true;
    $scope.flag = false;

    $http.get('/api/slideshows/'+$routeParams.slideshowId+'?include=images,videos,slides').then(function onSuccess(response) {
        $scope.slideshow = response.data.data;
        $scope.loading = false;
    });

    $scope.sortableImages = {
        cursor: 'move',
        handle: '.handle'
    };

    $scope.sortableSlides = {
        axis: 'y',
        cursor: 'move',
        handle: '.handle',
        cancel: '',
        'ui-floating': false,
        stop: function() {
            var order = [];
            angular.forEach($scope.slideshow.slides.data, function(item) {
                order.push(item.id);
            });
            $http.post('/api/slides/order', {slideOrder: order}).then(function () {}, function onError() {});
        }
    };

    $scope.sortableVideos = {
        cursor: 'move',
        handle: '.handle'
    };

    $scope.saveSlideshow = function(isValid) {
        if(isValid) {
            var data = $scope.slideshow;
            $http.put('/api/slideshows/'+$scope.slideshow.id, data).then(function () {
                $scope.flag = true;
                $timeout(function() {
                    $scope.flag = false;
                }, 3000);
            }, function onError(response) {
                if (response.status === 405) {
                    window.location = '/';
                }
            });
        }
    };

    $scope.deleteSlideshow = function() {
        $http.delete('/api/slideshows/'+$scope.slideshow.id).then(function () {
            $location.path("/slideshows");
            $("#deleteSlideshow").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.onFileSelect1 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.slideshow.id,
                        owner_resource: 'slideshow',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        var image = {
                            id: response.data.created_id,
                            content_type: file.type,
                            owner_id: $scope.slideshow.id,
                            owner_resource: 'slideshow',
                            position: 999,
                            size: file.size,
                            src: e.target.result
                        };
                        $scope.slideshow.images.data.push(image);
                    }
                });
            }
        });
    };

    $scope.onFileSelect2 = function ($files) {
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/videos',
                    data: {
                        owner_id: $scope.slideshow.id,
                        owner_resource: 'slideshow',
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        var video = {
                            id: response.data.created_id,
                            content_type: file.type,
                            owner_id: $scope.slideshow.id,
                            owner_resource: 'slideshow',
                            position: 999,
                            size: file.size,
                            src: e.target.result
                        };
                        $scope.slideshow.videos.data.push(video);
                    }
                });
            }
        });
    };

    $scope.confirmDeleteImage = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteImage = function (idx) {
        $http.delete('/api/images/' + $scope.slideshow.images.data[idx].id).then(function () {
            $scope.slideshow.images.data.splice(idx, 1);
            $("#deleteImage").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.confirmDeleteVideo = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteVideo = function (idx) {
        $http.delete('/api/videos/' + $scope.slideshow.videos.data[idx].id).then(function () {
            $scope.slideshow.videos.data.splice(idx, 1);
            $("#deleteVideo").modal('hide');
            $(".modal-backdrop").hide(500).remove();
        });
    };

    $scope.addSlide = function () {
        $scope.slide = {
            slideshow_id: $scope.slideshow.id,
            image_id: null,
            video_id: null,
            link: '',
            text: '',
            position: 999
        };
        $scope.focusAddSlide = true;
    };

    $scope.saveNewSlide = function () {
        var data = $scope.slide;
        $http.post('/api/slides', data).then(function onSuccess(response) {
            if (response.status === 201) {
                $scope.slide.id = response.data.created_id;
                $scope.slideshow.slides.data.push($scope.slide);
                $scope.focusAddSlide = false;
                $("#addSlide").modal('hide');
            }
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editSlide = function (slide) {
        $scope.slide = slide;
        $scope.focusEditSlide = true;
    };

    $scope.confirmDeleteSlide = function (idx) {
        $scope.idx = idx;
    };

    $scope.deleteSlide = function (idx) {
        $http.delete('/api/slides/' + $scope.slideshow.slides.data[idx].id).then(function () {
            $scope.slideshow.slides.data.splice(idx, 1);
            $("#deleteSlide").modal('hide');
        });
    };

    $scope.selectSlideImage = function(slide) {
        $scope.slide = slide;
    };

    $scope.updateSlideImage = function(slide) {
        $http.put('/api/slides/'+slide.id, slide).then(function () {
            $('#selectSlideImage').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.clearSlideImage = function(slide) {
        slide.image_id = null;
        $http.put('/api/slides/'+slide.id, slide).then(function () {
            $('#selectSlideImage').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.selectSlideVideo = function(slide) {
        $scope.slide = slide;
    };

    $scope.updateSlideVideo = function(slide) {
        $http.put('/api/slides/'+slide.id, slide).then(function () {
            $('#selectSlideVideo').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.clearSlideVideo = function(slide) {
        slide.video_id = null;
        $http.put('/api/slides/'+slide.id, slide).then(function () {
            $('#selectSlideVideo').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.getImageSrc = function(imageId) {
        if ($scope.slideshow.images.data.length > 0 && imageId > 0) {
            var image = $filter('filter')($scope.slideshow.images.data, {id: imageId}, undefined);
            if (typeof image[0] !== 'undefined' && typeof image[0].src !== 'undefined') {

                return image[0].src;
            }

            return 'https://placehold.it/80';
        }
    };

    $scope.getVideoSrc = function(videoId) {
        if ($scope.slideshow.videos.data.length > 0 && videoId > 0) {
            var video = $filter('filter')($scope.slideshow.videos.data, {id: videoId}, undefined);
            if (typeof video[0] !== 'undefined' && typeof video[0].src !== 'undefined') {

                return video[0].src;
            }

            return 'https://placehold.it/80';
        }
    };

    $scope.editSlide = function(slide) {
        $scope.slide = slide;
        $scope.focusEditSlide = true;
    };

    $scope.updateSlide = function(slide) {
        $http.put('/api/slides/'+slide.id, slide).then(function () {
            $('#editSlide').modal('hide');
        }, function onError(response) {
            $scope.data = response.data;
        });
    };
}]);

ctrl.controller('slideshowCtrl', ["$scope", "$http", "$location", "$timeout", function ($scope, $http, $location, $timeout) {
    $scope.loading = false;
    $scope.searchParams = $location.search();

    var tempQueryString = '', queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            tempQueryString = newValue;
            queryTimeout = $timeout(function () {
                $scope.searchParams.query = tempQueryString;
                $location.search($scope.searchParams);
                $scope.change();
            }, 1500);
        }
    }, true);

    $scope.change = function () {
        $scope.loading = true;
        $http.get('/api/slideshows', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.slideshows = response.data.data;
            $scope.loading = false;
        });
    };

    $scope.change();

    $scope.addSlideshow = function () {
        $scope.slideshow = {name: ''};
        $scope.focusSlideshow = true;
    };

    $scope.saveSlideshow = function () {
        var data = $scope.slideshow;
        $http.post('/api/slideshows', data).then(function onSuccess(response) {
            if (response.status === 201) {
                $location.path("/slideshows/" + response.data.created_id);
                $("#addSlideshow").modal('hide');
                $(".modal-backdrop").hide(500).remove();
                $scope.focusSlideshow = false;
            }
        }, function onError(response) {
            if (response.status === 422) {
                $scope.errors = response.data.error.validation_errors;
            }
        });
    };

}]);

ctrl.controller('tagCtrl', ["$scope", "$http", "$timeout", "$location", function ($scope, $http, $timeout, $location) {
    $scope.flagMessage = null;
    $scope.loading = true;
    $scope.refreshingTags = true;
    $scope.allResults = false;
    $scope.tags = [];
    $scope.data = null;

    $scope.tagAsync = {
        value: ''
    };

    $scope.tag = {
        id: 0,
        name: '',
        is_master: false,
        product_count: 0,
        parent: null,
        parent_name: ''
    };

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = ($scope.searchParams.take % 15 !== 0) ? 15 : $scope.searchParams.take;

    $scope.orderBy = 'name';
    $scope.searchParams.order_by =  $scope.orderBy;

    $scope.toggleMaster = function (idx) {
        $scope.data = null;
        $scope.tags[idx].is_master = !$scope.tags[idx].is_master;
        var data = $scope.tags[idx];
        $http.put('/api/tags/' + $scope.tags[idx].id, data).then(function onSuccess(response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteTag = function (idx) {
        $scope.idx = idx;
        $scope.tag = {
            id: 0,
            name: '',
            is_master: false,
            product_count: 0,
            parent: null,
            parent_name: ''
        };
    };

    $scope.deleteTag = function (idx) {
        $http.delete('/api/tags/' + $scope.tags[idx].id).then(function (response) {
            $scope.tags.splice(idx, 1);
            $('#deleteTag').modal('hide');
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
        });
    };

    $scope.confirmMergeTag = function (idx) {
        $scope.idx = idx;
        $scope.tag = {
            id: 0,
            name: '',
            is_master: false,
            product_count: 0,
            parent: null,
            parent_name: ''
        };
    };

    $scope.mergeTag = function (idx) {
        $scope.data = null;
        var data = [];
        $http.put('/api/tags/' + $scope.tags[idx].id + '/merge/' + $scope.tag.id, data).then(function onSuccess(response) {
            $scope.change();
            $('#mergeTag').modal('hide');
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.setTag = function (item) {
        $scope.tag = item;
    };

    $scope.loadingTags = false;

    $scope.getTags = function (val) {
        $scope.loadingTags = true;

        return $http.get('/api/tags', {
            params: {
                query: val,
                order_by: $scope.orderBy
            }
        }).then(function (res) {
            var results = [];
            angular.forEach(res.data.data, function (item) {
                results.push(item);
            });
            $scope.loadingTags = false;

            return results;
        });
    };

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (!initialize) {
            if (newValue !== oldValue) {
                if (queryTimeout) $timeout.cancel(queryTimeout);
                tempQueryString = newValue;
                queryTimeout = $timeout(function () {
                    $scope.searchParams.query = tempQueryString;
                    $location.search($scope.searchParams);
                    $scope.change();
                }, 1000);
            }
        }
    }, true);

    $scope.change = function () {
        $scope.loading = true;
        $scope.refreshingTags = true;
        $http.get('/api/tags', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.tags = response.data.data;
            if (response.data.data.length !== $scope.tags.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingTags = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.loadMore = function () {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.refreshingTags = true;
        $http.get('/api/tags', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.tags.length) {
                $scope.tags = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingTags = false;
        });
    };

    $scope.loadingProductList = false;
    $scope.products = [];
    $scope.showRelatedProducts = function(idx) {
        $scope.tag = $scope.tags[idx];
        $scope.loadingProductList = true;
        $scope.products = [];
        $http.get('/api/tags/' + $scope.tag.id + '/products?include=variants').then(function(response) {
            $scope.products = response.data.data;
            $scope.loadingProductList = false;
        });
    };

    $scope.goToProduct = function(product_id) {
        $('#tagModalShowProductList').modal('hide');
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        $location.path('/products/' + product_id);
    };

    $scope.focusEditTag = false;
    $scope.editTag = function(idx) {
        $scope.tag = angular.copy($scope.tags[idx]);
        $scope.focusEditTag = true;
        $scope.tagAsync.value = $scope.tag.parent_name;
    };

    $scope.updateTag = function(tag) {
        $scope.focusEditTag = false;
        $scope.data = [];
        $http.put('/api/tags/' + tag.id, tag).then(function onSuccess(response) {
            $scope.flagMessage = response.data.message;
            $timeout(function () {
                $scope.flagMessage = null;
            }, 3000);
            $('#editTag').modal('hide');
            $('.modal-backdrop').hide(500).remove();
            $scope.change();
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.getParentTags = function (val) {
        return $http.get('/api/tags', {
            params: {
                query: val,
                order_by: $scope.orderBy,
                exclude: $scope.tag.id
            }
        }).then(function (res) {
            var results = [];
            angular.forEach(res.data.data, function (item) {
                results.push(item);
            });
            return results;
        });
    };

    $scope.setParentTag = function(item) {
        $scope.tag.parent = item.id;
        $scope.tag.parent_name = item.name;
    };

    $scope.cleanParentTag = function() {
        $scope.tag.parent = null;
        $scope.tagAsync.value = null;
    };

}]);

ctrl.controller('themeCtrl', [function() {

}])
ctrl.controller('translationCtrl', ["$scope", "$http", "$timeout", function ($scope, $http, $timeout) {
    $scope.loading = false;
    $scope.flagCreated = false;
    $scope.flagUpdated = false;
    $scope.flagDeleted = false;
    $scope.data = null;

    $scope.shopsLocales = {};
    $scope.getShopsLocales = function() {
        $http.get('/api/accounts/0/shops-locales').then(function onSuccess(response) {
            $scope.shopsLocales = response.data.data;
        });
    };

    // Variants
    $scope.refreshingVariantsTranslations = true;
    $scope.allVariantsResults = false;
    $scope.variantsTranslations = {};

    $scope.takeVariant = 15;
    $scope.searchVariantParams = {};
    $scope.searchVariantParams.take = $scope.takeVariant;
    $scope.searchVariantParams.type = null;

    var initializeVariant = true;

    $scope.changeVariant = function() {
        $scope.loading = true;
        $scope.refreshingVariantsTranslations = true;
        $scope.searchVariantParams.type = 'variant';
        $http.get('/api/translations', {
            params: $scope.searchVariantParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.variantsTranslations.length) {
                $scope.variantsTranslations = response.data.data;
                $scope.allVariantsResults = false;
            }
            if (response.data.data.length < $scope.takeVariant) {
                $scope.allVariantsResults = true;
            }
            $scope.loading = false;
            $scope.refreshingVariantsTranslations = false;
            if (initializeVariant === true) {
                initializeVariant = false;
            }
        });
    };

    $scope.changeVariant();

    $scope.variantTranslation = {
        id: '',
        type: 'variant',
        translation_option: '',
        translation_locale: '',
        translation_translation: ''
    };

    $scope.addVariantTranslation = function() {
        $scope.data = null;
        $scope.variantTranslation = {
            id: '',
            type: 'variant',
            translation_option: '',
            translation_locale: '',
            translation_translation: ''
        };
        $scope.getVariantsOptions();
        $scope.getShopsLocales();
    };

    $scope.variantsOptions = {};

    $scope.getVariantsOptions = function() {
        $http.get('/api/accounts/0/variants-options').then(function onSuccess(response) {
            $scope.variantsOptions = response.data.data;
        });
    };

    $scope.saveVariantTranslation = function() {
        $scope.data = null;
        var data = $scope.variantTranslation;
        $http.post('/api/translations', data).then(function onSuccess(response) {
            $scope.flagCreated = true;
            $scope.variantTranslation.id = response.data.created_id;
            $scope.variantsTranslations.unshift($scope.variantTranslation);
            $('#addVariantTranslation').modal('hide');
            $timeout(function () {
                $scope.flagCreated = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editVariantTranslation = function(variantTranslation) {
        $scope.data = null;
        $scope.getVariantsOptions();
        $scope.getShopsLocales();
        $scope.variantTranslation = variantTranslation;
        $scope.variantTranslation.type = 'variant';
    };

    $scope.updateVariantTranslation = function (variantTranslation) {
        $scope.data = null;
        $http.put('/api/translations/' + variantTranslation.id, variantTranslation).then(function () {
            $scope.flagUpdated = true;
            $('#editVariantTranslation').modal('hide');
            $timeout(function () {
                $scope.flagUpdated = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteVariantTranslation = function(idx) {
        $scope.idx = idx;
    };

    $scope.deleteVariantTranslation = function(idx) {
        $http.delete('/api/translations/' + $scope.variantsTranslations[idx].id).then(function () {
            $scope.flagDeleted = true;
            $scope.variantsTranslations.splice(idx, 1);
            $('#deleteVariantTranslation').modal('hide');
            $timeout(function () {
                $scope.flagDeleted = false;
            }, 3000);
        });
    };

    $scope.loadMoreVariants = function() {
        $scope.takeVariant += 15;
        $scope.refreshingVariantsTranslations = true;
        $scope.searchVariantParams.type = 'variant';
        $scope.searchVariantParams.take = $scope.takeVariant;
        $http.get('/api/translations', {
            params: $scope.searchVariantParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.variantsTranslations.length) {
                $scope.variantsTranslations = response.data.data;
                $scope.allVariantsResults = false;
            }
            if (response.data.data.length < $scope.takeVariant) {
                $scope.allVariantsResults = true;
            }
            $scope.refreshingVariantsTranslations = false;
        });
    };

    // Customisations
    $scope.refreshingCustomisationsTranslations = true;
    $scope.allCustomisationsResults = false;
    $scope.customisationsTranslations = {};

    $scope.takeCustomisation = 15;
    $scope.searchCustomisationParams = {};
    $scope.searchCustomisationParams.take = $scope.takeCustomisation;
    $scope.searchCustomisationParams.type = null;

    var initializeCustomisation = true;

    $scope.changeCustomisation = function() {
        $scope.loading = true;
        $scope.refreshingCustomisationsTranslations = true;
        $scope.searchCustomisationParams.type = 'customisation';
        $http.get('/api/translations', {
            params: $scope.searchCustomisationParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.customisationsTranslations.length) {
                $scope.customisationsTranslations = response.data.data;
                $scope.allCustomisationsResults = false;
            }
            if (response.data.data.length < $scope.takeCustomisation) {
                $scope.allCustomisationsResults = true;
            }
            $scope.loading = false;
            $scope.refreshingCustomisationsTranslations = false;
            if (initializeCustomisation === true) {
                initializeCustomisation = false;
            }
        });
    };

    $scope.changeCustomisation();

    $scope.customisationTranslation = {
        id: '',
        type: 'customisation',
        translation_option: '',
        translation_locale: '',
        translation_translation: ''
    };

    $scope.addCustomisationTranslation = function() {
        $scope.data = null;
        $scope.customisationTranslation = {
            id: '',
            type: 'customisation',
            translation_option: '',
            translation_locale: '',
            translation_translation: ''
        };
        $scope.getCustomisationsOptions();
        $scope.getShopsLocales();
    };

    $scope.customisationsOptions = {};

    $scope.getCustomisationsOptions = function() {
        $http.get('/api/accounts/0/customisations-options').then(function onSuccess(response) {
            $scope.customisationsOptions = response.data.data;
        });
    };

    $scope.saveCustomisationTranslation = function() {
        $scope.data = null;
        var data = $scope.customisationTranslation;
        $http.post('/api/translations', data).then(function onSuccess(response) {
            $scope.flagCreated = true;
            $scope.customisationTranslation.id = response.data.created_id;
            $scope.customisationsTranslations.unshift($scope.customisationTranslation);
            $('#addCustomisationTranslation').modal('hide');
            $timeout(function () {
                $scope.flagCreated = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.editCustomisationTranslation = function(customisationTranslation) {
        $scope.data = null;
        $scope.getCustomisationsOptions();
        $scope.getShopsLocales();
        $scope.customisationTranslation = customisationTranslation;
        $scope.customisationTranslation.type = 'customisation';
    };

    $scope.updateCustomisationTranslation = function (customisationTranslation) {
        $scope.data = null;
        $http.put('/api/translations/' + customisationTranslation.id, customisationTranslation).then(function () {
            $scope.flagUpdated = true;
            $('#editCustomisationTranslation').modal('hide');
            $timeout(function () {
                $scope.flagUpdated = false;
            }, 3000);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

    $scope.confirmDeleteCustomisationTranslation = function(idx) {
        $scope.idx = idx;
    };

    $scope.deleteCustomisationTranslation = function(idx) {
        $http.delete('/api/translations/' + $scope.customisationsTranslations[idx].id).then(function () {
            $scope.flagDeleted = true;
            $scope.customisationsTranslations.splice(idx, 1);
            $('#deleteCustomisationTranslation').modal('hide');
            $timeout(function () {
                $scope.flagDeleted = false;
            }, 3000);
        });
    };

    $scope.loadMoreCustomisations = function() {
        $scope.takeCustomisation += 15;
        $scope.refreshingCustomisationsTranslations = true;
        $scope.searchCustomisationParams.type = 'customisation';
        $scope.searchCustomisationParams.take = $scope.takeCustomisation;
        $http.get('/api/translations', {
            params: $scope.searchCustomisationParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.customisationsTranslations.length) {
                $scope.customisationsTranslations = response.data.data;
                $scope.allCustomisationsResults = false;
            }
            if (response.data.data.length < $scope.takeCustomisation) {
                $scope.allCustomisationsResults = true;
            }
            $scope.refreshingCustomisationsTranslations = false;
        });
    };
}]);

ctrl.controller('vendorDetailCtrl', ["$scope", "$routeParams", "$location", "$http", "$timeout", "activeProfile", "$filter", "Upload", function ($scope, $routeParams, $location, $http, $timeout, activeProfile, $filter, Upload) {
    $scope.account_langs = activeProfile.account_languages.split(',');
    $scope.countActiveLangs = activeProfile.account_languages.split(',').length;
    $scope.loading = true;
    $scope.flag = false;
    $scope.data = null;

    $scope.vendor = {
        name: '',
        description: '',
        sku: '',
        redirect: '',
        handle: '',
        email: '',
        is_active: 0,
        is_archived: 0,
        note: '',
        image: {
            id: 0,
            src: null
        },
        masterImage: {
            id: 0,
            src: null
        },
        order_by_newest: false,
        robots: 'index,follow',
        metafields: {
            data: []
        },
        is_master: false
    };

    $scope.titleKey = -1;
    $scope.descriptionKey = -1;
    $scope.specificationsKey = -1;

    $http.get('/api/vendors/' + $routeParams.vendorId + '?include=metafields,products,products.images').then(function onSuccess(response) {
        $scope.vendor = response.data.data;
        $scope.vendor.translations = {};

        var titleObject = $filter('filter')($scope.vendor.metafields.data, {key: 'title', namespace: null}, undefined);
        $scope.titleKey = $scope.vendor.metafields.data.indexOf(titleObject[0]);
        var descriptionObject = $filter('filter')($scope.vendor.metafields.data, {
            key: 'description',
            namespace: null
        }, undefined);
        $scope.descriptionKey = $scope.vendor.metafields.data.indexOf(descriptionObject[0]);
        var specificationsObject = $filter('filter')($scope.vendor.metafields.data, {
            key: 'specifications',
            namespace: null
        }, undefined);
        $scope.specificationsKey = $scope.vendor.metafields.data.indexOf(specificationsObject[0]);

        if (activeProfile.account_languages.split(',').length > 1) {
            angular.forEach($scope.account_langs, function (langCode) {
                var title = $filter('filter')($scope.vendor.metafields.data, {
                    key: "title",
                    namespace: langCode
                }, undefined)[0];
                var description = $filter('filter')($scope.vendor.metafields.data, {
                    key: "description",
                    namespace: langCode
                }, undefined)[0];
                var specifications = $filter('filter')($scope.vendor.metafields.data, {
                    key: "specifications",
                    namespace: langCode
                }, undefined)[0];
                title = (title !== undefined) ? title.value : '';
                description = (description !== undefined) ? description.value : '';
                specifications = (specifications !== undefined) ? specifications.value : '';
                $scope.vendor.translations[langCode] = {
                    title: title,
                    description: description,
                    specifications: specifications
                };
            });
        }
        $scope.getImage();
        $scope.getMasterImage();
        $scope.loading = false;
    });

    $scope.saveVendor = function (isValid) {
        if (isValid) {
            $scope.data = null;
            var data = $scope.vendor;
            $http.put('/api/vendors/' + $scope.vendor.id, data).then(function () {
                $scope.flag = true;
                $timeout(function () {
                    $scope.flag = false;
                }, 3000);
            }, function onError(response) {
                $scope.data = response.data;
            });
        }
    };

    $scope.deleteVendor = function () {
        $http.delete('/api/vendors/' + $scope.vendor.id).then(function () {
            $('#deleteVendor').modal('hide');
            setTimeout(function () {
                $location.path('/vendors');
            }, 300);
        });
    };

    $scope.getImage = function () {
        $http.get('/api/vendors/' + $routeParams.vendorId + '/image').then(function onSuccess(response) {
            $scope.vendor.image = response.data.data;
        });
    };

    $scope.getMasterImage = function () {
        $http.get('/api/vendors/' + $routeParams.vendorId + '/master-image').then(function onSuccess(response) {
            $scope.vendor.masterImage = response.data.data;
        });
    };

    $scope.imageUploading = false;
    $scope.onFileSelect = function ($files, image_key) {
        $scope.imageUploading = true;
        angular.forEach($files, function (file) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                Upload.http({
                    url: '/api/images',
                    data: {
                        owner_id: $scope.vendor.id,
                        owner_resource: 'vendor',
                        image_key: image_key,
                        fileName: file.name,
                        fileSize: file.size,
                        fileType: file.type,
                        file: e.target.result.match(/,(.*)$/)[1]
                    }
                }).then(function (response) {
                    if (response.status === 201) {
                        $scope.imageUploading = false;

                        var $object = {
                            id: response.data.created_id,
                            content_type: file.type,
                            owner_id: $scope.vendor.id,
                            owner_resource: 'vendor',
                            position: 999,
                            size: file.size,
                            src: e.target.result
                        };

                        if (!image_key) {
                            $scope.vendor.image = $object;
                        } else if (image_key === 'master') {
                            $scope.vendor.masterImage = $object;
                        }
                    }
                });
            }
        });
    };

    $scope.deleteImage = function () {
        $scope.imageUploading = false;
        $http.delete('/api/images/' + $scope.vendor.image.id).then(function () {
            $scope.vendor.image = null;
        });
    };

    $scope.deleteMasterImage = function () {
        $scope.imageUploading = false;
        $http.delete('/api/images/' + $scope.vendor.masterImage.id).then(function () {
            $scope.vendor.masterImage = null;
        });
    };

}]);

ctrl.controller('vendorCtrl', ["$scope", "$http", "$location", "$timeout", function ($scope, $http, $location, $timeout) {
    $scope.loading = false;
    $scope.refreshingVendors = true;
    $scope.allResults = false;
    $scope.vendors = null;
    $scope.data = null;

    $scope.take = 15;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = ($scope.searchParams.take % 15 !== 0) ? 15 : $scope.searchParams.take;

    var initialize = true;

    var tempQueryString = '';
    var queryTimeout;
    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (queryTimeout) $timeout.cancel(queryTimeout);
            tempQueryString = newValue;
            queryTimeout = $timeout(function () {
                $scope.searchParams.query = tempQueryString;
                $location.search($scope.searchParams);
                $scope.change();
            }, 1500);
        }
    }, true);

    $scope.change = function() {
        $scope.loading = true;
        $scope.refreshingVendors = true;
        $http.get('/api/vendors', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.vendors = response.data.data;
            if (response.data.data.length !== $scope.vendors.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingVendors = false;
            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.loadMore = function() {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.refreshingVendors = true;
        $http.get('/api/vendors', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            if (response.data.data.length !== $scope.vendors.length) {
                $scope.vendors = response.data.data;
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.refreshingVendors = false;
        });
    };

    $scope.vendor = {
        name: '',
        handle: ''
    };

    $scope.addVendor = function () {
        $scope.vendor = {
            name: '',
            handle: ''
        };
        $scope.focusVendor = true;
        $scope.updateHandle = function (name) {
            $scope.vendor.handle = getSlug(name);
        }
    };

    $scope.saveVendor = function () {
        $scope.data = null;
        var data = $scope.vendor;
        $http.post('/api/vendors', data).then(function onSuccess(response) {
            $("#addVendor").modal('hide');
            $scope.focusVendor = false;
            setTimeout(function() {
                $location.path("/vendors/" + response.data.created_id);
            }, 300);
        }, function onError(response) {
            $scope.data = response.data;
        });
    };

}]);

ctrl.controller('shopsCtrl', ["$scope", "$http", "$location", "$timeout", "activeProfile", "$filter", "$window", function ($scope, $http, $location, $timeout, activeProfile, $filter, $window) {
    $scope.loading = true;
    $scope.refreshingShops = true;
    $scope.allResults = false;
    $scope.data = null;
    $scope.shops = [];
    $scope.allShops = [];
    $scope.take = 10000;
    $scope.searchParams = $location.search();
    $scope.searchParams.take = $scope.take;
    $scope.selectedShop = $scope.searchParams.shop_id;
    var initialize = true;

    // $scope.$watch('searchParams.shop_id', function (value) {
    //     if (!initialize) {
    //         $scope.selectedAll = false;
    //         $scope.showActions = false;
    //         $scope.checkedCount = 0;
    //
    //         $scope.searchParams.shop_id = value;
    //         $location.search($scope.searchParams);
    //         $scope.change();
    //     }
    // }, true);


    // var tempQueryString = '';
    // var queryTimeout;
    // $scope.$watch('searchParams.query', function (newValue, oldValue) {
    //     if (!initialize) {
    //         if (newValue !== oldValue) {
    //             if (queryTimeout) $timeout.cancel(queryTimeout);
    //             tempQueryString = newValue;
    //             queryTimeout = $timeout(function () {
    //                 $scope.searchParams.query = tempQueryString;
    //                 $location.search($scope.searchParams);
    //                 $scope.change();
    //             }, 1000);
    //         }
    //     }
    // }, true);
    $http.get('/api/giftuniverse/shops', {
        params: {'showAll': true}
    }).then(function onSuccess(response) {
        $scope.allShops = response.data.data;
    });
    $scope.change = function () {
        $scope.loading = true;
        $scope.refreshingShops = true;
        $http.get('/api/giftuniverse/shops', {
            params: $scope.searchParams
        }).then(function onSuccess(response) {
            $scope.shops = response.data.data;
            if (response.data.data.length !== $scope.shops.length) {
                $scope.allResults = false;
            }
            if (response.data.data.length < $scope.take) {
                $scope.allResults = true;
            }
            $scope.loading = false;
            $scope.refreshingShops = false;

            //filter
            $scope.totalAntal = $scope.shops.length;
            $scope.totalTurnover = $scope.shops.reduce(function (acc, obj) {
                return acc + obj.turnover;
            }, 0);
            $scope.totalGift = $scope.shops.reduce(function (acc, obj) {
                return acc + obj.product_shop;
            }, 0);

            if (initialize === true) {
                initialize = false;
            }
        });
    };

    $location.search($scope.searchParams);
    $scope.change();

    $scope.loadMoreShops = function () {
        $scope.take += 15;
        $scope.searchParams.take = $scope.take;
        $location.search($scope.searchParams);
        $scope.loadMore();
    };

    $scope.shop = null;
    $scope.getShop = function (shop_id) {
        $http.get('/api/shops/' + shop_id).then(function onSuccess(response) {
            $scope.shop = response.data.data;
        });
    };

    $scope.datetimePickerConfig1 = {
        dropdownSelector: '#dropdown1',
        startView: 'year',
        minView: 'day'
    };
    $scope.filterDate = {
        date_from: localStorage.getItem('datetimeFormatted1') !== null ? localStorage.getItem('datetimeFormatted1') : null,
        date_to: localStorage.getItem('datetimeFormatted2') !== null ? localStorage.getItem('datetimeFormatted2') : null
    };
    $scope.$watch('filterDate.date_from', function (newValue) {
        // if (newValue !== oldValue) {
        if (typeof newValue !== "object" || newValue) {
            localStorage.setItem('datetimeFormatted1', newValue);
            $scope.searchParams.datetimeFormatted1 = moment(newValue).format('L');
            //$scope.change();
        } else {
            $scope.searchParams.datetimeFormatted1 = '';
        }
        //  }
    }, true);
    $scope.$watch('filterDate.date_to', function (newValue) {
        //if (newValue !== oldValue) {
        if (typeof newValue !== "object" || newValue) {
            localStorage.setItem('datetimeFormatted2', newValue);
            $scope.searchParams.datetimeFormatted2 = moment(newValue).format('L');
            $scope.change();
        } else {
            $scope.searchParams.datetimeFormatted2 = '';
        }
        // }
    }, true);

    if (localStorage.getItem('filterType') !== null) {
        if (localStorage.getItem('filterType') == '2') {
            $scope.filterType = {id: 2, title: 'Luk'};
        } else if (localStorage.getItem('filterType') == '3') {
            $scope.filterType = {id: 3, title: 'Modtagelse'};
        } else if (localStorage.getItem('filterType') == '1') {
            $scope.filterType = {id: 1, title: 'Åben'};
        }
    } else {
        $scope.filterType = {id: 1, title: 'Åben'};
    }

    $scope.$watch('filterType', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.searchParams.filterType = newValue.id;
            localStorage.setItem('filterType', newValue.id);
            $scope.change();
        }
    }, true);

    $scope.searchParams.filterType = localStorage.getItem('filterType') !== null ? localStorage.getItem('filterType') : 1;

    $scope.searchParams.datetimeFormatted1 = localStorage.getItem('datetimeFormatted1') !== null ? moment(localStorage.getItem('datetimeFormatted1')).format('L') : null;
    $scope.searchParams.datetimeFormatted2 = localStorage.getItem('datetimeFormatted2') !== null ? moment(localStorage.getItem('datetimeFormatted2')).format('L') : null;

    $scope.typeFilters = [
        {id: 1, title: 'Åben'},
        {id: 2, title: 'Luk'},
        {id: 3, title: 'Modtagelse'},
    ];

    $scope.excludedShops = {};
    $scope.excludedShops.items = localStorage.getItem('excludedShops') !== null ? JSON.parse(localStorage.getItem('excludedShops')) : []
    $scope.searchParams.excludedShops = [];

    var tmpExcludedShops = '';
    var tmpExcludedShopsStorage = [];
    $scope.$watch('excludedShops', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            localStorage.setItem('excludedShops', []);
            angular.forEach(newValue.items, function (item) {
                $scope.searchParams.excludedShops.push(item.id);
                var found = tmpExcludedShopsStorage.some(el => el.id === item.id);
                if (!found) {
                    tmpExcludedShopsStorage.push({id: item.id, title: item.title});
                }
            });
            angular.forEach($scope.searchParams.excludedShops, function (item) {
                tmpExcludedShops += item + ','
            });
            localStorage.setItem('excludedShops', JSON.stringify(tmpExcludedShopsStorage));
            $scope.searchParams.excludedShopsString = tmpExcludedShops;

            $scope.change();
        }
    }, true);


    $scope.sortBy = function (propertyName) {
        $scope.reverse = ($scope.propertyName === propertyName) ? !$scope.reverse : false;
        $scope.propertyName = propertyName;
        $scope.order();
    };

    $scope.setShopAction = function (shopId, action) {
        $http.post('/api/giftuniverse/shops/store-user-action', {
            params: {shopId: shopId, action: action}
        }).then(function onSuccess(response) {
            //$scope.change();
        });
    };

    $scope.order = function () {
        $scope.shops = $filter("orderBy")($scope.shops, $scope.propertyName, $scope.reverse);
    };
}]);

ctrl.controller('settingsCtrl', ["$scope", "$routeParams", "$http", "$cookieStore", "$location", "$timeout", "$window", function ($scope, $routeParams, $http, $cookieStore, $location, $timeout, $window) {
    $scope.loading = true;
    $scope.flag = false;
    $scope.error = false;
    $scope.fields = {
        settings: {},
        shippingIntervals: []
    };

    $http.get('/api/gaveuniverset/settings').then(function onSuccess(response) {
        $scope.fields = response.data.fields;

        $scope.fields.settings.allow_adjustment_up = $scope.fields.settings.allow_adjustment_up === "1" ? true: false;
        $scope.fields.settings.allow_adjustment_down = $scope.fields.settings.allow_adjustment_down === "1" ? true: false;
        $scope.fields.settings.use_fixed_price_for_business_delivery = $scope.fields.settings.use_fixed_price_for_business_delivery === "1" ? true: false;

        $scope.loading = false;
    }, function onError(response) {
        $scope.error = true;
        $timeout(function () {
            $scope.error = false;
        }, 3000);
    });

    $scope.saveSettings = () => {
        $http.put('/api/gaveuniverset/settings', {fields: $scope.fields}).then(function () {
            $scope.flag = true;
            $timeout(function () {
                $scope.flag = false;
            }, 3000);
        }, function onError(response) {
            $scope.error = true;
            $timeout(function () {
                $scope.error = false;
            }, 3000);
        });
    }
}]);
ctrl.controller('userDetailsCtrl', ["$scope", "$routeParams", "$http", "$cookieStore", "$location", "$timeout", "$filter", function ($scope, $routeParams, $http, $cookieStore, $location, $timeout, $filter) {
    $scope.customer = null;
    $scope.loading = true;
    $scope.fetchCustomer = function() {
        $http.get('/api/giftuniverse/users/' + $routeParams.userId).then(function onSuccess(response) {
            $scope.customer = response.data.data;
            $scope.loading = false;
        });
    };
    $scope.fetchCustomer();

    $scope.translateShopType = function (type) {
        return $filter('translate')(type.toUpperCase());
    }
}]);
ctrl.controller('usersCtrl', ["$scope", "$routeParams", "$http", "$cookieStore", "$location", "$timeout", "$window", function ($scope, $routeParams, $http, $cookieStore, $location, $timeout, $window) {
    $scope.customers = null;
    $scope.filteredCustomers = null;
    $scope.flag = false;
    $scope.loading = true;
    $scope.fetchCustomers = function() {
        $http.get('/api/giftuniverse/users').then(function onSuccess(response) {
            $scope.customers = response.data.data;
            $scope.filteredCustomers = $scope.customers;
            $scope.loading = false;
        });
    };
    $scope.fetchCustomers();

    $scope.resetDefaultPasswordOnGaveuniverset = function (user) {
        $http.put('api/customers/' + user + '/reset-password').then(function onSuccess(response) {
            if(response.status === 200) {
                $scope.flag = true;
                $timeout(function () {
                    $scope.flag = false;
                }, 3000);
            }
        }, function onError() {
            $scope.errorFlag = true;
            $timeout(function () {
                $scope.errorFlag = false;
            }, 3000);
        });
    }

    $scope.loginOnGaveUniverset = function (user) {
        var page = "api/customers/" + user + "/login-on-gave-universet";
        $window.open(page, '_blank');
    }

    $scope.$watch('searchParams.query', function (newValue, oldValue) {
        if (newValue != oldValue) {
            var customers = $scope.customers;
            var tempCustomers = [];
            for (var i = 0; i < customers.length; i++) {
                var customer = customers[i];
                if (customer.company_name.toLowerCase().includes(newValue.toLowerCase())) {
                    tempCustomers.push(customer);
                }
            }
            $scope.filteredCustomers = tempCustomers;
        }
    }, true);
}]);
